import React from 'react';

const CheckboxEditor = (props) => {
  return (
    <input
      type="checkbox"
      checked={props.value}
      onChange={(event) => props.setValue(event.target.checked)}
      onBlur={() => props.stopEditing()}
    />
  );
};

export default CheckboxEditor;
