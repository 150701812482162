import { TextField } from "@mui/material";
import React from "react";
import { useController } from "react-hook-form";

const GlobalInput = ({
  control,
  name,
  label,
  multiline,
  rows,
  onClick,
  onBlur,
  type,
  classform,
  maxdigit,
  oninput,

  ...rest
}) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { error, invalid, isDirty, isTouched },
  } = useController({
    name,
    control,
    defaultValue: "",
  });

  const handleInputClick = (event) => {
    if (onClick) {
      onClick(event);
    }
  };

  const handleInputBlur = (event) => {
    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <TextField
      sx={{
        width: "100%",
      }}
      size="small"
      label={label}
      error={!!error}
      multiline={multiline}
      rows={rows}
      type={type}
      className={classform}
      maxdigit={maxdigit}
      helperText={error ? error.message : ""}
      inputRef={ref}
      onClick={handleInputClick}
      onBlur={handleInputBlur}
      oninput={oninput}
      // min={min}
      inputProps={{ min: "0", ...inputProps }}
      {...rest}
    />
  );
};

export default GlobalInput;
