import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableData: {
    data: [],
    headers: [],
  },
};

const channelLabels = createSlice({
  name: "channelLabels",
  initialState,
  reducers: {
    updateChaneelLabels: (state, action) => {
      state.tableData = action.payload;
    },
    updateSingleChannelLabel: (state, action) => {
      const updatedRow = action.payload;
      const index = state.tableData.data.findIndex((data) => {
        return data?.id === updatedRow?.id;
      });
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.tableData.data[index][key] = updatedRow[key];
      });
    },
    addSingleChannelLabel: (state, action) => {
      state.tableData.data.unshift(action.payload);
    },
    removeSingleChannelLabel: (state, action) => {
      state.tableData.data = state.tableData.data.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});

export const {
  updateChaneelLabels,
  addSingleChannelLabel,
  updateSingleChannelLabel,
  removeSingleChannelLabel,
} = channelLabels.actions;
export default channelLabels.reducer;
