import React from "react";
import {
  Grid,
  Chip,
  useEffect,
  useState,
  Box,
  moment,
  PageHeader,
  useNavigate,
  useLocation,
  AddOutlined,
  ArrowBack,
  AddIcon,
} from "../../Collections/Imports";
import Filters from "../../Components/UIComponents/Filters";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import { useDispatch, useSelector } from "react-redux";
import { allPubData, allPubList } from "../../Store/PublisherModule";
import AgGridComponent from "../../Components/CommonComponents/AG-Grid";
import PublisherFilters from "../../Forms/PublisherFilters";
import AddEditChannel from "../../Forms/AddEditChannel";
import {
  channelLabelModuleAPiEndPoints,
  channelModuleApiEndPoints,
  publisherModuleApiEndPoints,
} from "../../Config/APIsEndpoints";
import { updateChaneelLabels } from "../../Store/ChannelLabels";
import { updateChannelFilterData } from "../../Store/ChannelModule/filter";
import { getData, getChannelListData } from "../../Store/ChannelModule";
import ChannelFilters from "../../Forms/ChannelFilters";
import { Outlet } from "react-router-dom";

function ChannelListing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const [rowSelected, setRowSelected] = useState({});
  const [componentIntialization, setComponentIntialization] = useState(false);
  const [defaultChannel, setDefaultChannel] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isOpenAddEditBox, setIsOpenAddEditBox] = useState(false);
  const [channelName, setChannelName] = useState("");

  const [isOpenChannelFilter, setIsOpenChannelFilter] = useState(false);
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [itemPerIndex, setItemPerIndex] = useState(100);
  const [GetPublisherList, PublisherList] = useApiCallMutation();
  const [GetChannelList, ChannelList] = useApiCallMutation();
  const [channelLabelOptions, setChannelLabelOptions] = useState([]);
  const [channelOptions, setChannelOptions] = useState([]);
  const channelData = useSelector(
    (state) => state.ChannelData?.data?.channelData
  );
  const TableData = useSelector(
    (state) => state.publisherData?.data?.allPubData
  );
  const pubOptions = useSelector(
    (state) => state.publisherData?.data?.allPublisherList
  );
  const channelLabels = useGetApiCallQuery({
    endPoint: channelLabelModuleAPiEndPoints.getChannelLabels,
    method: "GET",
  });
  const perPageCountOptions = [
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "1000", value: 1000 },
  ];
  const statusOptions = [
    { label: "Active", value: "true" },
    { label: "Disabled", value: "false" },
  ];

  const previousFilterData = useSelector(
    (state) => state?.channelFilterSession?.filterData
  );
  console.log("previousFilterData", previousFilterData);
  const [selectedDateFilter, setSelectedDateFilter] = useState({
    startDate: new Date("July 01, 2020"),
    endDate: new Date(),
    key: "selection",
  });
  const toggleDrawer = (ele) => {
    ele === "created"
      ? setDefaultChannel("created")
      : ele === "channel"
      ? setDefaultChannel("channel")
      : ele === "publisher"
      ? setDefaultChannel("publisher")
      : ele === "label"
      ? setDefaultChannel("label")
      : ele === "status"
      ? setDefaultChannel("status")
      : setDefaultChannel(false);

    setIsOpenChannelFilter((prev) => !prev);
  };

  // get Preselected Filter label
  const getFilterLabel = (filterType, options) => {
    console.log(filterType, options);
    if (options?.length === 1) {
      return (
        <span>
          <Chip label={options[0]?.label} sx={{ m: "2px" }} size="small" />
        </span>
      );
    } else if (options?.length > 1) {
      return (
        <>
          <span>
            <Chip label={options[0]?.label} sx={{ m: "2px" }} size="small" />
          </span>
          <span
            style={{ cursor: "pointer", color: "#000", paddingLeft: "4px" }}
          >
            {`+${previousFilterData[filterType]?.length - 1}`}
          </span>
        </>
      );
    } else {
      return (
        <span>
          <Chip label={"All"} sx={{ m: "2px" }} size="small" />
        </span>
      );
    }
  };

  //set Preselected Filter label
  const setFilterLabel = (filterType) => {
    switch (filterType) {
      case "publisherNames":
        const puboptions = pubOptions?.filter((opt) =>
          previousFilterData?.publisherNames?.find((val) => val == opt.value)
        );
        return getFilterLabel("publisherNames", puboptions);

        break;
      case "status":
        const statusoptions = statusOptions?.filter(
          (opt) => previousFilterData?.status == opt.value
        );
        return getFilterLabel("status", statusoptions);
        break;
      case "channelLabels":
        const labeloptions = channelLabelOptions?.filter((opt) =>
          previousFilterData?.channelLabels?.find((val) => val == opt.value)
        );
        return getFilterLabel("channelLabels", labeloptions);
        break;
      case "channelNames":
        const channeloptions = channelOptions?.filter((opt) =>
          previousFilterData?.channelNames?.find((val) => val == opt.value)
        );
        return getFilterLabel("channelNames", channeloptions);
        break;
    }
  };

  const handleActions = (previousData, action) => {
    switch (action) {
      case "EDIT":
        setRowSelected(previousData);
        setIsEditMode(true);
        setIsOpenAddEditBox(true);
        break;
      case "VIEW":
        setRowSelected(previousData);
        sessionStorage.setItem(
          "currentChannelData",
          JSON.stringify(previousData)
        );
        const currentChannelData = JSON.parse(
          sessionStorage.getItem("currentChannelData")
        );
        setChannelName(currentChannelData.channelName);
        navigate(String(previousData?.channelUniqueId));
        break;
    }
  };
  const handlePageChange = (data) => {
    setPaginationIndex(data);
  };
  const handleRowsPerPageChange = (data) => {
    setItemPerIndex(data);
    setPaginationIndex(0);
  };
  const Datapub = {
    index: paginationIndex,
    itemPerIndex: itemPerIndex,
    fromDate: moment(previousFilterData?.fromDate).toISOString(),
    toDate:
      moment(previousFilterData?.toDate).toISOString() == ""
        ? moment(selectedDateFilter?.endDate).toISOString()
        : moment(previousFilterData?.toDate).toISOString(),
    publisherNames: previousFilterData?.publisherNames || [],
    channelNames: previousFilterData?.channelNames || [],
    channelLabels: previousFilterData?.channelLabels || [],
    status: previousFilterData?.status || "all",
  };

  useEffect(() => {
    GetPublisherList({
      endPoint: publisherModuleApiEndPoints.getAllPubs,
      method: "POST",
      data: {
        data: {
          index: 0,
          itemPerIndex: 2000,
          fromDate: moment(new Date("July 01, 2020")).toISOString(),
          toDate: moment(new Date()).toISOString(),
          publisherNames: [],
          emails: [],
        },
      },
    });
  }, []);
  useEffect(() => {
    if (PublisherList?.isSuccess && PublisherList?.data?.data?.publishers) {
      dispatch(allPubData(PublisherList?.data?.data));
      const pubDropdownList = PublisherList?.data?.data?.publishers?.map(
        (publisher) => {
          return {
            label: publisher?.publisherName,
            value: publisher?.publisherName,
          };
        }
      );
      dispatch(allPubList(pubDropdownList));
    }
  }, [PublisherList]);
  useEffect(() => {
    if (channelLabels.isSuccess) {
      setChannelLabelOptions(
        channelLabels.data.data.channelLabel.map(({ id, name }) => ({
          value: name,
          label: name,
          id: id,
        }))
      );
      dispatch(updateChaneelLabels(channelLabels.data.data));
    }
  }, [channelLabels]);

  useEffect(() => {
    GetChannelList({
      endPoint: channelModuleApiEndPoints.getAllChannels,
      method: "POST",
      data: {
        data: { ...Datapub },
      },
    });
  }, [previousFilterData]);

  useEffect(() => {
    dispatch(updateChannelFilterData(Datapub));
    setComponentIntialization(true);
  }, []);
  useEffect(() => {
    if (ChannelList?.isSuccess && ChannelList?.data?.data?.Channels) {
      dispatch(getData(ChannelList?.data?.data));
      const channelList = ChannelList.data.data.Channels.map(
        ({ id, channelName, channelUniqueId }) => ({
          value: channelName,
          label: channelName,
          id: channelUniqueId,
        })
      );
      if (componentIntialization == true) {
        dispatch(getChannelListData(channelList));
        setChannelOptions(channelList);
      }
      setComponentIntialization(false);
    }
  }, [ChannelList]);

  const ClearFilters = () => {
    const filterData = {
      status: "all",
      publisherNames: ["all"],
      channelNames: ["all"],
      channelLabels: ["all"],
      fromDate: moment(new Date("July 01, 2020")).toISOString(),
      toDate: moment().endOf("day").toDate(),
    };

    dispatch(updateChannelFilterData(filterData));
    // reset();
  };

  useEffect(() => {
    if (location?.pathname === "/channel") {
      setChannelName("");
    } else {
      const currentChannelData = JSON.parse(
        sessionStorage.getItem("currentChannelData")
      );
      setChannelName(currentChannelData.channelName);
    }
  }, [location]);
  return (
    <>
      {/* Page Header Start */}
      <PageHeader
        title={channelName || "Channels"}
        backIcon={
          location?.pathname === "/channel" ? null : (
            <ArrowBack sx={{ color: "#0c247b" }} />
          )
        }
        onBack={() => navigate(-1)}
        helpText={
          "This section allows you to view all publisher channels and provides you with the functionality to either activate or block a channel. All common channel information is displayed, such as the channel name, the publisher’s name, status, etc."
        }
      />
      {/* Page Header End */}
      {/* =========================================================================================== */}
      {location?.pathname === "/channel" && (
        <>
          {/* Filters Start*/}
          <Box sx={{ pl: "16px" }} mt={5}>
            <Grid container spacing={2}>
              <Filters title="Channel:" onClick={() => toggleDrawer("channel")}>
                {setFilterLabel("channelNames")}
              </Filters>
              <Filters
                title="Publisher:"
                onClick={() => toggleDrawer("publisher")}
              >
                {setFilterLabel("publisherNames")}
              </Filters>
              <Filters title="Status :" onClick={() => toggleDrawer("status")}>
                {setFilterLabel("status")}
              </Filters>
              <Filters
                title="Created :"
                onClick={() => toggleDrawer("created")}
              >
                <Box>
                  <span style={{ fontSize: "14px" }}>
                    <Chip
                      label={moment(
                        previousFilterData?.fromDate
                          ? previousFilterData?.fromDate
                          : selectedDateFilter?.startDate
                      ).format("ll")}
                      sx={{ m: "2px" }}
                      size="small"
                    />
                  </span>
                  {" - "}
                  <span style={{ fontSize: "14px" }}>
                    <Chip
                      label={moment(
                        previousFilterData?.toDate
                          ? previousFilterData?.toDate
                          : selectedDateFilter?.endDate
                      ).format("ll")}
                      sx={{ m: "2px" }}
                      size="small"
                    />
                  </span>
                </Box>
              </Filters>
              <Filters title="Label :" onClick={() => toggleDrawer("label")}>
                {setFilterLabel("channelLabels")}
              </Filters>
              <Filters
                title="Filters"
                icons={<AddIcon />}
                onClick={() => toggleDrawer("filters")}
              ></Filters>
              <Filters title="Clear All" onClick={ClearFilters}></Filters>
            </Grid>
          </Box>
          {/* Filters End*/}
          {/* =========================================================================================== */}
          <Box>
            <AgGridComponent
              rowData={channelData?.Channels || []}
              columnDefs={channelData?.headers || []}
              actions={["EDIT", "VIEW"]}
              performAction={handleActions}
              totalPageCount=""
              paginationIndex={paginationIndex}
              itemPerIndex={itemPerIndex}
              perPageCountOptions={perPageCountOptions}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowMode
              addAction={setIsOpenAddEditBox}
              AddButtonName={"Add New Channel"}
              addActionIcon={<AddOutlined />}
              tableName={"Channels"}
              downlodBtn={true}
            />
          </Box>

          {/* Publisher Add Edit Form Start*/}
          {isOpenAddEditBox && (
            <AddEditChannel
              defaultValues={rowSelected}
              isOpen={isOpenAddEditBox}
              setOpen={setIsOpenAddEditBox}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
            />
          )}
          {/* Publisher Add Edit Form End*/}
          {isOpenChannelFilter && (
            <ChannelFilters
              defaultValues={rowSelected}
              isOpen={isOpenChannelFilter}
              setOpen={setIsOpenChannelFilter}
              isEditMode={isEditMode}
              defaultChannel={defaultChannel}
              ClearFilters={ClearFilters}
            />
          )}
        </>
      )}
      <div>
        <Outlet />
      </div>
    </>
  );
}

export default ChannelListing;
