import React from "react";
import {
  Box,
  Tab,
  Divider,
  TabList,
  TabContext,
  TabPanel,
  CurrencyExchangeOutlinedIcon,
  SwapCallsOutlinedIcon,
  QueryStatsOutlinedIcon,
  LeaderboardOutlinedIcon,
  DynamicFeedOutlinedIcon,
  IntegrationInstructionsOutlinedIcon,
} from "./../../../Collections/Imports";
import { useTabContext } from "../../../Collections/Tabcontext";
import LeadBody from "./LeadBody";
import LeadLogs from "./LeadLogs";
import Redirect from "./Redirect";
import LeadTracking from "./LeadTracking";
import LeadPostingLogs from "./LeadPostingLogs";
import Revenue from "./Revenue";
const LeadInfo = () => {
  const { tabValue, handleChange } = useTabContext();
  const tabIconColorActive = "#97B7F9";
  const tabTextColorActive = "#3E7DFD";
  const tabIconColorInactive = "#525252";
  const tabTextColorInactive = "#525252";

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabContext value={tabValue}>
          <TabList
            onChange={handleChange}
            className="tabList"
            sx={{
              p: 0,
              m: 0,
              height: "15px",
              "& .MuiTabs-indicator": {
                borderRadius: "4px 4px 0px 0px",
                borderBottom: "3px solid #3E7DFD",
              },
            }}
          >
            <Tab
              icon={
                <LeaderboardOutlinedIcon
                  style={{
                    fill:
                      tabValue === 1
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Lead Info"
              value={1}
              className="tabHeading"
              style={{
                color:
                  tabValue === 1 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 1 ? 600 : 400,
              }}
            />
            <Tab
              icon={
                <IntegrationInstructionsOutlinedIcon
                  style={{
                    fill:
                      tabValue === 2
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Lead Logs"
              value={2}
              className="tabHeading"
              style={{
                color:
                  tabValue === 2 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 2 ? 600 : 400,
              }}
            />
            <Tab
              icon={
                <QueryStatsOutlinedIcon
                  style={{
                    fill:
                      tabValue === 3
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Lead Tracking Response"
              value={3}
              className="tabHeading"
              style={{
                color:
                  tabValue === 3 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 3 ? 600 : 400,
              }}
            />
            <Tab
              icon={
                <DynamicFeedOutlinedIcon
                  style={{
                    fill:
                      tabValue === 4
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Lead Posting Logs"
              value={4}
              className="tabHeading"
              style={{
                color:
                  tabValue === 4 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 4 ? 600 : 400,
              }}
            />
            {/* <Tab
                  icon={
                    <RestoreOutlinedIcon
                      style={{
                        fill:
                          tabValue === 5
                            ? tabIconColorActive
                            : tabIconColorInactive,
                        marginRight: "4px",
                      }}
                    />
                  }
                  iconPosition="start"
                  label="Login History"
                  value={4}
                  className="tabHeading"
                  style={{
                    color:
                      tabValue === 5 ? tabTextColorActive : tabTextColorInactive,
                    fontWeight: tabValue === 5 ? 600 : 400,
                  }}
                /> */}
            <Tab
              icon={
                <SwapCallsOutlinedIcon
                  style={{
                    fill:
                      tabValue === 6
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Redirect"
              value={6}
              className="tabHeading"
              style={{
                color:
                  tabValue === 6 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 6 ? 600 : 400,
              }}
            />
            <Tab
              icon={
                <CurrencyExchangeOutlinedIcon
                  style={{
                    fill:
                      tabValue === 7
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Revenue"
              value={7}
              className="tabHeading"
              style={{
                color:
                  tabValue === 7 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 7 ? 600 : 400,
              }}
            />
          </TabList>
          <Divider />
          <Box sx={{ background: "white", minHeight: "100vh" }}>
            <TabPanel value={1} sx={{ paddingRight: "0" }}>
              <LeadBody />
            </TabPanel>
            <TabPanel
              value={2}
              sx={{ background: "#fff", padding: "0", pt: "0px" }}
            >
              <LeadLogs />
            </TabPanel>
            <TabPanel value={3} sx={{ paddingRight: "0" }}>
              <LeadTracking />
            </TabPanel>
            <TabPanel value={4} sx={{ paddingRight: "0" }}>
              <LeadPostingLogs />
            </TabPanel>
            <TabPanel
              value={5}
              sx={{ background: "#fff", padding: "0", pt: "0px" }}
            ></TabPanel>
            <TabPanel value={6} sx={{ paddingRight: "0" }}>
              <Redirect />
            </TabPanel>
            <TabPanel value={7} sx={{ paddingRight: "0" }}>
              <Revenue />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </Box>
  );
};

export default LeadInfo;
