import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

const MappingData = createSlice({
  name: "mappingData",
  initialState,
  reducers: {
    getMappingData: (state, action) => {
      state.data = action.payload;
    },

    addMappingData: (state, action) => {
      state.data.sourceChannelMappings.unshift(action.payload);
    },
    updateMappingData: (state, action) => {
      const updatedRow = action.payload;
      const index = state.data.sourceChannelMappings.findIndex(
        (data) => data?.mappingId === updatedRow?.mappingId
      );
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.data.sourceChannelMappings[index][key] = updatedRow[key];
      });
    },
    removeMappingData: (state, action) => {
      state.data.sourceChannelMappings =
        state.data.sourceChannelMappings.filter(
          (data) => data?.mappingId !== action.payload
        );
    },
    mappingListOptions: (state, action) => {
      state.data.mappingListOptions = action.payload;
    },
  },
});

export const {
  getMappingData,
  addMappingData,
  updateMappingData,
  removeMappingData,
  mappingListOptions,
} = MappingData.actions;
export default MappingData.reducer;
