import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableData: {
    data: [],
    headers: [],
  },
};

const campaignTags = createSlice({
  name: "campaignTags",
  initialState,
  reducers: {
    updateCampaignTags: (state, action) => {
      state.tableData = action.payload;
    },
    updateSingleCampaignTags: (state, action) => {
      const updatedRow = action.payload;
      const index = state.tableData.data.findIndex((data) => {
        return data?.id === updatedRow?.id;
      });
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.tableData.data[index][key] = updatedRow[key];
      });
    },
    addSingleCampaignTags: (state, action) => {
      state.tableData.data.unshift(action.payload);
    },
    removeSingleCampaignTags: (state, action) => {
      state.tableData.data = state.tableData.data.filter(
        (data) => data.id !== action.payload
        
      );
    },
  },
});

export const {
  updateCampaignTags,
  addSingleCampaignTags,
  updateSingleCampaignTags,
  removeSingleCampaignTags
} = campaignTags.actions;
export default campaignTags.reducer;
