import React from "react";
import {
  Chip,
  useEffect,
  useState,
  Box,
  moment,
  useNavigate,
  useLocation,
  AddOutlined,
  AddIcon,
  Grid,
} from "../../../Collections/Imports";
import Filters from "../../../Components/UIComponents/Filters";
import { useApiCallMutation } from "../../../Services/ApiCallService";
import { useDispatch, useSelector } from "react-redux";
import AgGridComponent from "../../../Components/CommonComponents/AG-Grid";
import CircularProgressBar from "../../../Components/UIComponents/CircularProgressBar";
import { publisherModuleApiEndPoints } from "../../../Config/APIsEndpoints";
import { leadReportModuleApiEndPoints } from "../../../Config/APIsEndpoints";
import { getChannelGroupBy } from "../../../Store/LeadReportsGroupBy";
import AddEditPublisher from "../../../Forms/AddEditPublisher";
import { updatePublisherFilterData } from "../../../Store/PublisherModule/filter";
import PublisherFilters from "../../../Forms/PublisherFilters";
import { Outlet } from "react-router-dom";
import "@inovua/reactdatagrid-community/theme/blue-light.css";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/index.css";

const ChannelGroupBy = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const TableData = useSelector(
    (state) => state.LeadReportsGroupBy?.data?.getChannelGroupBy
  );
  console.log("TableData", TableData);
  const [columnDefs, setColumnDefs] = useState([]);
  const [rowSelected, setRowSelected] = useState({});
  const [isOpenAddEditBox, setIsOpenAddEditBox] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [publisherName, setPublisherName] = useState("");
  const pubOptions = useSelector(
    (state) => state?.publisherData?.data?.allPublisherList
  );
  const previousFilterData = useSelector(
    (state) => state?.publisherFilterSession?.filterData
  );
  console.log("previousFilterData", previousFilterData);
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [isOpenPubFilter, setIsOpenPubFilter] = useState(false);
  const [defaultPub, setDefaultPub] = useState(false);
  const [itemPerIndex, setItemPerIndex] = useState(100);
  const perPageCountOptions = [
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "1000", value: 1000 },
  ];

  const statusOptions = [
    { label: "Active", value: "true" },
    { label: "Disabled", value: "false" },
  ];
  const [GetChannelGroupByList, ChannelGroupByList] = useApiCallMutation();
  console.log("ChannelGroupByList", ChannelGroupByList);
  // Handle Grid Button ACtion
  useEffect(() => {
    handleActions();
  }, []);
  const currencyFormatter = (params) => {
    return "$" + formatNumber(params.value);
  };
  const formatNumber = (number) => {
    return Math.floor(number).toLocaleString();
  };
  const percentageFormatter = (params) => {
    return params.value + "%";
  };

  useEffect(() => {
    if (TableData && TableData.headers && TableData.groupByChannel) {
      const headers = TableData.headers.map((header) => {
        if (header.field === "epl") {
          return {
            ...header,
            cellRenderer: currencyFormatter,
            cellStyle: {
              color: "green",
            },
          };
        }
        if (header.field === "alp") {
          return {
            ...header,
            cellRenderer: currencyFormatter,
            cellStyle: {
              color: "green",
            },
          };
        }
        if (header.field === "pub") {
          return {
            ...header,
            cellRenderer: currencyFormatter,
            cellStyle: {
              color: "green",
            },
          };
        }
        if (header.field === "adm") {
          return {
            ...header,
            cellRenderer: currencyFormatter,
            cellStyle: {
              color: "green",
            },
          };
        }
        if (header.field === "ttl") {
          return {
            ...header,
            cellRenderer: currencyFormatter,
            cellStyle: {
              color: "green",
            },
          };
        }
        if (header.field === "revShare") {
          return {
            ...header,
            cellRenderer: percentageFormatter,
          };
        }
        if (header.field === "reject") {
          return {
            ...header,
            cellStyle: {
              color: "red",
            },
          };
        }
        // if (header.field === "publisherTags") {
        // return {
        // ...header,
        // cellRenderer: CustomePublisherTags,
        // };
        // }
        return header;
      });
      setColumnDefs(headers);
      setRowSelected(TableData.groupByChannel);
    }
  }, [TableData]);

  const handleActions = (previousData, action) => {
    switch (action) {
      case "EDIT":
        setRowSelected(previousData);
        setIsEditMode(true);
        setIsOpenAddEditBox(true);
        break;
      case "CONFIG":
        setRowSelected(previousData);
        sessionStorage.setItem(
          "currentPublisherData",
          JSON.stringify(previousData)
        );
        const currentPubData = JSON.parse(
          sessionStorage.getItem("currentPublisherData")
        );
        setPublisherName(currentPubData?.publisherName);
        navigate(String(previousData?.pubUniqueId));
        break;
    }
  };
  const handlePageChange = (data) => {
    setPaginationIndex(data);
  };
  const handleRowsPerPageChange = (data) => {
    setItemPerIndex(data);
    setPaginationIndex(0);
  };
  const toggleDrawer = (ele) => {
    console.log("=-=-=-=-=-=-=-=-=-==--", ele);
    ele === "created"
      ? setDefaultPub("created")
      : ele === "publisher"
      ? setDefaultPub("publisher")
      : ele === "label"
      ? setDefaultPub("label")
      : ele === "status"
      ? setDefaultPub("status")
      : setDefaultPub(false);
    setIsOpenPubFilter((prev) => !prev);
  };
  const [selectedDateFilter, setSelectedDateFilter] = useState({
    startDate: new Date("July 01, 2020"),
    endDate: new Date(),
    key: "selection",
  });
  // get Preselected Filter label
  const getFilterLabel = (filterType, options) => {
    // console.log(filterType, options);
    if (options?.length === 1) {
      return (
        <span>
          <Chip label={options[0]?.label} sx={{ m: "2px" }} size="small" />
        </span>
      );
    } else if (options?.length > 1) {
      return (
        <>
          <span>
            <Chip label={options[0]?.label} sx={{ m: "2px" }} size="small" />
          </span>
          <span
            style={{ cursor: "pointer", color: "#000", paddingLeft: "4px" }}
          >
            {`+${previousFilterData[filterType]?.length - 1}`}
          </span>
        </>
      );
    } else {
      return (
        <span>
          <Chip label={"All"} sx={{ m: "2px" }} size="small" />
        </span>
      );
    }
  };
  //set Preselected Filter label
  const setFilterLabel = (filterType) => {
    switch (filterType) {
      case "publisher":
        if (previousFilterData?.publisher != "") {
          const puboptions = pubOptions?.filter((opt) =>
            previousFilterData?.publisher?.find((val) => val == opt.value)
          );
          return getFilterLabel("publisher", puboptions);
        }
        break;
      case "status":
        const statusoptions = statusOptions?.filter(
          (opt) => previousFilterData?.status == opt.value
        );
        return getFilterLabel("status", statusoptions);
        break;
    }
  };
  // get all publisher data
  const Datapub = {
    index: paginationIndex,
    itemPerIndex: itemPerIndex,
    fromDate: moment(previousFilterData?.fromDate).toISOString(),
    toDate:
      moment(previousFilterData?.toDate).toISOString() == ""
        ? moment(selectedDateFilter?.endDate).toISOString()
        : moment(previousFilterData?.toDate).toISOString(),
    // publisherNames: previousFilterData?.publisher,
    status: previousFilterData?.status || "all",
    emails: previousFilterData?.emails || ["all"],
  };
  useEffect(() => {
    GetChannelGroupByList({
      endPoint: leadReportModuleApiEndPoints.getGroupByChannel,
      method: "POST",
      data: {
        data: { ...Datapub },
      },
    });
  }, [previousFilterData]);
  useEffect(() => {
    if (ChannelGroupByList?.isSuccess && ChannelGroupByList?.data?.data) {
      dispatch(getChannelGroupBy(ChannelGroupByList?.data?.data));
    }
  }, [ChannelGroupByList]);
  useEffect(() => {
    if (location?.pathname === "/publisher") {
      setPublisherName("");
    } else {
      const currentPubData = JSON.parse(
        sessionStorage.getItem("currentPublisherData")
      );
      setPublisherName(currentPubData?.publisherName);
    }
  }, [location]);
  const ClearFilters = () => {
    const filterData = {
      status: "All",
      publisher: ["All"],
      label: ["All"],
      labelIds: ["All"],
      fromDate: selectedDateFilter?.startDate,
      toDate: moment().endOf("day").toDate(),
    };
    dispatch(updatePublisherFilterData(filterData));
    // reset();
  };
  return (
    <>
      {/* Page Header Start */}
      {/* <PageHeader */}
      {/* // title={publisherName || "Publishers"} */}
      {/* // backIcon={ */}
      {/* //   location?.pathname === "/publisher" ? null : ( */}
      {/* // <ArrowBack sx={{ color: "#0c247b" }} /> */}
      {/* //   ) */}
      {/* // } */}
      {/* // onBack={() => navigate(-1)} */}
      {/* // helpText={ */}
      {/* //   "This section is a complete list of all active publishers registe
     {/* // } */}
      {/* // onClick={() => setIsOpenAddEditBox(true)} */}
      {/* // buttonLabel="Add New Publisher" */}
      {/* //   /> */}
      {/* Page Header End */}
      <>
        {/* Filters Start*/}
        <Box sx={{ pl: "16px" }} mt={5}>
          <Grid container spacing={2}>
            <Filters
              title="Publisher:"
              onClick={() => toggleDrawer("publisher")}
            >
              {setFilterLabel("publisher")}
            </Filters>
            <Filters title="Status :" onClick={() => toggleDrawer("status")}>
              {setFilterLabel("status")}
            </Filters>
            <Filters title="Created :" onClick={() => toggleDrawer("created")}>
              <Box>
                {/* <span style={{ fontSize: "14px" }}>
            <Chip
              label={moment(
                previousFilterData?.fromDate
                  ? previousFilterData?.fromDate
                  : selectedDateFilter?.startDate
              ).format("ll")}
              sx={{ m: "2px" }}
              size="small"
            />
          </span>{" "}
          {"-"}{" "}
          <span style={{ fontSize: "14px" }}>
            <Chip
              label={moment(
                previousFilterData?.toDate
                  ? previousFilterData?.toDate
                  : selectedDateFilter?.endDate
              ).format("ll")}
              sx={{ m: "2px" }}
              size="small"
            />
          </span> */}
                <span style={{ fontSize: "14px" }}>
                  <Chip
                    label={moment(
                      previousFilterData?.fromDate
                        ? previousFilterData?.fromDate
                        : selectedDateFilter?.startDate
                    ).format("ll")}
                    sx={{ m: "2px" }}
                    size="small"
                  />
                </span>
                {" - "}
                <span style={{ fontSize: "14px" }}>
                  <Chip
                    label={moment(
                      previousFilterData?.toDate
                        ? previousFilterData?.toDate
                        : selectedDateFilter?.endDate
                    ).format("ll")}
                    sx={{ m: "2px" }}
                    size="small"
                  />
                </span>
              </Box>
            </Filters>
            <Filters title="Label :" onClick={() => toggleDrawer("label")}>
              {setFilterLabel("label")}
            </Filters>
            <Filters
              icons={<AddIcon />}
              title="Filters"
              onClick={() => toggleDrawer("filters")}
            ></Filters>
            <Filters title="Clear All" onClick={ClearFilters}></Filters>
          </Grid>
        </Box>
        {/* Filters End*/}
        {/* Filters End*/}
        {/* Data Table Start */}
        {ChannelGroupByList.status === "isUninitialized" && (
          <CircularProgressBar />
        )}
        {ChannelGroupByList.status !== "isUninitialized" && (
          <Box>
            <AgGridComponent
              rowData={TableData?.groupByChannel || []}
              // columnDefs={TableData?.headers || []}
              columnDefs={columnDefs}
              performAction={handleActions}
              totalPageCount={ChannelGroupByList?.data?.total}
              paginationIndex={paginationIndex}
              itemPerIndex={itemPerIndex}
              perPageCountOptions={perPageCountOptions}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowMode
              addActionIcon={<AddOutlined />}
            />
          </Box>
        )}
        {/* Data Table End */}

        {/* Publisher Filter Form */}
        {isOpenPubFilter && (
          <PublisherFilters
            defaultValues={rowSelected}
            isOpen={isOpenPubFilter}
            setOpen={setIsOpenPubFilter}
            isEditMode={isEditMode}
            defaultCampaign={defaultPub}
            ClearFilters={ClearFilters}
          />
        )}
        {/* Publisher Filter Form End */}
      </>
      <div>
        <Outlet />
      </div>
    </>
  );
};

export default ChannelGroupBy;
