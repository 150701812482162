import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const GlobalDropdown = ({
  control,
  name,
  label,
  options,
  valueGet,
  defaultValue,

  ...props
}) => {
  const [selectedValue, setSelectedValue] = useState(null);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const a = options?.find((res) => res.value === field?.value);
        setSelectedValue(a);
        return (
          <Autocomplete
            {...props}
            options={options}
            size="small"
            fullWidth
            value={
              defaultValue
                ? field?.value || options[0] || null
                : options?.find((res) => res.value === field?.value) || null
            }
            // value={options?.find((res) => res.value === field?.value) || null}
            onChange={(_, newValue) => {
              field.onChange(newValue?.value || null);
              setSelectedValue(newValue);
              if (typeof valueGet === "function") {
                valueGet(newValue);
              }
            }}
            onBlur={() => {
              field.onBlur(selectedValue?.value || null);
            }}
            renderInput={(params) => (
              <TextField
                sx={{ textTransform: "capitalize" }}
                required={props?.required || false}
                size="small"
                {...params}
                label={label}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        );
      }}
    />
  );
};
export default GlobalDropdown;
