import React, { useEffect, useState } from "react";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./style.css";

const GlobalDateRangePicker = ({
  name,
  control,
  defaultRange,
  setControl,
  ...rest
}) => {
  const [dateRange, setDateRange] = useState([
    {
      startDate: rest?.value ? rest?.value?.startDate : defaultRange?.startDate,
      endDate: rest?.value ? rest?.value?.endDate : defaultRange?.endDate,
      key: "selection",
    },
  ]);

  return (
    <div>
      <DateRangePicker
        sx={{ maxWidth: "400px" }}
        input
        name={name}
        staticRanges={createStaticRanges([
          {
            label: "Today",
            range: () => ({
              startDate: new Date(),
              endDate: new Date(),
            }),
          },
          {
            label: "Yesterday",
            range: () => {
              const endDate = new Date();
              endDate.setDate(endDate.getDate() - 1);
              const startDate = new Date(endDate);
              startDate.setHours(0, 0, 0, 0);
              return { startDate, endDate };
            },
          },
          {
            label: "Last 7 Days",
            range: () => {
              const endDate = new Date();
              const startDate = new Date();
              startDate.setDate(endDate.getDate() - 6);
              return { startDate, endDate };
            },
          },
          {
            label: "Last 30 Days",
            range: () => {
              const endDate = new Date();
              const startDate = new Date();
              startDate.setDate(endDate.getDate() - 29);
              return { startDate, endDate };
            },
          },
          {
            label: "This Month",
            range: () => {
              const endDate = new Date();
              const startDate = new Date(
                endDate.getFullYear(),
                endDate.getMonth(),
                1
              );
              return { startDate, endDate };
            },
          },
        ])}
        maxDate={new Date()}
        ranges={dateRange}
        onChange={(item) => {
          setDateRange([item.selection]);
          rest?.onChange([item.selection]);
        }}
      />
    </div>
  );
};

export default GlobalDateRangePicker;
