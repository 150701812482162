import React from "react";
import {
  useState,
  Drawer,
  Grid,
  Box,
  Typography,
  Divider,
  Stack,
  useEffect,
} from "../../Collections/Imports";
import { useDispatch } from "react-redux";
import { useApiCallMutation } from "../../Services/ApiCallService";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import GlobalInput from "../../Components/CommonComponents/GlobalInput";
import GlobalDropdown from "../../Components/CommonComponents/GlobalDropdown";
import CustomButton from "../../Components/CommonComponents/GlobalButton";
import { publisherModuleApiEndPoints } from "../../Config/APIsEndpoints";
import { addData, updateData } from "../../Store/PublisherModule/bucketBank";
import RadioGroupComponent from "../../Components/CommonComponents/GlobalRadioButton";
import GlobalCheckbox from "../../Components/CommonComponents/GlobalCheckBox";
import { updateSnackBarConfig } from "../../Store/Global/SnackBar";
function AddEditPubBucketRule(props) {
  const { setOpen, isOpen, defaultValues, isEditMode, setIsEditMode } = props;
  console.log(props);
  const dispatch = useDispatch();
  const [AddBucketBank, addBucketBankData] = useApiCallMutation();
  const [UpdateBucketBank, updateBucketBankData] = useApiCallMutation();
  let currentPubData = JSON.parse(
    sessionStorage.getItem("currentPublisherData")
  );
  const [showChannel, setShowChannel] = useState(false);
  const ChannelOptions = [
    { label: "Channel1", id: 1, value: "ch1" },
    { label: "Channel2", id: 2, value: "ch2" },
    { label: "channel3", id: 3, value: "ch3" },
  ];
  const StatusOptions = [
    { label: "Active", id: 1, value: "true" },
    { label: "Disabled", id: 2, value: "false" },
  ];
  const bucketBankValidationSchema = Yup.object().shape({
    activeOn: Yup.string().required("Bucket Type is required"),
    channelName: showChannel
      ? Yup.string().required("This Field is required")
      : Yup.string().notRequired(),
    thresholdAmount: Yup.number()
      .max(1000)
      .typeError("Please enter a valid digit")
      .required("This Field is required"),
    status: Yup.boolean().required("This Field is required"),
  });

  // useForm for Bucket Bank form
  const {
    handleSubmit,
    control: bucketBankControl,
    formState: { errors: bucketBankErrors },
    reset: bucketBankReset,
    setValue: bucketBankSetValue,
    watch,
  } = useForm({
    defaultValues: {
      pubUniqueId: currentPubData.pubUniqueId,
      activeOn: "",
      groupByChannel: false,
      groupBySource: false,
      thresholdAmount: "",
      channelName: "",
    },
    resolver: yupResolver(bucketBankValidationSchema),
    mode: "all",
  });

  const onFormSubmit = (data) => {
    console.log("data", data);
    if (!isEditMode) {
      AddBucketBank({
        endPoint: publisherModuleApiEndPoints.addBucketBank,
        method: "POST",
        data: { data },
      });
    } else {
      const modifiedData = data;
      modifiedData.bucketBankId = defaultValues?.bucketBankId;
      UpdateBucketBank({
        endPoint: publisherModuleApiEndPoints.updateBucketBank,
        method: "POST",
        data: { data: modifiedData },
      });
    }
  };

  const SetFormValues = (data) => {
    let grpChannel = false;
    let grpSource = false;
    if (data?.groupByChannel === "true") {
      grpChannel = true;
    }

    if (data?.groupBySource === "true") {
      grpSource = true;
    }
    bucketBankSetValue("activeOn", data?.activeOn, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    bucketBankSetValue("groupByChannel", grpChannel, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    bucketBankSetValue("groupBySource", grpSource, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    bucketBankSetValue("thresholdAmount", data?.thresholdAmount, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    bucketBankSetValue("channelName", data?.channelName, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    bucketBankSetValue("status", data?.status == true ? "true" : "false", {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  useEffect(() => {
    if (addBucketBankData?.isSuccess && !addBucketBankData?.data?.error) {
      dispatch(addData(addBucketBankData?.data?.data));
      dispatch(
        updateSnackBarConfig({
          open: true,
          color: "success",
          message: "Data Added successfully",
        })
      );
      setOpen(false);
      setIsEditMode(false);
    }
  }, [addBucketBankData]);
  useEffect(() => {
    const activeOn = watch("activeOn");

    setShowChannel(activeOn == "channel");
  }, [watch("activeOn")]);

  //Set Incoming Default values
  useEffect(() => {
    if (Object.keys(defaultValues).length && isEditMode) {
      SetFormValues(defaultValues);
    }
  }, [defaultValues]);
  useEffect(() => {
    if (updateBucketBankData?.isSuccess && !updateBucketBankData?.data?.error) {
      dispatch(updateData(updateBucketBankData.data?.data));
      dispatch(
        updateSnackBarConfig({
          open: true,
          color: "success",
          message: "Data Updated successfully",
        })
      );
      setOpen(false);
      setIsEditMode(false);
    }
  }, [updateBucketBankData]);
  return (
    <>
      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={() => {
          setOpen(false);
          setIsEditMode(false);
        }}
        sx={{
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            top: "15px",
            borderRadius: "16px",
            width: "100%",
            maxWidth: "500px",
          },
        }}
      >
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent={"space-between"}
          >
            <Box marginBottom={"15px"}>
              <Typography component="h6" variant="h6" fontWeight={600}>
                {(isEditMode && "Update Rule") || "Add Rule"}
              </Typography>
            </Box>
            <Box marginBottom={"15px"}>
              <Typography
                component="h7"
                variant="h7"
                fontWeight={200}
                onClick={() => bucketBankReset()}
              >
                Clear All
              </Typography>
            </Box>
          </Stack>
          <Divider
            sx={{
              mb: "20px",
            }}
          />
          <Box component={"form"}>
            <Grid container spacing={4}>
              <Grid item xs={12} sx={{ mt: "10px" }}>
                <GlobalDropdown
                  control={bucketBankControl}
                  size="small"
                  options={[
                    { label: "Publisher Account", value: "publisher" },
                    { label: "Channel", value: "channel" },
                  ]}
                  name="activeOn"
                  label="Bucketing On"
                  error={!!bucketBankErrors.activeOn}
                  helperText={bucketBankErrors.activeOn?.message}
                  required
                />
              </Grid>
              {showChannel && (
                <Grid item xs={12} sx={{ mt: "10px" }}>
                  <GlobalDropdown
                    control={bucketBankControl}
                    size="small"
                    options={ChannelOptions}
                    name="channelName"
                    label="Channel"
                    error={!!bucketBankErrors.channelName}
                    helperText={bucketBankErrors.channelName?.message}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  name="groupByChannel"
                  control={bucketBankControl}
                  render={({ field }) => (
                    <GlobalCheckbox
                      name={field.name}
                      control={bucketBankControl}
                      label="Group By Channel"
                    />
                  )}
                />
                <Controller
                  name="groupBySource"
                  control={bucketBankControl}
                  render={({ field }) => (
                    <GlobalCheckbox
                      name={field.name}
                      control={bucketBankControl}
                      label="Group By Source"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: "10px" }}>
                <GlobalInput
                  control={bucketBankControl}
                  size="small"
                  name="thresholdAmount"
                  label="Threshould Amount"
                  error={!!bucketBankErrors.thresholdAmount}
                  helperText={bucketBankErrors.thresholdAmount?.message}
                  required
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: "10px" }}>
                <GlobalDropdown
                  control={bucketBankControl}
                  size="small"
                  name="status"
                  label="Status"
                  options={StatusOptions}
                  error={!!bucketBankErrors.status}
                  helperText={bucketBankErrors.status?.message}
                />
              </Grid>
              <Box
                sx={{
                  width: "100%",
                  borderTop: "1px solid #DCE0E6",
                  marginTop: "10px",
                }}
              >
                <Stack
                  direction="row"
                  component={Box}
                  useFlexGap
                  flexWrap="wrap"
                  justifyContent={"flex-end"}
                  bottom={0}
                  marginTop={"1rem"}
                >
                  <Box
                    marginBottom={"15px"}
                    marginRight={"5px"}
                    float={"right"}
                  >
                    <CustomButton
                      color="#3a5396"
                      label="Save"
                      onClick={handleSubmit(onFormSubmit)}
                    />
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

export default AddEditPubBucketRule;
