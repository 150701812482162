import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterData: {
    fromDate:"2020-06-30T18:30:00.000Z",
  },
  pubOptions:{},
};

const publisherFilterSession = createSlice({
  name: "publisherFilterSession",
  initialState,
  reducers: {
    updatePublisherFilterData: (state, action) => {
      state.filterData = action.payload;
    },
    updatePubOptions:(state,action) => {
      state.pubOptions = action.payload;
    }
  },
});

export const { updatePublisherFilterData,updatePubOptions } = publisherFilterSession.actions;
export default publisherFilterSession.reducer;
