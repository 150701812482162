import { useCallback } from "react";
import { useApiCallMutation } from "../../Services/ApiCallService";
import { apiEndPointsConfig } from "../../Config/APIsEndpoints";
import { useNavigate } from "react-router-dom";

function useLogout() {
  const navigate = useNavigate();
  const [logOutCall, logOutData] = useApiCallMutation();

  const logout = useCallback(async () => {
    try {
      const res = await logOutCall({
        endPoint: apiEndPointsConfig.revokToken,
        method: "POST",
        data: {
          data: {
            clientName: "lds",
          },
        },
      });
      if (res.data.data === "Token revoked successfully") {
        localStorage.clear();
        navigate("/");
      }
    } catch (error) {
      localStorage.clear();
      navigate("/");
    }
  }, []);

  return logout;
}

export default useLogout;
