import React, { useEffect, useState } from "react";
import { Box, Chip, PageHeader,Grid, AddOutlined, AddIcon, moment } from "../../Collections/Imports";
import AgGridComponent from "../../Components/CommonComponents/AG-Grid";
import Filters from "../../Components/UIComponents/Filters";
import { useDispatch, useSelector } from "react-redux";
import { useApiCallMutation, useGetApiCallQuery } from "../../Services/ApiCallService";
import { campaignTagsModuleAPiEndPoints } from "../../Config/APIsEndpoints";
import { removeSingleCampaignTags, updateCampaignTags } from "../../Store/CampaignTags";
import CircularProgressBar from "../../Components/UIComponents/CircularProgressBar";
import AddEditCampaignTags from "../../Forms/AddEditCampaignTag";
import { updateCampaignTagFilterData } from "../../Store/CampaignTags/filterSession";

function CampaignTagsPage() {
  const dispatch=useDispatch()
  const [rowSelected, setRowSelected] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
   // Form pagination handler
   const [paginationIndex, setPaginationIndex] = useState(0);
   const [itemPerIndex, setItemPerIndex] = useState(100);
   const perPageCountOptions = [
     { label: "100", value: 100 },
     { label: "500", value: 500 },
     { label: "1000", value: 1000 },
   ];
   const handlePageChange = (data) => {
    setPaginationIndex(data);
  };
  const handleRowsPerPageChange = (data) => {
    setItemPerIndex(data);
    setPaginationIndex(0);
  };
  const [isOpenAddEditBox, setIsOpenAddEditBox] = useState(false);
  //TableData
  const TableData = useSelector(
    (state) => state.campaignTags.tableData
  );
 // Get Campaign Tags 
 const GetCampaignTagList = useGetApiCallQuery({
  endPoint: campaignTagsModuleAPiEndPoints.getCampaignsTags,
  method: "GET",
});
 // Get Campaign Tags api response 
 useEffect(() => {
  if (GetCampaignTagList?.isSuccess && GetCampaignTagList?.data?.data) {
    const tableData = {
      data: GetCampaignTagList?.data?.data?.campaignTags,
      headers: GetCampaignTagList?.data?.data?.headers,
    };
    console.log(tableData,'tableData');
    dispatch(updateCampaignTags(tableData));
  }
}, [GetCampaignTagList?.data]);

//delete campaign tag api call
const [DeleteCampaignTag, DeleteCampaignTagData] = useApiCallMutation();
// Handle Grid Button ACtion
const handleActions = (previousData, action) => {
    switch (action) {
      case "EDIT":
        setRowSelected(previousData);
        setIsEditMode(true);
        setIsOpenAddEditBox(true);
        break;
      case "DELETE":
        console.log('delete ho raha hu');
        const tagId = {
          id: previousData?.id,
        };
        DeleteCampaignTag({
          endPoint: campaignTagsModuleAPiEndPoints.deleteCampaignTags,
          method: "POST",
          data: { data: tagId },
        });
        dispatch(removeSingleCampaignTags(previousData?.id));
        break;
      default:       
    }
};
  return (
    <>
      {/* Page Header Start */}
      <PageHeader
        title={"Campaign Tags"}
        helpText={
          "To create a tag, click on 'Add New Tag button'. Fill in the name and the tag's badge(in other words status). After the tag is created you may assign to a campaign"
        }
      />
      {/* Page Header End */}

      {/* Filters Start*/}
      {/* <Box sx={{ pl: "16px" }} mt={5}>
            <Grid container spacing={2}>
              <Filters
                 title="CampaignTags:"
                 onClick={() => toggleDrawer("publisher")}
              >
                 {setFilterLabel("publisher")} 
              </Filters>
              <Filters
                title="Created :"
                 onClick={() => toggleDrawer("created")}
              >
                <Box>
                  <span style={{ fontSize: "14px" }}>
                    <Chip
                      label={moment(
                        previousFilterData?.fromDate
                          ? previousFilterData?.fromDate
                          : selectedDateFilter?.startDate
                      ).format("ll")}
                      sx={{ m: "2px" }}
                      size="small"
                    />
                  </span>{" "}
                  {"-"}{" "}
                  <span style={{ fontSize: "14px" }}>
                    <Chip
                      label={moment(
                        previousFilterData?.toDate
                          ? previousFilterData?.toDate
                          : selectedDateFilter?.endDate
                      ).format("ll")}
                      sx={{ m: "2px" }}
                      size="small"
                    />
                  </span>
                </Box>
              </Filters>
              {/*
               <Filters title="Status :" onClick={() => toggleDrawer("status")}>
                {setFilterLabel("status")}
              </Filters>
              
              <Filters title="Label :" onClick={() => toggleDrawer("label")}>
                {setFilterLabel("label")}
              </Filters>
            
             
               */}
                 {/* <Filters
                icons={<AddIcon />}
                title="Filters"
                // onClick={() => toggleDrawer("filters")}
              ></Filters>
                <Filters
                title="Clear All"
                onClick={() => dispatch(updateCampaignTagFilterData({}))}
              ></Filters>
            </Grid> */}
      {/* </Box> */}
      {/* Filters End*/}

      {/* Data Table Start */}
      {GetCampaignTagList?.status === "isUninitialized" && (
            <CircularProgressBar />
      )}
        {GetCampaignTagList?.status !==  "isUninitialized" && (
      <Box>
          <AgGridComponent
                // tableName={'hello'}
                rowData={TableData?.data || []}
                columnDefs={TableData?.headers|| []}
                actions={["EDIT", "DELETE"]}
                performAction={handleActions}
                totalPageCount={TableData?.data.length}
                paginationIndex={paginationIndex}
                itemPerIndex={itemPerIndex}
                perPageCountOptions={perPageCountOptions}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowMode
                addAction={setIsOpenAddEditBox}
                AddButtonName={"Add New Tag"}
                addActionIcon={<AddOutlined />}
          />
      </Box>
        )}
      {/* Data Table ends */}

      {/* Campaign Add Edit Form Start*/}
      {isOpenAddEditBox && (
            <AddEditCampaignTags
              defaultValues={rowSelected}
              isOpen={isOpenAddEditBox}
              setOpen={setIsOpenAddEditBox}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
            />
      )}
      {/* Campaign Add Edit Form End*/}
    </>
  );
}

export default CampaignTagsPage;
