import React, { useEffect, useState } from "react";
import AgGridComponent from "../../../../Components/CommonComponents/AG-Grid";
import {
  Box,
  Chip,
  PageHeader,
  Grid,
  AddOutlined,
  AddIcon,
  moment,
  Stack,
  useNavigate,
  useLocation,
  Button,
} from "../../../../Collections/Imports";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../Services/ApiCallService";
// import {
//   getTableData,
//   removeSinglePingTree,
// } from "../../../../Store/PingTreeModule";
import { pingtreeApiEndPoints } from "../../../../Config/APIsEndpoints";
import { useDispatch, useSelector } from "react-redux";
import AddEditPingTree from "../../../../Forms/AddEditPingTree";
import CircularProgressBar from "../../../../Components/UIComponents/CircularProgressBar";

import { Outlet } from "react-router-dom";
import {
  getTableDataSilent,
  removeSingleSilentPingTree,
} from "../../../../Store/PingTreeModule/PingTreeSilent";

const SilentPingTree = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();
  const [rowSelected, setRowSelected] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  // Form pagination handler
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [itemPerIndex, setItemPerIndex] = useState(100);
  const perPageCountOptions = [
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "1000", value: 1000 },
  ];
  const handlePageChange = (data) => {
    setPaginationIndex(data);
  };
  const handleRowsPerPageChange = (data) => {
    setItemPerIndex(data);
    setPaginationIndex(0);
  };
  const [isOpenAddEditBox, setIsOpenAddEditBox] = useState(false);

  const TableData = useSelector((state) => state.PingTreeSilent?.tableData);

  ///
  const [GetPingTreeList, PingTreeList] = useApiCallMutation();

  useEffect(() => {
    GetPingTreeList({
      endPoint: pingtreeApiEndPoints.getPingtreeList,
      method: "POST",
      data: {
        data: { pingTreeType: "Silent" },
      },
    });
  }, []);

  useEffect(() => {
    if (PingTreeList?.isSuccess && PingTreeList?.data?.data?.pingTree) {
      const tableData = {
        data: PingTreeList?.data?.data?.pingTree,
        headers: PingTreeList?.data?.data?.headers,
      };

      dispatch(getTableDataSilent(tableData));
    }
  }, [PingTreeList]);

  // Get List
  // const pingTreeDataList = useGetApiCallQuery({
  //   endPoint: pingtreeApiEndPoints.getPingtreeList,
  //   method: "GET",
  // });
  // delete pentree
  const [DeletePingTree, DeletePingTreeData] = useApiCallMutation();
  // Get Campaign Tags api response
  // useEffect(() => {
  //   if (pingTreeDataList?.isSuccess && pingTreeDataList?.data?.data) {
  //     const tableData = {
  //       data: pingTreeDataList?.data?.data?.pingTree,
  //       headers: pingTreeDataList?.data?.data?.headers,
  //     };
  //     dispatch(getTableDataSilent(tableData));
  //   }
  // }, [pingTreeDataList?.data]);

  const handleActions = (previousData, action) => {
    switch (action) {
      case "EDIT":
        setRowSelected(previousData);
        setIsEditMode(true);
        setIsOpenAddEditBox(true);
        break;
      case "DELETE":
        // console.log("delete ho raha hu");
        const treeUniqueId = {
          treeUniqueId: previousData?.treeUniqueId,
        };
        DeletePingTree({
          endPoint: pingtreeApiEndPoints.deletePingTree,
          method: "POST",
          data: { data: treeUniqueId },
        });
        dispatch(removeSingleSilentPingTree(previousData?.treeUniqueId));
        break;
      case "CONFIG":
        setRowSelected(previousData);
        sessionStorage.setItem(
          "currentPingTreeRowData",
          JSON.stringify(previousData)
        );
        // dispatch(updateCampaignRowData(previousData));
        const currentPingTreeData = JSON.parse(
          sessionStorage.getItem("currentPingTreeRowData")
        );

        // setCampainName(currentCampData.campaignName);
        navigate(String(currentPingTreeData?.treeUniqueId));

        break;
      default:
    }
  };

  return (
    <>
      {location?.pathname === "/ping-tree" && (
        <>
          {/* {PingTreeList.isLoading && <CircularProgressBar />}
          {!PingTreeList.isLoading && ( */}
          <Box>
            <AgGridComponent
              rowData={TableData?.data || []}
              columnDefs={TableData?.headers || []}
              tableName={"Silent Pign Tree"}
              actions={["EDIT", "DELETE", "CONFIG"]}
              performAction={handleActions}
              totalPageCount={TableData?.data.length}
              paginationIndex={paginationIndex}
              itemPerIndex={itemPerIndex}
              perPageCountOptions={perPageCountOptions}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowMode
              addAction={setIsOpenAddEditBox}
              AddButtonName={"Add New Tag"}
              addActionIcon={<AddOutlined />}
            />
          </Box>
          {/* )} */}
          {isOpenAddEditBox && (
            <AddEditPingTree
              TreeType="Silent"
              defaultValues={rowSelected}
              isOpen={isOpenAddEditBox}
              setOpen={setIsOpenAddEditBox}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
            />
          )}
        </>
      )}
      <div>
        <Outlet />
      </div>
    </>
  );
};

export default SilentPingTree;
