import React, { useEffect } from "react";
import {
  AddIcon,
  AddOutlined,
  ArrowBack,
  Box,
  Chip,
  Grid,
  PageHeader,
  useLocation,
  useNavigate,
} from "../../Collections/Imports";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import { publisherLabelApiEndPoint } from "../../Config/APIsEndpoints";
import {
  getPLData,
  removeSinglePublisherLabelData,
  updateSinglePublisherLabelData,
} from "../../Store/PublisherLabelModule";
import moment from "moment/moment";
import CircularProgressBar from "../../Components/UIComponents/CircularProgressBar";
import AgGridComponent from "../../Components/CommonComponents/AG-Grid";
import AddEditPublisherLabel from "../../Forms/AddEditPublisherLabel";

const PublisherLabel = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // Form pagination handler
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [itemPerIndex, setItemPerIndex] = useState(100);
  const perPageCountOptions = [
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "1000", value: 1000 },
  ];

  const [selectedDateFilter, setSelectedDateFilter] = useState({
    startDate: new Date("July 01, 2020"),
    endDate: new Date(),
    key: "selection",
  });

  const [isOpenAddEditPublisherLabel, setIsOpenAddEditPublisherLabel] =
    useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const [rowSelected, setRowSelected] = useState({});

  const TableData = useSelector((state) => state.publisherLabelData.tableData);
  console.log("datatata", TableData);

  //Api Initialization
  const [GetAllPublisherLabel, AllPublisherLabelData] = useApiCallMutation();
  const [UpdatePublisherLabel, UpdatePublisherLabelData] = useApiCallMutation();
  const [DeletePublisherLabel, setDeletePublisherLabel] = useApiCallMutation();

  // const previousFilterData = useSelector(
  //   (state) => state?.publisherLabelDataFilterSession?.filterData
  // );

  const publisherLabelData = useGetApiCallQuery({
    endPoint: publisherLabelApiEndPoint.getPublisherLabel,
    method: "GET",
  });

  //   const PublisherLabelreqData = {
  //     fromDate: moment(previousFilterData?.fromDate).toISOString(),
  //     toDate:
  //       moment(previousFilterData?.toDate).toISOString() == ""
  //         ? moment(selectedDateFilter?.endDate).toISOString()
  //         : moment(previousFilterData?.toDate).toISOString(),
  //     id: previousFilterData?.id,
  //   };

  //   useEffect(() => {
  //     return () => {
  //       dispatch(updatePublisherLabelFilterData(PublisherLabelreqData));
  //     };
  //   }, []);

  useEffect(() => {
    if (
      UpdatePublisherLabelData?.isSuccess &&
      !UpdatePublisherLabelData?.data?.error
    ) {
      dispatch(
        updateSinglePublisherLabelData(UpdatePublisherLabelData.data?.data)
      );
    }
  }, [UpdatePublisherLabelData]);

  useEffect(() => {
    if (publisherLabelData.data) {
      const filteredFieldsData = publisherLabelData?.data?.data?.pubLabel;

      const tableData = {
        data: filteredFieldsData,
        headers: publisherLabelData?.data?.data?.headers,
      };

      // console.log("tableData Schedule", tableData);
      dispatch(getPLData(tableData));
    }
  }, [publisherLabelData]);

  // Handle Grid Button ACtion
  const handleActions = (previousData, action) => {
    switch (action) {
      case "EDIT":
        setRowSelected(previousData);
        setIsEditMode(true);
        setIsOpenAddEditPublisherLabel(true);
        break;

      case "DELETE":
        // Optimistically remove the deleted item from the UI
        dispatch(removeSinglePublisherLabelData(previousData.id));

        DeletePublisherLabel({
          endPoint: publisherLabelApiEndPoint.deletePublisherLabel,
          method: "POST",
          data: {
            data: {
              id: previousData?.id,
            },
          },
        });
        break;
    }
  };

  const handlePageChange = (data) => {
    setPaginationIndex(data);
  };
  const handleRowsPerPageChange = (data) => {
    setItemPerIndex(data);
    setPaginationIndex(0);
  };

  return (
    <>
      <PageHeader
        title={"Publisher Labels"}
        backIcon={
          location?.pathname === "/publisher-labels" ? null : (
            <ArrowBack sx={{ color: "#0c247b" }} />
          )
        }
        onBack={() => navigate(-1)}
      />
      {location?.pathname === "/publisher-label" && (
        <>
          {AllPublisherLabelData.isLoading && <CircularProgressBar />}
          {!AllPublisherLabelData.isLoading && (
            <Box>
              <AgGridComponent
                rowData={TableData?.data || []}
                columnDefs={TableData?.headers || []}
                actions={["EDIT", "DELETE"]}
                performAction={handleActions}
                totalPageCount={TableData?.data.length}
                paginationIndex={paginationIndex}
                itemPerIndex={itemPerIndex}
                perPageCountOptions={perPageCountOptions}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                addAction={setIsOpenAddEditPublisherLabel}
                AddButtonName={"Add New Label"}
                addActionIcon={<AddOutlined />}
                tableName={"Publisher Labels"}
                rowMode
              />
            </Box>
          )}

          {isOpenAddEditPublisherLabel && (
            <AddEditPublisherLabel
              defaultValues={rowSelected}
              isOpen={isOpenAddEditPublisherLabel}
              setOpen={setIsOpenAddEditPublisherLabel}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
            />
          )}
        </>
      )}
    </>
  );
};

export default PublisherLabel;
