// import React from "react";
// import { useController } from "react-hook-form";
// import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
// import TextField from "@mui/material/TextField";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import moment from "moment";

// const GlobalDatePicker = ({ control, name, defaultValue, ...rest }) => {
//   const {
//     field: { ref, ...inputProps },
//     fieldState: { error, invalid, isDirty, isTouched },
//   } = useController({
//     name,
//     control,
//     defaultValue: defaultValue ? moment(defaultValue) : null,
//   });

//   return (
//     <LocalizationProvider dateAdapter={AdapterMoment}>
//       <DatePicker
//         {...inputProps}
//         inputRef={ref}
//         sx={{
//           "& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root": {
//             height: "40px", // Set the desired height here
//           },
//           width: "100%",
//         }}
//         slotProps={{
//           textField: {
//             helperText: error ? (
//               <span className="error">{error.message}</span>
//             ) : (
//               ""
//             ),
//           },
//         }}
//         {...rest}
//       />
//     </LocalizationProvider>
//   );
// };

// export default GlobalDatePicker;

import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField, Box } from "@mui/material";
import { Controller } from "react-hook-form";
import "./style.css";

const SingleDatePicker = ({
  label,
  name,
  control,
  disablePast,
  minDate,
  maxDate,
}) => {
  console.log("DATE PICKER", disablePast);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      {/* <Box width={"25%"}>{label}</Box> */}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              name={name}
              inputFormat="yyyy-MM-dd"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              minDate={minDate ? minDate : ""} // Set the minimum date to the current date
              maxDate={maxDate ? maxDate : ""} // Set the maximum date to the current date
              // disablePast={disablePast} // Disable selection of past dates
            />
          </LocalizationProvider>
        )}
      />
    </Box>
  );
};

export default SingleDatePicker;
