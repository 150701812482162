import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterData: {
    fromDate: "2020-06-30T18:30:00.000Z",
  },
};

const channelFilterSession = createSlice({
  name: "channelFilterSession",
  initialState,
  reducers: {
    updateChannelFilterData: (state, action) => {
      state.filterData = action.payload;
    },
    updatePubOptions: (state, action) => {
      state.pubOptions = action.payload;
    },
  },
});

export const { updateChannelFilterData } = channelFilterSession.actions;
export default channelFilterSession.reducer;
