import {
    useEffect, 
    Box
} from "../../../../Collections/Imports";
import { useApiCallMutation } from "../../../../Services/ApiCallService";
import { useDispatch, useSelector } from "react-redux";
import { leadDetailsModuleApiEndPoints } from "../../../../Config/APIsEndpoints";
import { getRevenueLogs } from "../../../../Store/LeadDetailsModule/LeadInfoBody";
import AgGridComponent from "../../../../Components/CommonComponents/AG-Grid";
import { Container, Card, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Divider, ThemeProvider,createTheme } from '@mui/material';
const Revenue = () =>{
    const theme = createTheme({
        palette: {
          text: {
            primary: '#3D506E',
            secondary: '#000000',
          },
        },
      });
    const dispatch = useDispatch();
    const rowStyle = { background: "" };
    let currentLeadInfo = JSON.parse(sessionStorage.getItem("currentLeadData"));
    const [getAllRevenue, revenueLogs] = useApiCallMutation();
    const revenueLeadData = useSelector((state) => state.LeadInfoBody?.data?.getRevenueLogs );
    console.log("tableData",revenueLeadData)
    console.log("revenueLogs",revenueLogs?.data?.data?.revenueShare)
    useEffect(() => {
        getAllRevenue({
          endPoint: leadDetailsModuleApiEndPoints.getAllLeadDetailsById,
          method: "POST",
          data: {
            data: {
              leadId: currentLeadInfo.leadId,
            },
          },
        });
      }, []);
      useEffect(() => {
        if (revenueLogs) {
          dispatch(getRevenueLogs(revenueLogs?.data?.data?.revenueShare));
        }
      }, [revenueLogs]);

      return (
        <Container>
        <ThemeProvider theme={theme}>
        <Card sx={{ padding: 2, marginTop: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" sx={{fontWeight:'600',fontSize:'14px',lineHeight:'16.94px', color: 'text.primary'}}>
             Admin Revenue: {revenueLeadData?.adminRevenue}
          </Typography>
        </Box>
        <Divider sx={{ marginY: 2 }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6"  sx={{fontWeight:'600',fontSize:'14px',lineHeight:'16.94px', color: 'text.primary'}}>
            Publisher Payout : {revenueLeadData?.publisherPayout}
            </Typography>
        </Box>
  
        <Divider sx={{ marginY: 2 }} />
        <Typography variant="h6" gutterBottom sx={{fontWeight:'600',fontSize:'14px',lineHeight:'16.94px', color: 'text.primary'}}>
          Total Revenue: {revenueLeadData?.totalRevenue}
        </Typography>
 

    
      </Card>
        </ThemeProvider>
   
    </Container>  
 
 

 
 

   
    
      );

}

export default Revenue;