import { Button } from "@mui/material";
import React from "react";

const CustomButton = ({
  color,
  label,
  labelColor,
  onClick,
  variant,
  ...rest
}) => {
  const handleClick = () => {
    if (onClick !== undefined) {
      onClick();
    }
  };
  return (
    <Button
      variant={variant ? variant : "contained"}
      style={{
        backgroundColor: color,
        color: labelColor ? labelColor : "white",
      }}
      onClick={handleClick}
      {...rest}
    >
      {label}
    </Button>
  );
};

export default CustomButton;
