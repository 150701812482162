import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Divider, Drawer, Grid, Stack, Typography } from "@mui/material";
import GlobalInput from "../../Components/CommonComponents/GlobalInput";
import GlobalDropdown from "../../Components/CommonComponents/GlobalDropdown";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useApiCallMutation } from "../../Services/ApiCallService";
import { useDispatch } from "react-redux";
import CustomButton from "../../Components/CommonComponents/GlobalButton";
import {
  buyerTagModuleAPiEndPoints,
  pingtreeApiEndPoints,
} from "../../Config/APIsEndpoints";
// import {
//   addSingleBuyerTags,
//   updateSingleBuyerTags,
// } from "../../Store/BuyerTagModule";
// import {
//   addSinglePingTree,
//   updateSinglePingTree,
// } from "../../Store/PingTreeModule";
import {
  addSingleRedirectPingTree,
  updateSingleRedirectPingTree,
} from "../../Store/PingTreeModule/PingTreeRedirect";
import {
  addSingleSilentPingTree,
  updateSingleSilentPingTree,
} from "../../Store/PingTreeModule/PingTreeSilent";

const AddEditPingTree = (props) => {
  const {
    setOpen,
    isOpen,
    defaultValues,
    isEditMode,
    setIsEditMode,
    TreeType,
  } = props;

  console.log("Tree Datattatatatat==========", TreeType);
  const dispatch = useDispatch();
  //badge options
  const badgeOptions = [
    { label: "Primary", id: "status-1", value: "Primary" },
    { label: "Info", id: "status-2", value: "Info" },
    { label: "Warning", id: "status-3", value: "Warning" },
    { label: "Error", id: "status-4", value: "Error" },
  ];
  // Api Call Intialization
  const [UpdatePingTree, updatePingTreeData] = useApiCallMutation();
  const [CreatePingTree, CreatePingTreeData] = useApiCallMutation();

  // Set Initial Value here
  const initialValues = {
    name: "",
    comment: "",
    status: "",
  };

  // Set Validation Schema here
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2)
      .trim("Name cannot include leading and trailing spaces")
      .max(50)
      .matches(/[A-Za-z]/, "Only numbers are not allowed")
      .required("campaignTag Name is required"),
    comment: Yup.string().required("Field is required"),
  });

  // initialise Form here
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  // const optionsStatus = [
  //   { label: "True", id: 1, value: "true" },
  //   { label: "False", id: 2, value: "false" },
  // ];

  const optionsStatus = [
    { label: "True", id: "status-1", value: "true" },
    { label: "False", id: "status-2", value: "false" },
  ];

  const SetFormValues = (data) => {
    setValue("name", data?.name, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("comment", data?.comment, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("status", `${data?.status}`, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const onFormSubmit = (data) => {
    if (isEditMode) {
      const modifiedData = data;
      modifiedData.treeUniqueId = defaultValues?.treeUniqueId;
      UpdatePingTree({
        endPoint: pingtreeApiEndPoints.updatePingTree,
        method: "POST",
        data: { data: modifiedData },
      });
    } else {
      CreatePingTree({
        endPoint: pingtreeApiEndPoints.createPingTree,
        method: "POST",
        data: { data: { ...data, pingTreeType: TreeType } },
      });
    }
  };
  //Set Incoming Default values
  useEffect(() => {
    if (Object.keys(defaultValues).length && isEditMode) {
      SetFormValues(defaultValues);
    }
  }, []);
  useEffect(() => {
    if (CreatePingTreeData?.isSuccess && !CreatePingTreeData?.data?.error) {
      if (CreatePingTreeData?.isSuccess && !CreatePingTreeData?.data?.error) {
        if (TreeType === "Redirect") {
          dispatch(addSingleRedirectPingTree(CreatePingTreeData.data?.data));
        } else {
          dispatch(addSingleSilentPingTree(CreatePingTreeData.data?.data));
        }
      }

      setOpen(false);
      setIsEditMode(false);
    }
  }, [CreatePingTreeData]);
  useEffect(() => {
    if (updatePingTreeData?.isSuccess && !updatePingTreeData?.data?.error) {
      const updatedDataPingTree = {
        id: defaultValues?.id,
        ...updatePingTreeData.data.data,
      };
      if (TreeType === "Redirect") {
        dispatch(updateSingleRedirectPingTree(updatedDataPingTree));
      } else {
        dispatch(updateSingleSilentPingTree(updatedDataPingTree));
      }

      setOpen(false);
      setIsEditMode(false);
    }
  }, [updatePingTreeData]);

  return (
    <>
      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={() => {
          setOpen(false);
          setIsEditMode(false);
        }}
        sx={{
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            top: "15px",
            borderRadius: "16px",
            width: "100%",
            maxWidth: "500px",
          },
        }}
      >
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent={"space-between"}
          >
            <Box marginBottom={"15px"}>
              <Typography component="h6" variant="h6" fontWeight={600}>
                {(isEditMode && "Update PingTree") || "Add New PingTree"}
              </Typography>
            </Box>
            <Box marginBottom={"15px"} onClick={() => reset()}>
              <Typography component="h7" variant="h7" fontWeight={200}>
                Clear All
              </Typography>
            </Box>
          </Stack>

          <Divider
            sx={{
              mb: "20px",
            }}
          />

          <Box component={"form"}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <GlobalInput
                  control={control}
                  size="small"
                  name="name"
                  label="Name"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  required
                />
              </Grid>
              {isEditMode && (
                <Grid item xs={12}>
                  <GlobalDropdown
                    control={control}
                    size="small"
                    name="status"
                    label="Status"
                    options={optionsStatus}
                    error={!!errors.status}
                    helperText={errors.status?.message}
                    required
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                {/* <GlobalDropdown
                  control={control}
                  size="small"
                  name="badge"
                  label="Badge"
                  options={badgeOptions || []}
                  error={!!errors.badge}
                  helperText={errors.badge?.message}
                /> */}

                <GlobalInput
                  control={control}
                  size="small"
                  name="comment"
                  label="Comment"
                  error={!!errors.comment}
                  helperText={errors.comment?.message}
                  required
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            // pl: "1rem",
          }}
        >
          <Stack
            direction="row"
            component={Box}
            useFlexGap
            flexWrap="wrap"
            justifyContent={"flex-end"}
            bottom={0}
            marginTop={"1rem"}
          >
            <Box marginBottom={"15px"} marginRight={"5px"}>
              <CustomButton
                color="#3a5396"
                // label="Add Campaign"
                label={isEditMode ? "Update" : "Add"}
                onClick={handleSubmit(onFormSubmit)}
              />
            </Box>
            <Box marginBottom={"15px"}>
              <CustomButton
                labelColor="#7a7870"
                variant="outlined"
                color="white"
                label="Close"
                onClick={() => {
                  setOpen(false);
                  setIsEditMode(false);
                }}
              />
            </Box>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};
export default AddEditPingTree;
