import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableData: {
    data: [],
    headers: [],
  },
};

const publisherTags = createSlice({
  name: "publisherTags",
  initialState,
  reducers: {
    updatePublisherTags: (state, action) => {
      state.tableData = action.payload;
    },
    updateSinglePublisherTags: (state, action) => {
      const updatedRow = action.payload;
      const index = state.tableData.data.findIndex((data) => {
        return data?.id === updatedRow?.id;
      });
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.tableData.data[index][key] = updatedRow[key];
      });
    },
    addSinglePublisherTags: (state, action) => {
      state.tableData.data.unshift(action.payload);
    },
    removeSinglePublisherTags: (state, action) => {
      state.tableData.data = state.tableData.data.filter(
        (data) => data.id !== action.payload
        
      );
    },
  },
});

export const {
    updatePublisherTags,
    updateSinglePublisherTags,
    addSinglePublisherTags,
    removeSinglePublisherTags
} = publisherTags.actions;
export default publisherTags.reducer;
