import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterData: {
    fromDate: "2020-06-30T18:30:00.000Z",
  },
};

const mappingFilterSession = createSlice({
  name: "mappingFilterSession",
  initialState,
  reducers: {
    updateMappingFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
});

export const { updateMappingFilterData } = mappingFilterSession.actions;
export default mappingFilterSession.reducer;
