import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterData: {
    fromDate: "2020-06-30T18:30:00.000Z",
  },
};

const plDnplDataFilterSession = createSlice({
  name: "plDnplDataFilterSession",
  initialState,
  reducers: {
    updatePlDnplFilterData: (state, action) => {
      // debugger;
      state.filterData = action.payload;
    },
  },
});

export const { updatePlDnplFilterData } = plDnplDataFilterSession.actions;
export default plDnplDataFilterSession.reducer;
