import React, { useEffect, useState } from "react";
import { AddIcon, Chip, Grid, moment } from "../../Collections/Imports";
import {
  AddOutlined,
  ArrowBack,
  Box,
  PageHeader,
  useLocation,
  useNavigate,
} from "../../Collections/Imports";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import { useDispatch, useSelector } from "react-redux";
import CircularProgressBar from "../../Components/UIComponents/CircularProgressBar";
import AgGridComponent from "../../Components/CommonComponents/AG-Grid";
import AddEditMapping from "../../Forms/AddEditMapping";
import { allPubData, allPubList } from "../../Store/PublisherModule";
import { getData, getChannelListData } from "../../Store/ChannelModule";
import {
  getMappingData,
  removeMappingData,
  mappingListOptions,
} from "../../Store/ChannelMappingModule";
import {
  channelMappingModuleApiEndPoints,
  channelModuleApiEndPoints,
  publisherModuleApiEndPoints,
} from "../../Config/APIsEndpoints";
import { updateMappingFilterData } from "../../Store/ChannelMappingModule/filter";
import Filters from "../../Components/UIComponents/Filters";
import MappingFilters from "../../Forms/MappingFilters/";
function ChannelMapping() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();
  const [isOpenMappingFilter, setIsOpenMappingFilter] = useState(false);
  const [isOpenAddEditBox, setIsOpenAddEditBox] = useState(false);
  const [rowSelected, setRowSelected] = useState({});
  const [defaultMapping, setDefaultMapping] = useState(false);

  const [paginationIndex, setPaginationIndex] = useState(0);
  const [itemPerIndex, setItemPerIndex] = useState(100);
  const [isEditMode, setIsEditMode] = useState(false);
  const [GetPublisherList, PublisherList] = useApiCallMutation();
  const [GetChannelList, ChannelList] = useApiCallMutation();
  const [GetMappingList, MappingList] = useApiCallMutation();
  const [DeleteMapping, setDeleteMapping] = useApiCallMutation();
  const previousFilterData = useSelector(
    (state) => state?.mappingFilterSession?.filterData
  );

  const pubOptions = useSelector(
    (state) => state.publisherData?.data?.allPubData?.publishers
  );

  // Extract unique publishers along with pubUniqueId
  const uniquePublishers = (pubOptions || []).reduce((unique, current) => {
    if (!unique[current.publisherName]) {
      unique[current.publisherName] = current.pubUniqueId;
    }
    return unique;
  }, {});

  const publisherOptionsList = Object.entries(uniquePublishers).map(
    ([name, id]) => ({
      value: id,
      label: name,
    })
  );

  const channelsData = useSelector(
    (state) => state.ChannelData?.data?.channelData?.Channels
  );
  // Extract unique channels along with Id
  const uniqueChannels = (channelsData || []).reduce((unique, current) => {
    if (!unique[current.channelName]) {
      unique[current.channelName] = current.channelUniqueId;
    }
    return unique;
  }, {});

  const channelOptionsList = Object.entries(uniqueChannels).map(
    ([name, id]) => ({
      value: id,
      label: name,
    })
  );
  const mappingData = useSelector((state) => state.MappingData?.data);
  const handleActions = (previousData, action) => {
    switch (action) {
      case "EDIT":
        setRowSelected(previousData);
        setIsEditMode(true);
        setIsOpenAddEditBox(true);
        break;
      case "DELETE":
        // Optimistically remove the deleted item from the UI
        dispatch(removeMappingData(previousData.mappingId));

        DeleteMapping({
          endPoint: channelMappingModuleApiEndPoints.deleteChannelMapping,
          method: "POST",
          data: {
            data: {
              mappingId: previousData.mappingId,
            },
          },
        });
        break;
      default:
        break;
    }
  };
  const handlePageChange = (data) => {
    setPaginationIndex(data);
  };
  const handleRowsPerPageChange = (data) => {
    setItemPerIndex(data);
    setPaginationIndex(0);
  };
  const perPageCountOptions = [
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "1000", value: 1000 },
  ];
  const [selectedDateFilter, setSelectedDateFilter] = useState({
    startDate: new Date("July 01, 2020"),
    endDate: new Date(),
    key: "selection",
  });
  const statusOptions = [
    { label: "Active", value: "true" },
    { label: "Disabled", value: "false" },
  ];

  const ClearFilters = () => {
    const filterData = {
      source: ["all"],
      mappingId: ["all"],
      pubUniqueId: ["all"],
      sourceWebsite: ["all"],
      fromDate: moment(new Date("July 01, 2020")).toISOString(),
      toDate: moment().endOf("day").toDate(),
    };

    dispatch(updateMappingFilterData(filterData));
    // reset();
  };

  const toggleDrawer = (ele) => {
    ele === "created"
      ? setDefaultMapping("created")
      : ele === "source"
      ? setDefaultMapping("source")
      : ele === "publisher"
      ? setDefaultMapping("publisher")
      : ele === "channel"
      ? setDefaultMapping("channel")
      : ele === "status"
      ? setDefaultMapping("status")
      : setDefaultMapping(false);

    setIsOpenMappingFilter((prev) => !prev);
  };

  //set Preselected Filter label
  const setFilterLabel = (filterType) => {
    switch (filterType) {
      case "publisher":
        const puboptions = publisherOptionsList?.filter((opt) =>
          previousFilterData?.pubUniqueId?.find((val) => val == opt.value)
        );
        return getFilterLabel("pubUniqueId", puboptions);

        break;
      case "status":
        const statusoptions = statusOptions?.filter(
          (opt) => previousFilterData?.status == opt.value
        );
        return getFilterLabel("status", statusoptions);
        break;
      case "channel":
        const channeloptions = channelOptionsList?.filter((opt) =>
          previousFilterData?.channelUniqueId?.find((val) => val == opt.value)
        );
        return getFilterLabel("channelUniqueId", channeloptions);
        break;
      case "source":
        // const labeloptions = channelLabelOptions?.filter((opt) =>
        //   previousFilterData?.source?.find((val) => val == opt.value)
        // );
        return getFilterLabel("source", previousFilterData?.source);
        break;
    }
  };

  // get Preselected Filter label
  const getFilterLabel = (filterType, options) => {
    console.log("====", filterType, options);
    if (options?.length === 1) {
      if (options[0]?.label != undefined && options[0]?.label != null) {
        return (
          <span>
            <Chip label={options[0]?.label} sx={{ m: "2px" }} size="small" />
          </span>
        );
      } else {
        return (
          <span>
            <Chip label={options[0]} sx={{ m: "2px" }} size="small" />
          </span>
        );
      }
    } else if (options?.length > 1) {
      if (options[0]?.label != undefined && options[0]?.label != null) {
        return (
          <>
            <span>
              <Chip label={options[0]?.label} sx={{ m: "2px" }} size="small" />
            </span>
            <span
              style={{ cursor: "pointer", color: "#000", paddingLeft: "4px" }}
            >
              {`+${previousFilterData[filterType]?.length - 1}`}
            </span>
          </>
        );
      } else {
        return (
          <>
            <span>
              <Chip label={options[0]} sx={{ m: "2px" }} size="small" />
            </span>
            <span
              style={{ cursor: "pointer", color: "#000", paddingLeft: "4px" }}
            >
              {`+${previousFilterData[filterType]?.length - 1}`}
            </span>
          </>
        );
      }
    } else {
      return (
        <span>
          <Chip label={"All"} sx={{ m: "2px" }} size="small" />
        </span>
      );
    }
  };

  useEffect(() => {
    GetPublisherList({
      endPoint: publisherModuleApiEndPoints.getAllPubs,
      method: "POST",
      data: {
        data: {
          index: 0,
          itemPerIndex: 2000,
          fromDate: moment(new Date("July 01, 2020")).toISOString(),
          toDate: moment(new Date()).toISOString(),
          publisherNames: [],
          emails: [],
        },
      },
    });

    GetChannelList({
      endPoint: channelModuleApiEndPoints.getAllChannels,
      method: "POST",
      data: {
        data: {
          index: 0,
          itemPerIndex: 2000,
          fromDate: moment(new Date("July 01, 2020")).toISOString(),
          toDate: moment(new Date()).toISOString(),
          publisherNames: [],
          status: "all",
          channelNames: [],
          channelLabels: [],
        },
      },
    });

    GetMappingList({
      endPoint: channelMappingModuleApiEndPoints.getAllChannelMapping,
      method: "POST",
      data: {
        data: {
          index: paginationIndex,
          itemPerIndex: itemPerIndex,
          fromDate: moment(previousFilterData?.fromDate).toISOString(),
          toDate:
            moment(previousFilterData?.toDate).toISOString() == ""
              ? moment(selectedDateFilter?.endDate).toISOString()
              : moment(previousFilterData?.toDate).toISOString(),
          channelUniqueId: previousFilterData?.channelUniqueId || ["all"],
          source: previousFilterData?.source || ["all"],
          mappingId: ["all"],
          pubUniqueId: previousFilterData?.pubUniqueId || ["all"],
          sourceWebsite: previousFilterData?.sourceWebsite || ["all"],
          status: previousFilterData?.status || "all",
        },
      },
    });
  }, []);

  useEffect(() => {
    GetMappingList({
      endPoint: channelMappingModuleApiEndPoints.getAllChannelMapping,
      method: "POST",
      data: {
        data: {
          index: paginationIndex,
          itemPerIndex: itemPerIndex,
          fromDate: moment(previousFilterData?.fromDate).toISOString(),
          toDate:
            moment(previousFilterData?.toDate).toISOString() == ""
              ? moment(selectedDateFilter?.endDate).toISOString()
              : moment(previousFilterData?.toDate).toISOString(),
          channelUniqueId: previousFilterData?.channelUniqueId || ["all"],
          source: previousFilterData?.source || ["all"],
          mappingId: ["all"],
          pubUniqueId: previousFilterData?.pubUniqueId || ["all"],
          sourceWebsite: previousFilterData?.sourceWebsite || ["all"],
          status: previousFilterData?.status || "all",
        },
      },
    });
  }, [previousFilterData]);

  useEffect(() => {
    if (PublisherList?.isSuccess && PublisherList?.data?.data?.publishers) {
      dispatch(allPubData(PublisherList?.data?.data));
      const pubDropdownList = PublisherList?.data?.data?.publishers?.map(
        (publisher) => {
          return {
            label: publisher?.publisherName,
            value: publisher?.publisherName,
          };
        }
      );
      dispatch(allPubList(pubDropdownList));
    }
  }, [PublisherList]);

  useEffect(() => {
    if (ChannelList?.isSuccess && ChannelList?.data?.data?.Channels) {
      dispatch(getData(ChannelList?.data?.data));
      const channelList = ChannelList.data.data.Channels.map(
        ({ id, channelName, channelUniqueId }) => ({
          value: channelName,
          label: channelName,
          id: channelUniqueId,
        })
      );
      dispatch(getChannelListData(channelList));
    }
  }, [ChannelList]);

  useEffect(() => {
    if (
      MappingList?.isSuccess &&
      MappingList?.data?.data?.sourceChannelMappings
    ) {
      dispatch(getMappingData(MappingList?.data?.data));
      const mappingList = MappingList.data.data.sourceChannelMappings.map(
        ({ id, mappingId }) => ({
          value: mappingId,
          label: mappingId,
          id: id,
        })
      );
      dispatch(mappingListOptions(mappingList));
    }
  }, [MappingList]);

  return (
    <>
      {/* Page Header Start */}
      <PageHeader
        title={"Channel Mapping"}
        backIcon={
          location?.pathname === "/channel-mapping" ? null : (
            <ArrowBack sx={{ color: "#0c247b" }} />
          )
        }
        onBack={() => navigate(-1)}
        helpText={
          "This section allows you to map channels with custom publisher id incase of posting through a website instead of direct POST."
        }
      />
      {/* Page Header End */}
      {/* Filters Start*/}
      <Box sx={{ pl: "16px" }} mt={5}>
        <Grid container spacing={2}>
          <Filters title="Status:" onClick={() => toggleDrawer("status")}>
            {setFilterLabel("status")}
          </Filters>
          <Filters title="Source:" onClick={() => toggleDrawer("source")}>
            {setFilterLabel("source")}
          </Filters>
          <Filters title="Publisher:" onClick={() => toggleDrawer("publisher")}>
            {setFilterLabel("publisher")}
          </Filters>
          <Filters title="Channel :" onClick={() => toggleDrawer("channel")}>
            {setFilterLabel("channel")}
          </Filters>
          {/* <Filters title="Website :" onClick={() => toggleDrawer("website")}>
            {setFilterLabel("website")}
          </Filters> */}
          <Filters title="Created :" onClick={() => toggleDrawer("created")}>
            <Box>
              <span style={{ fontSize: "14px" }}>
                <Chip
                  label={moment(
                    previousFilterData?.fromDate
                      ? previousFilterData?.fromDate
                      : selectedDateFilter?.startDate
                  ).format("ll")}
                  sx={{ m: "2px" }}
                  size="small"
                />
              </span>
              {" - "}
              <span style={{ fontSize: "14px" }}>
                <Chip
                  label={moment(
                    previousFilterData?.toDate
                      ? previousFilterData?.toDate
                      : selectedDateFilter?.endDate
                  ).format("ll")}
                  sx={{ m: "2px" }}
                  size="small"
                />
              </span>
            </Box>
          </Filters>
          <Filters
            title="Filters"
            icons={<AddIcon />}
            onClick={() => toggleDrawer("filters")}
          ></Filters>
          <Filters title="Clear All" onClick={ClearFilters}></Filters>
        </Grid>
      </Box>
      {/* Filters End*/}
      {/* =========================================================================================== */}

      {mappingData.isLoading && <CircularProgressBar />}
      {!mappingData.isLoading && (
        <Box>
          <AgGridComponent
            rowData={mappingData?.sourceChannelMappings || []}
            columnDefs={mappingData?.headers || []}
            actions={["EDIT", "DELETE"]}
            performAction={handleActions}
            totalPageCount={mappingData?.total || 0}
            paginationIndex={paginationIndex}
            itemPerIndex={itemPerIndex}
            perPageCountOptions={perPageCountOptions}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            addAction={setIsOpenAddEditBox}
            AddButtonName={"Add New Mapping"}
            addActionIcon={<AddOutlined />}
            downlodBtn={true}
            tableName={"Mappings"}
            rowMode
          />
        </Box>
      )}
      {/* Publisher Add Edit Form Start*/}
      {isOpenAddEditBox && (
        <AddEditMapping
          defaultValues={rowSelected}
          isOpen={isOpenAddEditBox}
          setOpen={setIsOpenAddEditBox}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
        />
      )}

      {isOpenMappingFilter && (
        <MappingFilters
          defaultValues={rowSelected}
          isOpen={isOpenMappingFilter}
          setOpen={setIsOpenMappingFilter}
          defaultChannel={defaultMapping}
          ClearFilters={ClearFilters}
        />
      )}
    </>
  );
}

export default ChannelMapping;
