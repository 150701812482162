import React from "react";
import {
  Box,
  Tab,
  Tabs,
  Divider,
  Typography,
  TabList,
  TabContext,
  TabPanel,
  RuleOutlinedIcon,
  ContentCopy,
  LanguageOutlinedIcon,
  FilterAltOutlinedIcon,
  CalendarMonthOutlinedIcon,
  SwitchAccountOutlinedIcon,
  DataObjectOutlinedIcon,
} from "./../../../Collections/Imports";
import PropTypes from "prop-types";
import { useTabContext } from "../../../Collections/Tabcontext";
import CampaignFilters from "./campaignFilters";
import Global from "./Global";
import DuplicateSetting from "./Duplicate";
import "./style.css";
import CampaignShedule from "./campaignShedule";
import PLDNPL from "./PLDNPL";
import Integration from "./Integration";
import FilterByPub from "./FilterByPub";

const CampaignConfiguration = () => {
  const { tabValue, handleChange } = useTabContext();
  const tabIconColorActive = "#97B7F9";
  const tabTextColorActive = "#3E7DFD";
  const tabIconColorInactive = "#525252";
  const tabTextColorInactive = "#525252";

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabContext value={tabValue}>
          <TabList
            onChange={handleChange}
            className="tabList"
            sx={{
              p: 0,
              m: 0,
              height: "15px",
              "& .MuiTabs-indicator": {
                borderRadius: "4px 4px 0px 0px",
                borderBottom: "3px solid #3E7DFD",
              },
            }}
          >
            <Tab
              icon={
                <LanguageOutlinedIcon
                  style={{
                    fill:
                      tabValue === 1
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Global"
              value={1}
              className="tabHeading"
              style={{
                color:
                  tabValue === 1 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 1 ? 600 : 400,
              }}
            />
            <Tab
              icon={
                <DataObjectOutlinedIcon
                  style={{
                    fill:
                      tabValue === 2
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Integration"
              value={2}
              className="tabHeading"
              style={{
                color:
                  tabValue === 2 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 2 ? 600 : 400,
              }}
            />
            <Tab
              icon={
                <ContentCopy
                  style={{
                    fill:
                      tabValue === 3
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Duplicates"
              value={3}
              className="tabHeading"
              style={{
                color:
                  tabValue === 3 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 3 ? 600 : 400,
              }}
            />
            <Tab
              icon={
                <FilterAltOutlinedIcon
                  style={{
                    fill:
                      tabValue === 4
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Filters"
              value={4}
              className="tabHeading"
              style={{
                color:
                  tabValue === 4 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 4 ? 600 : 400,
              }}
            />
            <Tab
              icon={
                <RuleOutlinedIcon
                  style={{
                    fill:
                      tabValue === 5
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="PL/DNPL"
              value={5}
              className="tabHeading"
              style={{
                color:
                  tabValue === 5 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 5 ? 600 : 400,
              }}
            />
            <Tab
              icon={
                <CalendarMonthOutlinedIcon
                  style={{
                    fill:
                      tabValue === 6
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Schedule"
              value={6}
              className="tabHeading"
              style={{
                color:
                  tabValue === 6 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 6 ? 600 : 400,
              }}
            />
            <Tab
              icon={
                <SwitchAccountOutlinedIcon
                  style={{
                    fill:
                      tabValue === 7
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Filter by Pubs"
              value={7}
              className="tabHeading"
              style={{
                color:
                  tabValue === 7 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 7 ? 600 : 400,
              }}
            />
          </TabList>
          <Divider />
          <Box>
            {/* global setting tab */}
            <TabPanel
              value={1}
              sx={{ background: "#fff", padding: "0", pt: "22px" }}
            >
              <Global />
            </TabPanel>
            {/* integration setting tab */}
            <TabPanel
              value={2}
              sx={{ background: "#fff", padding: "0", pt: "22px" }}
            >
              <Integration />
            </TabPanel>
            {/* duplicate setting tab */}
            <TabPanel
              value={3}
              sx={{ background: "#fff", padding: "0", pt: "22px" }}
            >
              <DuplicateSetting />
            </TabPanel>
            {/* filters setting tab */}
            <TabPanel
              value={4}
              sx={{ background: "#fff", padding: "0", pt: "22px" }}
            >
              <CampaignFilters />
            </TabPanel>
            {/* pl/dnpl setting tab */}
            <TabPanel
              value={5}
              sx={{ background: "#fff", padding: "0", pt: "22px" }}
            >
              <PLDNPL />
            </TabPanel>
            {/* schedule setting tab */}
            <TabPanel
              value={6}
              sx={{ background: "#fff", padding: "0", pt: "0px" }}
            >
              <CampaignShedule />
            </TabPanel>
            {/* test lead tab */}
            <TabPanel
              value={7}
              sx={{ background: "#fff", padding: "0", pt: "22px" }}
            >
              <FilterByPub />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </Box>
  );
};

export default CampaignConfiguration;
