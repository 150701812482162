import React from "react";
import {
  Grid,
  Chip,
  useEffect,
  useState,
  Box,
  moment,
  PageHeader,
  useNavigate,
  useLocation,
  AddOutlined,
  AddIcon,
  ArrowBack,
} from "../../Collections/Imports";
import Filters from "../../Components/UIComponents/Filters";
import { useApiCallMutation } from "../../Services/ApiCallService";
import { useDispatch, useSelector } from "react-redux";
import AgGridComponent from "../../Components/CommonComponents/AG-Grid";
import { Outlet } from "react-router-dom";
import CircularProgressBar from "../../Components/UIComponents/CircularProgressBar";
import "@inovua/reactdatagrid-community/theme/blue-light.css";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/index.css";
import { leadDetailsModuleApiEndPoints } from "../../Config/APIsEndpoints";
import LeadeDetailsFilter from "../../Forms/LeadDetailsFilter";
import { allLeadData } from "../../Store/LeadDetailsModule";
import { updateLeadDetailsFilterData } from "../../Store/LeadDetailsModule/filter";
import LeadInfoIdButton from "./CustomeCellRenderer/LeadInfoIdButton";

function LeadDetails() {
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const TableData = useSelector(
    (state) => state.LeadDetailsData?.data?.allLeadData
  );
  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    if (TableData && TableData.headers && TableData.leads) {
      const headers = TableData.headers.map((header) => {
        if (header.field === "id") {
          return {
            ...header,
            cellRenderer: LeadInfoIdButton,
            cellStyle: {
              backgroundColor: "rgb(226, 226, 226)",
            },
          };
        }
        return header;
      });

      setColumnDefs(headers);
      setRowSelected(TableData.leads);
    }
  }, [TableData]);

  const leadOptions = useSelector(
    (state) => state.LeadDetailsData?.data?.allLeadDetailsList
  );
  // Form pagination handler
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [isOpenLeadFilter, setIsOpenLeadFilter] = useState(false);
  const [defaultLead, setDefaultLead] = useState(false);
  const [itemPerIndex, setItemPerIndex] = useState(100);
  const [leadId, setLeadId] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [rowSelected, setRowSelected] = useState({});
  const perPageCountOptions = [
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "1000", value: 1000 },
  ];
  const statusOptions = [
    { label: "Active", value: "true" },
    { label: "Disabled", value: "false" },
  ];
  const [GetLeadDetailsList, LeadDetailsList] = useApiCallMutation();

  const previousFilterData = useSelector(
    (state) => state?.leadDetailsFilterSession?.filterData
  );
  const LeadData = {
    index: paginationIndex,
    itemPerIndex: itemPerIndex,
    fromDate: moment(previousFilterData?.fromDate).toISOString(),
    // fromDate: "2024-05-08T18:30:00.000Z",
    toDate:
      moment(previousFilterData?.toDate).toISOString() == ""
        ? moment(selectedDateFilter?.endDate).toISOString()
        : moment(previousFilterData?.toDate).toISOString(),
    // toDate: "2024-06-14T18:29:59.999Z",
    sources: [
      // "2222545"
    ],
    campaignIds: [
      // "66583e6f9a94503491997d13",
      // "665830409a94503491997d0d"
      // "66583dc19a94503491997d12"
    ],
    leadIds: [
      // "6666f2a82f8682744861a724"
    ],
    buyerIds: [
      // "664f1f1191699335a46c2670"
      // "655deb05a1dfd265af2511a4"
      // "655ee426b60ede7e5c2176ad"
    ],
    // pubUniqueIds: [
    // 4
    // ],
    channelUniqueIds: [
      // 2
    ],
    pubTagIds: [],
    status: "all", //Sold, Rejected, all
  };

  useEffect(() => {
    GetLeadDetailsList({
      endPoint: leadDetailsModuleApiEndPoints.getAllLeadDetails,
      method: "POST",
      data: {
        data: { ...LeadData },
      },
    });
  }, [previousFilterData]);

  useEffect(() => {
    if (LeadDetailsList?.data?.data?.leads) {
      dispatch(allLeadData(LeadDetailsList?.data?.data));
    }
  }, [LeadDetailsList]);
  const handlePageChange = (data) => {
    setPaginationIndex(data);
  };
  const handleRowsPerPageChange = (data) => {
    setItemPerIndex(data);
    setPaginationIndex(0);
  };

  const ClearFilters = () => {
    const filterData = {
      status: "All",
      publisher: ["All"],
      leadId: ["All"],
      email: ["All"],
      fromDate: selectedDateFilter?.startDate,
      toDate: moment().endOf("day").toDate(),
    };
    dispatch(updateLeadDetailsFilterData(filterData));
    // reset();
  };

  const toggleDrawer = (ele) => {
    console.log("=-=-=-=-=-=-=-=-=-==--", ele);
    ele === "created"
      ? setDefaultLead("created")
      : ele === "publisher"
      ? setDefaultLead("publisher")
      : ele === "email"
      ? setDefaultLead("email")
      : ele === "status"
      ? setDefaultLead("status")
      : ele === "leadId"
      ? setDefaultLead("leadId")
      : setDefaultLead(false);
    setIsOpenLeadFilter((prev) => !prev);
  };

  const [selectedDateFilter, setSelectedDateFilter] = useState({
    startDate: new Date("July 01, 2020"),
    endDate: new Date(),
    key: "selection",
  });

  // get Preselected Filter label
  const getFilterLabel = (filterType, options) => {
    if (options?.length === 1) {
      return (
        <span>
          <Chip label={options[0]?.label} sx={{ m: "2px" }} size="small" />
        </span>
      );
    } else if (options?.length > 1) {
      return (
        <>
          <span>
            <Chip label={options[0]?.label} sx={{ m: "2px" }} size="small" />
          </span>
          <span
            style={{ cursor: "pointer", color: "#000", paddingLeft: "4px" }}
          >
            {`+${previousFilterData[filterType]?.length - 1}`}
          </span>
        </>
      );
    } else {
      return (
        <span>
          <Chip label={"All"} sx={{ m: "2px" }} size="small" />
        </span>
      );
    }
  };
  //set Preselected Filter label
  const setFilterLabel = (filterType) => {
    switch (filterType) {
      case "publisher":
        if (previousFilterData?.leads != "") {
          const leadoptions = leadOptions?.filter((opt) =>
            previousFilterData?.leads?.find((val) => val == opt.value)
          );
          return getFilterLabel("publisher", leadoptions);
        }
        break;
      case "status":
        const statusoptions = statusOptions?.filter(
          (opt) => previousFilterData?.status == opt.value
        );
        return getFilterLabel("status", statusoptions);
        break;
    }
  };

  useEffect(() => {
    if (location?.pathname === "/lead-details") {
      setLeadId("");
    } else {
      const currentLeadData = JSON.parse(
        sessionStorage.getItem("currentLeadData")
      );
      setLeadId(currentLeadData.id);
    }
  }, [location]);

  return (
    <>
      {/* Page Header Start */}
      <PageHeader
        title={leadId || "Lead Details"}
        backIcon={
          location?.pathname === "/lead-details" ? null : (
            <ArrowBack sx={{ color: "#0c247b" }} />
          )
        }
        onBack={() => navigate(-1)}
        helpText={
          "This section is a complete list of all active leads registered in the system with information about their User IDs, Usernames, E-Mails, Statuses, and Registration Date. You can select any Publisher and change their settings, statuses, or confirm the accuracy of their profiles. You can also use the 'View' setting and see details for their activity in the system."
        }
      />
      {/* Page Header End */}

      {location?.pathname === "/lead-details" && (
        <>
          <Box sx={{ pl: "16px" }} mt={5}>
            <Grid container spacing={2}>
              <Filters title="Lead Id:" onClick={() => toggleDrawer("leadId")}>
                {setFilterLabel("leadId")}
              </Filters>
              <Filters
                title="Publisher:"
                onClick={() => toggleDrawer("publisher")}
              >
                {setFilterLabel("publisher")}
              </Filters>
              <Filters title="Email:" onClick={() => toggleDrawer("email")}>
                {setFilterLabel("email")}
              </Filters>
              <Filters title="Status :" onClick={() => toggleDrawer("status")}>
                {setFilterLabel("status")}
              </Filters>
              <Filters
                title="Created :"
                onClick={() => toggleDrawer("created")}
              >
                <Box>
                  <span style={{ fontSize: "14px" }}>
                    <Chip
                      label={moment(
                        previousFilterData?.fromDate
                          ? previousFilterData?.fromDate
                          : selectedDateFilter?.startDate
                      ).format("ll")}
                      sx={{ m: "2px" }}
                      size="small"
                    />
                  </span>
                  {" - "}
                  <span style={{ fontSize: "14px" }}>
                    <Chip
                      label={moment(
                        previousFilterData?.toDate
                          ? previousFilterData?.toDate
                          : selectedDateFilter?.endDate
                      ).format("ll")}
                      sx={{ m: "2px" }}
                      size="small"
                    />
                  </span>
                </Box>
              </Filters>
              <Filters title="Label :" onClick={() => toggleDrawer("label")}>
                {setFilterLabel("label")}
              </Filters>
              <Filters
                icons={<AddIcon />}
                title="Filters"
                onClick={() => toggleDrawer("filters")}
              ></Filters>
              <Filters title="Clear All" onClick={ClearFilters}></Filters>
            </Grid>
          </Box>

          {/* Filters End*/}

          {LeadDetailsList.status === "isUninitialized" && (
            <CircularProgressBar />
          )}

          {/* Data Table Start */}
          {LeadDetailsList.status !== "isUninitialized" && (
            <Box>
              <AgGridComponent
                rowData={TableData?.leads || []}
                // columnDefs={TableData?.headers || []}
                columnDefs={columnDefs}
                frameworkComponents={{
                  LeadInfoIdButton: LeadInfoIdButton,
                }}
                totalPageCount={LeadDetailsList?.data?.total}
                paginationIndex={paginationIndex}
                itemPerIndex={itemPerIndex}
                perPageCountOptions={perPageCountOptions}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowMode
                // addAction={setIsOpenAddEditBox}
                AddButtonName={"Add New Publisher"}
                addActionIcon={<AddOutlined />}
              />
            </Box>
          )}
          {/* Data Table End */}

          {/* Lead Details Filter Form */}
          {isOpenLeadFilter && (
            <LeadeDetailsFilter
              defaultValues={rowSelected}
              isOpen={isOpenLeadFilter}
              setOpen={setIsOpenLeadFilter}
              isEditMode={isEditMode}
              defaultCampaign={defaultLead}
              ClearFilters={ClearFilters}
            />
          )}
          {/* Lead Details Filter Form */}
        </>
      )}
      <div>
        <Outlet />
      </div>
    </>
  );
}

export default LeadDetails;
