import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    getpublisherGroupBy: [],
    allPublisherGroupByList: [],
    getChannelGroupBy: [],
    getSourceGroupBy: [],
    getDateGroupBy: [],
  },
};

const LeadReportsGroupBy = createSlice({
  name: "LeadReportsGroupBy",
  initialState,
  reducers: {
    getPublisherGroupBy: (state, action) => {
      state.data.getPublisherGroupBy = action.payload;
    },
    allPublisherGroupByList: (state, action) => {
      state.data.allPublisherGroupByList = action.payload;
    },
    getChannelGroupBy: (state, action) => {
      state.data.getChannelGroupBy = action.payload;
    },
    getSourceGroupBy: (state, action) => {
      state.data.getSourceGroupBy = action.payload;
    },
    getDateGroupBy: (state, action) => {
      state.data.getDateGroupBy = action.payload;
    },
  },
});

export const {
  getPublisherGroupBy,
  allPublisherGroupByList,
  getChannelGroupBy,
  getSourceGroupBy,
  getDateGroupBy,
} = LeadReportsGroupBy.actions;
export default LeadReportsGroupBy.reducer;
