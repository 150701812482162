import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  data: {
    bucketBankData: [],
  },
};

const BucketBankData = createSlice({
  name: "bucketBankData",
  initialState,
  reducers: {
    getData: (state, action) => {
      state.data.bucketBankData = action.payload;
    },
    addData: (state, action) => {
      state.data.bucketBankData.unshift(action.payload);
    },
    updateData: (state, action) => {
      const updatedRow = action.payload;
      const index = state.data.bucketBankData.findIndex(
        (data) => data?.bucketBankId === updatedRow?.bucketBankId
      );
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.data.bucketBankData[index][key] = updatedRow[key];
      });
    },
    removeData: (state, action) => {
      state.data.bucketBankData = state.data.bucketBankData.filter(
        (data) => data?.bucketBankId !== action.payload
      );
    },
  },
});

export const { getData,addData,updateData,removeData } = BucketBankData.actions;
export default BucketBankData.reducer;
