import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableData: {
    data: [],
    headers: [],
  },
};

const buyerTags = createSlice({
  name: "buyerTags",
  initialState,
  reducers: {
    updateBuyerTags: (state, action) => {
      state.tableData = action.payload;
    },
    updateSingleBuyerTags: (state, action) => {
      const updatedRow = action.payload;
      const index = state.tableData.data.findIndex((data) => {
        return data?.id === updatedRow?.id;
      });
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.tableData.data[index][key] = updatedRow[key];
      });
    },
    addSingleBuyerTags: (state, action) => {
      state.tableData.data.unshift(action.payload);
    },
    removeSingleBuyerTags: (state, action) => {
      state.tableData.data = state.tableData.data.filter(
        (data) => data.id !== action.payload
        
      );
    },
  },
});

export const {
    updateBuyerTags,
    updateSingleBuyerTags,
    addSingleBuyerTags,
    removeSingleBuyerTags
} = buyerTags.actions;
export default buyerTags.reducer;
