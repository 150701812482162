import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  tableData: {
    data: [],
    headers: [],
  },
};

const PlAndDnpl = createSlice({
  name: "PldnplData",
  initialState,
  reducers: {
    getPlDnplData: (state, action) => {
      state.tableData = action.payload;
    },
    addSinglePlAndDnpl: (state, action) => {
      state.tableData.data.unshift(action.payload);
    },
    updateSinglePlAndDnpl: (state, action) => {
      const updatedRow = action.payload;
      const index = state.tableData.data.findIndex(
        (data) => data?.uniqueId === updatedRow?.uniqueId
      );
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.tableData.data[index][key] = updatedRow[key];
      });
    },

    updateSinglePlAndDnplMasterValue: (state, action) => {
      const updatedRow = action.payload;
      const index = state.tableData.data.findIndex(
        (data) => data?.masterPlDnplId === updatedRow?.masterPlDnplId
      );
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.tableData.data[index][key] = updatedRow[key];
      });
    },

    updateSinglePlAndDnplListCount: (state, action) => {
      const updatedRow = action.payload;
      const index = state.tableData.data.findIndex(
        (data) => data?.id === updatedRow?.id
      );
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.tableData.data[index][key] = updatedRow[key];
      });
    },

    removeSinglePlDnpl: (state, action) => {
      state.tableData.data = state?.tableData?.data?.filter(
        (data) => data.masterPlDnplId !== action.payload
      );
    },
  },
});

export const {
  addSinglePlAndDnpl,
  getPlDnplData,
  updateSinglePlAndDnpl,
  updateSinglePlAndDnplListCount,
  removeSinglePlDnpl,
  updateSinglePlAndDnplMasterValue,
} = PlAndDnpl.actions;
export default PlAndDnpl.reducer;
