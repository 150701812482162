import { useEffect, Box, AddOutlined } from "../../../../Collections/Imports";
import { useApiCallMutation } from "../../../../Services/ApiCallService";
import { useDispatch, useSelector } from "react-redux";
import { leadDetailsModuleApiEndPoints } from "../../../../Config/APIsEndpoints";
import { getRedirect } from "../../../../Store/LeadDetailsModule/LeadInfoBody";
import AgGridComponent from "../../../../Components/CommonComponents/AG-Grid";
const Redirect = () => {
  const dispatch = useDispatch();
  const rowStyle = { background: "" };
  let currentLeadInfo = JSON.parse(sessionStorage.getItem("currentLeadData"));
  const [getAllRedirect, redirectLogs] = useApiCallMutation();
  const tableData = useSelector(
    (state) => state.LeadInfoBody?.data?.getRedirect
  );
  console.log("tableData", tableData);
  console.log("redirectLogs", redirectLogs?.data?.data?.redirect);
  useEffect(() => {
    getAllRedirect({
      endPoint: leadDetailsModuleApiEndPoints.getAllLeadDetailsById,
      method: "POST",
      data: {
        data: {
          leadId: currentLeadInfo.leadId,
        },
      },
    });
  }, []);
  useEffect(() => {
    if (redirectLogs) {
      dispatch(getRedirect(redirectLogs?.data?.data?.redirect));
    }
  }, [redirectLogs]);

  return (
    <Box sx={{ padding: "22px" }}>
      <AgGridComponent
        rowData={tableData?.redirectInfo || []}
        columnDefs={tableData?.headers || []}
        search={false}
        addActionIcon={<AddOutlined />}
        downlodBtn={false}
        tableName={"Redirect Leads"}
        domLayout="autoHeight"
        height={"100%"}
        rowStyle={rowStyle}
      />
    </Box>
  );
};
export default Redirect;
