import React from "react";
import {
  Grid,
  useEffect,
  useState,
  Box,
  Card,
  Stack,
  Typography,
  Divider,
  AddOutlined,
} from "../../../../Collections/Imports";

import CustomButton from "../../../../Components/CommonComponents/GlobalButton";
import AgGridComponent from "../../../../Components/CommonComponents/AG-Grid";

import AddEditPublisherTracking from "../../../../Forms/AddEditPublisherTracking";
import { useApiCallMutation } from "../../../../Services/ApiCallService";
import { publisherModuleApiEndPoints } from "../../../../Config/APIsEndpoints";

import { useDispatch, useSelector } from "react-redux";
import {
  getPublisherTrackingData,
  removeSinglePubTracking,
} from "../../../../Store/PublisherModule/PublisherTrackingModule";
import { updateSnackBarConfig } from "../../../../Store/Global/SnackBar";
function Tracking() {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isOpenAddEditBox, setIsOpenAddEditBox] = useState(false);
  const [rowSelected, setRowSelected] = useState({});

  const [GetPublisherTracking, PublisherTrackingData] = useApiCallMutation();
  const [DeletePubTracking, setDeletePubTracking] = useApiCallMutation();
  const TableData = useSelector((state) => state.PubTrackData?.tableData);
  // console.log("TRACKING  DAYT", PublisherTrackingData?.data?.product?.data);
  const dispatch = useDispatch();
  let currentPubData = JSON.parse(
    sessionStorage.getItem("currentPublisherData")
  );

  // get all publisher data
  useEffect(() => {
    GetPublisherTracking({
      endPoint: publisherModuleApiEndPoints.getPubTracking,
      method: "POST",
      data: {
        data: {
          pubUniqueId: currentPubData.pubUniqueId,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (
      PublisherTrackingData?.isSuccess &&
      PublisherTrackingData?.data?.data?.tracking?.length
    ) {
      const campaignTrackingDataList = [
        ...PublisherTrackingData?.data?.data?.tracking,
      ];

      const tableData = {
        data: campaignTrackingDataList,
        headers: PublisherTrackingData?.data?.data?.headers,
      };

      console.log("tableData Schedule", tableData);

      dispatch(getPublisherTrackingData(tableData));
    }
  }, [PublisherTrackingData]);

  // Handle Grid Button ACtion
  const handleActions = (previousData, action) => {
    switch (action) {
      case "EDIT":
        setRowSelected(previousData);
        setIsEditMode(true);
        setIsOpenAddEditBox(true);
        break;
      // case "CONFIG":
      //   setRowSelected(previousData);
      //   sessionStorage.setItem(
      //     "currentCampaignData",
      //     JSON.stringify(previousData)
      //   );

      //   break;
      // case "Status":
      //   const data = {
      //     id: previousData?.campaignId,
      //     campaignName: previousData?.campaignName,
      //     timezone: previousData?.timezone,
      //     campaignType: previousData?.campaignType,
      //     minPrice: previousData?.minPrice,
      //     campaignIntegrations: previousData?.campaignIntegrations,
      //     status: previousData?.status,
      //   };
      //   break;

      case "DELETE":
        DeletePubTracking({
          endPoint: publisherModuleApiEndPoints.deletePubTracking,
          method: "POST",
          // data: { data: listId },

          data: {
            data: {
              trackingId: previousData.id,
            },
          },
        });
        dispatch(removeSinglePubTracking(previousData.id));
        dispatch(
          updateSnackBarConfig({
            open: true,
            color: "success",
            message: "Data deleted successfully",
          })
        );
        break;

      // UpdateCampaign({
      //   endPoint: campaignModuleAPiEndPoints.updateCampaign,
      //   method: "POST",
      //   data: { data },
      // });
      // break;
    }
  };

  return (
    <>
      <>
        <Box
          sx={{
            padding: "28px",
          }}
        >
          <Box>
            {isOpenAddEditBox && (
              <AddEditPublisherTracking
                defaultValues={rowSelected}
                isOpen={isOpenAddEditBox}
                setOpen={setIsOpenAddEditBox}
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
              />
            )}
          </Box>

          <Box sx={{ background: "white", border: "none", pb: "10px" }}>
            <AgGridComponent
              // rowData={AllowTableData?.data || []}
              // columnDefs={AllowTableData?.headers || []}
              // performAction={handleAllowTableActions}

              addActionIcon={<AddOutlined />}
              addAction={setIsOpenAddEditBox}
              AddButtonName={"Add Rule"}
              search={false}
              tableName={"Event Tracking Rules"}
              performAction={handleActions}
              rowData={TableData?.data || {}}
              columnDefs={TableData?.headers || {}}
              actions={["EDIT", "DELETE"]}
              domLayout="autoHeight"
              height={"100%"}
              rowMode
            />
          </Box>
        </Box>
      </>
    </>
  );
}

export default Tracking;
