import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {
        allLeadData: [],
        allLeadDetailsList: [],
    }
}

const LeadDetailsData = createSlice({
    name: "leadDetailsData",
    initialState,
    reducers: {
        allLeadData: (state, action) => {
            state.data.allLeadData = action.payload;
        },
        allLeadDetailsList: (state, action) => {
            state.data.allLeadDetailsList = action.payload;
          },

    }
})

export const {
    allLeadData,allLeadDetailsList
} = LeadDetailsData.actions;
export default LeadDetailsData.reducer;