import * as React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import styles from './IDButtonRenderer.module.css';
import {useNavigate} from "../../../Collections/Imports"
import { useState } from "../../../Collections/Imports"
const LeadInfoIdButton = (props) => {
  const navigate = useNavigate();
  const [leadId, setLeadId] = useState("");
  const [rowSelected, setRowSelected] = useState({});
    const handleClick = () => {
      // debugger;
      setRowSelected(props.data);
      sessionStorage.setItem("currentLeadData",JSON.stringify(props.data));
      const currentLeadInfoData = JSON.parse(sessionStorage.getItem("currentLeadData")) ; 
      setLeadId(currentLeadInfoData?.leadId)
      navigate(String(props.data.id))
    };
  
    return (
      <div className={styles.button} onClick={handleClick}>
      <VisibilityOutlinedIcon className={styles.buttonIcon} />
      {props.value}
    </div>
    );
  };
  
  export default LeadInfoIdButton;
  
