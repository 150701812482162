import React, { useEffect, useState } from "react";
import { Box, AddOutlined } from "../../../../Collections/Imports";
import { useDispatch, useSelector } from "react-redux";
import { useApiCallMutation } from "../../../../Services/ApiCallService";
import { publisherModuleApiEndPoints } from "../../../../Config/APIsEndpoints";
import AgGridComponent from "../../../../Components/CommonComponents/AG-Grid";
import AddEditPubBucketRule from "../../../../Forms/AddEditPubBucketRule";
import {
  getData,
  removeData,
} from "../../../../Store/PublisherModule/bucketBank";
import { updateSnackBarConfig } from "../../../../Store/Global/SnackBar";

export default function BucketBank() {
  const dispatch = useDispatch();
  const [isOpenAddEditBox, setIsOpenAddEditBox] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [rowSelected, setRowSelected] = useState({});
  const [GetBucketBank, getBucketBankData] = useApiCallMutation();
  const [DeleteBucket, DeleteBucketData] = useApiCallMutation();
  let currentPubData = JSON.parse(
    sessionStorage.getItem("currentPublisherData")
  );
  const TableData = useSelector(
    (state) => state.bucketBank?.data?.bucketBankData
  );
  const [itemPerIndex, setItemPerIndex] = useState(100);
  const [paginationIndex, setPaginationIndex] = useState(0);
  const perPageCountOptions = [
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "1000", value: 1000 },
  ];
  // Handle Grid Button ACtion
  const handleActions = (previousData, action) => {
    switch (action) {
      case "EDIT":
        setRowSelected(previousData);
        setIsEditMode(true);
        setIsOpenAddEditBox(true);
        break;
      case "DELETE":
        const listId = {
          bucketBankId: previousData?.bucketBankId,
        };
        DeleteBucket({
          endPoint: publisherModuleApiEndPoints.deleteBucketBank,
          method: "POST",
          data: { data: listId },
        });
        dispatch(
          updateSnackBarConfig({
            open: true,
            color: "success",
            message: "Data deleted successfully",
          })
        );
        dispatch(removeData(previousData?.bucketBankId));
        break;
        break;
    }
  };
  useEffect(() => {
    GetBucketBank({
      endPoint: publisherModuleApiEndPoints.getBucketBank,
      method: "POST",
      data: {
        data: {
          pubUniqueId: currentPubData.pubUniqueId,
        },
      },
    });
  }, [paginationIndex, itemPerIndex]);

  useEffect(() => {
    if (
      getBucketBankData?.isSuccess &&
      getBucketBankData?.data?.data?.bucketBanks?.length
    ) {
      dispatch(getData(getBucketBankData?.data?.data?.bucketBanks));
    }
  }, [getBucketBankData]);
  return (
    <>
      <Box
        sx={{
          padding: "22px",
        }}
      >
        <Box>
          <AddEditPubBucketRule
            defaultValues={rowSelected}
            isOpen={isOpenAddEditBox}
            setOpen={setIsOpenAddEditBox}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
          />
        </Box>
        <AgGridComponent
          // rowData={AllowTableData?.data || []}
          // columnDefs={AllowTableData?.headers || []}
          // performAction={handleAllowTableActions}
          // search={false}
          rowData={TableData || []}
          columnDefs={getBucketBankData?.data?.data?.headers || []}
          search={false}
          addActionIcon={<AddOutlined />}
          addAction={setIsOpenAddEditBox}
          AddButtonName={"Add Bucket Rule"}
          downlodBtn={false}
          tableName={"Bucket Bank Setting"}
          performAction={handleActions}
          actions={["EDIT", "DELETE"]}
          domLayout="autoHeight"
          height={"100%"}
          rowMode
        />
      </Box>
      {/* <Box sx={{ width: "100%", margin: "0 auto" }}>
      <Box sx={{ width: "70%",float:"left"}}>
      <Box sx={{ background: "white", border: "none", pb: "10px" }}>
            <AgGridComponent
              // rowData={AllowTableData?.data || []}
              // columnDefs={AllowTableData?.headers || []}
              // performAction={handleAllowTableActions}
              search={false}
              rowData={[]}
              columnDefs={[]}
              actions={["EDIT", "DELETE"]}
              domLayout="autoHeight"
              height={"100%"}
              rowMode
            />
          </Box>
      </Box>
      <Box sx={{ width: "30%",float:"left"}}>
        <Box marginBottom={"15px"}>
          <Typography component="h6" variant="h6" fontWeight={600}>
            Bucket Bank Setting
          </Typography>
        </Box>
        <Divider sx={{ mb: "20px" }} />
        <div>
          <Box component={"form"}>
            <Grid container>
              <Grid item xs={12} sx={{ mt: "10px" }}>
                <GlobalDropdown
                  control={bucketBankControl}
                  size="small"
                  options={[
                    { label: "Publisher Account", value: "group_by_pub" },
                    { label: "Group by Channel", value: "group_by_channel" },
                    { label: "Group by Source", value: "group_by_source" },
                  ]}
                  name="bankType"
                  label="Bucket Type"
                  error={!!bucketBankErrors.bankType}
                  helperText={bucketBankErrors.bankType?.message}
                  required
                />
              </Grid>

              <Grid item xs={12} sx={{ mt: "10px" }}>
                <GlobalInput
                  control={bucketBankControl}
                  size="small"
                  name="thresholdAmount"
                  label="Threshould Amount"
                  error={!!bucketBankErrors.thresholdAmount}
                  helperText={bucketBankErrors.thresholdAmount?.message}
                  required
                />
              </Grid>
              <Box
                sx={{
                  width: "100%",
                  borderTop: "1px solid #DCE0E6",
                  marginTop: "10px",
                }}
              >
                <Stack
                  direction="row"
                  component={Box}
                  useFlexGap
                  flexWrap="wrap"
                  justifyContent={"flex-end"}
                  bottom={0}
                  marginTop={"1rem"}
                >
                  <Box
                    marginBottom={"15px"}
                    marginRight={"5px"}
                    float={"right"}
                  >
                    <CustomButton
                      color="#3a5396"
                      label="Save"
                      onClick={handleSubmit(onFormSubmit)}
                    />
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Box>
        </div>
      </Box>
      </Box> */}
    </>
  );
}
