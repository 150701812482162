import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableData: {
    data: [],
    headers: [],
  },
};

const PingTreeRedirect = createSlice({
  name: "PingTreeRedirect",
  initialState,
  reducers: {
    getTableDataRedirect: (state, action) => {
      state.tableData = action.payload;
    },

    // addSinglePingTree: (state, action) => {
    //   state.tableData.data.unshift(action.payload);
    // },
    addSingleRedirectPingTree: (state, action) => {
      state.tableData.data.unshift(action.payload);
    },
    updateSingleRedirectPingTree: (state, action) => {
      const updatedRow = action.payload;
      const index = state.tableData.data.findIndex((data) => {
        return data?.treeUniqueId === updatedRow?.treeUniqueId;
      });
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.tableData.data[index][key] = updatedRow[key];
      });
    },

    removeSingleRedirectPingTree: (state, action) => {
      state.tableData.data = state.tableData.data.filter(
        (data) => data.treeUniqueId !== action.payload
      );
    },

    updateSinglePingTree: (state, action) => {
      const updatedRow = action.payload;
      const index = state.tableData.data.findIndex((data) => {
        return data?.treeUniqueId === updatedRow?.treeUniqueId;
      });
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.tableData.data[index][key] = updatedRow[key];
      });
    },
    removeSinglePingTree: (state, action) => {
      state.tableData.data = state.tableData.data.filter(
        (data) => data.treeUniqueId !== action.payload
      );
    },
  },
});

export const {
  getTableDataRedirect,
  addSingleRedirectPingTree,
  updateSingleRedirectPingTree,
  //   addSinglePingTree,

  //   updateSinglePingTree,
  removeSingleRedirectPingTree,
  // addSingleBuyerTags,
  // removeSingleBuyerTags,
} = PingTreeRedirect.actions;
export default PingTreeRedirect.reducer;
