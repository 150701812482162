import React, { useMemo } from "react";
import {
  useState,
  Drawer,
  Grid,
  Box,
  Typography,
  Divider,
  Stack,
  useEffect,
  moment,
} from "../../Collections/Imports";
import GlobalInput from "../../Components/CommonComponents/GlobalInput";
import GlobalDropdown from "../../Components/CommonComponents/GlobalDropdown";
import CustomButton from "../../Components/CommonComponents/GlobalButton";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  pingtreeApiEndPoints,
  publisherModuleApiEndPoints,
  channelModuleApiEndPoints,
} from "../../Config/APIsEndpoints";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import {
  addData,
  updateData,
} from "../../Store/PublisherModule/publisherPingtreeSetting";
import { useDispatch, useSelector } from "react-redux";
import { updateSnackBarConfig } from "../../Store/Global/SnackBar";
import { getChannelListData, getData } from "../../Store/ChannelModule";
import { toDate } from "date-fns";

function AddEditPublisherPingtree(props) {
  const dispatch = useDispatch();
  const { setOpen, isOpen, defaultValues, isEditMode, setIsEditMode } = props;

  const [pingtreeOptions, setPingtreeOptions] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [channelLabelOptions, setChannelLabelOptions] = useState([]);
  let currentPubData = JSON.parse(
    sessionStorage?.getItem("currentPublisherData")
  );
  const [GetPingTreeList, PingTreeList] = useApiCallMutation();
  const [AddTreeSetting, AddTreeSettingData] = useApiCallMutation();
  const [UpdatePingTreeSetting, UpdatePingTreeSettingData] =
    useApiCallMutation();
  const [GetChannelList, ChannelList] = useApiCallMutation();

  const treeOption = useGetApiCallQuery({
    endPoint: pingtreeApiEndPoints.getPingtreeList,
    method: "POST",
    data: {
      data: { pingTreeType: selectedType },
    },
  });

  const allChannelList =
    useSelector((state) => state.ChannelData?.data?.allChannelList) || [];

  useEffect(() => {
    GetChannelList({
      endPoint: channelModuleApiEndPoints.getAllChannels,
      method: "POST",
      data: {
        data: {
          index: 0,
          itemPerIndex: 1000,
          fromDate: moment(new Date("July 01, 2020")).toISOString(),
          toDate: moment(new Date()).toISOString(),
          publisherNames: [],
          channelNames: [],
          channelLabels: [],
          status: "",
        },
      },
    });
  }, []);

  useEffect(() => {
    if (ChannelList?.isSuccess && ChannelList?.data?.data?.Channels) {
      dispatch(getData(ChannelList?.data?.data));
      const channelList = ChannelList.data.data.Channels.map(
        ({ channelName, channelUniqueId }) => ({
          value: channelUniqueId,
          label: channelName,
        })
      );
      dispatch(getChannelListData(channelList));
      setChannelLabelOptions(channelList);
    }
  }, [ChannelList]);

  useEffect(() => {
    // debugger;
    setChannelLabelOptions(
      allChannelList.map((channel) => ({
        label: channel.label,
        value: channel.id,
      }))
    );
    dispatch(getChannelListData(allChannelList));
  }, []);

  // Fetch channel list data on component mount

  // Map channel list data to dropdown options if it's an array
  const options = Array.isArray(allChannelList)
    ? allChannelList.map((channel) => ({
        label: channel.label,
        value: channel.id,
      }))
    : [];

  const filteredPingtrees = Array.isArray(treeOption?.data?.data?.pingTree)
    ? treeOption.data.data.pingTree.filter(
        (pingtree) => pingtree.pingTreeType === selectedType
      )
    : [];

  // Set Initial Value here
  const initialValues = {
    channelUniqueId: "",
    channelName: "",
    pingTreeType: "",
    pingTrees: [],
    ...pingtreeOptions.reduce((acc, option) => {
      acc[`pingtree-${option.id}`] = 0; // Default value for the input
      return acc;
    }, {}),
  };

  // Set Validation Schema here
  const validationSchema = Yup.object().shape({
    pingTreeType: Yup.string().required("First Name is required"),
  });

  // initialise Form here
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const SetFormValues = (data) => {
    setValue("channelUniqueId", data?.channelUniqueId, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("channelName", data?.channelName, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("pingTreeType", data?.pingTreeType, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    data.pingTrees.forEach((pingtree) => {
      setValue(`pingtree-${pingtree.pingTreeId}`, pingtree.value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
  };

  const onFormSubmit = (data) => {
    // Create a sanitized copy of form values
    const sanitizedFormValues = Object.keys(data).reduce((acc, key) => {
      acc[key] =
        key.startsWith("pingtree-") && (data[key] === "" || data[key] === null)
          ? 0
          : data[key];
      return acc;
    }, {});

    // Extract pingtree values
    const pingtreeValues = Object.keys(sanitizedFormValues)
      .filter((key) => key.startsWith("pingtree-"))
      .map((key) => ({
        pingTreeId: parseInt(key.replace("pingtree-", ""), 10),
        value: sanitizedFormValues[key],
      }));

    // Combine the pingtree values with the rest of the form data
    const commonData = {
      ...data,
      pubUniqueId: currentPubData.pubUniqueId,
      pingTrees: pingtreeValues,
    };

    const finalData = isEditMode
      ? { ...commonData, id: defaultValues.id }
      : commonData;

    // Determine API endpoint based on edit mode
    const apiDetails = isEditMode
      ? {
          endPoint: publisherModuleApiEndPoints.updateTreeSetting,
          method: "POST",
          data: { data: finalData },
        }
      : {
          endPoint: publisherModuleApiEndPoints.addTreeSetting,
          method: "POST",
          data: { data: finalData },
        };

    // Make the API call
    if (isEditMode) {
      UpdatePingTreeSetting(apiDetails);
    } else {
      AddTreeSetting(apiDetails, reset());
    }
  };

  const handleSetButton = (e) => {
    setValue(e, "100");
  };

  useEffect(() => {
    const type = watch("pingTreeType");
    setSelectedType(type);
  }, [watch("pingTreeType")]);

  useEffect(() => {
    setPingtreeOptions((prevOptions) => {
      const newOptions = filteredPingtrees?.map((pingtree) => ({
        name: pingtree.name,
        id: pingtree.treeUniqueId,
      }));
      // Check if options have changed before updating the state
      if (JSON.stringify(newOptions) !== JSON.stringify(prevOptions)) {
        if (!isEditMode) {
          const resetValues = {};
          // newOptions?.forEach((pingtree) => {
          //   resetValues[`pingtree-${pingtree.id}`] = 0;
          // });

          // reset(resetValues);
        }
        return newOptions;
      }

      return prevOptions;
    });
  }, [selectedType, filteredPingtrees, reset]);

  //Set Incoming Default values
  useEffect(() => {
    if (Object.keys(defaultValues)?.length && isEditMode) {
      SetFormValues(defaultValues);
      console.log("defaultValues", defaultValues);
    }
  }, [defaultValues, isEditMode]);

  useEffect(() => {
    if (AddTreeSettingData?.isSuccess && !AddTreeSettingData?.data?.error) {
      dispatch(addData({ data: AddTreeSettingData?.data?.data }));
      dispatch(
        updateSnackBarConfig({
          open: true,
          color: "success",
          message: "Data Added Successfully",
        })
      );
      setOpen(false);
      setIsEditMode(false);
    }
  }, [AddTreeSettingData, dispatch]);

  useEffect(() => {
    if (
      UpdatePingTreeSettingData?.isSuccess &&
      !UpdatePingTreeSettingData?.data?.error
    ) {
      const dataToUpdate = UpdatePingTreeSettingData?.data;
      // Debugging: Check the structure of data
      console.log("dataToUpdate:", dataToUpdate);
      if (dataToUpdate) {
        dispatch(updateData(dataToUpdate));
        dispatch(
          updateSnackBarConfig({
            open: true,
            color: "success",
            message: "Data Updated Successfully",
          })
        );
        setOpen(false);
        setIsEditMode(false);
      } else {
        console.error(
          "Data to update is not properly structured",
          dataToUpdate
        );
      }
    }
  }, [UpdatePingTreeSettingData, dispatch]);

  return (
    <>
      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={() => {
          setOpen(false);
          setIsEditMode(false);
        }}
        sx={{
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            top: "15px",
            borderRadius: "16px",
            width: "100%",
            maxWidth: "500px",
          },
        }}
      >
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent={"space-between"}
          >
            <Box marginBottom={"15px"}>
              <Typography component="h6" variant="h6" fontWeight={600}>
                {(isEditMode && "Update Rule") || "Add Rule"}
              </Typography>
            </Box>
            <Box marginBottom={"15px"}>
              <Typography component="h7" variant="h7" fontWeight={200}>
                Clear All
              </Typography>
            </Box>
          </Stack>
          <Divider
            sx={{
              mb: "20px",
            }}
          />
          <Box component={"form"}>
            <Grid container spacing={4}>
              <Grid item xs={12} sx={{ mt: "10px" }}>
                <GlobalDropdown
                  control={control}
                  size="small"
                  options={channelLabelOptions || []}
                  name="channelUniqueId"
                  label="Channels"
                  error={!!errors.activeOn}
                  helperText={errors.activeOn?.message}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: "10px" }}>
                <GlobalDropdown
                  control={control}
                  size="small"
                  options={[
                    { label: "Redirect", value: "Redirect" },
                    { label: "Silent", value: "Silent" },
                  ]}
                  name="pingTreeType"
                  label="Type"
                  error={!!errors.activeOn}
                  helperText={errors.activeOn?.message}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: "10px" }}>
              {pingtreeOptions?.map((label, index) => (
                <Grid
                  container
                  spacing={4}
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid item xs={3}>
                    {label.name}
                  </Grid>
                  <Grid item xs={5} sx={{ mt: "5px" }}>
                    <div key={index}>
                      <GlobalInput
                        control={control}
                        size="small"
                        id={`pingtree-${label.id}`}
                        name={`pingtree-${label.id}`}
                        type="number"
                        maxLength="3"
                        min="0"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <CustomButton
                      labelColor="#7a7870"
                      variant="outlined"
                      color="white"
                      label="Set to 100"
                      sx={{ fontSize: "12px" }}
                      onClick={() => handleSetButton(`pingtree-${label.id}`)}
                      value={`pingtree-${label.id}`}
                    />
                  </Grid>
                </Grid>
              ))}
            </Box>
            <Box
              sx={{
                width: "100%",
                borderTop: "1px solid #DCE0E6",
                marginTop: "10px",
              }}
            >
              <Stack
                direction="row"
                component={Box}
                useFlexGap
                flexWrap="wrap"
                justifyContent={"flex-end"}
                bottom={0}
                marginTop={"1rem"}
              >
                <Box marginBottom={"15px"} marginRight={"5px"} float={"right"}>
                  <CustomButton
                    color="#3a5396"
                    label="Save"
                    onClick={handleSubmit(onFormSubmit)}
                  />
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

export default AddEditPublisherPingtree;
