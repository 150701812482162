import React from 'react';
import { Chip } from '@mui/material';
import { Height } from '@mui/icons-material';

const StatusChipRenderer = (props) => {
  const getChipStyles = (status) => {
    if (status === 'Rejected') {
      return { backgroundColor: '#FADBD8', color: '#BE2203', border:'1px solid #BE2203', borderRadius:'4px', height:'25px'};
    } else if (status === 'Filtered') {
      return { backgroundColor: '#EFD8B8', color: '#EE8B04', border:'1px solid #EE8B04', borderRadius:'4px', height:'25px'};
    }
    return { backgroundColor: '#D5F5E3', color: '#229954' , border:'1px solid #229954',borderRadius:'4px', height:'25px'};
  };

  const styles = getChipStyles(props.value);

  return (
    <Chip
      label={props.value}
      style={{ backgroundColor: styles.backgroundColor, color: styles.color, border: styles.border, borderRadius:styles.borderRadius, height: styles.height}}
    />
  );
};

export default StatusChipRenderer;
