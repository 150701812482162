import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rowData: {},
};

const currentScheduleRowData = createSlice({
  name: "currentScheduleRowData",
  initialState,
  reducers: {
    updateScheduleRowData: (state, action) => {
      state.rowData = action.payload;
    },
  },
});

export const { updateScheduleRowData } = currentScheduleRowData.actions;
export default currentScheduleRowData.reducer;
