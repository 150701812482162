import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomButton from "../../../../Components/CommonComponents/GlobalButton";
import { useDispatch } from "react-redux";
import {
    Grid,
    useEffect,
    useState,
    Box,
    moment,
    Typography,
    Divider,
    Stack
  } from "../../../../Collections/Imports";
  import GlobalMultiSelect from "../../../../Components/CommonComponents/GlobalMultiSelect";
import { updatePldnplOptions } from "../../../../Store/Global/PLDNPLOptions";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../Services/ApiCallService";
import {
  campaignModuleAPiEndPoints,
  coreAPIEndsPoints,
} from "../../../../Config/APIsEndpoints";
import { useSelector } from "react-redux";

  const PLDNPL = () => {
    let currentCampaignData = JSON.parse(sessionStorage.getItem('currentCampaignData'));

    const [AddPldnplList, addPldnplListData] = useApiCallMutation();
    const [GetCampaignPLDNPL, CampaigPLDNPL] = useApiCallMutation();
    const dispatch = useDispatch();
    // Set Initial Value here
  const initialValues = {
    plDnplIds: [],
    
  };

  // Set Validation Schema here
  const validationSchema = Yup.object().shape({
    plDnplIds: Yup.array().required("This Field is required")
  });
  // initialise Form here
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const setFormData = (data) => {
    setValue("plDnplIds", data?.plDnplIds, {
      shouldValidate: false,
      shouldDirty: true,
      shouldTouch: false,
    });
  }

  const onFormSubmit = (data) => {
      data.campaignId = currentCampaignData?.campaignId;
      AddPldnplList({
        endPoint: campaignModuleAPiEndPoints.addCampaignPLDNPL,
        method: "POST",
        data: { data },
      });
  };

  const getPldnplOptions = useGetApiCallQuery({
    endPoint: coreAPIEndsPoints.getAllPLDNPL,
    method: "GET",
  });
  let method = '';
  if (getPldnplOptions?.isSuccess) {
    dispatch(updatePldnplOptions(getPldnplOptions?.data?.data));
    if (Array.isArray(getPldnplOptions.data.data)) {
      method = getPldnplOptions.data.data.map((labels) => ({
        label: labels.listName,
        id: labels.id,
        value: labels.id,
      }));
    }
  }
  
  // update form with api data
  useEffect(() => {
    if (CampaigPLDNPL?.isSuccess && !CampaigPLDNPL?.data?.error) {
      setFormData(CampaigPLDNPL?.data?.data);
    }
  }, [CampaigPLDNPL]);
  // api call to get campaign pl dnpl lists
  useEffect(() => {
    
    GetCampaignPLDNPL({
      endPoint: campaignModuleAPiEndPoints.getCampaignPLDNPL,
      method: "POST",
      data: {
        data: {
          campaignId: currentCampaignData?.campaignId,
        },
      },
    });
  }, []);
    return <div>
      <Box sx={{width:"50%",margin:"0 auto"}}>
        <Box marginBottom={"15px"}>
              <Typography component="h6" variant="h6" fontWeight={600}>
                  Present & Do Not Present Lists Settings
              </Typography>
          </Box>
          <Divider sx={{mb: "20px"}}/>
          <Box component={"form"}>
            <Grid container>
              <Grid item xs={12} sx={{mt:"20px"}}>
                <GlobalMultiSelect
                    control={control}
                    size="small"
                    name="plDnplIds"
                    label="Select PL/DNPL"
                    options={method}
                    error={!!errors.plDnplIds}
                    helperText={errors.plDnplIds?.message}
                    required
                />
              </Grid>
              <Box
                sx={{
                    width: "100%",
                    borderTop:"1px solid #DCE0E6",
                    marginTop:"10px"
                }}
              >
                  <Stack
                      direction="row"
                      component={Box}
                      useFlexGap
                      flexWrap="wrap"
                      justifyContent={"flex-end"}
                      bottom={0}
                      marginTop={"1rem"}
                  >
                      <Box marginBottom={"15px"} marginRight={"5px"} float={"right"}>
                          <CustomButton
                              color="#3a5396"
                              label="Save Setting" 
                              onClick={handleSubmit(onFormSubmit)}
                          />
                      </Box>
                  </Stack>
              </Box>
            </Grid>
          </Box>
      </Box>
        
        
        
    </div>
  };

export default PLDNPL;