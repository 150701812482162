import {
    useEffect,
    AddOutlined,
    Box,
    Button
    
} from "../../../../Collections/Imports";
import { useApiCallMutation } from "../../../../Services/ApiCallService";
import { useDispatch, useSelector } from "react-redux";
import { leadDetailsModuleApiEndPoints } from "../../../../Config/APIsEndpoints";
import { useState, useCallback  } from "react";
import { getLeadLogs } from "../../../../Store/LeadDetailsModule/LeadInfoBody";
import AgGridComponent from "../../../../Components/CommonComponents/AG-Grid";
import "./LeadLogs.css"
import StatusChipRenderer from "./StatusChipRenderer";
import CheckboxEditor from "../../../../Components/CommonComponents/AG-Grid/CheckboxEditor";
import LeadLogInfo from "./LeadLogInfo";
const LeadLogs = () => {
  let currentLeadInfo = JSON.parse(sessionStorage.getItem("currentLeadData"));
  const [getAllLeadLogs, leadLogs] = useApiCallMutation();
  const [rowSelected, setRowSelected] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isOpenAddEditBox, setIsOpenAddEditBox] = useState(false);
  const dispatch = useDispatch();
  const rowStyle = { background: "" };
  const tableData = useSelector((state) => state.LeadInfoBody?.data?.getLeadLogs);
  const postedLeadLogs = leadLogs?.data?.data?.leadLogsData?.postedLeadLogs;
  const filteredLeadLogs = leadLogs?.data?.data?.leadLogsData?.filteredLeadLogs;
  const [columnDefs, setColumnDefs] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [dataSet, setDataSet] = useState('posted'); // To keep track of the selected dataset

  const getLeadStatusCellStyle = (params) => {
    if (params.value === 'Rejected') {
      return { color: 'red' };
    } else if (params.value === 'Filtered') {
      return { color: 'yellow' };
    }
    return null;
  };

  useEffect(() => {
    if (tableData?.headers) {
      const newColumnDefs = tableData.headers.map(header => {
        if (header.field === "leadStatus") {
          return { ...header, cellRenderer: StatusChipRenderer,editable: true, cellEditor: 'checkboxEditor' };
        }
        return header;
      });
      setColumnDefs(newColumnDefs);
    }
  }, [tableData]);

  const defaultColDef = {
    flex: 1,
    minWidth: 150,
    enableValue: true,
    sortable: true,
    resizable: true,
    filter: true,
  };

  useEffect(() => {
    getAllLeadLogs({
      endPoint: leadDetailsModuleApiEndPoints.getAllLeadDetailsById,
      method: "POST",
      data: {
        data: {
          leadId: currentLeadInfo.leadId,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (leadLogs?.data?.data?.leadLogsData) {
      dispatch(getLeadLogs(leadLogs?.data?.data?.leadLogsData));
    }
  }, [leadLogs]);

  useEffect(() => {
    if (dataSet === 'posted') {
      setRowData(tableData?.postedLeadLogs || []);
    } else if (dataSet === 'filtered') {
      setRowData(tableData?.filteredLeadLogs || []);
    } 
  }, [tableData, dataSet]);

  const handleDataSetChange = (set) => {
    setDataSet(set);
  };
  const filteredCount = filteredLeadLogs ? filteredLeadLogs.filter(log => log.leadStatus === 'Filtered').length : 0;

  const handleActions = (previousData, action) => {
    switch (action) {
      case "LOG":
        setRowSelected(previousData);
        setIsEditMode(true);
        setIsOpenAddEditBox(true);
        break;
    }
  };

  return (
    <>
       <Box display="flex" justifyContent="space-between" alignItems="center"  style={{float:"right"}}>
        <Box sx={{marginTop:'14px',marginRight:'16px'}}>
          <Button
            className={`custom-button ${dataSet === 'posted' ? 'selected' : ''}`}
            onClick={() => handleDataSetChange('posted')}
          >
            Posted <span className="count-badge">{postedLeadLogs?.length || 0}</span>
          </Button>
          <Button
            className={`custom-button ${dataSet === 'filtered' ? 'selected' : ''}`}
            onClick={() => handleDataSetChange('filtered')}
          >
            Filtered <span className="count-badge">{filteredCount}</span>
          </Button>
        </Box>
      </Box>

      <AgGridComponent
        rowData={rowData}
        // columnDefs={tableData?.headers || []}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        performAction={handleActions}
        actions={["LOG"]}
        checkboxEditor= {CheckboxEditor}
        tableName={"Lead Logs"}
        domLayout="autoHeight"
        search={false}
        addActionIcon={<AddOutlined />}
        height={"100%"}
        rowStyle={rowStyle}
        rowSelection={"single"}
        rowMode
      />

    {isOpenAddEditBox && (
        <LeadLogInfo 
        defaultValues={rowSelected}
        isOpen={isOpenAddEditBox}
        setOpen={setIsOpenAddEditBox}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        />

    )}
    </>
  );
}

export default LeadLogs;