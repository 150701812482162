import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { callBackURL } from "../../Config/envConfig";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import { apiEndPointsConfig } from "../../Config/APIsEndpoints";

const CallbackHandlerPage = () => {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const [getToken, UserDeatils] = useApiCallMutation();

  const rdata = {
    redirectUri: callBackURL,
    clientName: "lds",
    code: code,
  };
  useEffect(() => {
    getToken({
      endPoint: apiEndPointsConfig.getToken,
      method: "POST",
      data: {
        data: rdata,
      },
      headers: {},
    });
  }, [null]);

  useEffect(() => {
    if (UserDeatils?.isSuccess && UserDeatils?.data) {
      localStorage.setItem(
        "userInfo",
        JSON.stringify(UserDeatils?.data?.data?.user)
      );
      localStorage.setItem(
        "userCredential",
        JSON.stringify(UserDeatils?.data?.data?.token)
      );
      navigate("/dashboard");
    }
  }, [UserDeatils]);

  return (
    <>
      <div>Loading...</div>
    </>
  );
};

export default CallbackHandlerPage;
