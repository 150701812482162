import React from "react";
import {
  Grid,
  Chip,
  useEffect,
  useState,
  Box,
  moment,
  PageHeader,
  useNavigate,
  useLocation,
  AddOutlined,
  AddIcon,
  ArrowBack,
} from "../../Collections/Imports";
import Filters from "../../Components/UIComponents/Filters";
import { useApiCallMutation } from "../../Services/ApiCallService";
import { useDispatch, useSelector } from "react-redux";
import AgGridComponent from "../../Components/CommonComponents/AG-Grid";
import CircularProgressBar from "../../Components/UIComponents/CircularProgressBar";
import { publisherModuleApiEndPoints } from "../../Config/APIsEndpoints";
import { allPubData } from "../../Store/PublisherModule";
import AddEditPublisher from "../../Forms/AddEditPublisher";
import { updatePublisherFilterData } from "../../Store/PublisherModule/filter";
import PublisherFilters from "../../Forms/PublisherFilters";
import { Outlet } from "react-router-dom";
import "@inovua/reactdatagrid-community/theme/blue-light.css";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/index.css";

function PublisherListing() {
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const TableData = useSelector(
    (state) => state.publisherData?.data?.allPubData
  );
  console.log("TableData", TableData);
  const TablePubOptions = useSelector((state) => state.publisherFilterSession);

  // const TablePubOptions = useSelector((state) => state.publisherFilterSession);

  const [rowSelected, setRowSelected] = useState({});
  const [isOpenAddEditBox, setIsOpenAddEditBox] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [publisherName, setPublisherName] = useState("");

  // const pubOptions = Array.isArray(TablePubOptions?.pubOptions)
  //   ? TablePubOptions.pubOptions.map((publisher) => publisher)
  //   : [];

  const pubOptions = useSelector(
    (state) => state?.publisherData?.data?.allPublisherList
  );
  const previousFilterData = useSelector(
    (state) => state?.publisherFilterSession?.filterData
  );
  console.log("previousFilterData", previousFilterData);
  // Form pagination handler
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [isOpenPubFilter, setIsOpenPubFilter] = useState(false);
  const [defaultPub, setDefaultPub] = useState(false);
  const [itemPerIndex, setItemPerIndex] = useState(100);
  const perPageCountOptions = [
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "1000", value: 1000 },
  ];
  const statusOptions = [
    { label: "Active", value: "true" },
    { label: "Disabled", value: "false" },
  ];
  const [GetPublisherList, PublisherList] = useApiCallMutation();

  // Handle Grid Button ACtion

  useEffect(() => {
    handleActions();
  }, []);

  const handleActions = (previousData, action) => {
    switch (action) {
      case "EDIT":
        setRowSelected(previousData);
        setIsEditMode(true);
        setIsOpenAddEditBox(true);
        break;
      case "CONFIG":
        setRowSelected(previousData);
        sessionStorage.setItem(
          "currentPublisherData",
          JSON.stringify(previousData)
        );
        const currentPubData = JSON.parse(
          sessionStorage.getItem("currentPublisherData")
        );
        setPublisherName(currentPubData.publisherName);
        navigate(String(previousData?.pubUniqueId));
        break;
    }
  };
  const handlePageChange = (data) => {
    setPaginationIndex(data);
  };
  const handleRowsPerPageChange = (data) => {
    setItemPerIndex(data);
    setPaginationIndex(0);
  };
  const toggleDrawer = (ele) => {
    console.log("=-=-=-=-=-=-=-=-=-==--", ele);
    ele === "created"
      ? setDefaultPub("created")
      : ele === "publisher"
      ? setDefaultPub("publisher")
      : ele === "label"
      ? setDefaultPub("label")
      : ele === "status"
      ? setDefaultPub("status")
      : setDefaultPub(false);

    setIsOpenPubFilter((prev) => !prev);
  };

  const [selectedDateFilter, setSelectedDateFilter] = useState({
    startDate: new Date("July 01, 2020"),
    endDate: new Date(),
    key: "selection",
  });

  // get Preselected Filter label
  const getFilterLabel = (filterType, options) => {
    console.log(filterType, options);
    if (options?.length === 1) {
      return (
        <span>
          <Chip label={options[0]?.label} sx={{ m: "2px" }} size="small" />
        </span>
      );
    } else if (options?.length > 1) {
      return (
        <>
          <span>
            <Chip label={options[0]?.label} sx={{ m: "2px" }} size="small" />
          </span>
          <span
            style={{ cursor: "pointer", color: "#000", paddingLeft: "4px" }}
          >
            {`+${previousFilterData[filterType]?.length - 1}`}
          </span>
        </>
      );
    } else {
      return (
        <span>
          <Chip label={"All"} sx={{ m: "2px" }} size="small" />
        </span>
      );
    }
  };

  //set Preselected Filter label
  const setFilterLabel = (filterType) => {
    switch (filterType) {
      case "publisher":
        if (previousFilterData?.publisher != "") {
          const puboptions = pubOptions?.filter((opt) =>
            previousFilterData?.publisher?.find((val) => val == opt.value)
          );
          return getFilterLabel("publisher", puboptions);
        }

        break;
      case "status":
        const statusoptions = statusOptions?.filter(
          (opt) => previousFilterData?.status == opt.value
        );
        return getFilterLabel("status", statusoptions);
        break;
    }
  };

  // get all publisher data

  const Datapub = {
    index: paginationIndex,
    itemPerIndex: itemPerIndex,
    fromDate: moment(previousFilterData?.fromDate).toISOString(),
    toDate:
      moment(previousFilterData?.toDate).toISOString() == ""
        ? moment(selectedDateFilter?.endDate).toISOString()
        : moment(previousFilterData?.toDate).toISOString(),
    publisherNames: previousFilterData?.publisher,
    status: previousFilterData?.status || "all",
    emails: previousFilterData?.emails || ["all"],
  };

  useEffect(() => {
    GetPublisherList({
      endPoint: publisherModuleApiEndPoints.getAllPubs,
      method: "POST",
      data: {
        data: { ...Datapub },
      },
    });
  }, [previousFilterData]);

  // useEffect(() => {
  //   dispatch(updatePublisherFilterData(Datapub));
  // }, []);

  useEffect(() => {
    if (PublisherList?.isSuccess && PublisherList?.data?.data?.publishers) {
      dispatch(allPubData(PublisherList?.data?.data));
    }
  }, [PublisherList]);
  useEffect(() => {
    if (location?.pathname === "/publisher") {
      setPublisherName("");
    } else {
      const currentPubData = JSON.parse(
        sessionStorage.getItem("currentPublisherData")
      );
      setPublisherName(currentPubData.publisherName);
    }
  }, [location]);

  const ClearFilters = () => {
    const filterData = {
      status: "All",
      publisher: ["All"],
      label: ["All"],
      labelIds: ["All"],
      fromDate: selectedDateFilter?.startDate,
      toDate: moment().endOf("day").toDate(),
    };

    dispatch(updatePublisherFilterData(filterData));
    // reset();
  };

  return (
    <>
      {/* Page Header Start */}
      <PageHeader
        title={publisherName || "Publishers"}
        backIcon={
          location?.pathname === "/publisher" ? null : (
            <ArrowBack sx={{ color: "#0c247b" }} />
          )
        }
        onBack={() => navigate(-1)}
        helpText={
          "This section is a complete list of all active publishers registered in the system with information about their User IDs, Usernames, E-Mails, Statuses, and Registration Date. You can select any Publisher and change their settings, statuses, or confirm the accuracy of their profiles. You can also use the 'View' setting and see details for their activity in the system."
        }
        // onClick={() => setIsOpenAddEditBox(true)}
        // buttonLabel="Add New Publisher"
      />
      {/* Page Header End */}
      {location?.pathname === "/publisher" && (
        <>
          {/* Filters Start*/}
          <Box sx={{ pl: "16px" }} mt={5}>
            <Grid container spacing={2}>
              <Filters
                title="Publisher:"
                onClick={() => toggleDrawer("publisher")}
              >
                {setFilterLabel("publisher")}
              </Filters>
              <Filters title="Status :" onClick={() => toggleDrawer("status")}>
                {setFilterLabel("status")}
              </Filters>
              <Filters
                title="Created :"
                onClick={() => toggleDrawer("created")}
              >
                <Box>
                  {/* <span style={{ fontSize: "14px" }}>
                    <Chip
                      label={moment(
                        previousFilterData?.fromDate
                          ? previousFilterData?.fromDate
                          : selectedDateFilter?.startDate
                      ).format("ll")}
                      sx={{ m: "2px" }}
                      size="small"
                    />
                  </span>{" "}
                  {"-"}{" "}
                  <span style={{ fontSize: "14px" }}>
                    <Chip
                      label={moment(
                        previousFilterData?.toDate
                          ? previousFilterData?.toDate
                          : selectedDateFilter?.endDate
                      ).format("ll")}
                      sx={{ m: "2px" }}
                      size="small"
                    />
                  </span> */}
                  <span style={{ fontSize: "14px" }}>
                    <Chip
                      label={moment(
                        previousFilterData?.fromDate
                          ? previousFilterData?.fromDate
                          : selectedDateFilter?.startDate
                      ).format("ll")}
                      sx={{ m: "2px" }}
                      size="small"
                    />
                  </span>
                  {" - "}
                  <span style={{ fontSize: "14px" }}>
                    <Chip
                      label={moment(
                        previousFilterData?.toDate
                          ? previousFilterData?.toDate
                          : selectedDateFilter?.endDate
                      ).format("ll")}
                      sx={{ m: "2px" }}
                      size="small"
                    />
                  </span>
                </Box>
              </Filters>
              <Filters title="Label :" onClick={() => toggleDrawer("label")}>
                {setFilterLabel("label")}
              </Filters>
              <Filters
                icons={<AddIcon />}
                title="Filters"
                onClick={() => toggleDrawer("filters")}
              ></Filters>
              <Filters title="Clear All" onClick={ClearFilters}></Filters>
            </Grid>
          </Box>
          {/* Filters End*/}

          {/* Data Table Start */}
          {PublisherList.status === "isUninitialized" && (
            <CircularProgressBar />
          )}
          {PublisherList.status !== "isUninitialized" && (
            <Box>
              <AgGridComponent
                rowData={TableData?.publishers || []}
                columnDefs={TableData?.headers || []}
                actions={["EDIT", "CONFIG"]}
                performAction={handleActions}
                totalPageCount={PublisherList?.data?.total}
                paginationIndex={paginationIndex}
                itemPerIndex={itemPerIndex}
                perPageCountOptions={perPageCountOptions}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowMode
                addAction={setIsOpenAddEditBox}
                AddButtonName={"Add New Publisher"}
                addActionIcon={<AddOutlined />}
              />
            </Box>
          )}
          {/* Data Table End */}
          {/* Publisher Add Edit Form Start*/}
          {isOpenAddEditBox && (
            <AddEditPublisher
              defaultValues={rowSelected}
              isOpen={isOpenAddEditBox}
              setOpen={setIsOpenAddEditBox}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
            />
          )}
          {/* Publisher Add Edit Form End*/}

          {/* Publisher Filter Form */}
          {isOpenPubFilter && (
            <PublisherFilters
              defaultValues={rowSelected}
              isOpen={isOpenPubFilter}
              setOpen={setIsOpenPubFilter}
              isEditMode={isEditMode}
              defaultCampaign={defaultPub}
              ClearFilters={ClearFilters}
            />
          )}
          {/* Publisher Filter Form End */}
        </>
      )}
      <div>
        <Outlet />
      </div>
    </>
  );
}

export default PublisherListing;
