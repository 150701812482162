import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  data: {
    revShareData: [],
  },
};

const RevShareData = createSlice({
  name: "publisherData",
  initialState,
  reducers: {
    revShareData: (state, action) => {
      state.data.revShareData = action.payload;
    },
    addData: (state, action) => {
      state.data.revShareData.unshift(action.payload);
    },
    updateData: (state, action) => {
      const updatedRow = action.payload;
      const index = state.data.revShareData.findIndex(
        (data) => data?.id === updatedRow?.id
      );
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.data.revShareData[index][key] = updatedRow[key];
      });
    },
  },
});

export const { revShareData,addData,updateData } = RevShareData.actions;
export default RevShareData.reducer;
