import React from "react";
import { useForm } from "react-hook-form";
import { Card, Divider, Drawer, Stack, Typography } from "@mui/material";
import {
  Grid,
  useEffect,
  useState,
  Box,
  moment,
} from "../../Collections/Imports";
import GlobalDropdown from "../../Components/CommonComponents/GlobalDropdown";
import GlobalInput from "../../Components/CommonComponents/GlobalInput";
import CustomButton from "../../Components/CommonComponents/GlobalButton";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { allPubData } from "../../Store/PublisherModule";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import {
  campaignModuleAPiEndPoints,
  publisherModuleApiEndPoints,
} from "../../Config/APIsEndpoints";
import { useDispatch } from "react-redux";
import { addSinglePublisherToDeny } from "../../Store/FIlterByPub";

function AddEditPublisherAllowList(props) {
  const { setOpen, isOpen, defaultValues, isEditMode, setIsEditMode } = props;
  const [AddPublisherList, addPublisherListData] = useApiCallMutation();
  const [GetPublisherList, PublisherList] = useApiCallMutation();
  const [GetFilterByPub, filterByPubData] = useApiCallMutation();
  const dispatch = useDispatch();
  const pubData = useSelector((state) => state?.publisherData?.data?.allPubData?.publishers);
  const pubListing = pubData.map((labels) => {
    return { label: labels.publisherName, value: labels.id, id: labels.id };
  });
  let currentCampaignData = JSON.parse(sessionStorage.getItem('currentCampaignData'));

  //Set Incoming Default values
  useEffect(() => {
    if (Object.keys(defaultValues).length && isEditMode) {
      setFormData(defaultValues);
    }
  }, []);
  // Set Initial Value here
  const initialValues = {
    campaignId:currentCampaignData?.campaignId,
    publisherId: "",
    listType: "Deny List",
    publisherChannel:[],
    publisherSource:[],
    comment:"",
    reason:"",
  };

  // Set Validation Schema here
  const validationSchema = Yup.object().shape({
    publisherId: Yup.string().required("This Field is required"),
  });

  // initialise Form here
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const setFormData = (data) => {
    console.log(data);
    setValue("publisherId", data?.publisherId, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("comment", data?.comment, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("reason", data?.reason, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const onFormSubmit = (data) => {
      AddPublisherList({
        endPoint: campaignModuleAPiEndPoints.addFilterByPub,
        method: "POST",
        data: { data },
      });
  };
  // get all publisher data
  useEffect(() => {
    GetPublisherList({
      endPoint: publisherModuleApiEndPoints.getAllPubs,
      method: "POST",
      data: {
        data: {
          index: 0,
          itemPerIndex: 2000,
          fromDate: moment(new Date("July 01, 2020")).toISOString(),
          toDate: moment(new Date()).toISOString(),
          publisherNames: [],
          emails: [],
        },
      },
    });
  }, []);
  // create publisher list from api data
  useEffect(() => {
    if (
      PublisherList?.isSuccess &&
      PublisherList?.data?.data?.publishers.length
    ) {
      dispatch(allPubData(PublisherList?.data?.data));
      const pubListing = PublisherList?.data?.data?.publishers.map((labels) => {
        return { label: labels.publisherName, value: labels.id, id: labels.id };
      });
    }
  }, [PublisherList]);

  useEffect(() => {
    if (addPublisherListData?.isSuccess && !addPublisherListData?.data?.error) {
      dispatch(addSinglePublisherToDeny(addPublisherListData.data?.data));
      setOpen(false);
      setIsEditMode(false);
    }
  }, [addPublisherListData]);

  // // api call to get previous set data
  // useEffect(() => {
  //   GetFilterByPub({
  //     endPoint: campaignModuleAPiEndPoints.getFilterByPub,
  //     method: "POST",
  //     data: {
  //       data: {
  //         id: currentCampaignData?.campaignId,
  //       },
  //     },
  //   });
  // }, []);
  // // update form with api data
  // useEffect(() => {
  //   if (filterByPubData?.isSuccess && !filterByPubData?.data?.error) {
  //     setFormData(filterByPubData?.data?.data);
  //     setIsEditMode(true);
  //   }
  // }, [filterByPubData]);
  return (
    <Drawer
      anchor={"right"}
      open={isOpen}
      onClose={() => {
        setOpen(false);
        setIsEditMode(false);
      }}
      sx={{
        [`& .MuiDrawer-paper`]: {
          boxSizing: "border-box",
          top: "15px",
          borderRadius: "16px",
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <Box
        sx={{
          pt: "1rem",
          pr: "1rem",
          pl: "1rem",
        }}
      >
        <Stack
          spacing={{ xs: 1, sm: 2 }}
          direction="row"
          useFlexGap
          flexWrap="wrap"
          justifyContent={"space-between"}
        >
          <Box marginBottom={"15px"}>
            <Typography component="h6" variant="h6" fontWeight={600}>
              {(isEditMode && "Update Deny List") || "Add Publisher To Deny List"}
            </Typography>
          </Box>
          <Box marginBottom={"15px"} onClick={() => reset()}>
            <Typography component="h7" variant="h7" fontWeight={200}>
              Clear All
            </Typography>
          </Box>
        </Stack>

        <Divider
          sx={{
            mb: "20px",
          }}
        />

        <Box component={"form"}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <GlobalDropdown
                control={control}
                size="small"
                name="publisherId"
                label="Publisher"
                options={pubListing || []}
                required
                error={!!errors.publisherId}
                helperText={errors.publisherId?.message}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: "10px" }}>
              <GlobalInput
                control={control}
                size="small"
                name="comment"
                label="Comment"
                error={!!errors.comment}
                helperText={errors.comment?.message}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: "10px",mb:"10px" }}>
              <GlobalInput
                control={control}
                size="small"
                name="reason"
                label="Reason"
                error={!!errors.reason}
                helperText={errors.reason?.message}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          borderTop: "1px solid #DCE0E6",
          pr: "1rem",
          // pl: "1rem",
        }}
      >
        <Stack
          direction="row"
          component={Box}
          useFlexGap
          flexWrap="wrap"
          justifyContent={"flex-end"}
          bottom={0}
          marginTop={"1rem"}
        >
          <Box marginBottom={"15px"} marginRight={"5px"}>
            <CustomButton
              color="#3a5396"
              label={isEditMode ? "Update List" : "Add Publisher"}
              onClick={handleSubmit(onFormSubmit)}
            />
          </Box>
          <Box marginBottom={"15px"}>
            <CustomButton
              labelColor="#7a7870"
              variant="outlined"
              color="white"
              label="Close"
              onClick={() => {
                setOpen(false);
                setIsEditMode(false);
              }}
            />
          </Box>
        </Stack>
      </Box>
    </Drawer>
  );
}

export default AddEditPublisherAllowList;
