import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  PageHeader,
  Grid,
  AddOutlined,
  AddIcon,
  moment,
} from "../../Collections/Imports";
import { useDispatch, useSelector } from "react-redux";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import { buyerTagModuleAPiEndPoints } from "../../Config/APIsEndpoints";
import CircularProgressBar from "../../Components/UIComponents/CircularProgressBar";
import AgGridComponent from "../../Components/CommonComponents/AG-Grid";
import AddEditBuyerTags from "../../Forms/AddEditBuyerTag";
import {
  removeSingleBuyerTags,
  updateBuyerTags,
} from "../../Store/BuyerTagModule";

function BuyersTagPage() {
  const dispatch = useDispatch();
  const [rowSelected, setRowSelected] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  // Form pagination handler
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [itemPerIndex, setItemPerIndex] = useState(100);
  const perPageCountOptions = [
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "1000", value: 1000 },
  ];
  const handlePageChange = (data) => {
    setPaginationIndex(data);
  };
  const handleRowsPerPageChange = (data) => {
    setItemPerIndex(data);
    setPaginationIndex(0);
  };
  const [isOpenAddEditBox, setIsOpenAddEditBox] = useState(false);
  //TableData
  const TableData = useSelector((state) => state.buyerTags.tableData);
  // Get Campaign Tags
  const GetBuyerTagList = useGetApiCallQuery({
    endPoint: buyerTagModuleAPiEndPoints.getBuyerTags,
    method: "GET",
  });
  // Get Campaign Tags api response
  useEffect(() => {
    if (GetBuyerTagList?.isSuccess && GetBuyerTagList?.data?.data) {
      const tableData = {
        data: GetBuyerTagList?.data?.data?.buyersTags,
        headers: GetBuyerTagList?.data?.data?.headers,
      };
      dispatch(updateBuyerTags(tableData));
    }
  }, [GetBuyerTagList?.data]);

  //delete campaign tag api call
  const [DeleteBuyerTag, DeleteBuyerTagData] = useApiCallMutation();
  // Handle Grid Button ACtion
  const handleActions = (previousData, action) => {
    switch (action) {
      case "EDIT":
        setRowSelected(previousData);
        setIsEditMode(true);
        setIsOpenAddEditBox(true);
        break;
      case "DELETE":
        const tagId = {
          buyerTagUniqueId: previousData?.buyerTagUniqueId,
        };
        DeleteBuyerTag({
          endPoint: buyerTagModuleAPiEndPoints.deleteBuyerTags,
          method: "POST",
          data: { data: tagId },
        });
        dispatch(removeSingleBuyerTags(previousData?.id));
        break;
      default:
    }
  };
  return (
    <>
      {/* Page Header Start */}
      <PageHeader
        title={"Buyer Tags"}
        helpText={
          "The “Buyer Tags” feature allows you to create tags that can be added to Buyers and be used as an additional filter in reports."
        }
      />
      {/* Page Header End */}

      {/* Data Table Start */}
      {GetBuyerTagList?.status === "isUninitialized" && <CircularProgressBar />}
      {GetBuyerTagList?.status !== "isUninitialized" && (
        <Box>
          <AgGridComponent
            rowData={TableData?.data || []}
            columnDefs={TableData?.headers || []}
            actions={["EDIT", "DELETE"]}
            performAction={handleActions}
            totalPageCount={TableData?.data.length}
            paginationIndex={paginationIndex}
            itemPerIndex={itemPerIndex}
            perPageCountOptions={perPageCountOptions}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowMode
            addAction={setIsOpenAddEditBox}
            AddButtonName={"Add New Tag"}
            addActionIcon={<AddOutlined />}
          />
        </Box>
      )}
      {/* Data Table ends */}

      {/* Buyer Tag Add Edit Form Start*/}
      {isOpenAddEditBox && (
        <AddEditBuyerTags
          defaultValues={rowSelected}
          isOpen={isOpenAddEditBox}
          setOpen={setIsOpenAddEditBox}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
        />
      )}
      {/* Buyer Tag Add Edit Form End*/}
    </>
  );
}

export default BuyersTagPage;
