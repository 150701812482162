import React, { useMemo } from "react";
import {
  Grid,
  Chip,
  useEffect,
  useState,
  Box,
  moment,
  PageHeader,
  useNavigate,
  useLocation,
  AddOutlined,
  MenuItem,
  Menu,
  Divider,
  AddIcon,
} from "../../Collections/Imports";
import "@inovua/reactdatagrid-community/theme/blue-light.css";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/index.css";
import "./../BuyerListing/style.css";

import Filters from "../../Components/UIComponents/Filters";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import {
  buyerModuleAPiENdPoints,
  coreAPIEndsPoints,
} from "../../Config/APIsEndpoints";
import { useDispatch, useSelector } from "react-redux";
import AgGridComponent from "../../Components/CommonComponents/AG-Grid";

import CircularProgressBar from "../../Components/UIComponents/CircularProgressBar";
import { Outlet } from "react-router-dom";

import AddEditPlAndDnpl from "../../Forms/AddEditPlAndDmpl";
import {
  mdiCircleEditOutline,
  mdiPlusCircleOutline,
  mdiTuneVertical,
} from "@mdi/js";
import Icon from "@mdi/react";
import { getPlDnplData, removeSinglePlDnpl } from "../../Store/PlDnplModule";
import { currentPlDnplRow } from "../../Store/PlDnplModule/currentPlDnplRowData";
import PlDnplFilters from "../../Forms/PlDnplFilters";
import { updatePlDnplFilterData } from "../../Store/PlDnplModule/filterSession";

const PlAndDnpl = () => {
  const dispatch = useDispatch();
  let location = useLocation();

  const statusOptions = [
    { label: "Active", value: "true" },
    { label: "Inactive", value: "false" },
  ];

  // menu Work

  // Memu

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [rowSelected, setRowSelected] = useState({});
  const [isOpenAddEditBox, setIsOpenAddEditBox] = useState(false);
  const [openSetValueForm, setOpenSetValueForm] = useState(false);
  const [openAppendForm, setOpenAppendForm] = useState(false);
  const [isOpenPlDnplFilters, setIsOpenPlDnplFilter] = useState(false);

  const [isEditMode, setIsEditMode] = useState(false);

  const [defaultPlDnpl, setDefaultPlDnpl] = useState(false);

  const TableData = useSelector((state) => state.PlAndDnpl?.tableData);
  
  const previousFilterData = useSelector(
    (state) => state?.plDnplDataFilterSession?.filterData
  );

  const typeOptions = useSelector((state) => state?.typeOptions?.data);

  const [GetAllPl, AllPlData] = useApiCallMutation();

  //pagination

  const [paginationIndex, setPaginationIndex] = useState(0);
  const [itemPerIndex, setItemPerIndex] = useState(100);
  const perPageCountOptions = [
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "1000", value: 1000 },
  ];

  const handlePageChange = (data) => {
    setPaginationIndex(data);
  };
  const handleRowsPerPageChange = (data) => {
    setItemPerIndex(data);
    setPaginationIndex(0);
  };

  //Api Initialization
  // debugger;

  const plDnplOptions = TableData?.data?.map((pldnpl) => {
    return { label: pldnpl?.listType, value: pldnpl?.listType };
  });

  // List Type

  const ListTypeOptions = [
    { label: "PL", id: 1, value: "PL" },
    { label: "DNPL", id: 2, value: "DNPL" },
  ];
  // GetPl Dnpl options
  const [plDnplFieldNameOptions, setPlDnplFieldNameOptions] = useState([]);
  const GetPlDnplOptions = useGetApiCallQuery({
    endPoint: buyerModuleAPiENdPoints.getStandardField,
    method: "GET",
  });

  // Check if the necessary properties are available in the API response
  useEffect(() => {
    if (GetPlDnplOptions.data?.data?.getAllFields) {
      const filteredFieldsData = GetPlDnplOptions.data.data.getAllFields
        .filter((field) => field.pldnplList)
        .map(({ id, name }) => ({ value: name, label: name, id: id }));

      setPlDnplFieldNameOptions(filteredFieldsData);
    }
  }, [GetPlDnplOptions.data]);

  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 2);

  // debugger;

  const plDnplData = {
    // index: paginationIndex,
    // itemPerIndex: itemPerIndex,
    listName: previousFilterData?.listName || ["All"],
    listType: previousFilterData?.listType || ["All"],
    fieldName: previousFilterData?.fieldName || [],
    status: previousFilterData?.status || [],
    fromDate: moment(previousFilterData?.fromDate).toISOString(),
    toDate:
      moment(previousFilterData?.toDate).toISOString() == ""
        ? moment(selectedDateFilter?.endDate).toISOString()
        : moment(previousFilterData?.toDate).toISOString(),
  };

  useEffect(() => {
    GetAllPl({
      endPoint: coreAPIEndsPoints.getAllMasterPlDnpl,
      method: "POST",
      data: { data: { ...plDnplData } },
    });
  }, [paginationIndex, itemPerIndex, previousFilterData]);

  useEffect(() => {
    dispatch(updatePlDnplFilterData(plDnplData));
  }, []);

  useEffect(() => {
    if (AllPlData?.isSuccess && AllPlData?.data?.data?.masterPlDnpl) {
      const pldnplDataList = [...AllPlData?.data?.data?.masterPlDnpl];

      const tableData = {
        data: pldnplDataList,
        headers: AllPlData?.data?.data?.headers,
      };

      console.log("tableData Schedule", tableData);

      dispatch(getPlDnplData(tableData));
    }
  }, [AllPlData]);

  // set Preselected Filter label
  const setFilterLabel = (filterType) => {
    // debugger;
    switch (filterType) {
      case "listType":
        let listTypeOption = [];
        if (ListTypeOptions?.length > 0) {
          listTypeOption = ListTypeOptions?.filter((opt) =>
            previousFilterData?.listType?.find((val) => val == opt?.value)
          );
        }
        return getFilterLabel("listType", listTypeOption);

        break;
      case "listName":
        let listName = [];
        // if (previousFilterData.listName != "") {
        listName = previousFilterData.listName;
        // }
        console.log(statusOptions, previousFilterData?.listName);
        return getFilterLabel("listName", listName);
        break;
      case "fieldName":
        let fieldName = [];

        if (plDnplFieldNameOptions?.length > 0) {
          fieldName = plDnplFieldNameOptions?.filter((opt) =>
            previousFilterData?.fieldName?.find((val) => val == opt.value)
          );
        }
        // console.log(labelOptions?.fieldName, previousFilterData?.fieldName);

        return getFilterLabel("fieldName", fieldName);
        break;
      case "type":
        let typeoptions = [];
        if (typeOptions.length > 0) {
          typeoptions = typeOptions?.type.filter((opt) =>
            previousFilterData?.type?.find((val) => val == opt.value)
          );
        }

        return getFilterLabel("type", typeoptions);
        break;
    }
  };

  // get Preselected Filter label
  const getFilterLabel = (filterType, options) => {
    if (options?.length === 1) {
      return (
        <span>
          <Chip label={options[0]?.label} sx={{ m: "2px" }} size="small" />
        </span>
      );
    } else if (options?.length > 1) {
      return (
        <>
          <span>
            <Chip label={options[0]?.label} sx={{ m: "2px" }} size="small" />
          </span>
          <span
            style={{ cursor: "pointer", color: "#000", paddingLeft: "4px" }}
          >
            {`+${previousFilterData[filterType]?.length - 1}`}
          </span>
        </>
      );
    } else {
      return (
        <span>
          <Chip label={"All"} sx={{ m: "2px" }} size="small" />
        </span>
      );
    }
  };

  const toggleDrawer = (ele) => {
    ele === "created"
      ? setDefaultPlDnpl("created")
      : ele === "listType"
      ? setDefaultPlDnpl("listType")
      : ele === "fieldName"
      ? setDefaultPlDnpl("fieldName")
      : ele === "listName"
      ? setDefaultPlDnpl("listName")
      : setDefaultPlDnpl(false);

    setIsOpenPlDnplFilter((prev) => !prev);
  };

  const [selectedDateFilter, setSelectedDateFilter] = useState({
    startDate: new Date("July 01, 2020"),
    endDate: new Date(),
    key: "selection",
  });
  const [DeletePlDnplRow, setDeletePlDnplRow] = useApiCallMutation();

  const [CurrentValu, setCurrentValue] = useState("");
  // Handle Grid Button ACtion
  const handleActions = (previousData, action) => {
    switch (action) {
      case "EDIT":
        setRowSelected(previousData);
        setIsEditMode(true);
        setIsOpenAddEditBox(true);
        sessionStorage.setItem(
          "currentPlDnplData",
          JSON.stringify(previousData)
        );
        dispatch(currentPlDnplRow(previousData));

        break;
      case "CONFIG":
        // setRowSelected(previousData);
        // sessionStorage.setItem(
        //   "currentPlDnplData",
        //   JSON.stringify(previousData)
        // );
        // dispatch(updateCampaignRowData(previousData));
        // const currentCampData = JSON.parse(
        //   sessionStorage.getItem("currentCampaignData")
        // );
        // setCampainName(currentCampData?.campaignName);
        // navigate(String(previousData?.uniqueId));

        break;

      case "VALUES":
        handleClick(previousData.eGridCell);
        setRowSelected(previousData);
        break;
      case "Status":
        const data = {
          id: previousData?.campaignId,
          campaignName: previousData?.campaignName,
          timezone: previousData?.timezone,
          campaignType: previousData?.campaignType,
          minPrice: previousData?.minPrice,
          campaignIntegrations: previousData?.campaignIntegrations,
          status: previousData?.status,
        };

      case "DELETE":
        DeletePlDnplRow({
          endPoint: coreAPIEndsPoints.deleteMasterPlDnpl,
          method: "POST",
          // data: { data: listId },

          data: {
            data: {
              masterPlDnplId: previousData.masterPlDnplId,
            },
          },
        });
        dispatch(removeSinglePlDnpl(previousData.masterPlDnplId));
        break;
    }
  };

  // const handlePageChange = (data) => {
  //   setPaginationIndex(data);
  // };
  // const handleRowsPerPageChange = (data) => {
  //   setItemPerIndex(data);
  //   setPaginationIndex(0);
  // };

  const ITEM_HEIGHT = 35;

  const handleOpenMenuOne = () => {
    setOpenSetValueForm(true);
    setIsOpenAddEditBox(true);
    handleCloseMenu();
  };

  const handleOpenMenuTwo = () => {
    setOpenAppendForm(true);
    setIsOpenAddEditBox(true);
    handleCloseMenu();
  };

  return (
    <>
      <Box>
        <PageHeader title="Pl/Dnpl" helpText={"Pl/Dnpl"} />

        {location?.pathname === "/pldnpl" && (
          <>
            {/* Filters Start*/}
            <Box sx={{ pl: "15px", pr: "10px" }} mt={3} mb={1}>
              <Grid container spacing={2}>
                <Filters
                  title="List Type :"
                  onClick={() => toggleDrawer("listType")}
                >
                  {setFilterLabel("listType")}
                </Filters>

                <Filters
                  title="List Name :"
                  onClick={() => toggleDrawer("listName")}
                >
                  {previousFilterData?.listName && (
                    <span>
                      {console.log(
                        "reviousFilterData?.listName",
                        previousFilterData?.listName
                      )}
                      <Chip
                        label={previousFilterData?.listName || "All"}
                        sx={{ m: "2px" }}
                        size="small"
                      />
                    </span>
                  )}
                </Filters>

                <Filters
                  title="Field Name :"
                  onClick={() => toggleDrawer("fieldName")}
                >
                  {setFilterLabel("fieldName")}
                </Filters>

                <Filters
                  title="Created :"
                  onClick={() => toggleDrawer("created")}
                >
                  <Box>
                    <span style={{ fontSize: "14px" }}>
                      <Chip
                        label={moment(
                          previousFilterData?.fromDate
                            ? previousFilterData?.fromDate
                            : selectedDateFilter?.startDate
                        ).format("ll")}
                        sx={{ m: "2px" }}
                        size="small"
                      />
                    </span>
                    {" - "}
                    <span style={{ fontSize: "14px" }}>
                      <Chip
                        label={moment(
                          previousFilterData?.toDate
                            ? previousFilterData?.toDate
                            : selectedDateFilter?.endDate
                        ).format("ll")}
                        sx={{ m: "2px" }}
                        size="small"
                      />
                    </span>
                  </Box>
                </Filters>

                <Filters
                  title="Filters"
                  icons={<AddIcon fontSize="small" />}
                  onClick={() => toggleDrawer("filters")}
                ></Filters>
                <Filters
                  title="Clear All"
                  onClick={() => dispatch(updatePlDnplFilterData({}))}
                ></Filters>
              </Grid>
            </Box>
            {/* Filters End*/}

            {TableData.status === "isUninitialized" && <CircularProgressBar />}
            {TableData.status !== "isUninitialized" && (
              <Box>
                <AgGridComponent
                  rowData={TableData?.data || []}
                  columnDefs={TableData.headers || []}
                  actions={["EDIT", "DELETE", "VALUES"]}
                  performAction={handleActions}
                  // totalPageCount={AllCampaigns.data?.data?.total}
                  paginationIndex={paginationIndex}
                  itemPerIndex={itemPerIndex}
                  perPageCountOptions={perPageCountOptions}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  // handleClick={handleClick}
                  rowMode
                  addAction={setIsOpenAddEditBox}
                  AddButtonName={"Add New List"}
                  addActionIcon={<AddOutlined />}
                  downlodBtn={true}
                  tableName={"PL&DNPL"}
                />
              </Box>
            )}

            {isOpenAddEditBox && (
              <AddEditPlAndDnpl
                defaultValues={rowSelected}
                isOpen={isOpenAddEditBox}
                setOpen={setIsOpenAddEditBox}
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
                setOpenSetValueForm={setOpenSetValueForm}
                openSetValueForm={openSetValueForm}
                setOpenAppendForm={setOpenAppendForm}
                openAppendForm={openAppendForm}
              />
            )}

            {isOpenPlDnplFilters && (
              <PlDnplFilters
                defaultValues={rowSelected}
                isOpen={isOpenPlDnplFilters}
                setOpen={setIsOpenPlDnplFilter}
                isEditMode={isEditMode}
                defaultPlDnpl={defaultPlDnpl}
              />
            )}

            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleCloseMenu}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 3.5,
                  width: "15ch",
                },
              }}
            >
              <MenuItem
                onClick={handleOpenMenuOne}
                sx={{ fontSize: "12px", pt: "0", pb: "0" }}
              >
                <Icon path={mdiTuneVertical} size={0.6} />
                <span style={{ marginLeft: "4px" }}>Set Values</span>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={handleOpenMenuTwo}
                sx={{ fontSize: "12px", pt: "0", pb: "0" }}
              >
                <Icon path={mdiPlusCircleOutline} size={0.6} />
                <span style={{ marginLeft: "4px" }}>Append</span>
              </MenuItem>

              <Divider />
              <MenuItem
                onClick={handleOpenMenuOne}
                sx={{ fontSize: "12px", pt: "0", pb: "0" }}
              >
                <Icon path={mdiCircleEditOutline} size={0.6} />
                <span style={{ marginLeft: "4px" }}>Edit Values</span>
              </MenuItem>
            </Menu>
          </>
        )}
        <div>
          <Outlet />
        </div>
      </Box>
    </>
  );
};
export default PlAndDnpl;
