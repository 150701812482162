import * as React from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Drawer,
  AppBar,
  CssBaseline,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
  AccountCircle,
  Menu,
  MenuItem,
  useNavigate,
  SellIcon,
  LabelIcon,
  RuleOutlinedIcon,
  AddBusinessOutlinedIcon,
  DynamicFeedOutlinedIcon,
} from "../Collections/Imports";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import ManageHistoryOutlinedIcon from "@mui/icons-material/ManageHistoryOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import AltRouteOutlinedIcon from "@mui/icons-material/AltRouteOutlined";
import SwitchAccountOutlinedIcon from "@mui/icons-material/SwitchAccountOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import DatasetLinkedOutlinedIcon from "@mui/icons-material/DatasetLinkedOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import DetailsIcon from "@mui/icons-material/Details";
import { Outlet } from "react-router-dom";
import "./style.css";
import useLogout from "../CustomHooks/useLogout";
import useIntrospect from "../CustomHooks/useIntrospect";
import { idpURL, logoutURL } from "../Config/envConfig";
import useRefreshToken from "../CustomHooks/useRefreshToken";
import { useEffect } from "react";
import LoginLogo from "../assets/images/login-logo.png";
import ZapLogo from "../assets/images/zappian-logo.png";
import { useState } from "react";
import { Collapse } from "@mui/material";
import { color, minWidth } from "@mui/system";
import { useApiCallMutation } from "../Services/ApiCallService";
import { coreAPIEndsPoints } from "../Config/APIsEndpoints";
const drawerWidth = 200;
const ClippedDrawer = () => {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const introspectCall = useIntrospect();
  const refreshTokenCall = useRefreshToken();
  const [isActive, setIsActive] = React.useState(false);
  const [openSubMenuIndex, setOpenSubMenuIndex] = useState(-1);

  const activeSubmenuItemStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.08)", // Example background color for active submenu item
    borderLeft: "4px solid #3f51b5", // Example left border for active submenu item
  };

  const location = useLocation();
  // const handlePopstate = () => {
  //   const currentPath = location.pathname;
  //   const activeIndex = allNav.findIndex((item) => item.to === currentPath);

  //   if (activeIndex !== -1) {
  //     setIsActive(true);
  //     setActiveMenuItem(currentPath);
  //   } else {
  //     setIsActive(false);
  //     setActiveMenuItem(allNav[0]?.to);
  //   }
  // };

  //My Code Nav

  const publisherManagementWithHeading = [
    {
      // name: "Dashboard",
      items: [
        {
          name: "Dashboard",
          route: "/dashboard",
          icon: "AppsOutlinedIcon",
        },
      ],
    },
    {
      name: "Report & Analytics",
      items: [
        {
          name: "Pub-Reports",
          route: "/pub-report",
          icon: "AssessmentOutlinedIcon",
          submenu: [
            {
              name: "Publisher Details",
              route: "/pub-report/pldnpl",
              icon: "AssessmentOutlinedIconRepo1",
            },
            {
              name: "Publisher Settings",
              route: "/pub-report/publisher",
              icon: "AssessmentOutlinedIconRepo2",
            },
            {
              name: "Lead Details",
              route: "/lead-details",
              icon: "DetailsIcon",
            },
          ],
        },
      ],
    },
    {
      name: "Client Management",
      items: [
        {
          name: "Buyers",
          route: "/buyers",
          icon: "ListAltOutlinedIcon",
        },
        {
          name: "Campaigns",
          route: "/campaigns",
          icon: "CampaignOutlinedIcon",
        },
        {
          name: "Buyer Labels",
          route: "/buyer-labels",
          icon: "LabelIconCm1",
        },
        {
          name: "Buyer Tags",
          route: "/buyer-tags",
          icon: "SellIconCm1",
        },
        {
          name: "Campaign Tags",
          route: "/campaign-tags",
          icon: "SellIconCm2",
        },
        {
          name: "PL / DNPL",
          route: "/pldnpl",
          icon: "RuleOutlinedIcon",
        },
      ],
    },
    {
      name: "Publisher Management",
      items: [
        {
          name: "Publishers",
          route: "/publisher",
          icon: "SwitchAccountOutlinedIcon",
        },

        {
          name: "Channels",
          route: "/channel",
          icon: "AccountTreeOutlinedIcon",
        },
        {
          name: "Publisher Tags",
          route: "/publisher-tags",
          icon: "SellIconPub1",
        },
        {
          name: "Publisher Label",
          route: "/publisher-label",
          icon: "LabelIconPub1",
        },
        {
          name: "Channel Label",
          route: "/channel-label",
          icon: "LabelIconPub2",
        },
        {
          name: "Source - Channel Mapping",
          route: "/channel-mapping",
          icon: "DatasetLinkedOutlinedIcon",
        },
      ],
    },

    {
      name: "Lead Distribution",
      items: [
        {
          name: "Pingtree",
          route: "/ping-tree",
          icon: "AltRouteOutlinedIcon",
        },
        {
          name: "Marketplace",
          route: "",
          icon: "AddBusinessOutlinedIcon",
        },
        {
          name: "Silent",
          route: "",
          icon: "DynamicFeedOutlinedIcon",
        },
      ],
    },
    {
      name: "User Management",
      items: [
        {
          name: "Users",
          route: "",
          icon: "AdminPanelSettingsOutlinedIcon",
        },
        {
          name: "Add User",
          route: "",
          icon: "PersonAddAltOutlinedIcon",
        },
      ],
    },
  ];
  const [GetNavList, AllRepoNav] = useApiCallMutation();
  let currentPubData = JSON.parse(localStorage.getItem("userInfo"));

  // Ensuring AllReoLinks is an array

  const AllReoLinks = Array.isArray(AllRepoNav?.data?.data)
    ? AllRepoNav?.data?.data
    : [];
  // const AllReoLinks = AllRepoNav?.data?.data;
  const AllNavLinks = useEffect(() => {
    {
      currentPubData &&
        GetNavList({
          endPoint: coreAPIEndsPoints.getRepo,
          method: "POST",
          data: {
            data: { userId: currentPubData?.id },
          },
        });
    }
  }, []);
  //live icon name

  const DefaultIcon = ({ iconName, isActive }) => {
    switch (iconName) {
      case "DashboardIcon":
        return <AppsOutlinedIcon color={isActive} />;
      case "AssessmentOutlinedIcon":
        return <AssessmentOutlinedIcon color={isActive} />;
      case "BuyersIcon":
        return <ListAltOutlinedIcon color={isActive} />;
      case "CampaignsIcon":
        return <CampaignOutlinedIcon color={isActive} />;

      case "BuyerLabelIcon":
        return <LabelIcon color={isActive} />;
      case "BuyerTagsIcon":
        return <SellIcon color={isActive} />;
      case "CampaignTagsIcon":
        return <SellIcon color={isActive} />;
      case "PLDNPLIcon":
        return <RuleOutlinedIcon color={isActive} />;

      case "PublisherIcon":
        return <SwitchAccountOutlinedIcon color={isActive} />;

      case "ChannelIcon":
        return <AccountTreeOutlinedIcon color={isActive} />;

      case "PublisherTagsIcon":
        return <SellIcon color={isActive} />;

      case "ChannelLabelsIcon":
        return <LabelIcon color={isActive} />;

      case "ChannelMappingIcon":
        return <LabelIcon color={isActive} />;

      case "PingTreeIcon":
        return <DatasetLinkedOutlinedIcon color={isActive} />;

      case "SilentTreeIcon":
        return <AltRouteOutlinedIcon color={isActive} />;

      case "AddBusinessOutlinedIcon":
        return <AddBusinessOutlinedIcon color={isActive} />;

      case "DynamicFeedOutlinedIcon":
        return <DynamicFeedOutlinedIcon color={isActive} />;

      case "AdminPanelSettingsOutlinedIcon":
        return <AdminPanelSettingsOutlinedIcon color={isActive} />;

      case "UserIcon":
        return <PersonAddAltOutlinedIcon color={isActive} />;

      case "AssessmentOutlinedIconRepo1":
        return <ManageHistoryOutlinedIcon color={isActive} />;

      case "AssessmentOutlinedIconRepo2":
        return <ManageAccountsOutlinedIcon color={isActive} />;

      case "PersonAddAltOutlinedIcon":
        return <ManageAccountsOutlinedIcon color={isActive} />;
      case "LeadDetailsIcon":
        return <DetailsIcon color={isActive} />;
      case "PerformanceSummaryIcon":
        return <SummarizeOutlinedIcon />;

      default:
        return null;
    }
  };

  // static icon name

  // const DefaultIcon = ({ iconName, isActive }) => {
  //   switch (iconName) {
  //     case "AppsOutlinedIcon":
  //       return <AppsOutlinedIcon color={isActive} />;
  //     case "AssessmentOutlinedIcon":
  //       return <AssessmentOutlinedIcon color={isActive} />;
  //     case "AssessmentOutlinedIconRepo1":
  //       return <ListAltOutlinedIcon color={isActive} />;
  //     case "AssessmentOutlinedIconRepo2":
  //       return <CampaignOutlinedIcon color={isActive} />;

  //     case "ListAltOutlinedIcon":
  //       return <LabelIcon color={isActive} />;
  //     case "CampaignOutlinedIcon":
  //       return <SellIcon color={isActive} />;
  //     case "CampaignTagsIcon":
  //       return <SellIcon color={isActive} />;
  //     case "LabelIconCm1":
  //       return <RuleOutlinedIcon color={isActive} />;

  //     case "SellIconCm1":
  //       return <SwitchAccountOutlinedIcon color={isActive} />;

  //     case "SellIconCm2":
  //       return <AccountTreeOutlinedIcon color={isActive} />;

  //     case "RuleOutlinedIcon":
  //       return <SellIcon color={isActive} />;

  //     case "SwitchAccountOutlinedIcon":
  //       return <LabelIcon color={isActive} />;

  //     case "AccountTreeOutlinedIcon":
  //       return <LabelIcon color={isActive} />;

  //     case "SellIconPub1":
  //       return <DatasetLinkedOutlinedIcon color={isActive} />;

  //     case "LabelIconPub1":
  //       return <AltRouteOutlinedIcon color={isActive} />;

  //     case "LabelIconPub2":
  //       return <AddBusinessOutlinedIcon color={isActive} />;

  //     case "DatasetLinkedOutlinedIcon":
  //       return <DynamicFeedOutlinedIcon color={isActive} />;

  //     case "AltRouteOutlinedIcon":
  //       return <AdminPanelSettingsOutlinedIcon color={isActive} />;

  //     case "AddBusinessOutlinedIcon":
  //       return <PersonAddAltOutlinedIcon color={isActive} />;

  //     case "DynamicFeedOutlinedIcon":
  //       return <ManageHistoryOutlinedIcon color={isActive} />;

  //     case "AdminPanelSettingsOutlinedIcon":
  //       return <ManageAccountsOutlinedIcon color={isActive} />;

  //     case "PersonAddAltOutlinedIcon":
  //       return <ManageAccountsOutlinedIcon color={isActive} />;

  //     default:
  //       return null;
  //   }
  // };

  const activeStyle = {
    width: "233px",
    height: "40px",
    margin: "0px 5px 5px 5px",
    background: "#2A4BC0",
    color: "white",
    gap: "4.17px",
    borderRadius: "5px",
    "&:hover": {
      background: "#4a72ff",
    },
  };
  const textActiveStyle = {
    color: "#fff !important",
    fontSize: "14px",
    "& .MuiListItemText-primary": {
      color: "#fff !important",
    },
  };
  // useEffect(() => {
  //   handlePopstate();
  //   window.addEventListener("popstate", handlePopstate);
  //   return () => {
  //     window.removeEventListener("popstate", handlePopstate);
  //   };
  // }, [location, allNav]);
  useEffect(() => {
    if (!document.hidden) {
      if (JSON.parse(localStorage.getItem("userCredential"))?.access_token) {
        introspectCall();
      } else {
        navigate("/");
      }
    }
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        if (JSON.parse(localStorage.getItem("userCredential"))?.access_token) {
          introspectCall();
        } else {
          navigate("/");
        }
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      const responce = await introspectCall();
      if (responce.data?.data?.active) {
        const id_token = JSON.parse(
          localStorage.getItem("userCredential")
        )?.id_token;
        window.location.href = `${idpURL}/connect/logout?post_logout_redirect_uri=${logoutURL}&id_token_hint=${id_token}`;
      } else {
        const res = await refreshTokenCall();
        if (res.data.data) {
          const id_token = JSON.parse(
            localStorage.getItem("userCredential")
          )?.id_token;
          window.location.href = `${idpURL}/connect/logout?post_logout_redirect_uri=${logoutURL}&id_token_hint=${id_token}`;
        }
      }
    } catch (error) {
      localStorage.clear();
      navigate("/");
    }
  };
  return (
    <>
      <Box sx={{ display: "flex" }} className="layout">
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: "#0C247B",
            zIndex: "2",
            // zIndex: (theme) => theme.zIndex.drawer + 1,
            [`& .MuiToolbar-root`]: {
              minHeight: "50px",
            },
          }}
        >
          <Toolbar className="topToolbar">
            <Box component="div" sx={{ flexGrow: 1 }}>
              <img src={LoginLogo} alt="icon" />
              <img src={ZapLogo} alt="Zappian Logo" className="zappian-logo" />
            </Box>

            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem
                  onClick={() => {
                    handleLogout();
                    handleClose();
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            zIndex: 1,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: "auto" }} className="sideNavBox">
            <List className="sideNav">
              {AllReoLinks?.map((group, groupIndex) => (
                <React.Fragment key={groupIndex}>
                  <ul style={{ listStyleType: "none", padding: "3px 0px" }}>
                    <Typography
                      sx={{ mt: 0.5, ml: 2 }}
                      color="text.secondary"
                      display="block"
                      variant="caption"
                    >
                      {group.name}
                    </Typography>
                  </ul>
                  {group.items.map((item, index) => (
                    <React.Fragment key={item.name}>
                      {console.log("Namve icon styale icon ame", group.name)}
                      <ListItem
                        disablePadding
                        className="sideMenuItem"
                        onClick={() => {
                          if (
                            group.name === "Report & Analytics" &&
                            index === 0
                          ) {
                            setOpenSubMenuIndex(
                              openSubMenuIndex === groupIndex ? -1 : groupIndex
                            );
                          } else {
                            navigate(item.route);
                          }
                        }}
                      >
                        <ListItemButton
                          className="sideNavButton"
                          sx={
                            location.pathname === item.route ? activeStyle : ""
                          }
                        >
                          <ListItemIcon
                            className="sideNavIcon"
                            sx={
                              location.pathname === item.route
                                ? { filter: "invert(1)", minWidth: "35px" }
                                : { minWidth: "35px", filter: "invert(0)" }
                            }
                          >
                            <DefaultIcon
                              iconName={item.icon}
                              isActive={
                                isActive && location.pathname === item.route
                                  ? textActiveStyle
                                  : ""
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            sx={
                              location.pathname === item.route
                                ? textActiveStyle
                                : ""
                            }
                            primary={item.name}
                            className="sideMenuItem"
                          />
                          {group.name === "Report & Analytics" &&
                            index === 0 && (
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setOpenSubMenuIndex(
                                    openSubMenuIndex === groupIndex
                                      ? -1
                                      : groupIndex
                                  );
                                }}
                              >
                                {openSubMenuIndex === groupIndex ? (
                                  <ExpandMoreIcon />
                                ) : (
                                  <ExpandLessIcon />
                                )}
                              </IconButton>
                            )}
                        </ListItemButton>
                      </ListItem>
                      {/* Submenu */}
                      <>
                        {group.name === "Report & Analytics" &&
                          index === 0 &&
                          item.submenu && (
                            <Collapse
                              in={openSubMenuIndex === groupIndex}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" disablePadding>
                                {item.submenu.map((subItem, subIndex) => (
                                  <ListItem
                                    key={subItem.name}
                                    disablePadding
                                    className="sideMenuItem"
                                    onClick={() => navigate(subItem.route)}
                                    style={{ background: "#e6e6e24d" }}
                                  >
                                    <ListItemButton
                                      className="sideNavIcon"
                                      sx={
                                        location.pathname === subItem.route // Compare with subItem.route instead of item.route
                                          ? activeStyle
                                          : ""
                                      }
                                    >
                                      <ListItemIcon
                                        className="sideNavIcon"
                                        sx={
                                          location.pathname === subItem.route
                                            ? {
                                                filter: "invert(1)",
                                                minWidth: "35px",
                                              }
                                            : {
                                                minWidth: "35px",
                                                filter: "invert(0)",
                                              }
                                        }
                                      >
                                        <DefaultIcon
                                          iconName={subItem.icon}
                                          isActive={
                                            isActive &&
                                            location.pathname === item.route
                                              ? textActiveStyle
                                              : ""
                                          }
                                        />
                                        {/* Render appropriate icons based on condition */}
                                        {/* {subIndex == "0" ? (
                                          <SellIcon sx={{ color: "#fff" }} />
                                        ) : subIndex == "1" ? (
                                          <LabelIcon />
                                        ) : (
                                          <AltRouteOutlinedIcon />
                                        )} */}
                                        {/* <Box
                                          sx={{
                                            width: "5px",
                                            height: "5px",
                                            backgroundColor: "#000",
                                            borderRadius: "50%",
                                          }}
                                        /> */}
                                      </ListItemIcon>

                                      <ListItemText
                                        primary={subItem.name}
                                        className="sideMenuItem"
                                        sx={
                                          location.pathname === subItem.route
                                            ? textActiveStyle
                                            : ""
                                        }
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                ))}
                              </List>
                            </Collapse>
                          )}
                      </>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </List>
          </Box>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            pt: 1,
            pb: 1,
            background: "#F1F2F4",
          }}
        >
          {/* <Box>
            <Toolbar />
          </Box> */}

          <Box mt={8} ml={1} mr={1}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ClippedDrawer;
