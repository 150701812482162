import { useCallback } from "react";
import { useApiCallMutation } from "../../Services/ApiCallService";
import { apiEndPointsConfig } from "../../Config/APIsEndpoints";
import useRefreshToken from "../useRefreshToken";

function useIntrospect() {
  const [IntroSpect, IntroSpectData] = useApiCallMutation();
  const refreshTokenCall = useRefreshToken();

  const introspect = useCallback(async () => {
    try {
      const response = await IntroSpect({
        endPoint: apiEndPointsConfig.introspect,
        method: "POST",
        data: {
          data: {
            clientName: "lds",
          },
        },
      });
      if (!response?.data?.data?.active) {
        await refreshTokenCall();
      }
      return response;
    } catch (error) {
      throw(error)
    }
  }, [IntroSpect]);

  return introspect;
}

export default useIntrospect;
