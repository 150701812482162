import React, { useEffect } from "react";
import useLogout from "../../CustomHooks/useLogout";

const Logout = () => {
  const logout = useLogout();
  useEffect(() => {
    logout();
  },[]);
  return <div></div>;
};

export default Logout;
