import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  snackbarMessage: {
    open: false,
    color: "success",
    message: "",
    vertical: "bottom",
    horizontal: "left",
  },
};
const snackbarConfig = createSlice({
  name: "snackbarConfig",
  initialState,
  reducers: {
    updateSnackBarConfig: (state, action) => {
      state.snackbarMessage = { ...state.snackbarMessage, ...action.payload };
    },
  },
});

export const { updateSnackBarConfig } = snackbarConfig.actions;
export default snackbarConfig.reducer;
