import React, { useEffect } from "react";
import {
  AddIcon,
  AddOutlined,
  ArrowBack,
  Box,
  Chip,
  Grid,
  PageHeader,
  useLocation,
  useNavigate,
} from "../../Collections/Imports";
import Filters from "../../Components/UIComponents/Filters";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import moment from "moment/moment";
import { updateBuyersLabelFilterData } from "../../Store/BuyerLabelModule/filter";
import { buyerLabelApiEndPoint } from "../../Config/APIsEndpoints";
import CircularProgressBar from "../../Components/UIComponents/CircularProgressBar";
import AgGridComponent from "../../Components/CommonComponents/AG-Grid";
import AddEditBuyer from "../../Forms/AddEditBuyer";
import AddEditBuyerLabel from "../../Forms/AddEditBuyerLabel";
import BuyerLabelFilters from "../../Forms/BuyerLabelFilter";
import {
  getBLData,
  removeSingleBuyerLabelData,
  updateSingleBuyerLabelData,
} from "../../Store/BuyerLabelModule";

const BuyerLabel = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [defaultBuyers, setDefaultBuyers] = useState(false);

  const [isOpenBuyerFilter, setIsOpenBuyerFilter] = useState(false);

  // Form pagination handler
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [itemPerIndex, setItemPerIndex] = useState(100);
  const perPageCountOptions = [
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "1000", value: 1000 },
  ];

  const [rowSelected, setRowSelected] = useState({});
  const [isOpenAddEditBuyerLabel, setIsOpenAddEditBuyerLabel] = useState(false);
  const [isOpenBuyerLabelFilter, setIsOpenBuyerLabelFilter] = useState(false);
  const [isOpenAddEditBuyerConfig, setIsOpenAddEditBuyerConfig] =
    useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const TableData = useSelector((state) => state.buyersLabelData?.tableData);
  // debugger
  // console.log('TableData-----',TableData)

  const labelOptions = useSelector((state) => state?.labelOptions?.data);

  //Api Initialization
  const [GetAllBuyerLabel, AllBuyerLabelData] = useApiCallMutation();
  const [UpdateBuyerLabel, UpdateBuyerLabelData] = useApiCallMutation();
  const [DeleteBuyerLabel, setDeleteBuyerLabel] = useApiCallMutation();

  const [buyerLabelField, setBuyerLabelField] = useState([]);

  // console.log("BL",AllBuyerLabelData);

  // console.log('buyerLabelField----',buyerLabelField)

  const previousFilterData = useSelector(
    (state) => state?.buyersLabelDataFilterSession?.filterData
  );

  const buyerLabelData = useGetApiCallQuery({
    endPoint: buyerLabelApiEndPoint.getBuyerLabel,
    method: "GET",
  });

  // Check if the necessary properties are available in the API response
  // useEffect(() => {
  //   debugger;
  //   if (buyerLabelData.data) {
  //     const filteredFieldsData = buyerLabelData?.data?.data?.labels;
  //     // .filter((field) => field.pldnplList)
  //     // .map(({ id, name }) => ({ value: name, label: name, id: id }));

  //     setBuyerLabelField(filteredFieldsData);
  //   }
  // }, [buyerLabelData]);

  const BuyerLabelreqData = {
    fromDate: moment(previousFilterData?.fromDate).toISOString(),
    toDate:
      moment(previousFilterData?.toDate).toISOString() == ""
        ? moment(selectedDateFilter?.endDate).toISOString()
        : moment(previousFilterData?.toDate).toISOString(),
    labelUniqueId: previousFilterData?.labelUniqueId,
  };

  useEffect(() => {
    return () => {
      dispatch(updateBuyersLabelFilterData(BuyerLabelreqData));
    };
  }, []);

  useEffect(() => {
    if (UpdateBuyerLabelData?.isSuccess && !UpdateBuyerLabelData?.data?.error) {
      dispatch(updateSingleBuyerLabelData(UpdateBuyerLabelData.data?.data));
    }
  }, [UpdateBuyerLabelData]);

  useEffect(() => {
    // debugger;
    if (buyerLabelData.data) {
      const filteredFieldsData = buyerLabelData?.data?.data?.labels;

      const tableData = {
        data: filteredFieldsData,
        headers: buyerLabelData?.data?.data?.headers,
      };

      // console.log("tableData Schedule", tableData);
      dispatch(getBLData(tableData));
    }
  }, [buyerLabelData]);

  // const toggleDrawer = (ele) => {
  //   // debugger;
  //   ele === "created"
  //     ? setDefaultBuyers("created")
  //     : ele === "label"
  //     ? setDefaultBuyers("label")
  //     : setDefaultBuyers(false);

  //   setIsOpenBuyerLabelFilter((prev) => !prev);
  // };

  const [selectedDateFilter, setSelectedDateFilter] = useState({
    startDate: new Date("July 01, 2020"),
    endDate: new Date(),
    key: "selection",
  });

  // const getFilterLabel = (filterType, options) => {
  //   if (options?.length === 1) {
  //     return (
  //       <span>
  //         <Chip label={options[0]?.label} sx={{ m: "2px" }} size="small" />
  //       </span>
  //     );
  //   } else if (options?.length > 1) {
  //     return (
  //       <>
  //         <span>
  //           <Chip label={options[0]?.label} sx={{ m: "2px" }} size="small" />
  //         </span>
  //         <span
  //           style={{ cursor: "pointer", color: "#000", paddingLeft: "4px" }}
  //         >
  //           {`+${previousFilterData[filterType]?.length - 1}`}
  //         </span>
  //       </>
  //     );
  //   } else {
  //     return (
  //       <span>
  //         <Chip label={"All"} sx={{ m: "2px" }} size="small" />
  //       </span>
  //     );
  //   }
  // };

  // const setFilterLabel = (filterType) => {
  //   switch (filterType) {
  //     case "label":
  //       let labeloptions = [];
  //       if (labelOptions.length > 0) {
  //         labeloptions = labelOptions?.label.filter((opt) =>
  //           previousFilterData?.label?.find((val) => val == opt.value)
  //         );
  //       }
  //       // console.log(labelOptions?.label, previousFilterData?.label);

  //       return getFilterLabel("label", labeloptions);
  //       break;
  //   }
  // };

  // Handle Grid Button ACtion
  const handleActions = (previousData, action) => {
    switch (action) {
      case "EDIT":
        setRowSelected(previousData);
        setIsEditMode(true);
        setIsOpenAddEditBuyerLabel(true);
        break;
      // case "CONFIG":
      //   setRowSelected(previousData);
      //   setIsOpenAddEditBuyerConfig(true);
      //   setIsEditMode(true);
      //   break;

      case "Status":
        const data = {
          labelUniqueId: previousData?.labelUniqueId,
          name: previousData?.name,
          badge: previousData?.badge,
          status: false,
        };
        UpdateBuyerLabel({
          endPoint: buyerLabelApiEndPoint.updateBuyerLabel,
          method: "POST",
          data: { data },
        });
        break;

      case "DELETE":
        // Optimistically remove the deleted item from the UI
        dispatch(removeSingleBuyerLabelData(previousData.labelUniqueId));

        DeleteBuyerLabel({
          endPoint: buyerLabelApiEndPoint.deleteBuyerLabel,
          method: "POST",
          data: {
            data: {
              labelUniqueId: previousData.labelUniqueId,
            },
          },
        });
        break;
    }
  };

  const handlePageChange = (data) => {
    setPaginationIndex(data);
  };
  const handleRowsPerPageChange = (data) => {
    setItemPerIndex(data);
    setPaginationIndex(0);
  };

  return (
    <>
      {/* Page Header Start */}
      <PageHeader
        title={"Buyer Labels"}
        backIcon={
          location?.pathname === "/buyer-labels" ? null : (
            <ArrowBack sx={{ color: "#0c247b" }} />
          )
        }
        onBack={() => navigate(-1)}
        // helpText={
        //     "This section is a complete list of all active publishers registered in the system with information about their User IDs, Usernames, E-Mails, Statuses, and Registration Date. You can select any Publisher and change their settings, statuses, or confirm the accuracy of their profiles. You can also use the 'View' setting and see details for their activity in the system."
        //   }
        // onClick={() => setIsOpenAddEditBox(true)}
        // buttonLabel="Add New Publisher"
      />
      {/* Page Header End */}
      {location?.pathname === "/buyer-labels" && (
        <>
          {/* Filters Start*/}
          {/*  <Box sx={{ pl: "16px" }} mt={5}>
            <Grid container spacing={2}>
              <Filters
                title="Created :"
                onClick={() => toggleDrawer("created")}
              >
                <Box>
                  <span style={{ fontSize: "14px" }}>
                    <Chip
                      label={moment(
                        previousFilterData?.fromDate
                          ? previousFilterData?.fromDate
                          : selectedDateFilter?.startDate
                      ).format("ll")}
                      sx={{ m: "2px" }}
                      size="small"
                    />
                  </span>{" "}
                  {"-"}{" "}
                  <span style={{ fontSize: "14px" }}>
                    <Chip
                      label={moment(
                        previousFilterData?.toDate
                          ? previousFilterData?.toDate
                          : selectedDateFilter?.endDate
                      ).format("ll")}
                      sx={{ m: "2px" }}
                      size="small"
                    />
                  </span>
                </Box>
              </Filters>
              <Filters title="Label :" onClick={() => toggleDrawer("label")}>
                {setFilterLabel("label")}
              </Filters>
              <Filters
                icons={<AddIcon />}
                title="Filters"
                onClick={() => toggleDrawer("filters")}
              ></Filters>
              <Filters
                title="Clear All"
                onClick={() => dispatch(updateBuyersLabelFilterData({}))}
              ></Filters>
            </Grid>
          </Box>*/}
          {/* Filters End */}

          {AllBuyerLabelData.isLoading && <CircularProgressBar />}
          {!AllBuyerLabelData.isLoading && (
            <Box>
              <AgGridComponent
                rowData={TableData?.data || []}
                columnDefs={TableData?.headers || []}
                actions={["EDIT", "DELETE"]}
                performAction={handleActions}
                totalPageCount={TableData?.data.length}
                paginationIndex={paginationIndex}
                itemPerIndex={itemPerIndex}
                perPageCountOptions={perPageCountOptions}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                addAction={setIsOpenAddEditBuyerLabel}
                AddButtonName={"Add New Label"}
                addActionIcon={<AddOutlined />}
                tableName={"Buyer Labels"}
                rowMode
              />
            </Box>
          )}

          {isOpenAddEditBuyerLabel && (
            <AddEditBuyerLabel
              defaultValues={rowSelected}
              isOpen={isOpenAddEditBuyerLabel}
              setOpen={setIsOpenAddEditBuyerLabel}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
            />
          )}

          {/* {isOpenBuyerLabelFilter && (
            <BuyerLabelFilters
              defaultValues={rowSelected}
              isOpen={isOpenBuyerLabelFilter}
              setOpen={setIsOpenBuyerLabelFilter}
              isEditMode={isEditMode}
              defaultBuyers={defaultBuyers}
            />
          )} */}
        </>
      )}
    </>
  );
};

export default BuyerLabel;
