import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ExpandMore,
  moment,
  Checkbox,
} from "../../Collections/Imports";
import GlobalDateRangePicker from "../../Components/CommonComponents/GlobalDateRangePicker";
import "./style.css";
import GlobalMultiSelect from "../../Components/CommonComponents/GlobalMultiSelect";
import { useDispatch, useSelector } from "react-redux";

import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import { buyerModuleAPiENdPoints } from "../../Config/APIsEndpoints";

import CustomButton from "../../Components/CommonComponents/GlobalButton";
import { updatePlDnplFilterData } from "../../Store/PlDnplModule/filterSession";
import GlobalInput from "../../Components/CommonComponents/GlobalInput";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const PlDnplFilters = (props) => {
  const { setOpen, isOpen, defaultValues, isEditMode, defaultPlDnpl } = props;

  // const currentDate = new Date();
  // currentDate.setMonth(currentDate.getMonth() - 2);

  const AllFilterLength = useSelector(
    (state) => state?.plDnplDataFilterSession?.filterData
  );

  // const buyersOptions = useSelector((state) => state?.buyersOptions?.data);
  const dispatch = useDispatch();

  const currentDate = new Date("July 01, 2020");
  // currentDate.setMonth(currentDate.getMonth() - 2);
  const [selectedDateFilter, setSelectedDateFilter] = useState({
    startDate: new Date("July 01, 2020"),
    endDate: new Date(),
    key: "selection",
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      listName: ["All"],
      listType: [],
      fieldName: [],
    },
    // resolver: yupResolver(validationSchema),
    mode: "all",
  });

  //***********FORM SUBMISSION ********//
  const onFormSubmit = (data) => {
    // debugger;
    // console.log("Date===", data);
    const filterData = {
      listName: [data?.listName || "All"],
      listType: data?.listType,
      fieldName: data?.fieldName,

      fromDate: data?.dateRange
        ? moment(selectedDateFilter?.startDate).startOf("day").toDate()
        : currentDate,
      toDate: data?.dateRange
        ? moment(data?.dateRange[0]?.endDate).endOf("day").toDate()
        : new Date(),
    };

    console.log("onFormSubmit", filterData);
    dispatch(updatePlDnplFilterData(filterData));
    setOpen(false);
  };

  // Call setFormValue after initialization
  useEffect(() => {
    if (AllFilterLength && Object.keys(AllFilterLength)?.length) {
      setFormValue(AllFilterLength);
    }
  }, [AllFilterLength]);

  // Set Form Value
  const setFormValue = (data) => {
    const dateRange = {
      startDate: new Date(data?.fromDate),
      endDate: data?.toDate,
      key: "selection",
    };
    setValue("listName", data?.listName, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("listType", data?.listType, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("fieldName", data?.fieldName, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("dateRange", dateRange, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  // GetPl Dnpl options
  const [plDnplOptions, setplDnplOptions] = useState([]);
  const GetPlDnplOptions = useGetApiCallQuery({
    endPoint: buyerModuleAPiENdPoints.getStandardField,
    method: "GET",
  });

  // Check if the necessary properties are available in the API response
  useEffect(() => {
    if (GetPlDnplOptions.data?.data?.getAllFields) {
      const filteredFieldsData = GetPlDnplOptions.data.data.getAllFields
        .filter((field) => field.pldnplList)
        .map(({ id, name }) => ({ value: name, label: name, id: id }));

      setplDnplOptions(filteredFieldsData);
    }
  }, [GetPlDnplOptions.data]);

  // Select Options

  const AllOptions = { label: "All", id: 1, value: "All" };

  const [selectedOptions, setSelectedOptions] = useState([
    { label: "PL", id: 1, value: "PL" },
    { label: "DNPL", id: 2, value: "DNPL" },
  ]);

  // Pushing the AllOptions object into the Options array
  useEffect(() => {
    setSelectedOptions((prevOptions) => [AllOptions, ...prevOptions]);
    setplDnplOptions((prevOptions) => [AllOptions, ...prevOptions]);
  }, []);

  return (
    <>
      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={() => setOpen(false)}
        sx={{
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            top: "15px",
            borderRadius: "16px",
            maxWidth: "500px",
            width: "100%",
          },
        }}
      >
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent={"space-between"}
          >
            <Box marginBottom={"15px"}>
              <Typography component="h6" variant="h6" fontWeight={600}>
                Filters
              </Typography>
            </Box>
            <Box marginBottom={"15px"}>
              <Typography
                component="h7"
                variant="h7"
                fontWeight={200}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(updatePlDnplFilterData({}));
                  reset();
                }}
              >
                Clear All
              </Typography>
            </Box>
          </Stack>

          <Divider
            sx={{
              mb: "20px",
            }}
          />

          <Box component={"form"} className="accordionGrid">
            <Accordion
              className="accordionWrapper"
              defaultExpanded={
                defaultPlDnpl == "listType"
                  ? true
                  : defaultPlDnpl == "all"
                  ? true
                  : false
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                className="accordionSummary"
              >
                <Typography className="filterTitle">
                  List Type{" "}
                  {AllFilterLength.listType &&
                    AllFilterLength.listType.length > 0 && (
                      <span className="count-style">
                        <p>{AllFilterLength.listType.length}</p>
                      </span>
                    )}
                </Typography>
              </AccordionSummary>

              <AccordionDetails sx={{ backgroundColor: "#e0e0e05e" }}>
                {/* <GlobalInput control={control} name="listType" label="" /> */}
                <GlobalMultiSelect
                  control={control}
                  size="small"
                  name="listType"
                  label=""
                  options={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                  required
                  error={!!errors.listType}
                  helperText={errors.listType?.message}
                />
                {/* <GlobalMultiSelect
                  control={control}
                  size="small"
                  name="listType"
                  label=""
                  options={options}
                  required
                  error={!!errors.listType}
                  helperText={errors.listType?.message}
                  // onChange={(event, value) => {
                  //   debugger;
                  //   handleSelectChange(value);
                  // }}
                  renderValue={(selected) =>
                    selected.includes("All") ? "All" : selected.join(", ")
                  }
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        // Disable "PL" and "DNPL" options if "All" is selected
                        disabled={
                          selectedOptions.includes("All") &&
                          option.value !== "All"
                        }
                      />
                      {option.label}
                    </React.Fragment>
                  )}
                  // Additional props for the functionality similar to Autocomplete
                  value={selectedOptions}
                  getOptionLabel={(option) => option.label}
                  // getOptionDisabled={(option) => {
                  //   debugger;
                  //   option.value === "All" &&
                  //     selectedOptions.some((option) =>
                  //       console.log("option-------", option)
                  //     );
                  // }}
                /> */}
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="accordionWrapper"
              defaultExpanded={
                defaultPlDnpl == "listName"
                  ? true
                  : defaultPlDnpl == "all"
                  ? true
                  : false
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                className="accordionSummary"
              >
                <Typography className="filterTitle">List Name</Typography>
              </AccordionSummary>

              <AccordionDetails sx={{ backgroundColor: "#e0e0e05e" }}>
                <GlobalInput
                  control={control}
                  size="small"
                  name="listName"
                  label="List Name"
                  error={!!errors.listName}
                  helperText={errors.listName?.message}
                  required
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="accordionWrapper"
              defaultExpanded={
                defaultPlDnpl == "fieldName"
                  ? true
                  : defaultPlDnpl == "all"
                  ? true
                  : false
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                className="accordionSummary"
              >
                <Typography className="filterTitle">
                  Field Name{" "}
                  {AllFilterLength.fieldName &&
                    AllFilterLength.fieldName.length > 0 && (
                      <span className="count-style">
                        <p>{AllFilterLength.fieldName.length}</p>
                      </span>
                    )}
                </Typography>
              </AccordionSummary>

              <AccordionDetails sx={{ backgroundColor: "#e0e0e05e" }}>
                {/* <GlobalDropdown control={control} name="label" options={[]} /> */}
                <GlobalMultiSelect
                  control={control}
                  size="small"
                  name="fieldName"
                  label="Field Name"
                  options={plDnplOptions || []}
                  required
                  error={!!errors.fieldName}
                  helperText={errors.fieldName?.message}
                />
              </AccordionDetails>
            </Accordion>

            {/* </Grid> */}

            {/* <Grid item xs={12}> */}
            <Accordion
              className="accordionWrapper"
              defaultExpanded={
                defaultPlDnpl == "created"
                  ? true
                  : defaultPlDnpl == "all"
                  ? true
                  : false
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                className="accordionSummary"
              >
                <Typography className="filterTitle">Create Date</Typography>
              </AccordionSummary>

              <AccordionDetails sx={{ backgroundColor: "#e0e0e05e" }}>
                <Controller
                  name="dateRange"
                  control={control}
                  render={({ field }) => (
                    <GlobalDateRangePicker
                      defaultRange={{
                        startDate: new Date(
                          new Date().setDate(new Date().getDate() - 60)
                        ),
                        endDate: new Date(),
                        key: "selection",
                      }}
                      {...field}
                    />
                  )}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Stack
            direction="row"
            component={Box}
            useFlexGap
            flexWrap="wrap"
            justifyContent={"flex-end"}
            bottom={0}
            marginTop={"1rem"}
          >
            <Box marginBottom={"15px"} marginRight={"5px"}>
              <CustomButton
                color="#3a5396"
                // label="Add Campaign"
                label="Apply Filter"
                onClick={handleSubmit(onFormSubmit)}
              />
            </Box>
            <Box marginBottom={"15px"}>
              <CustomButton
                labelColor="#7a7870"
                variant="outlined"
                color="white"
                label="Close"
                onClick={() => setOpen(false)}
              />
            </Box>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default PlDnplFilters;
