import React from "react";
import {
  Box,
  Tab,
  Divider,
  TabList,
  TabContext,
  TabPanel,
  CodeOutlinedIcon,
  KeyboardCommandKeyOutlinedIcon,
  PhoneInTalkOutlinedIcon,
  RestoreOutlinedIcon,
  CurrencyExchangeOutlinedIcon,
  AltRouteOutlinedIcon,
  AccountBalanceWalletOutlinedIcon,
} from "./../../../Collections/Imports";
import PropTypes from "prop-types";
import { useTabContext } from "../../../Collections/Tabcontext";
import Main from "./Main";
import Contact from "./Contact";
import Tracking from "./Tracking";
import RevShare from "./RevShare";
import TrafficSource from "./TrafficSource";
import LoginHistory from "./LoginHistory";
import PubPingtreeSetting from "./PubPingtreeSetting";
import BucketBank from "./BucketBank";

const PublisherConfiguration = () => {
  const { tabValue, handleChange } = useTabContext();
  const tabIconColorActive = "#97B7F9";
  const tabTextColorActive = "#3E7DFD";
  const tabIconColorInactive = "#525252";
  const tabTextColorInactive = "#525252";

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabContext value={tabValue}>
          <TabList
            onChange={handleChange}
            className="tabList"
            sx={{
              p: 0,
              m: 0,
              height: "15px",
              "& .MuiTabs-indicator": {
                borderRadius: "4px 4px 0px 0px",
                borderBottom: "3px solid #3E7DFD",
              },
            }}
          >
            <Tab
              icon={
                <KeyboardCommandKeyOutlinedIcon
                  style={{
                    fill:
                      tabValue === 1
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Main"
              value={1}
              className="tabHeading"
              style={{
                color:
                  tabValue === 1 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 1 ? 600 : 400,
              }}
            />
            <Tab
              icon={
                <PhoneInTalkOutlinedIcon
                  style={{
                    fill:
                      tabValue === 2
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Contacts"
              value={2}
              className="tabHeading"
              style={{
                color:
                  tabValue === 2 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 2 ? 600 : 400,
              }}
            />
            <Tab
              icon={
                <CurrencyExchangeOutlinedIcon
                  style={{
                    fill:
                      tabValue === 3
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Rev-Share"
              value={3}
              className="tabHeading"
              style={{
                color:
                  tabValue === 3 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 3 ? 600 : 400,
              }}
            />
            <Tab
              icon={
                <AccountBalanceWalletOutlinedIcon
                  style={{
                    fill:
                      tabValue === 4
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Bucket Bank"
              value={4}
              className="tabHeading"
              style={{
                color:
                  tabValue === 4 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 4 ? 600 : 400,
              }}
            />
            {/* <Tab
              icon={
                <RestoreOutlinedIcon
                  style={{
                    fill:
                      tabValue === 5
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Login History"
              value={4}
              className="tabHeading"
              style={{
                color:
                  tabValue === 5 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 5 ? 600 : 400,
              }}
            /> */}
            <Tab
              icon={
                <CodeOutlinedIcon
                  style={{
                    fill:
                      tabValue === 5
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Tracking"
              value={5}
              className="tabHeading"
              style={{
                color:
                  tabValue === 5 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 5 ? 600 : 400,
              }}
            />
            <Tab
              icon={
                <AltRouteOutlinedIcon
                  style={{
                    fill:
                      tabValue === 6
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Traffic Sources"
              value={6}
              className="tabHeading"
              style={{
                color:
                  tabValue === 6 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 6 ? 600 : 400,
              }}
            />
            <Tab
              icon={
                <AltRouteOutlinedIcon
                  style={{
                    fill:
                      tabValue === 7
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Pingtree"
              value={7}
              className="tabHeading"
              style={{
                color:
                  tabValue === 7 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 7 ? 600 : 400,
              }}
            />
          </TabList>
          <Divider />
          <Box sx={{ background: "white", minHeight: "100vh" }}>
            {/* Main tab */}
            <TabPanel value={1} sx={{ paddingRight: "0" }}>
              <Main />
            </TabPanel>
            {/* Contact Details tab */}
            <TabPanel
              value={2}
              sx={{ background: "#fff", padding: "0", pt: "0px" }}
            >
              <Contact />
            </TabPanel>
            {/* Revshare setting tab */}
            <TabPanel value={3} sx={{ paddingRight: "0" }}>
              <RevShare />
            </TabPanel>
            {/* Login history setting tab */}
            <TabPanel value={4} sx={{ paddingRight: "0" }}>
              <BucketBank />
            </TabPanel>
            {/* Tracking setting tab */}
            <TabPanel
              value={5}
              sx={{ background: "#fff", padding: "0", pt: "0px" }}
            >
              <Tracking />
            </TabPanel>
            {/* Traffic Sources setting tab */}
            <TabPanel value={6} sx={{ paddingRight: "0" }}>
              <TrafficSource />
            </TabPanel>
            {/* Pingtree setting tab */}
            <TabPanel value={7} sx={{ paddingRight: "0" }}>
              <PubPingtreeSetting />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </Box>
  );
};

export default PublisherConfiguration;
