import { Divider, Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  Grid,
  Tooltip,
} from "../../Collections/Imports";
import GlobalInput from "../../Components/CommonComponents/GlobalInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import CustomButton from "../../Components/CommonComponents/GlobalButton";

import GlobalDropdown from "../../Components/CommonComponents/GlobalDropdown";
import { publisherModuleApiEndPoints } from "../../Config/APIsEndpoints";
import { useApiCallMutation } from "../../Services/ApiCallService";
import {
  addSingleTracking,
  updateSingleTrackingData,
} from "../../Store/PublisherModule/PublisherTrackingModule";
import { useDispatch } from "react-redux";
import { updateSnackBarConfig } from "../../Store/Global/SnackBar";

function AddEditPublisherTracking(props) {
  const { setOpen, isOpen, defaultValues, isEditMode, setIsEditMode } = props;
  console.log("Def values tracking=====", defaultValues);
  const dispatch = useDispatch();
  const [AddPublisherTrack, AddPublisherTrackData] = useApiCallMutation();
  const [UpdatePublisherContact, setUpdatePublisherContact] =
    useApiCallMutation();

  console.log("add single datat", AddPublisherTrackData);

  let currentPubData = JSON.parse(
    sessionStorage.getItem("currentPublisherData")
  );

  const ruleTrigger = [
    { label: "leadSold", id: 1, value: "leadSold" },
    { label: "leadReject", id: 2, value: "leadReject" },
    { label: "leadSubmit", id: 3, value: "leadSubmit" },
  ];

  const ChannelOptions = [
    { label: "Channel1", id: 1, value: "ch1" },
    { label: "Channel2", id: 2, value: "ch2" },
    { label: "channel3", id: 3, value: "ch3" },
  ];

  const SourceOptions = [
    { label: "Source1", id: 1, value: "source1" },
    { label: "Source2", id: 2, value: "source2" },
    { label: "Source3", id: 3, value: "source3" },
  ];
  // Set Initial Value here
  const initialValues = {
    trigger: "",
    trackingUrl: "",
    channel: "",
    source: "",
  };

  const validationSchema = Yup.object().shape({
    trigger: Yup.string().required("This Field is required"),
    channel: Yup.string().required("This Field is required"),
    trackingUrl: Yup.string().required("This Field is required"),
    source: Yup.string().required("This Field is required"),
  });
  // initialise Form here
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const onFormSubmit = (data) => {
    // debugger;
    const testData = {
      ...data,
      pubUniqueId: `${currentPubData.pubUniqueId}`,
    };

    if (isEditMode) {
      UpdatePublisherContact(
        {
          endPoint: publisherModuleApiEndPoints.updatePubTracking,
          method: "POST",
          data: { data: { ...data, trackingId: defaultValues.id } },
        },
        reset()
      );
    } else {
      AddPublisherTrack(
        {
          endPoint: publisherModuleApiEndPoints.addPubTracking,
          method: "POST",
          data: { data: testData },
        },
        reset()
      );
    }
  };

  // Add single data
  useEffect(() => {
    if (
      AddPublisherTrackData?.isSuccess &&
      !AddPublisherTrackData?.data?.error
    ) {
      dispatch(addSingleTracking(AddPublisherTrackData?.data?.data));
      dispatch(
        updateSnackBarConfig({
          open: true,
          color: "success",
          message: "Data Added Successfully",
        })
      );
      setOpen(false);
      setIsEditMode(false);
    }
  }, [AddPublisherTrackData]);

  //Update Pub trak

  useEffect(() => {
    if (
      setUpdatePublisherContact?.isSuccess &&
      !setUpdatePublisherContact?.data?.error
    ) {
      // console.log("All Campaign DATA", setUpdatePublisherContact.data?.data);
      dispatch(updateSingleTrackingData(setUpdatePublisherContact.data?.data));
      dispatch(
        updateSnackBarConfig({
          open: true,
          color: "success",
          message: "Data Updated Successfully",
        })
      );
      setOpen(false);
      setIsEditMode(false);
    }
  }, [setUpdatePublisherContact]);

  //Set Incoming Default values
  useEffect(() => {
    if (Object.keys(defaultValues).length && isEditMode) {
      SetFormValues(defaultValues);
    }
  }, []);

  // Set Forms Value

  const SetFormValues = (data) => {
    console.log("Def values tracking=====", data);

    setValue("trigger", data?.trigger, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("channel", data?.channel, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("trackingUrl", data?.trackingUrl, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("source", data?.source, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };
  return (
    <>
      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={() => {
          setOpen(false);
          setIsEditMode(false);
        }}
        sx={{
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            top: "15px",
            borderRadius: "16px",
            width: "100%",
            maxWidth: "500px",
          },
        }}
      >
        <Box sx={{ padding: "1rem 1rem 1rem 1rem" }}>
          <Stack
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box marginBottom={"15px"}>
              <Typography component="h6" variant="h6" fontWeight={600}>
                {(isEditMode && "Update Rule") || "Add Rule"}
              </Typography>
            </Box>
            <Box
              marginBottom={"15px"}
              onClick={() => reset()}
              sx={{ color: "#000", cursor: "pointer" }}
            >
              <Typography component="h7" variant="h7" fontWeight={200}>
                Clear All
              </Typography>
            </Box>
          </Stack>
          <Box sx={{ pb: "2rem" }}>
            <Divider />
          </Box>

          <Box component={"form"}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  size="small"
                  options={ruleTrigger || []}
                  name="trigger"
                  label="Rule Trigger"
                  error={!!errors.trigger}
                  helperText={errors.trigger?.message}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  options={ChannelOptions || []}
                  size="small"
                  name="channel"
                  label="Channel"
                  error={!!errors.channel}
                  helperText={errors.channel?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  options={SourceOptions}
                  size="small"
                  name="source"
                  label="Source"
                  error={!!errors.source}
                  helperText={errors.source?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalInput
                  control={control}
                  size="small"
                  name="trackingUrl"
                  label="URL"
                  error={!!errors.trackingUrl}
                  helperText={errors.trackingUrl?.message}
                />

                <Tooltip
                  title={
                    "{{amount}}, {{pubId}}, {{leadId}}, {{clickId}}, {{sourceId}}, {{datetime}}, {{website}}"
                  }
                >
                  <Typography
                    component="p"
                    variant="p"
                    fontWeight={400}
                    fontSize={"10px"}
                    color={"#007bff"}
                  >
                    {/* {(isEditMode && "Update Allow List") || "Add Publisher To Allow List"} */}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>

            <Box
              sx={{
                borderTop: "1px solid #DCE0E6",
                pr: "1rem",
                pt: "1rem",
                mt: "1rem",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Box px={2}>
                <CustomButton
                  color="#3a5396"
                  label={isEditMode ? "Update" : "Add"}
                  onClick={handleSubmit(onFormSubmit)}
                />
              </Box>
              <CustomButton
                labelColor="#7a7870"
                variant="outlined"
                color="white"
                label="Close"
                onClick={() => {
                  reset();
                  setOpen(false);
                  setIsEditMode(false);
                }}
              />
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

export default AddEditPublisherTracking;
