import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterData: {
    fromDate: "2020-06-30T18:30:00.000Z",
  },
  pubOptions: {},
};

const LeadReportsGroupByFilterSession = createSlice({
  name: "LeadReportsGroupByFilterSession",
  initialState,
  reducers: {
    updatePublisherGroupByFilterData: (state, action) => {
      state.filterData = action.payload;
    },
    updatePublisherGroupByOptions: (state, action) => {
      state.pubOptions = action.payload;
    },
  },
});

export const {
  updateLeadReportsGroupByFilterData,
  updatePublisherGroupByOptions,
} = LeadReportsGroupByFilterSession.actions;
export default LeadReportsGroupByFilterSession.reducer;
