import React, { useEffect, useState } from "react";
import {
  AddOutlined,
  ArrowBack,
  Box,
  PageHeader,
  useLocation,
  useNavigate,
} from "../../Collections/Imports";
import AgGridComponent from "../../Components/CommonComponents/AG-Grid";
import CircularProgressBar from "../../Components/UIComponents/CircularProgressBar";
import { useDispatch, useSelector } from "react-redux";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import { channelLabelApiEndPoint } from "../../Config/APIsEndpoints";
import {
  removeSingleChannelLabel,
  updateChaneelLabels,
  updateSingleChannelLabel,
} from "../../Store/ChannelLabels";
import AddEditChannelLabel from "../../Forms/AddEditChannelLabel";

const ChannelLabel = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // Form pagination handler
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [itemPerIndex, setItemPerIndex] = useState(100);
  const perPageCountOptions = [
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "1000", value: 1000 },
  ];

  const [selectedDateFilter, setSelectedDateFilter] = useState({
    startDate: new Date("July 01, 2020"),
    endDate: new Date(),
    key: "selection",
  });

  const [isOpenAddEditPublisherLabel, setIsOpenAddEditPublisherLabel] =
    useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const [rowSelected, setRowSelected] = useState({});

  const TableData = useSelector((state) => state.channelLabels.tableData);

  //Api Initialization
  const [GetAllChannelLabel, AllChannelLabelData] = useApiCallMutation();
  const [UpdateChannelLabel, UpdateChannelLabelData] = useApiCallMutation();
  const [DeleteChannelLabel, setDeleteChannelLabel] = useApiCallMutation();

  const channelLabelData = useGetApiCallQuery({
    endPoint: channelLabelApiEndPoint.getChannelLabel,
    method: "GET",
  });

  useEffect(() => {
    if (
      UpdateChannelLabelData?.isSuccess &&
      !UpdateChannelLabelData?.data?.error
    ) {
      dispatch(updateSingleChannelLabel(UpdateChannelLabelData.data?.data));
    }
  }, [UpdateChannelLabelData]);

  useEffect(() => {
    if (channelLabelData.data) {
      const filteredFieldsData = channelLabelData?.data?.data?.channelLabel;

      const tableData = {
        data: filteredFieldsData,
        headers: channelLabelData?.data?.data?.headers,
      };

      // console.log("tableData Schedule", tableData);
      dispatch(updateChaneelLabels(tableData));
    }
  }, [channelLabelData]);

  // Handle Grid Button ACtion
  const handleActions = (previousData, action) => {
    switch (action) {
      case "EDIT":
        setRowSelected(previousData);
        setIsEditMode(true);
        setIsOpenAddEditPublisherLabel(true);
        break;

      case "DELETE":
        // Optimistically remove the deleted item from the UI
        dispatch(removeSingleChannelLabel(previousData.id));

        DeleteChannelLabel({
          endPoint: channelLabelApiEndPoint.deleteChannelLabel,
          method: "POST",
          data: {
            data: {
              id: previousData?.id,
            },
          },
        });
        break;
    }
  };

  const handlePageChange = (data) => {
    setPaginationIndex(data);
  };
  const handleRowsPerPageChange = (data) => {
    setItemPerIndex(data);
    setPaginationIndex(0);
  };

  return (
    <>
      <PageHeader
        title={"Channel Labels"}
        backIcon={
          location?.pathname === "/channel-labels" ? null : (
            <ArrowBack sx={{ color: "#0c247b" }} />
          )
        }
        onBack={() => navigate(-1)}
      />
      {location?.pathname === "/channel-label" && (
        <>
          {AllChannelLabelData.isLoading && <CircularProgressBar />}
          {!AllChannelLabelData.isLoading && (
            <Box>
              <AgGridComponent
                rowData={TableData?.data || []}
                columnDefs={TableData?.headers || []}
                actions={["EDIT", "DELETE"]}
                performAction={handleActions}
                totalPageCount={TableData?.data.length}
                paginationIndex={paginationIndex}
                itemPerIndex={itemPerIndex}
                perPageCountOptions={perPageCountOptions}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                addAction={setIsOpenAddEditPublisherLabel}
                AddButtonName={"Add New Label"}
                addActionIcon={<AddOutlined />}
                tableName={"Channel Labels"}
                rowMode
              />
            </Box>
          )}

          {isOpenAddEditPublisherLabel && (
            <AddEditChannelLabel
              defaultValues={rowSelected}
              isOpen={isOpenAddEditPublisherLabel}
              setOpen={setIsOpenAddEditPublisherLabel}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
            />
          )}
        </>
      )}
    </>
  );
};

export default ChannelLabel;
