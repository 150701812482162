import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Typography,
  Stack,
  Divider,
  useNavigate,
  AddOutlined,
} from "./../../../../Collections/Imports";
import CustomButton from "../../../../Components/CommonComponents/GlobalButton";
import GlobalInput from "../../../../Components/CommonComponents/GlobalInput";
import { useForm } from "react-hook-form";
import AgGridComponent from "../../../../Components/CommonComponents/AG-Grid";

import { useApiCallMutation } from "../../../../Services/ApiCallService";
import {
  buyerModuleAPiENdPoints,
  campaignModuleAPiEndPoints,
} from "../../../../Config/APIsEndpoints";
import { useDispatch, useSelector } from "react-redux";
import {
  removeSingleSchedule,
  updateSchedulerData,
} from "../../../../Store/ScheduleModule";
import { updateScheduleRowData } from "../../../../Store/ScheduleModule/currentScheduleRowData";
import CircularProgressBar from "../../../../Components/UIComponents/CircularProgressBar";
import AddEditSchedulerRule from "../../../../Forms/AddEditSchedulerRule";

const CampaignShedule = () => {
  const [isOpenAddEditBox, setIsOpenAddEditBox] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [rowSelected, setRowSelected] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // get CID

  let currentCampaignData = JSON.parse(
    sessionStorage.getItem("currentCampaignData")
  );

  const TableData = useSelector((state) => state.ScheduleData?.tableData);

  console.log("Schedule TableData", TableData);

  // Form pagination handler
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [itemPerIndex, setItemPerIndex] = useState(100);
  const perPageCountOptions = [
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "1000", value: 1000 },
  ];

  //Api Initialization

  const [GetAllSchedule, getAllScheduleData] = useApiCallMutation();
  const [DeleteCampaignSchedule, DeleteCampaignScheduleData] =
    useApiCallMutation();

  console.log("Schedule DELETE DATA ...", DeleteCampaignScheduleData);

  useEffect(() => {
    GetAllSchedule({
      endPoint: campaignModuleAPiEndPoints.getAllSchedule,
      method: "POST",
      data: {
        data: {
          id: currentCampaignData?.campaignId,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (
      getAllScheduleData?.isSuccess &&
      getAllScheduleData?.data?.data?.campaignSchedule
    ) {
      const campaignDataList = [
        ...getAllScheduleData?.data?.data?.campaignSchedule,
      ];

      const tableData = {
        data: campaignDataList,
        headers: getAllScheduleData?.data?.data?.headers,
      };

      console.log("tableData Schedule", tableData);

      dispatch(updateSchedulerData(tableData));
    }
  }, [getAllScheduleData]);

  // initialise Form here
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {},
    // resolver: yupResolver(validationSchema),
    mode: "all",
  });

  // Handle Grid Button ACtion
  const handleActions = (data, action) => {
    console.log("Privious data", data);

    switch (action) {
      case "EDIT":
        dispatch(updateScheduleRowData(data));
        setRowSelected(data);
        setIsEditMode(true);
        setIsOpenAddEditBox(true);
        break;
      case "CONFIG":
        setRowSelected(data);
        dispatch(updateScheduleRowData(data));
        // setCampainName(data?.campaignName);
        navigate(String(data?.uniqueId));
        break;

      case "DELETE":
        const listId = {
          id: data?.campaignScheduleId,
        };

        DeleteCampaignSchedule({
          endPoint: campaignModuleAPiEndPoints.deleteCampaignSchedule,
          method: "POST",
          data: { data: listId },
        });
        dispatch(removeSingleSchedule(data?.campaignScheduleId));
        break;
    }
  };
  return (
    <Box
      sx={{
        padding: "28px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {isOpenAddEditBox && (
          <AddEditSchedulerRule
            defaultValues={rowSelected}
            isOpen={isOpenAddEditBox}
            setOpen={setIsOpenAddEditBox}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
          />
        )}
      </Box>

      <Box>
        {getAllScheduleData.status === "isUninitialized" && (
          <CircularProgressBar />
        )}
        {getAllScheduleData.status !== "isUninitialized" && (
          <AgGridComponent
            rowData={TableData?.data || []}
            columnDefs={TableData.headers || []}
            actions={["EDIT", "DELETE"]}
            domLayout="autoHeight"
            height={"100%"}
            performAction={handleActions}
            addAction={setIsOpenAddEditBox}
            AddButtonName={"Add Schedule Rule"}
            addActionIcon={<AddOutlined />}
            downlodBtn={true}
            tableName={"Schedule"}
            totalPageCount={10000}
            paginationIndex={1}
            itemPerIndex={100}
            // perPageCountOptions={perPageCountOptions}
            // onPageChange={handlePageChange}
            // onRowsPerPageChange={handleRowsPerPageChange}
            rowMode
          />
        )}
      </Box>
    </Box>
  );
};

export default CampaignShedule;
