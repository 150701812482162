import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Card,
  Divider,
  Drawer,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import GlobalInput from "../../Components/CommonComponents/GlobalInput";
import GlobalDropdown from "../../Components/CommonComponents/GlobalDropdown";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import { useDispatch } from "react-redux";
import CustomButton from "../../Components/CommonComponents/GlobalButton";
import { publisherModuleApiEndPoints } from "../../Config/APIsEndpoints";
import {
  addSinglePublisherData,
  updateSinglePublisherData,
} from "../../Store/PublisherModule";
import { updateSnackBarConfig } from "../../Store/Global/SnackBar";

const AddEditPublisher = (props) => {
  const { setOpen, isOpen, defaultValues, isEditMode, setIsEditMode } = props;
  const dispatch = useDispatch();
  // Api Call Intialization
  const [CreatePublisher, createPublisherData] = useApiCallMutation();
  const [UpdatePublisher, updatePublisherData] = useApiCallMutation();
  const statusOptions = [
    { label: "Active", value: "true" },
    { label: "Disable", value: "false" },
  ];
  // Set Initial Value here
  const initialValues = {
    publisherName: "",
    pocFirstName: "",
    pocLastName: "",
    email: "",
    managerId: "",
    password: "",
    status: true,
  };

  // Set Validation Schema here
  const validationSchema = Yup.object().shape({
    publisherName: Yup.string()
      .min(2)
      .trim("Name cannot include leading and trailing spaces")
      .max(50)
      .matches(/[A-Za-z]/, "Only numbers are not allowed")
      .required("Publisher Name is required"),
    pocFirstName: Yup.string()
      .min(2)
      .max(50)
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .trim("Name cannot include leading and trailing spaces")
      .required("First Name is required"),
    pocLastName: Yup.string()
      .trim("Name cannot include leading and trailing spaces")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .min(2)
      .max(50)
      .required("Last Name is required"),
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    password: isEditMode
      ? Yup.string().notRequired()
      : Yup.string()
          .trim("Password cannot include leading and trailing spaces")
          .required("This Field is required"),
  });

  // initialise Form here
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const SetFormValues = (data) => {
    setValue("publisherName", data?.publisherName, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("pocFirstName", data?.pocFirstName, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("pocLastName", data?.pocLastName, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("email", data?.email, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("managerId", data?.managerId, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("status", data?.status == true ? "true" : "false", {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const onFormSubmit = (data) => {
    if (isEditMode) {
      const modifiedData = data;
      modifiedData.pubUniqueId = defaultValues?.pubUniqueId;
      UpdatePublisher({
        endPoint: publisherModuleApiEndPoints.updatePub,
        method: "POST",
        data: { data: modifiedData },
      });
    } else {
      CreatePublisher({
        endPoint: publisherModuleApiEndPoints.createPub,
        method: "POST",
        data: { data },
      });
    }
  };
  //Set Incoming Default values
  useEffect(() => {
    if (Object.keys(defaultValues).length && isEditMode) {
      SetFormValues(defaultValues);
    }
  }, []);
  useEffect(() => {
    if (createPublisherData?.isSuccess && !createPublisherData?.data?.error) {
      dispatch(addSinglePublisherData(createPublisherData.data?.data));
      dispatch(
        updateSnackBarConfig({
          open: true,
          color: "success",
          message: "Data Added successfully",
        })
      );
      setOpen(false);
      setIsEditMode(false);
    }
  }, [createPublisherData]);
  useEffect(() => {
    if (updatePublisherData?.isSuccess && !updatePublisherData?.data?.error) {
      dispatch(updateSinglePublisherData(updatePublisherData.data?.data));
      dispatch(
        updateSnackBarConfig({
          open: true,
          color: "success",
          message: "Data Updated successfully",
        })
      );
      setOpen(false);
      setIsEditMode(false);
    }
  }, [updatePublisherData]);
  return (
    <>
      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={() => {
          setOpen(false);
          setIsEditMode(false);
        }}
        sx={{
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            top: "15px",
            borderRadius: "16px",
            width: "100%",
            maxWidth: "500px",
          },
        }}
      >
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent={"space-between"}
          >
            <Box marginBottom={"15px"}>
              <Typography component="h6" variant="h6" fontWeight={600}>
                {(isEditMode && "Update Publisher") || "Publisher Registration"}
              </Typography>
            </Box>
            <Box marginBottom={"15px"} onClick={() => reset()}>
              <Typography component="h7" variant="h7" fontWeight={200}>
                Clear All
              </Typography>
            </Box>
          </Stack>

          <Divider
            sx={{
              mb: "20px",
            }}
          />

          <Box component={"form"}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <GlobalInput
                  control={control}
                  size="small"
                  name="publisherName"
                  label="Publisher Name"
                  error={!!errors.publisherName}
                  helperText={errors.publisherName?.message}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalInput
                  control={control}
                  size="small"
                  name="pocFirstName"
                  label="POC First Name"
                  error={!!errors.pocFirstName}
                  helperText={errors.pocFirstName?.message}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalInput
                  control={control}
                  size="small"
                  name="pocLastName"
                  label="POC Last Name"
                  error={!!errors.pocLastName}
                  helperText={errors.pocLastName?.message}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalInput
                  control={control}
                  size="small"
                  name="email"
                  label="Email"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  disabled={isEditMode ? "disabled" : false}
                  required
                />
              </Grid>
              {!isEditMode ? (
                <Grid item xs={12}>
                  <GlobalInput
                    control={control}
                    size="small"
                    name="password"
                    label="Password"
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    required
                    type="password"
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <GlobalDropdown
                    control={control}
                    size="small"
                    name="status"
                    label="Status"
                    options={statusOptions || []}
                    error={!!errors.status}
                    helperText={errors.status?.message}
                    required
                    type="password"
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  size="small"
                  name="managerId"
                  label="Manager"
                  options={[]}
                  error={!!errors.managerId}
                  helperText={errors.managerId?.message}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            // pl: "1rem",
          }}
        >
          <Stack
            direction="row"
            component={Box}
            useFlexGap
            flexWrap="wrap"
            justifyContent={"flex-end"}
            bottom={0}
            marginTop={"1rem"}
          >
            <Box marginBottom={"15px"} marginRight={"5px"}>
              <CustomButton
                color="#3a5396"
                // label="Add Campaign"
                label={isEditMode ? "Update" : "Add"}
                onClick={handleSubmit(onFormSubmit)}
              />
            </Box>
            <Box marginBottom={"15px"}>
              <CustomButton
                labelColor="#7a7870"
                variant="outlined"
                color="white"
                label="Close"
                onClick={() => {
                  setOpen(false);
                  setIsEditMode(false);
                }}
              />
            </Box>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};
export default AddEditPublisher;
