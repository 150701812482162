import React from "react";
import {
  Grid,
  useEffect,
  useState,
  Box,
  Card,
  Stack,
  Typography,
  Divider,
} from "../../../../Collections/Imports";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomButton from "../../../../Components/CommonComponents/GlobalButton";
import AgGridComponent from "../../../../Components/CommonComponents/AG-Grid";
import AddEditPublisherAllowList from "../../../../Forms/AddEditPublisherAllowList";
import AddEditPublisherDenyList from "../../../../Forms/AddEditPublisherDenyList";
import { campaignModuleAPiEndPoints } from "../../../../Config/APIsEndpoints";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../Services/ApiCallService";
import {
  removePublisherFromAllow,
  removePublisherFromDeny,
  updateAllowFilterByPubData,
  updateDenyFilterByPubData,
} from "../../../../Store/FIlterByPub";

function FilterByPub() {
  const [rowSelected, setRowSelected] = useState({});
  const [isOpenAddEditAllowList, setIsOpenAddEditAllowList] = useState(false);
  const [isOpenAddEditDenyList, setIsOpenAddEditDenyList] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [GetAllowList, AllowListData] = useApiCallMutation();
  const [GetDenyList, DenyListData] = useApiCallMutation();
  const [DeletePubFromAllowList, DeletePubFromAllowData] = useApiCallMutation();
  const [DeletePubFromDenyList, DeletePubFromDenyData] = useApiCallMutation();
  let currentCampaignData = JSON.parse(
    sessionStorage.getItem("currentCampaignData")
  );
  const dispatch = useDispatch();
  const AllowTableData = useSelector((state) => state.filterByPub?.allowList);
  const DenyTableData = useSelector((state) => state.filterByPub?.denyList);
  // Set Initial Value here
  const initialValues = {
    publisher: "",
    listType: "",
  };

  // Set Validation Schema here
  const validationSchema = Yup.object().shape({
    publisher: Yup.string().required("This Field is required"),
    listType: Yup.string().required("This Field is required"),
  });
  // initialise Form here
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  // Handle Grid Button ACtion
  const handleAllowTableActions = (previousData, action) => {
    console.log("Privious data", previousData);

    switch (action) {
      case "EDIT":
        setRowSelected(previousData);
        setIsEditMode(true);
        setIsOpenAddEditAllowList(true);
        break;
      case "DELETE":
        const listId = {
          id: previousData?.id,
        };
        DeletePubFromAllowList({
          endPoint: campaignModuleAPiEndPoints.deleteFilterByPub,
          method: "POST",
          data: { data: listId },
        });
        dispatch(removePublisherFromAllow(previousData?.id));
        break;
    }
  };

  const handleDenyTableActions = (previousData, action) => {
    console.log("Privious data", previousData);

    switch (action) {
      case "EDIT":
        setRowSelected(previousData);
        setIsEditMode(true);
        setIsOpenAddEditDenyList(true);
        break;
      case "DELETE":
        const listId = {
          id: previousData?.id,
        };
        DeletePubFromDenyList({
          endPoint: campaignModuleAPiEndPoints.deleteFilterByPub,
          method: "POST",
          data: { data: listId },
        });
        dispatch(removePublisherFromDeny(previousData?.id));
        break;

        break;
    }
  };

  useEffect(() => {
    GetAllowList({
      endPoint: campaignModuleAPiEndPoints.getFilterByPub,
      method: "POST",
      data: {
        data: {
          campaignId: currentCampaignData?.campaignId,
          listType: "Allow List",
        },
      },
    });
    GetDenyList({
      endPoint: campaignModuleAPiEndPoints.getFilterByPub,
      method: "POST",
      data: {
        data: {
          campaignId: currentCampaignData?.campaignId,
          listType: "Deny List",
        },
      },
    });
  }, []);

  useEffect(() => {
    if (AllowListData?.isSuccess) {
      const tableData = {
        data: AllowListData?.data?.data?.pubFilter,
        headers: AllowListData?.data?.data?.headers,
      };
      dispatch(updateAllowFilterByPubData(tableData));
    }
  }, [AllowListData]);
  useEffect(() => {
    if (DenyListData?.isSuccess) {
      const tableData = {
        data: DenyListData?.data?.data?.pubFilter,
        headers: DenyListData?.data?.data?.headers,
      };
      dispatch(updateDenyFilterByPubData(tableData));
    }
  }, [DenyListData]);

  return (
    <>
      <Card>
        <Box
          sx={{
            padding: "22px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "5px",
            }}
          >
            <Box>
              <Typography component="h6" variant="h6" fontWeight={600}>
                Allow List
              </Typography>
            </Box>
            <Box>
              <CustomButton
                color="#0C247B"
                label="Add Publisher"
                onClick={() => setIsOpenAddEditAllowList(true)}
              />
            </Box>
            {isOpenAddEditAllowList && (
              <AddEditPublisherAllowList
                defaultValues={rowSelected}
                isOpen={isOpenAddEditAllowList}
                setOpen={setIsOpenAddEditAllowList}
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          ></Box>
          <Divider
            sx={{
              mb: "20px",
            }}
          />
          <Box sx={{ background: "white", border: "none", pb: "10px" }}>
            <AgGridComponent
              rowData={AllowTableData?.data || []}
              columnDefs={AllowTableData?.headers || []}
              performAction={handleAllowTableActions}
              actions={["EDIT", "DELETE"]}
              domLayout="autoHeight"
              height={"100%"}
              rowMode
            />
          </Box>
        </Box>

        {/* Deny List */}
        <Box
          sx={{
            padding: "22px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "5px",
            }}
          >
            <Box>
              <Typography component="h6" variant="h6" fontWeight={600}>
                Deny List
              </Typography>
            </Box>
            <Box>
              <CustomButton
                color="#0C247B"
                label="Add Publisher"
                onClick={() => setIsOpenAddEditDenyList(true)}
              />
            </Box>
            {isOpenAddEditDenyList && (
              <AddEditPublisherDenyList
                isOpen={isOpenAddEditDenyList}
                setOpen={setIsOpenAddEditDenyList}
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
                defaultValues={rowSelected}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          ></Box>
          <Divider
            sx={{
              mb: "20px",
            }}
          />
          <Box sx={{ background: "white", border: "none", pb: "10px" }}>
            <AgGridComponent
              rowData={DenyTableData?.data || []}
              columnDefs={DenyTableData?.headers || []}
              actions={["EDIT", "DELETE"]}
              domLayout="autoHeight"
              height={"100%"}
              performAction={handleDenyTableActions}
              //   totalPageCount={AllbuyersData.data?.data?.total}
              //   paginationIndex={paginationIndex}
              //   itemPerIndex={itemPerIndex}
              //   perPageCountOptions={perPageCountOptions}
              //   onPageChange={handlePageChange}
              //   onRowsPerPageChange={handleRowsPerPageChange}
              rowMode
            />
          </Box>
        </Box>
      </Card>
    </>
  );
}

export default FilterByPub;
