import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  tableData: {
    data: [],
    headers: [],
  },
};

const buyersLabelData = createSlice({
  name: "buyersLabelData",
  initialState,
  reducers: {
    getBLData: (state, action) => {
      state.tableData = action.payload;
    },
    updateSingleBuyerLabelData: (state, action) => {
      const updatedRow = action.payload;
      const index = state.tableData.data.findIndex(
        (data) => data?.id === updatedRow?.id
      );
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.tableData.data[index][key] = updatedRow[key];
      });
    },
    addSingleBuyerLabelData: (state, action) => {
      state.tableData.data.unshift(action.payload);
    },
    removeSingleBuyerLabelData: (state, action) => {
      state.tableData.data = state.tableData.data.filter(
        (data) => data.labelUniqueId !== action.payload
      );
    },
  },
});

export const {
  getBLData,
  updateSingleBuyerLabelData,
  addSingleBuyerLabelData,
  removeSingleBuyerLabelData,
} = buyersLabelData.actions;
export default buyersLabelData.reducer;
