import { useEffect, forwardRef } from "react";
import Fade from "@mui/material/Fade";
import Snackbar from "@mui/material/Snackbar";
import { useSelector, useDispatch } from "react-redux";
import MuiAlert from "@mui/material/Alert";
import { updateSnackBarConfig } from "../../../Store/Global/SnackBar";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={1} ref={ref} variant="filled" {...props} />;
});

export default function GlobalSnackbar() {
  const dispatch = useDispatch();
  const { vertical, horizontal, open, message, color } = useSelector(
    (state) => state?.snackbarConfig?.snackbarMessage
  );

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        dispatch(
          updateSnackBarConfig({
            open: false,
            message: "",
          })
        );
      }, [4000]);
    }
  }, [open]);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(
      updateSnackBarConfig({
        open: false,
        message: "",
      })
    );
  };

  return (
    <div>
      <Snackbar
        autoHideDuration={2500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        key={vertical + horizontal}
        TransitionComponent={Fade}
      >
        <Alert severity={color}>{message}</Alert>
      </Snackbar>
    </div>
  );
}
