import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterData: {
    fromDate: "2020-06-30T18:30:00.000Z",
  },
};

const campaignDataFilterSession = createSlice({
  name: "campaignDataFilterSession",
  initialState,
  reducers: {
    updateCampaignFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
});

export const { updateCampaignFilterData } = campaignDataFilterSession.actions;
export default campaignDataFilterSession.reducer;
