import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  data: {
    pubPingtree: [],
  },
};

const PubPingtreeData = createSlice({
  name: "pubPingtreeData",
  initialState,
  reducers: {
    getData: (state, action) => {
      state.data.pubPingtree = action.payload;
    },
    // addData: (state, action) => {
      // state.data.pubPingtree.pubPingTreeSetting.unshift(action.payload);
    // },
    addData: (state, action) => {
      // Ensure pubPingTreeSetting is defined
      if (!state.data.pubPingtree.pubPingTreeSetting) {
        state.data.pubPingtree.pubPingTreeSetting = [];
      }
      state.data.pubPingtree.pubPingTreeSetting.unshift(action.payload.data);
    },
   updateData: (state, action) => {
      const updatedRow = action.payload.data;
      const pubPingTreeSetting = state.data.pubPingtree.pubPingTreeSetting;

      // Ensure pubPingTreeSetting is defined
      if (!pubPingTreeSetting) {
        console.error("pubPingTreeSetting is undefined");
        return;
      }

      const index = pubPingTreeSetting.findIndex(
        (data) => data.pubUniqueId === updatedRow.pubUniqueId
      );

      if (index !== -1) {
        const keys = Object.keys(updatedRow);
        keys.forEach((key) => {
          pubPingTreeSetting[index][key] = updatedRow[key];
        });
      } else {
        console.error("No matching index found for update");
      }
    },

    removeData: (state, action) => {
        state.data.pubPingtree.pubPingTreeSetting = state.data.pubPingtree.pubPingTreeSetting.filter(
          (data) => data?.ptsUniqueId !== action.payload
        );
    },
  },
});

export const { getData, addData, updateData, removeData } = PubPingtreeData.actions;
export default PubPingtreeData.reducer;
