import React from "react";
import { useForm } from "react-hook-form";
import { Divider, Drawer, Stack, Typography } from "@mui/material";
import { Grid, useEffect, useState, Box } from "../../Collections/Imports";

import GlobalInput from "../../Components/CommonComponents/GlobalInput";
import GlobalDropdown from "../../Components/CommonComponents/GlobalDropdown";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  buyerModuleAPiENdPoints,
  coreAPIEndsPoints,
} from "../../Config/APIsEndpoints";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import { useDispatch } from "react-redux";
import CustomButton from "../../Components/CommonComponents/GlobalButton";

import {
  addSinglePlAndDnpl,
  updateSinglePlAndDnpl,
  updateSinglePlAndDnplMasterValue,
} from "../../Store/PlDnplModule";

const AddEditPlAndDnpl = (props) => {
  const {
    setOpen,
    isOpen,
    defaultValues,
    isEditMode,
    setIsEditMode,
    openSetValueForm,
    setOpenSetValueForm,
    openAppendForm,
    setOpenAppendForm,
  } = props;

  const dispatch = useDispatch();

  // Api Call Intialization
  const [CreatePlDnpl, creatCreatePlDnpl] = useApiCallMutation();
  const [updatePlDnpl, updatePlDnplData] = useApiCallMutation();

  const [AddPlDnplMasterValue, addPlDnplMasterValueData] = useApiCallMutation();
  const [GetPlDnplMasterValue, GetPlDnplMasterValueData] = useApiCallMutation();
  const [addAppendMasterValue, setAddAppendMasterValueData] =
    useApiCallMutation();

  const [plDnplOptions, setplDnplOptions] = useState([]);

  // GetPl Dnpl options

  const GetPlDnplOptions = useGetApiCallQuery({
    endPoint: buyerModuleAPiENdPoints.getStandardField,
    method: "GET",
  });

  // Check if the necessary properties are available in the API response
  useEffect(() => {
    if (GetPlDnplOptions.data?.data?.getAllFields) {
      const filteredFieldsData = GetPlDnplOptions.data.data.getAllFields
        .filter((field) => field.pldnplList)
        .map(({ id, name }) => ({ value: name, label: name, id: id }));

      setplDnplOptions(filteredFieldsData);
    }
  }, [GetPlDnplOptions.data]);

  // GetPl Dnpl value

  useEffect(() => {
    if (openSetValueForm) {
      GetPlDnplMasterValue({
        endPoint: coreAPIEndsPoints.getPlDnplMasterValues,
        method: "POST",
        data: {
          data: { masterPlDnplId: defaultValues?.data?.masterPlDnplId },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (
      GetPlDnplMasterValueData?.isSuccess &&
      !GetPlDnplMasterValueData?.data?.error
    ) {
      setValue(
        "setNewNalues",
        GetPlDnplMasterValueData.data?.data?.values?.toString(),

        {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        }
      );
    }
  }, [GetPlDnplMasterValueData]);

  // set Value Upadte
  useEffect(() => {
    if (
      addPlDnplMasterValueData?.isSuccess &&
      !addPlDnplMasterValueData?.data?.error
    ) {
      dispatch(
        updateSinglePlAndDnplMasterValue(addPlDnplMasterValueData.data?.data)
      );
      setOpen(false);
      setIsEditMode(false);
      setOpenSetValueForm(false);
      setOpenAppendForm(false);
    }
  }, [addPlDnplMasterValueData]);

  // Append Value  Value Upadte
  useEffect(() => {
    if (
      setAddAppendMasterValueData?.isSuccess &&
      !setAddAppendMasterValueData?.data?.error
    ) {
      dispatch(
        updateSinglePlAndDnplMasterValue(setAddAppendMasterValueData.data?.data)
      );
      setOpen(false);
      setIsEditMode(false);
      setOpenSetValueForm(false);
      setOpenAppendForm(false);
    }
  }, [setAddAppendMasterValueData]);

  // Set Initial Value here
  const initialValues = {};

  if (openSetValueForm) {
    initialValues.setNewNalues = "";
  } else if (openAppendForm) {
    initialValues.append = "";
  } else {
    initialValues.listName = "";
    initialValues.fieldName = "";
    initialValues.listType = "";
  }

  // Set Validation Schema here
  const validationSchema = openSetValueForm
    ? Yup.object().shape({
        setNewNalues: Yup.string()
          .trim("Name cannot include leading and trailing spaces")
          .required("This Field is required"),
      })
    : openAppendForm
    ? Yup.object().shape({
        append: Yup.string()
          .trim("Name cannot include leading and trailing spaces")
          .required("This Field is required"),
      })
    : Yup.object().shape({
        listName: Yup.string()
          .min(2)
          .max(50)
          .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
          .trim("Name cannot include leading and trailing spaces")
          .required("This Field is required"),
        fieldName: Yup.string().required("This Field is required"),
        listType: Yup.string().required("This Field is required"),
      });

  // initialise Form here
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  // Set Forms Value

  const SetFormValues = (data) => {
    setValue("listName", data?.listName, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("fieldName", data?.fieldName, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

    setValue("listType", data?.listType, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  //Set Incoming Default values
  useEffect(() => {
    if (Object.keys(defaultValues).length && isEditMode) {
      SetFormValues(defaultValues);
    }
  }, []);

  // Created Campaign PlDnpl
  useEffect(() => {
    if (creatCreatePlDnpl?.isSuccess && !creatCreatePlDnpl?.data?.error) {
      dispatch(addSinglePlAndDnpl(creatCreatePlDnpl.data?.data));
      setOpen(false);
      setIsEditMode(false);
      setOpenSetValueForm(false);
      setOpenAppendForm(false);
    }
  }, [creatCreatePlDnpl]);

  // Update PlDnpl

  useEffect(() => {
    if (updatePlDnplData?.isSuccess && !updatePlDnplData?.data?.error) {
      dispatch(updateSinglePlAndDnpl(updatePlDnplData.data?.data));
      setOpen(false);
      setIsEditMode(false);
      setOpenSetValueForm(false);
      setOpenAppendForm(false);
    }
  }, [updatePlDnplData]);

  //***********FORM SUBMISSION ********//
  const onFormSubmit = (data) => {
    if (isEditMode) {
      updatePlDnpl({
        endPoint: coreAPIEndsPoints.updateMasterPlDnpl,
        method: "POST",
        data: {
          data: { ...data, masterPlDnplId: defaultValues?.masterPlDnplId },
        },
      });
    } else if (openSetValueForm) {
      AddPlDnplMasterValue({
        endPoint: coreAPIEndsPoints.addPlDnplMasterValues,
        method: "POST",
        data: {
          data: {
            values:
              typeof data.setNewNalues === "string"
                ? data.setNewNalues
                    .split(",")
                    .filter((item) => item.trim() !== "")
                : [],

            masterPlDnplId: defaultValues?.data?.masterPlDnplId,
          },
        },
      });
    } else if (openAppendForm) {
      addAppendMasterValue({
        endPoint: coreAPIEndsPoints.AddAppendPlDnplMasterValues,
        method: "POST",
        data: {
          data: {
            values:
              typeof data.append === "string"
                ? data.append.split(",").filter((item) => item.trim() !== "")
                : [],

            masterPlDnplId: defaultValues?.data?.masterPlDnplId,
          },
        },
      });
    } else {
      CreatePlDnpl({
        endPoint: coreAPIEndsPoints.addMasterPlDnpl,
        method: "POST",
        data: { data },
      });
    }
  };

  const ListTypeOptions = [
    { label: "PL", id: 1, value: "PL" },
    { label: "DNPL", id: 2, value: "DNPL" },
  ];

  return (
    <>
      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={() => {
          setOpen(false);
          setIsEditMode(false);
          setOpenSetValueForm(false);
          setOpenAppendForm(false);
        }}
        sx={{
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            top: "15px",
            borderRadius: "16px",
            width: "100%",
            maxWidth: "500px",
          },
        }}
      >
        {!openSetValueForm && !openAppendForm ? (
          <>
            <Box
              sx={{
                pt: "1rem",
                pr: "1rem",
                pl: "1rem",
              }}
            >
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                direction="row"
                useFlexGap
                flexWrap="wrap"
                justifyContent={"space-between"}
              >
                <Box marginBottom={"15px"}>
                  <Typography component="h6" variant="h6" fontWeight={600}>
                    {isEditMode ? "Edit" : "ADD"}
                  </Typography>
                </Box>
                <Box marginBottom={"15px"} onClick={() => reset()}>
                  <Typography component="h7" variant="h7" fontWeight={200}>
                    Clear All
                  </Typography>
                </Box>
              </Stack>

              <Divider
                sx={{
                  mb: "20px",
                }}
              />

              <Box component={"form"}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <GlobalInput
                      control={control}
                      size="small"
                      name="listName"
                      label="List Name"
                      error={!!errors.listName}
                      helperText={errors.listName?.message}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <GlobalDropdown
                      control={control}
                      size="small"
                      name="fieldName"
                      label="Field Name"
                      options={plDnplOptions}
                      required
                      error={!!errors.fieldName}
                      helperText={errors.fieldName?.message}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <GlobalDropdown
                      control={control}
                      size="small"
                      name="listType"
                      label="List Type"
                      options={ListTypeOptions}
                      required
                      error={!!errors.listType}
                      helperText={errors.listType?.message}
                    />
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
              </Box>
            </Box>
            <Box
              sx={{
                borderTop: "1px solid #DCE0E6",
                pr: "1rem",
                // pl: "1rem",
              }}
            >
              <Stack
                direction="row"
                component={Box}
                useFlexGap
                flexWrap="wrap"
                justifyContent={"flex-end"}
                bottom={0}
                marginTop={"1rem"}
              >
                <Box marginBottom={"15px"} marginRight={"5px"}>
                  <CustomButton
                    color="#3a5396"
                    // label="Add Campaign"
                    // onClick={handleSubmit(onFormSubmit)}
                    label={isEditMode ? "Update" : "Add "}
                    onClick={handleSubmit(onFormSubmit)}
                  />
                </Box>
                <Box marginBottom={"15px"}>
                  <CustomButton
                    labelColor="#7a7870"
                    variant="outlined"
                    color="white"
                    label="Close"
                    onClick={() => {
                      setOpen(false);
                      setIsEditMode(false);
                      setOpenSetValueForm(false);
                      setOpenAppendForm(false);
                    }}
                  />
                </Box>
              </Stack>
            </Box>
          </>
        ) : (
          <>
            {/* Setting Forms */}
            <Box
              sx={{
                pt: "1rem",
                pr: "1rem",
                pl: "1rem",
              }}
            >
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                direction="row"
                useFlexGap
                flexWrap="wrap"
                justifyContent={"space-between"}
              >
                <Box marginBottom={"15px"}>
                  <Typography component="h6" variant="h6" fontWeight={600}>
                    {openSetValueForm ? "Set Value" : "Append Value"}
                  </Typography>
                </Box>
                <Box marginBottom={"15px"} onClick={() => reset()}>
                  <Typography component="h7" variant="h7" fontWeight={200}>
                    Clear All
                  </Typography>
                </Box>
              </Stack>

              <Divider
                sx={{
                  mb: "20px",
                }}
              />
              {openSetValueForm ? (
                <>
                  <Typography component="p" variant="p" fontWeight={400} pb={2}>
                    {" "}
                    Currently 5 item(s) in this list.
                  </Typography>
                  <Typography component="p" variant="p" fontWeight={400}>
                    Currently 5 item(s) in this list. Please enter a value per
                    line or a value.
                  </Typography>
                  <Typography component="p" variant="p" fontWeight={400}>
                    {" "}
                    WARNING: All values in DB will be overwritten with the ones
                    you provide below!
                  </Typography>
                  <Typography component="p" variant="p" fontWeight={400} pb={3}>
                    {" "}
                    Save an empty list to truncate all current values.
                  </Typography>

                  <Box component={"form"}>
                    <Grid container>
                      <Grid item xs={12}>
                        <GlobalInput
                          control={control}
                          multiline
                          rows={3}
                          size="small"
                          name="setNewNalues"
                          label="Set Value"
                          error={!!errors.setNewNalues}
                          helperText={errors.setNewNalues?.message}
                          required
                        />
                      </Grid>
                    </Grid>

                    <Box
                      sx={{
                        borderTop: "1px solid #DCE0E6",
                        pr: "1rem",
                        // pl: "1rem",
                      }}
                    >
                      <Stack
                        direction="row"
                        component={Box}
                        useFlexGap
                        flexWrap="wrap"
                        justifyContent={"flex-end"}
                        bottom={0}
                        marginTop={"1rem"}
                      >
                        <Box marginBottom={"15px"} marginRight={"5px"}>
                          <CustomButton
                            color="#3a5396"
                            // onClick={handleSubmit(onFormSubmit)}
                            label={
                              openSetValueForm ? "Set Value" : "Append Value"
                            }
                            onClick={handleSubmit(onFormSubmit)}
                          />
                        </Box>
                        <Box marginBottom={"15px"}>
                          <CustomButton
                            labelColor="#7a7870"
                            variant="outlined"
                            color="white"
                            label="Close"
                            onClick={() => {
                              setOpen(false);
                              setIsEditMode(false);
                              setOpenSetValueForm(false);
                              setOpenAppendForm(false);
                            }}
                          />
                        </Box>
                      </Stack>
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Box component={"form"}>
                    <Grid container>
                      <Grid item xs={12}>
                        <GlobalInput
                          control={control}
                          multiline
                          rows={3}
                          size="small"
                          name="append"
                          label="Append Value"
                          error={!!errors.append}
                          helperText={errors.append?.message}
                          required
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box marginBottom={"15px"} marginRight={"5px"}>
                    <Stack
                      direction="row"
                      component={Box}
                      useFlexGap
                      flexWrap="wrap"
                      justifyContent={"flex-end"}
                      bottom={0}
                      marginTop={"1rem"}
                    >
                      <Box marginBottom={"15px"} marginRight={"5px"}>
                        <CustomButton
                          color="#3a5396"
                          // onClick={handleSubmit(onFormSubmit)}
                          label={
                            openSetValueForm ? "Set Value" : "Append Value"
                          }
                          onClick={handleSubmit(onFormSubmit)}
                        />
                      </Box>
                      <Box marginBottom={"15px"}>
                        <CustomButton
                          labelColor="#7a7870"
                          variant="outlined"
                          color="white"
                          label="Close"
                          onClick={() => {
                            setOpen(false);
                            setIsEditMode(false);
                            setOpenSetValueForm(false);
                            setOpenAppendForm(false);
                          }}
                        />
                      </Box>
                    </Stack>
                  </Box>
                </>
              )}
            </Box>
          </>
        )}
      </Drawer>
    </>
  );
};

export default AddEditPlAndDnpl;
