/**
 * The above code is a React component that renders a form for global settings and handles form
 * submission.
 * @returns The Global component is being returned.
 */
import React from "react";
import { useForm } from "react-hook-form";
import GlobalInput from "../../../../Components/CommonComponents/GlobalInput";
import GlobalDropdown from "../../../../Components/CommonComponents/GlobalDropdown";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomButton from "../../../../Components/CommonComponents/GlobalButton";
import { useApiCallMutation } from "../../../../Services/ApiCallService";
import {
  Grid,
  useEffect,
  useState,
  Box,
  moment,
  Typography,
  Divider,
  Stack,
  useNavigate,
  useParams,
} from "../../../../Collections/Imports";
import { campaignModuleAPiEndPoints } from "../../../../Config/APIsEndpoints";
import { useSelector } from "react-redux";

const Global = () => {
  let currentCampaignData = JSON.parse(sessionStorage.getItem('currentCampaignData'));
  const [GetCampaignById, CampaigData] = useApiCallMutation();
  const [UpdateCampaign, UpdateCamapignData] = useApiCallMutation();

  // Set Initial Value here
  const initialValues = {
    campaignName: "",
    campaignType: "",
    timezone: "",
    minPrice: "",
    status: "",
  };

  // Set Validation Schema here
  const validationSchema = Yup.object().shape({
    campaignName: Yup.string().required("This Field is required"),
    campaignType: Yup.string().required("This Field is required"),
    timezone: Yup.string().required("This Field is required"),
    minPrice: Yup.string().required("This Field is required"),
    status: Yup.boolean().required("This Field is required"),
  });
  // initialise Form here
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const campaignType = [
    { label: "Redirect", value: "redirect" },
    { label: "Silence", value: "silence" },
  ];
  const timeZone = [
    { label: "London (BST/GMT)", value: "Europe/London" },
    { label: "New York (EST/EDT)", value: "America/New_York" },
    { label: "Chicago (CST/CDT)", value: "America/Chicago" },
    { label: "Edmonton (MST/MDT)", value: "America/Edmonton" },
    { label: "Los Angeles (PST/PDT)", value: "America/Los_Angeles" },
  ];
  const status = [
    { label: "Active", value: "true" },
    { label: "Disabled", value: "false" },
  ];
  // Set form values
  const setFormData = (data) => {

    setValue("campaignName", data?.campaignName, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("campaignType", data?.campaignType, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("timezone", data?.timezone, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("minPrice", data?.minPrice, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("status", data?.status == true?"true":"false", {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const onFormSubmit = (data) => {
      data.id = currentCampaignData?.campaignId;
      UpdateCampaign({
        endPoint: campaignModuleAPiEndPoints.updateCampaign,
        method: "POST",
        data: { data },
      });
  };

  // update form with api data
  useEffect(() => {
    if (CampaigData?.isSuccess && !CampaigData?.data?.error) {
      setFormData(CampaigData?.data?.data);
    }
  }, [CampaigData]);
  // api call to get campaign data
  useEffect(() => {
    console.log(currentCampaignData);
    GetCampaignById({
      endPoint: campaignModuleAPiEndPoints.getCampaignById,
      method: "POST",
      data: {
        data: {
          id: currentCampaignData?.campaignId,
        },
      },
    });
  }, []);

  return (
    <div>
      <Box sx={{ width: "50%", margin: "0 auto" }}>
        <Box marginBottom={"15px"}>
          <Typography component="h6" variant="h6" fontWeight={600}>
            Global Setting
          </Typography>
        </Box>
        <Divider sx={{ mb: "20px" }} />
        <Box component={"form"}>
          <Grid container>
            <Grid item xs={12} sx={{ mt: "10px" }}>
              <GlobalInput
                control={control}
                size="small"
                name="campaignName"
                label="Name"
                error={!!errors.campaignName}
                helperText={errors.campaignName?.message}
                required
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: "10px" }}>
              <GlobalDropdown
                control={control}
                size="small"
                name="campaignType"
                options={campaignType}
                label="Camapign Type"
                error={!!errors.campaignType}
                helperText={errors.campaignType?.message}
                required
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: "10px" }}>
              <GlobalDropdown
                control={control}
                size="small"
                name="timezone"
                label="Timezone"
                options={timeZone}
                error={!!errors.timezone}
                helperText={errors.timezone?.message}
                required
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: "10px" }}>
              <GlobalInput
                control={control}
                size="small"
                name="minPrice"
                label="Min Price"
                error={!!errors.minPrice}
                helperText={errors.minPrice?.message}
                required
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: "10px" }}>
              <GlobalDropdown
                control={control}
                size="small"
                name="status"
                label="Status"
                options={status}
                error={!!errors.status}
                helperText={errors.status?.message}
                required
              />
            </Grid>
            <Box
              sx={{
                width: "100%",
                borderTop: "1px solid #DCE0E6",
                marginTop: "10px",
              }}
            >
              <Stack
                direction="row"
                component={Box}
                useFlexGap
                flexWrap="wrap"
                justifyContent={"flex-end"}
                bottom={0}
                marginTop={"1rem"}
              >
                <Box marginBottom={"15px"} marginRight={"5px"} float={"right"}>
                  <CustomButton
                    color="#3a5396"
                    label="Save Global Setting"
                    onClick={handleSubmit(onFormSubmit)}
                  />
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default Global;
