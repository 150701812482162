import React from "react";
import {
  Box,
  Stack,
  Typography,
  InfoOutlined,
  Button,
  Divider,
  Tooltip,
  PlusIcons,
  Grid,
} from "../../../Collections/Imports";

const PageHeader = ({
  title,
  buttonLabel,
  pageAction,
  onBack,
  backIcon,
  helpText,
  onClick,
}) => {
  const handleClick = () => {
    if (onClick !== undefined && onClick !== null) {
      onClick();
    }
  };
  return (
    <Box sx={{ bgcolor: "none" }}>
      <Grid container>
        <Grid item sm={12}>
          <Stack direction="row" sx={{ alignItems: "center" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  style={backIcon ? { minWidth: "30px" } : { display: "none" }}
                  onClick={onBack}
                >
                  {backIcon}
                </Button>
                <Typography
                  sx={{
                    color: "#202223",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    letterSpacing: "-0.48px",
                    paddingRight: 1,
                  }}
                  component="div"
                >
                  {title}
                </Typography>{" "}
                <Tooltip title={helpText}>
                  <InfoOutlined sx={{ color: "#87909B" }} />
                </Tooltip>
              </Box>
              {buttonLabel && buttonLabel !== "" && (
                <Box>
                  <Button
                    sx={{
                      background: "white",
                      color: "#0c247b",
                      borderRadius: "20px",
                      fontSize: "12px",
                    }}
                    onClick={handleClick}
                  >
                    <PlusIcons /> &nbsp;{buttonLabel ? buttonLabel : ""}
                  </Button>
                </Box>
              )}
            </Box>
          </Stack>
        </Grid>
      </Grid>

      <Divider sx={{ width: "100%", pt: "8px" }} />
    </Box>
  );
};

export default PageHeader;
