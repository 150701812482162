import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  tableData: {
    data: [],
    headers: [],
  },
};

const PubContactData = createSlice({
  name: "PubContactData",
  initialState,
  reducers: {
    getPublisherContactData: (state, action) => {
      state.tableData = action.payload;
    },

    addSingleContact: (state, action) => {
      state.tableData = action.payload;
    },
    // updateSingleScheduleData: (state, action) => {
    //   console.log(state?.tableData?.data);
    //   const index = state.tableData.data.findIndex(
    //     (data) => data?.campaignScheduleId === updatedRow?.id
    //   );

    //   const keys = Object.keys(updatedRow);

    //   keys.map((key) => {
    //     state.tableData.data[index][key] = updatedRow[key];
    //   });
    // },
    // Schedule Data
    // addSingleScheduleRule: (state, action) => {
    //   state.tableData.data.unshift(action.payload);
    // },
    removeSinglePubDetails: (state, action) => {
      console.log("removedId", action);
      state.tableData.data = state.tableData.data.filter(
        // (data) => data.type !== action.payload
        (data) => data.value !== action.payload
        // console.log("data.type !== action.payload", data.type, action.payload)
      );
    },
  },
});

export const {
  getPublisherContactData,
  addSingleContact,
  removeSinglePubDetails,
  // addSingleScheduleRule,
  //   updateSingleScheduleData,
  //   removeSingleSchedule,
} = PubContactData.actions;
export default PubContactData.reducer;
