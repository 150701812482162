// import React from 'react';
// import Checkbox from '@mui/material/Checkbox';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import { useController } from 'react-hook-form';

// const GlobalCheckbox = ({ name, control, label }) => {
//   const { field, fieldState } = useController({
//     name,
//     control,
//     defaultValue: false, // Set the default value for the checkbox
//   });

//   return (
//     <FormControlLabel
//       control={<Checkbox size='small' {...field} />}
//       label={label}
//       error={!!fieldState.error} // Show error state if there's an error
//     />
//   );
// };

// export default GlobalCheckbox;

import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useController } from "react-hook-form";

const GlobalCheckbox = ({ name, control, label }) => {
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue: false, // Set the default value for the checkbox
  });

  return (
    <FormControlLabel
      control={
        <Checkbox
          size="small"
          checked={field.value}
          onChange={(e) => field.onChange(e.target.checked)}
        />
      }
      label={label}
      error={!!fieldState.error} // Show error state if there's an error
    />
  );
};

export default GlobalCheckbox;
