import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../Config/envConfig";
import { updateSnackBarConfig } from "../Store/Global/SnackBar";
// import { responsiveProperty } from "@mui/material/styles/cssUtils";
// import { snackbarMessageUpdate } from "@store/config/configSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: baseURL,
  Credential: "include",
  // prepareHeaders: (headers, { getState }) => {
  //   headers.set(
  //     "authorization",
  //     `Bearer ${getState()?.user?.token?.access_token}`
  //   );
  //   return headers;
  // },
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.data?.status?.code !== 200) {
    api.dispatch(
      updateSnackBarConfig({
        open: true,
        color: "error",
        message: result.data?.data,
      })
    );
  }
  if (result?.error && result?.error?.status !== 401) {
    api.dispatch(
      updateSnackBarConfig({
        open: true,
        color: "error",
        message: result.error.data.error,
      })
    );
  }

  if (result?.error && result?.error?.status === 401) {
    const refreshToken =
      localStorage.getItem("userCredential") !== undefined &&
      localStorage.getItem("userCredential") !== null
        ? JSON.parse(localStorage.getItem("userCredential"))?.refresh_token
        : null;

    const refreshResult = await baseQuery(
      {
        url: "core/refresh-token",
        method: "POST",
        body: {
          data: {
            clientName: "lds",
            refreshToken: refreshToken,
          },
        },
        headers: {},
      },
      api,
      extraOptions
    );

    if (refreshResult.data) {
      const token = refreshResult.data?.data;
      localStorage.removeItem("userCredential");
      if (refreshResult.data?.error) {
        localStorage.clear();
        window.location.href = "/";
      } else {
        localStorage.setItem("userCredential", JSON.stringify(token));
        args.headers.authorization = `Bearer ${token?.access_token}`;
        result = await baseQuery(args, api, extraOptions);
      }
    } else if (refreshResult.data.error) {
      window.location.href = "/";
    }
  }
  return result;
};

// const userInfo = (data) => {
//   const user = JSON.parse(localStorage.getItem("userInfo"));
//   if (data) {
//     const result = {
//       ...data,
//       user: {
//         id: user?.id,
//         name: user?.name,
//       },
//     };
//     return result;
//   }
// };

export const ApiCallServices = createApi({
  reducerPath: "ApiCallServices",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getApiCall: builder.query({
      query: (arg) => ({
        url: arg.endPoint,
        method: arg.method,
        body: arg.data,
        headers: {
          "Access-Control-Allow-Origin": "*",
          authorization:
            localStorage.getItem("userCredential") !== undefined &&
            localStorage.getItem("userCredential") !== null
              ? `Bearer ${
                  JSON.parse(localStorage.getItem("userCredential"))
                    ?.access_token
                }`
              : null,
          ...arg.headers,
        },
        validateStatus: (response, result) => {
          if (response.status) {
          } else result = null;
          return result;
        },
      }),
    }),
    apiCall: builder.mutation({
      query: (arg) => ({
        url: arg.endPoint,
        method: arg.method,
        body: arg.data,
        headers: {
          "Access-Control-Allow-Origin": "*",
          authorization:
            localStorage.getItem("userCredential") !== undefined &&
            localStorage.getItem("userCredential") !== null
              ? `Bearer ${
                  JSON.parse(localStorage.getItem("userCredential"))
                    ?.access_token
                }`
              : null,
          ...arg.headers,
        },
      }),
    }),
  }),
});

export const { useGetApiCallQuery, useApiCallMutation } = ApiCallServices;
