import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rowData: {},
};

const currentPlDnplRowData = createSlice({
  name: "currentPlDnplRowData",
  initialState,
  reducers: {
    currentPlDnplRow: (state, action) => {
      state.rowData = action.payload;
    },
  },
});

export const { currentPlDnplRow } = currentPlDnplRowData.actions;
export default currentPlDnplRowData.reducer;
