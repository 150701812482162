import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  tableData: {
    data: [],
    headers: [],
  },
};

const ScheduleData = createSlice({
  name: "ScheduleData",
  initialState,

  reducers: {
    updateSchedulerData: (state, action) => {
      state.tableData = action.payload;
    },
    updateSingleScheduleData: (state, action) => {
      const updatedRow = action.payload;
      console.log(updatedRow);
      console.log(state?.tableData?.data);
      const index = state.tableData.data.findIndex(
        (data) => data?.campaignScheduleId === updatedRow?.id
      );

      const keys = Object.keys(updatedRow);

      keys.map((key) => {
        state.tableData.data[index][key] = updatedRow[key];
      });
    },
    // Schedule Data
    addSingleScheduleRule: (state, action) => {
      state.tableData.data.unshift(action.payload);
    },

    removeSingleSchedule: (state, action) => {
      console.log("removedId", action);
      state.tableData.data = state.tableData.data.filter(
        (data) => data.campaignScheduleId !== action.payload
      );
    },
    // removePublisherFromDeny: (state, action) => {
    //   console.log("removedId", action);
    //   state.denyList.data = state.denyList.data.filter(
    //     (data) => data.id !== action.payload
    //   );
    // },
  },
});

export const {
  updateSchedulerData,
  updateSingleScheduleData,
  addSingleScheduleRule,
  removeSingleSchedule,
} = ScheduleData.actions;
export default ScheduleData.reducer;
