import {
  Box,
  Tab,
  Divider,
  TabList,
  TabContext,
  TabPanel,
  IntegrationInstructionsOutlinedIcon,
  PublishedWithChangesOutlinedIcon,
  DateRangeOutlinedIcon,
  TravelExploreOutlinedIcon,
} from "./../../Collections/Imports";
import { useTabContext } from "../../Collections/Tabcontext";
import Publisher from "./Publisher";
import ChannelGroupBy from "./ChannelGroupBy";
import DateGroupBy from "./DateGroupBy";
import SourceGroupBy from "./SourceGroupBy";
const PerformanceSummary = () => {
  const { tabValue, handleChange } = useTabContext();
  const tabIconColorActive = "#97B7F9";
  const tabTextColorActive = "#3E7DFD";
  const tabIconColorInactive = "#525252";
  const tabTextColorInactive = "#525252";

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabContext value={tabValue}>
          <TabList
            onChange={handleChange}
            className="tabList"
            sx={{
              p: 0,
              m: 0,
              height: "15px",
              "& .MuiTabs-indicator": {
                borderRadius: "4px 4px 0px 0px",
                borderBottom: "3px solid #3E7DFD",
              },
            }}
          >
            <Tab
              icon={
                <PublishedWithChangesOutlinedIcon
                  style={{
                    fill:
                      tabValue === 1
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Publisher"
              value={1}
              className="tabHeading"
              style={{
                color:
                  tabValue === 1 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 1 ? 600 : 400,
              }}
            />
            <Tab
              icon={
                <DateRangeOutlinedIcon
                  style={{
                    fill:
                      tabValue === 2
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Date"
              value={2}
              className="tabHeading"
              style={{
                color:
                  tabValue === 2 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 2 ? 600 : 400,
              }}
            />
            <Tab
              icon={
                <TravelExploreOutlinedIcon
                  style={{
                    fill:
                      tabValue === 3
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Channel"
              value={3}
              className="tabHeading"
              style={{
                color:
                  tabValue === 3 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 3 ? 600 : 400,
              }}
            />
            <Tab
              icon={
                <IntegrationInstructionsOutlinedIcon
                  style={{
                    fill:
                      tabValue === 4
                        ? tabIconColorActive
                        : tabIconColorInactive,
                    marginRight: "4px",
                  }}
                />
              }
              iconPosition="start"
              label="Source"
              value={4}
              className="tabHeading"
              style={{
                color:
                  tabValue === 4 ? tabTextColorActive : tabTextColorInactive,
                fontWeight: tabValue === 4 ? 600 : 400,
              }}
            />
          </TabList>
          <Divider />
          <Box sx={{ background: "white", minHeight: "100vh" }}>
            <TabPanel value={1} sx={{ paddingRight: "0" }}>
              <Publisher />
            </TabPanel>
            <TabPanel
              value={2}
              sx={{ background: "#fff", padding: "0", pt: "0px" }}
            >
              <DateGroupBy />
            </TabPanel>
            <TabPanel value={3} sx={{ paddingRight: "0" }}>
              <ChannelGroupBy />
            </TabPanel>
            <TabPanel value={4} sx={{ paddingRight: "0" }}>
              <SourceGroupBy />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </Box>
  );
};

export default PerformanceSummary;
