import { createSlice, nanoid } from "@reduxjs/toolkit";
import { useActionData } from "react-router-dom";

const initialState = {
  allowList: {
    data: [],
    headers: [],
  },
  denyList: {
    data: [],
    headers: [],
  },
};

const FilterByPub = createSlice({
  name: "campaignsData",
  initialState,
  reducers: {
    updateAllowFilterByPubData: (state, action) => {
      state.allowList = action.payload;
    },
    updateDenyFilterByPubData: (state, action) => {
      state.denyList = action.payload;
    },
    updateSinglePublisherAllowList: (state, action) => {
      const updatedRow = action.payload;
      const index = state.allowList.data.findIndex(
        (data) => data?.id === updatedRow?.id
      );
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.allowList.data[index][key] = updatedRow[key];
      });
    },
    addSinglePublisherToAllow: (state, action) => {
      state.allowList.data.unshift(action.payload);
    },
    addSinglePublisherToDeny: (state, action) => {
      state.denyList.data.unshift(action.payload);
    },
    removePublisherFromAllow: (state, action) => {
      console.log("removedId", action);
      state.allowList.data = state.allowList.data.filter(
        (data) => data.id !== action.payload
      );
    },
    removePublisherFromDeny: (state, action) => {
      console.log("removedId", action);
      state.denyList.data = state.denyList.data.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});

export const {
  updateAllowFilterByPubData,
  updateDenyFilterByPubData,
  updateSinglePublisherAllowList,
  addSinglePublisherToAllow,
  addSinglePublisherToDeny,
  removePublisherFromAllow,
  removePublisherFromDeny,
} = FilterByPub.actions;
export default FilterByPub.reducer;
