import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  tableData: {
    data: [],
    headers: [],
  },
};

const publisherLabelData = createSlice({
  name: "publisherLabelData",
  initialState,
  reducers: {
    getPLData: (state, action) => {
      state.tableData = action.payload;
    },
    updateSinglePublisherLabelData: (state, action) => {
      const updatedRow = action.payload;
      const index = state.tableData.data.findIndex(
        (data) => data?.id === updatedRow?.id
      );
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.tableData.data[index][key] = updatedRow[key];
      });
    },
    addSinglePublisherLabelData: (state, action) => {
      state.tableData.data.unshift(action.payload);
    },
    removeSinglePublisherLabelData: (state, action) => {
      state.tableData.data = state.tableData.data.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});

export const {
  getPLData,
  updateSinglePublisherLabelData,
  addSinglePublisherLabelData,
  removeSinglePublisherLabelData,
} = publisherLabelData.actions;
export default publisherLabelData.reducer;
