import React, {
  useMemo,
  useRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Switch,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Icon from "@mdi/react";
import {
  mdiPencil,
  mdiDelete,
  mdiShieldKeyOutline,
  mdiSetCenter,
  mdiCogOutline,
  mdiTextBoxSearchOutline,
} from "@mdi/js";
import CommonFunctions from "../../../Utils/CommonFunctions";
import "../../CommonComponents/AG-Grid/agGrid.css";
import CustomButton from "../GlobalButton";
import { ColumnsToolPanelModule } from "ag-grid-enterprise";
import {
  ArrowDropDownIcon,
  UploadFileOutlinedIcon,
} from "../../../Collections/Imports";
import ConfirmDialog from "../ConfirmModal/ConfirmDialog";
// import { ModuleRegistry } from "ag-grid-community/core";
// import { MenuModule } from "ag-grid-enterprise";
// import { RowGroupingModule } from "ag-grid-enterprise";
// import { FiltersToolPanelModule } from "ag-grid-enterprise";
// import { ClientSideRowModelModule } from "ag-grid-enterprise";
//
// ModuleRegistry.registerModules([
// ColumnsToolPanelModule,
// RowGroupingModule,
// MenuModule,
// FiltersToolPanelModule,
// ClientSideRowModelModule,
// AggregationStage,
// ]);
let userPermissions = JSON.parse(localStorage.getItem("userInfo"));

const AgGridComponent = ({
  setselectedRows,
  rowData,
  actionButtons2,
  columnDefs,
  pagination,
  onRowSelectionChangedHandler,
  height,
  search,
  floatingFilter,
  actions,
  actioncondition,
  performAction,
  onPageChange,
  onRowsPerPageChange,
  totalPageCount,
  itemPerIndex,
  paginationIndex,
  perPageCountOptions,
  downlodBtn,
  addAction,
  tableName,
  AddButtonName,
  addActionIcon,
  disabledCondition,
  handleClick,
  rowStyle,
  getRowStyle,
  updatingRowData,

  // page,
  // rowsPerPage,
  // setPage,
  // setRowsPerPage,
  ...rest
}) => {
  const [column, setColumn] = useState();
  const [row, setRow] = useState();
  const [isGridDisplay, setIsGridDisplay] = useState(false);
  // const [searchInput, setSearchInput] = useState(false);
  const gridRef = useRef();

  // Rows per page

  const handleChangePage = (event, newPage) => {
    if (onPageChange !== undefined && onPageChange !== null) {
      onPageChange(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    if (onRowsPerPageChange !== undefined && onRowsPerPageChange !== null) {
      onRowsPerPageChange(event.target.value);
    }
  };

  const handleCustomDisabled = (data) => {
    if (disabledCondition) {
      return disabledCondition.some((res) => {
        // debugger;
        return res?.value !== data[res?.fieldName];
      });
    } else {
      return true;
    }
  };

  const defaultColDef = useMemo(() => {
    return {
      // width: 200,
      flex: 1,
      minWidth: 150,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      // headerClass: "centered-header",
      enableCellSelection: true,
      autoHeaderHeight: true,

      // editable: true,
      // enableRowGroup: true,
      // enablePivot: true,
      // floatingFilter: true,
      // minWidth: 200,
      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
      },
      suppressRowTransform: true,
      cellStyle: { borderRightColor: "#E2E2E2" },
    };
  }, []);

  useEffect(() => {
    userPermissions = JSON.parse(localStorage.getItem("userInfo"));
  }, []);

  const executeActions = (data, action) => {
    if (typeof handleClick !== "undefined") {
      handleClick(data, action);
    }

    if (typeof performAction !== "undefined") {
      performAction(data, action);
    }

    if (action) {
      setConfirmDialog({ isOpen: false });
    }
  };

  const onRowSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    if (
      onRowSelectionChangedHandler !== undefined &&
      onRowSelectionChangedHandler !== null
    ) {
      onRowSelectionChangedHandler(selectedRows);
    }

    if (setselectedRows !== undefined) {
      setselectedRows(selectedRows.length);
    }
  }, []);
  const onBtShowNoRows = useCallback(() => {
    gridRef.current.api.showNoRowsOverlay();
  }, []);

  const onBtHide = useCallback(() => {
    gridRef.current.api.hideOverlay();
  }, []);

  const statusSwitch = useCallback((params) => {
    return (
      // <span>
      //   <div>
      //     <Switch
      //       title={params.data?.status ? "Active" : "Inactive"}
      //       inputProps={{ "aria-label": "controlled" }}
      //       size="small"
      //       labelPlacement="bottom"
      //       color={params.data?.status ? "info" : "warning"}
      //       checked={params.data?.status}
      //       onChange={() => {
      //         if (
      //           userPermissions?.permissions?.includes("Modify Content") ||
      //           userPermissions?.roles[0] === "admin"
      //         ) {
      //           executeActions(params.data, "Status");
      //         }
      //       }}
      //       sx={{
      //         display: params.data?.isDeleted ? "none" : "",
      //         marginBottom: "20px",
      //         cursor:
      //           userPermissions?.permissions?.includes("Modify Content") ||
      //           userPermissions?.roles[0] === "admin"
      //             ? "pointer"
      //             : "not-allowed",
      //       }}
      //       disabled={
      //         !(
      //           userPermissions?.permissions?.includes("Modify Content") ||
      //           userPermissions?.roles[0] === "admin"
      //         )
      //       }
      //     />
      //   </div>
      // </span>

      <Box>
        <Chip
          size={"small"}
          label={
            <span>{params.data?.status === true ? "Active" : "Disabled"}</span>
          }
          color={params.data?.status === true ? "success" : "error"}
          variant="outlined"
          style={{
            marginRight: "5px",
            background: params.data?.status === true ? "#b2c7ca2e" : "#fff2f2",
          }}
        />
      </Box>
    );
  }, []);

  // DELETE CONFIRMAconfirmDialogTION
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const actionButtons = useCallback((params) => {
    // console.log("DATATATATATAAAA", params?.data.verify);
    //  const isFirstRow = params.node.rowIndex === 0;

    return (
      <span>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "6px",
          }}
        >
          {typeof actions !== "undefined" && actions.includes("VALUES") && (
            <span>
              <Button
                size="small"
                variant="contained"
                sx={{
                  marginRight: "10px",
                  fontSize: "10px",
                  // textAlign: "center",
                  height: "28px",
                  display: "flex",
                  // justifyContent: "center",
                  // alignItems: "center",
                }}
                onClick={() => {
                  executeActions(params, "VALUES");
                }}
              >
                Values
                <ArrowDropDownIcon
                  style={{
                    position: "relative",
                    top: "-3px",
                    fontSize: "20px",
                  }}
                />
              </Button>
            </span>
          )}
          {typeof actions !== "undefined" && actions.includes("EDIT") && (
            <Icon
              path={mdiPencil}
              title="Edit"
              onClick={() => {
                if (
                  userPermissions?.permissions?.includes("Modify Content") ||
                  (userPermissions?.roles[0] === "admin" &&
                    handleCustomDisabled(params?.data))
                ) {
                  executeActions(params.data, "EDIT");
                }
              }}
              size={1}
              style={{
                color: "#0c247b",
                display:
                  typeof actioncondition !== "undefined"
                    ? params.data[actioncondition?.edit?.key] ===
                      actioncondition?.edit?.value
                      ? "none"
                      : "inline-block"
                    : params.data?.isDeleted
                    ? "none"
                    : "inline-block",
                opacity:
                  userPermissions?.permissions?.includes("Modify Content") ||
                  (userPermissions?.roles[0] === "admin" &&
                    handleCustomDisabled(params?.data))
                    ? 1
                    : 0.5,
                cursor:
                  (userPermissions?.permissions?.includes("Modify Content") ||
                    userPermissions?.roles[0] === "admin") &&
                  handleCustomDisabled(params?.data)
                    ? "pointer"
                    : "not-allowed",
              }}
            />
          )}

          {typeof actions !== "undefined" && actions.includes("LOG") && (
            <Button
              size="small"
              variant="contained"
              sx={{
                marginRight: "10px",
                fontSize: "10px",
                textAlign: "center",
                height: "28px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#303D60",
                borderRadius: "0px",
              }}
              onClick={() => {
                // debugger;
                if (
                  userPermissions?.permissions?.includes("Modify Content") ||
                  (userPermissions?.roles[0] === "admin" &&
                    handleCustomDisabled(params?.data))
                ) {
                  executeActions(params.data, "LOG");
                }
              }}
            >
              LOG
            </Button>
          )}

          {typeof actions !== "undefined" && actions.includes("REGENERATE") && (
            <Icon
              path={mdiShieldKeyOutline}
              title="Re-Generate Key"
              onClick={() => {
                if (
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                ) {
                  executeActions(params.data, "REGENERATE");
                }
              }}
              size={1}
              style={{
                color: "#0c247b",
                display: params.data?.isDeleted ? "none" : "inline-block",
                opacity:
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? 1
                    : 0.5,
                cursor:
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? "pointer"
                    : "not-allowed",
              }}
            />
          )}
          {typeof actions !== "undefined" && actions.includes("VIEW") && (
            <Icon
              path={mdiTextBoxSearchOutline}
              title={
                params.data?.mappedHeader ? "View Details" : "View Details"
              }
              onClick={() => {
                if (
                  userPermissions?.permissions?.includes("Publish Content") ||
                  userPermissions?.roles[0] === "admin"
                ) {
                  executeActions(params.data, "VIEW");
                }
              }}
              size={1}
              style={{
                color: "#0c247b",
                display: params.data?.isDeleted ? "none" : "inline-block",
                opacity:
                  userPermissions?.permissions?.includes("Publish Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? 1
                    : 0.5,
                cursor:
                  userPermissions?.permissions?.includes("Publish Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? "pointer"
                    : "not-allowed",
              }}
            />
          )}
          {typeof actions !== "undefined" && actions.includes("CONFIG") && (
            <Icon
              path={mdiCogOutline}
              title={params.data?.mappedHeader ? "Update Config" : "Set Config"}
              onClick={() => {
                if (
                  userPermissions?.permissions?.includes("Publish Content") ||
                  userPermissions?.roles[0] === "admin"
                ) {
                  executeActions(params.data, "CONFIG");
                }
              }}
              size={1}
              style={{
                color: "#0c247b",
                display: params.data?.isDeleted ? "none" : "inline-block",
                opacity:
                  userPermissions?.permissions?.includes("Publish Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? 1
                    : 0.5,
                cursor:
                  userPermissions?.permissions?.includes("Publish Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? "pointer"
                    : "not-allowed",
              }}
            />
          )}
          {typeof actions !== "undefined" && actions.includes("MAPHEADER") && (
            <Icon
              path={mdiSetCenter}
              title={
                params.data?.mappedHeader ? "Update Map Headers" : "Map Headers"
              }
              onClick={() => {
                if (
                  userPermissions?.permissions?.includes("Publish Content") ||
                  userPermissions?.roles[0] === "admin"
                ) {
                  executeActions(params.data, "MAPHEADER");
                }
              }}
              size={1}
              style={{
                color: params.data?.mappedHeader ? "blue" : "grey",
                display: params.data?.isDeleted ? "none" : "inline-block",
                opacity:
                  userPermissions?.permissions?.includes("Publish Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? 1
                    : 0.5,
                cursor:
                  userPermissions?.permissions?.includes("Publish Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? "pointer"
                    : "not-allowed",
              }}
            />
          )}
          {typeof actions !== "undefined" && actions.includes("ACTIVE") && (
            <Switch
              title={params.data?.isActive ? "Active" : "Inactive"}
              inputProps={{ "aria-label": "controlled" }}
              size="small"
              labelPlacement="bottom"
              color={params.data?.isActive ? "success" : "warning"}
              checked={params.data?.isActive}
              onChange={() => {
                if (
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                ) {
                  executeActions(params.data, "ACTIVE");
                }
              }}
              sx={{
                display: params.data?.isDeleted ? "none" : "",
                marginBottom: "20px",
                cursor:
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? "pointer"
                    : "not-allowed",
              }}
              disabled={
                !(
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                )
              }
            />
          )}

          {typeof actions !== "undefined" && actions.includes("DELETE") && (
            <Icon
              path={mdiDelete}
              title="Delete"
              onClick={() => {
                if (
                  userPermissions?.permissions?.includes("Delete Content") ||
                  (userPermissions?.roles[0] === "admin" &&
                    handleCustomDisabled(params?.data))
                ) {
                  // executeActions(params.data, "DELETE");

                  // debugger;
                  setConfirmDialog({
                    isOpen: true,
                    title: `Are you sure you want to delete this ${tableName}?`,
                    subTitle: "You can't undo this operation",
                    onConfirm: () => {
                      executeActions(params.data, "DELETE");
                    },
                  });
                }
              }}
              size={1}
              style={{
                color: "#0c247b",
                display: params.data?.isDeleted ? "none" : "inline-block",
                opacity:
                  userPermissions?.permissions?.includes("Delete Content") ||
                  (userPermissions?.roles[0] === "admin" &&
                    handleCustomDisabled(params?.data))
                    ? 1
                    : 0.5,
                cursor:
                  userPermissions?.permissions?.includes("Delete Content") ||
                  (userPermissions?.roles[0] === "admin" &&
                    handleCustomDisabled(params?.data))
                    ? "pointer"
                    : "not-allowed",
              }}
            />
          )}
        </Box>
      </span>
    );
  }, []);

  const centerAlignedHeaderComponent = (props) => {
    return <div style={{ textAlign: "center" }}>Action</div>;
  };

  useEffect(() => {
    if (columnDefs.length && columnDefs !== undefined) {
      let col = [...columnDefs];
      if (typeof actions !== "undefined" && actions.length > 0) {
        col.push({
          field: "Action",
          cellRenderer: actionButtons,
          // headerComponent: centerAlignedHeaderComponent,
        });
      }
      const indexOfStatus = col.findIndex(
        (item) => item.headerName === "Status"
      );
      if (indexOfStatus) {
        col[indexOfStatus] = {
          field: "Status",
          cellRenderer: statusSwitch,
          // headerComponent: centerAlignedHeaderComponent,
        };
      }
      setColumn(col);
      // Slice the data based on the current page and rowsPerPage
      // const startIndex = page * rowsPerPage;
      // const endIndex = startIndex + rowsPerPage;
      // const slicedData = rowData.slice(startIndex, endIndex);

      setRow(rowData);
    } else {
      setIsGridDisplay(true);
    }
  }, [columnDefs]);
  useEffect(() => {
    // Slice the data based on the current page and rowsPerPage
    // const startIndex = page * rowsPerPage;
    // const endIndex = startIndex + rowsPerPage;
    // const slicedData = rowData.slice(startIndex, endIndex);

    setRow(rowData);
  }, [rowData]);

  const handleInputChange = (event) => {
    gridRef.current.api.setQuickFilter(event.target.value);

    if (gridRef.current.api.filterManager.rowModel.rowsToDisplay.length <= 0) {
      onBtShowNoRows();
    } else {
      onBtHide();
    }
  };
  const csvDownload = () => {
    const Keys = column
      .map((res) => res.field)
      .filter((res) => res !== "Action");
    if (gridRef.current.api.filterManager.rowModel.rowsToDisplay.length) {
      const searchedData =
        gridRef.current.api.filterManager.rowModel.rowsToDisplay.map(
          (res) => res.data
        );
      CommonFunctions.downloadTableData(searchedData, Keys);
    } else {
      CommonFunctions.downloadTableData(row, Keys);
    }
    setTimeout(() => {}, 1000);
  };

  const frameworkComponents = {
    actionButtons2: actionButtons2,
  };

  return (
    <div
      className="ag-theme-alpine"
      style={{
        width: "100%",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        background: "#fff",
        border: "1px solid #2196f31a",
        borderRadius: "6px",
        padding: "5px 0px 30px",
        height: "100%",
      }}
    >
      <Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ padding: "10px 20px" }}
        >
          <Box>
            {tableName && (
              <Typography
                component="h6"
                variant="h6"
                fontWeight={600}
                fontSize={"14px"}
              >
                {tableName}
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {search !== false && (
              <Box display="flex" justifyContent="flex-end">
                <Box
                  sx={{
                    padding: "10px 0px",
                    ml: "25px",
                  }}
                >
                  <TextField
                    id="search_Inp"
                    // label="Search"
                    placeholder="Search..."
                    variant="outlined"
                    InputLabelProps={{ shrink: false }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        fontSize: "12px", // Adjust the font size as needed
                        padding: "10px", // Adjust the padding as needed
                        height: "2.2rem",
                        borderRadius: "20px",
                      },
                      "& .MuiInputLabel-root": {
                        fontSize: "12px", // Adjust the label font size as needed
                      },
                    }}
                    onInput={handleInputChange}
                  />
                </Box>
              </Box>
            )}

            <Box display="flex" justifyContent="flex-end" pl={2}>
              {downlodBtn && (
                <Box>
                  <Button
                    startIcon={<UploadFileOutlinedIcon />}
                    sx={{
                      borderRadius: "20px",
                      border: "1px solid #DDE1E6",
                      background: `${"#fff"}`,
                      color: `${"#EE9329"}`,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                      marginRight: "1rem",
                      padding: "10px 15px",
                      height: "2.2rem",
                      width: "100%",
                      textTransform: "capitalize",
                    }}
                    onClick={csvDownload}
                  >
                    Export
                  </Button>
                </Box>
              )}
            </Box>

            <Box pl={2}>
              {addAction && (
                <Button
                  startIcon={addActionIcon}
                  sx={{
                    borderRadius: "20px",
                    border: "1px solid #DDE1E6",
                    background: `${"#fff"}`,
                    color: `${"#EE9329"}`,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    marginRight: "1rem",
                    padding: "8px 20px",
                    height: "2.2rem",
                    textTransform: "capitalize",
                  }}
                  onClick={() => addAction(true)}
                >
                  {AddButtonName}
                </Button>
              )}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            py: "5px",
            margin: "0px",
            px: "0px",
          }}
        >
          <Divider />
        </Box>

        {(row || isGridDisplay) && (
          <>
            <Box>
              <div
                // className="ag-theme-alpine"
                style={{
                  height: `calc(100vh - 300px)`,
                  // height: "100vh",
                  minHeight: "500px",
                  padding: "20px",
                }}
              >
                <AgGridReact
                  rowData={row || []}
                  columnDefs={column || []}
                  defaultColDef={defaultColDef}
                  suppressRowTransform={true}
                  pagination={false}
                  ref={gridRef}
                  animateRows={true}
                  paginationPageSize={15}
                  enableSorting={true}
                  enableFilter={true}
                  sideBar={true}
                  suppressDragLeaveHidesColumns={true}
                  frameworkComponents={frameworkComponents}
                  rowSelection={"multiple"}
                  // suppressSizeToFit={true}
                  onSelectionChanged={onRowSelectionChanged}
                  rowStyle={rowStyle}
                  getRowStyle={getRowStyle}
                  // onGridReady={onGridReady}
                  enableRangeSelection={true} // Enable range selection
                  enableRangeHandle={true}
                  overlayNoRowsTemplate={
                    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">No data was found</span>'
                  }
                  enableCellTextSelection="true"
                  {...rest}
                  // copyHeadersToClipboard={true}
                  // suppressCopyRowsToClipboard={true}
                  // getRowId={getRowId}
                  // paginationGoToPage={handlePaginationGoToPage}
                  // paginationGetRowCount={paginator?.itemCount}
                  // paginationGetRowCount={paginator.itemCount}
                  // paginationGetTotalPages={paginator?.pageCount}
                  // onSelectionChanged={onRowSelectionChanged}
                  suppressHorizontalScroll={false}
                  // headerHeight={150}
                ></AgGridReact>
              </div>
            </Box>
            {perPageCountOptions !== undefined &&
              perPageCountOptions !== null && (
                <Box>
                  <Card>
                    <TablePagination
                      component="div"
                      count={totalPageCount || 0}
                      page={paginationIndex || 0}
                      onPageChange={handleChangePage}
                      rowsPerPage={itemPerIndex || 0}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      rowsPerPageOptions={perPageCountOptions}
                    />
                  </Card>
                </Box>
              )}
            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
          </>
        )}
      </Box>
    </div>
  );
};

export default AgGridComponent;
