import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  data: {
    channelData: [],
    allChannelList: [],
  },
};

const ChannelData = createSlice({
  name: "channelData",
  initialState,
  reducers: {
    getData: (state, action) => {
      state.data.channelData = action.payload;
    },
    getChannelListData: (state, action) => {
      state.data.allChannelList = action.payload;
    },
    addData: (state, action) => {
      state.data.channelData.Channels.unshift(action.payload);
    },
    updateData: (state, action) => {
      const updatedRow = action.payload;
      const index = state.data.channelData.Channels.findIndex(
        (data) => data?.channelId === updatedRow?.channelId
      );
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.data.channelData.Channels[index][key] = updatedRow[key];
      });
    },
    removeData: (state, action) => {
      state.data.channelData.Channels = state.data.Channels.filter(
        (data) => data?.channelId !== action.payload
      );
    },
  },
});

export const { getData, addData, updateData, removeData, getChannelListData } =
  ChannelData.actions;
export default ChannelData.reducer;
