import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ExpandMore,
  moment,
} from "../../Collections/Imports";
import GlobalDateRangePicker from "../../Components/CommonComponents/GlobalDateRangePicker";
import "./style.css";
import GlobalMultiSelect from "../../Components/CommonComponents/GlobalMultiSelect";
import { useDispatch, useSelector } from "react-redux";
import { updatePublisherFilterData } from "../../Store/PublisherModule/filter";
// import { updatePubOptions } from "../../Store/PublisherModule/filter";
import { allPubData, allPubList } from "../../Store/PublisherModule";
import { updatePublisherGroupByOptions } from "../../Store/LeadReportsGroupBy/filter";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import {
  publisherModuleApiEndPoints,
  coreAPIEndsPoints,
} from "../../Config/APIsEndpoints";
import GlobalDropdown from "../../Components/CommonComponents/GlobalDropdown";
import CustomButton from "../../Components/CommonComponents/GlobalButton";
import { updatePubOptions } from "../../Store/PublisherModule/filter";
const PublisherGroupBy = (props) => {
  const {
    setOpen,
    isOpen,
    defaultValues,
    isEditMode,
    defaultCampaign,
    ClearFilters,
  } = props;
  console.log("0999999999999999999999999999999", defaultCampaign);
  const previousFilterData = useSelector(
    (state) => state?.updatePublisherGroupByFilterData?.filterData
  );

  const channelListOptions = useSelector(
    (state) => state?.ChannelData?.data?.allChannelList
  );

  const channelLabels = useSelector(
    (state) => state?.channelLabels?.tableData?.channelLabel
  );

  const channelLabelOptions = channelLabels.map(({ name }) => ({
    value: name,
    label: name,
  }));
  console.log("previousFilterData", previousFilterData);
  const dispatch = useDispatch();

  const [GetPublisherList, PublisherList] = useApiCallMutation();
  const [GetPublisherListTable, PublisherListTableData] = useApiCallMutation();
  const currentDate = new Date("July 01, 2020");
  useEffect(() => {
    GetPublisherListTable({
      endPoint: publisherModuleApiEndPoints.getAllPubs,
      method: "POST",
      data: {
        data: {
          index: "0",
          itemPerIndex: "500",
          publisher: "",
          sources: "all",
          channel: "all",
          fromDate: currentDate,
          toDate: new Date(),
        },
      },
    });
  }, []);
  const AllFilterLength = useSelector(
    (state) => state?.publishersDataFilterSession?.filterData
  );

  const allPubLists = useSelector(
    (state) => state?.publisherData?.data?.allPublisherList
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      sources: "All",
      name: ["All"],
      channel: ["All"],
      labelIds: ["All"],
      publisher: ["All"],
    },
    // resolver: yupResolver(validationSchema),
    mode: "all",
  });
  const [selectedDateFilter, setSelectedDateFilter] = useState({
    startDate: new Date("July 01, 2020"),
    endDate: new Date(),
    key: "selection",
  });
  console.log("--------------------------------", previousFilterData);

  //***********FORM SUBMISSION ********//
  const onFormSubmit = (data) => {
    console.log("Filter Data-----------", data);
    const filterData = {
      sources: data?.sources,
      publisher: data?.publisher,
      channel: data?.channel,
      fromDate: data?.dateRange
        ? moment(selectedDateFilter?.startDate).startOf("day").toDate()
        : moment(data?.dateRange[0]?.startDate).startOf("day").toDate(),
      toDate: data?.dateRange
        ? moment(data?.dateRange[0]?.endDate).endOf("day").toDate()
        : new Date(),
    };
    dispatch(updatePublisherFilterData(filterData));
    setOpen(false);
  };
  // get all publisher data
  useEffect(() => {
    GetPublisherList({
      endPoint: publisherModuleApiEndPoints.getAllPubs,
      method: "POST",
      data: {
        data: {
          index: "0",
          itemPerIndex: "5000",
          fromDate: moment(currentDate).toISOString(),
          toDate: moment(new Date()).toISOString(),
          publisher: [],
        },
      },
    });
  }, []);

  useEffect(() => {
    if (PublisherList?.isSuccess && PublisherList?.data?.data?.publishers) {
      const publisherOptionsSet = new Set();
      const publisherOptions = PublisherList?.data?.data?.publishers
        .map((pub) => {
          const trimmedPublisherName = pub?.publisherName?.trim();
          if (
            trimmedPublisherName &&
            !publisherOptionsSet.has(trimmedPublisherName)
          ) {
            publisherOptionsSet.add(trimmedPublisherName);
            return {
              label: trimmedPublisherName,
              value: trimmedPublisherName,
            };
          }
          return null;
        })
        .filter(Boolean);
      dispatch(updatePubOptions(publisherOptions));
      dispatch(allPubList(publisherOptions));
    }
  }, [PublisherList]);
  // Call setFormValue after initialization
  useEffect(() => {
    if (previousFilterData && Object.keys(previousFilterData)?.length) {
      setFormValue(previousFilterData);
    }
  }, [previousFilterData]);

  // Set Form Value
  const setFormValue = (data) => {
    const dateRange = {
      startDate: new Date(data?.fromDate),
      endDate: data?.toDate,
      key: "selection",
    };
    setValue("sources", data?.sources, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("publisher", data?.publisher, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("channelNames", data?.channel, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("dateRange", dateRange, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
  };
  //Push new option All
  const AllOptions = { label: "All", id: 1, value: "All" };
  const [statusOptions, setStatusOptions] = useState([
    { label: "Active", value: "true" },
    { label: "Disabled", value: "false" },
  ]);
  const [publisherOptions, setPublisherOptions] = useState([]);
  // Pushing the AllOptions object into the statusOptions array
  useEffect(() => {
    setStatusOptions((prevOptions) => [AllOptions, ...prevOptions]);
  }, []);
  useEffect(() => {
    setPublisherOptions((prevOptions) => [AllOptions, ...allPubLists]);
  }, [allPubLists]);
  return (
    <>
      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={() => setOpen(false)}
        sx={{
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            top: "15px",
            borderRadius: "16px",
            maxWidth: "500px",
            width: "100%",
          },
        }}
      >
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent={"space-between"}
          >
            <Box marginBottom={"15px"}>
              <Typography component="h6" variant="h6" fontWeight={600}>
                Filters
              </Typography>
            </Box>
            <Box marginBottom={"15px"}>
              <Typography
                component="h7"
                variant="h7"
                fontWeight={200}
                style={{ cursor: "pointer" }}
                onClick={ClearFilters}
              >
                Clear All
              </Typography>
            </Box>
          </Stack>
          <Divider
            sx={{
              mb: "20px",
            }}
          />
          <Box component={"form"} className="accordionGrid">
            <Accordion
              className="accordionWrapper"
              defaultExpanded={
                defaultCampaign == "sources"
                  ? true
                  : defaultCampaign == "all"
                  ? true
                  : false
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                className="accordionSummary"
              >
                <Typography className="filterTitle">
                  Sources{" "}
                  {AllFilterLength?.sources &&
                    AllFilterLength?.sources.length > 0 && (
                      <span className="count-style">
                        <p>{AllFilterLength?.sources.length}</p>
                      </span>
                    )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "#e0e0e05e" }}>
                {/* <GlobalInput control={control} name="sources" label="" /> */}
                {/* <GlobalMultiSelect
                  control={control}
                  size="small"
                  name="sources"
                  label=""
                  // options={statusOptions}
                  options={statusOptions}
                  // setSelectedOptions={setSelectedOptions}
                  required
                  error={!!errors.sources}
                  helperText={errors.sources?.message}
                /> */}
                <GlobalDropdown
                  control={control}
                  size="small"
                  name="sources"
                  options={statusOptions}
                  required
                  error={!!errors.sources}
                  helperText={errors.sources?.message}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="accordionWrapper"
              defaultExpanded={
                defaultCampaign == "publisher"
                  ? true
                  : defaultCampaign == "all"
                  ? true
                  : false
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                className="accordionSummary"
              >
                <Typography className="filterTitle">
                  Publisher{" "}
                  {AllFilterLength?.publisherOptions &&
                    AllFilterLength?.publisherOptions?.length > 0 && (
                      <span className="count-style">
                        <p>{AllFilterLength?.publisherOptions?.length}</p>
                      </span>
                    )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "#e0e0e05e" }}>
                <GlobalMultiSelect
                  control={control}
                  size="small"
                  name="publisher"
                  label=""
                  options={publisherOptions}
                  required
                  error={!!errors.publisher}
                  helperText={errors.publisher?.message}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="accordionWrapper"
              defaultExpanded={
                defaultCampaign == "channel"
                  ? true
                  : defaultCampaign == "all"
                  ? true
                  : false
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                className="accordionSummary"
              >
                <Typography className="filterTitle">
                  Channel{" "}
                  {AllFilterLength?.label &&
                    AllFilterLength?.label.length > 0 && (
                      <span className="count-style">
                        <p>{AllFilterLength?.channel.length}</p>
                      </span>
                    )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "#e0e0e05e" }}>
                {/* <GlobalDropdown control={control} name="label" options={[]} /> */}
                <GlobalMultiSelect
                  control={control}
                  size="small"
                  name="channel"
                  label=""
                  options={[]}
                  required
                  error={!!errors.channel}
                  helperText={errors.channel?.message}
                />
              </AccordionDetails>
            </Accordion>
            {/* </Grid> */}
            {/* <Grid item xs={12}> */}
            <Accordion
              className="accordionWrapper"
              defaultExpanded={
                defaultCampaign == "created"
                  ? true
                  : defaultCampaign == "all"
                  ? true
                  : false
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                className="accordionSummary"
              >
                <Typography className="filterTitle">Create Date</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "#e0e0e05e" }}>
                <Controller
                  name="dateRange"
                  control={control}
                  render={({ field }) => (
                    <GlobalDateRangePicker
                      defaultRange={{
                        startDate: new Date(),
                        endDate: new Date(),
                        key: "selection",
                      }}
                      {...field}
                    />
                  )}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Stack
            direction="row"
            component={Box}
            useFlexGap
            flexWrap="wrap"
            justifyContent={"flex-end"}
            bottom={0}
            marginTop={"1rem"}
          >
            <Box marginBottom={"15px"} marginRight={"5px"}>
              <CustomButton
                color="#3a5396"
                // label="Add Campaign"
                label="Apply Filter"
                onClick={handleSubmit(onFormSubmit)}
              />
            </Box>
            <Box marginBottom={"15px"}>
              <CustomButton
                labelColor="#7a7870"
                variant="outlined"
                color="white"
                label="Close"
                onClick={() => setOpen(false)}
              />
            </Box>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default PublisherGroupBy;
