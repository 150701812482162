import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};
const managerOptions = createSlice({
  name: "managerOptions",
  initialState,
  reducers: {
    updateManagerOptions: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { updateManagerOptions } = managerOptions.actions;
export default managerOptions.reducer;
