import React, { useEffect, useState } from "react";
import { AddOutlined, Box, PageHeader } from "../../Collections/Imports";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import { PublisherTagModuleAPiEndPoints } from "../../Config/APIsEndpoints";
import CircularProgressBar from "../../Components/UIComponents/CircularProgressBar";
import AgGridComponent from "../../Components/CommonComponents/AG-Grid";
import { useDispatch, useSelector } from "react-redux";
import {
  removeSinglePublisherTags,
  updatePublisherTags,
} from "../../Store/PublisherTagModule";
import AddEditPublisherTags from "../../Forms/AddEditPublisherTag";

const PublisherTags = () => {
  const dispatch = useDispatch();
  const [rowSelected, setRowSelected] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isOpenAddEditBox, setIsOpenAddEditBox] = useState(false);

  const [paginationIndex, setPaginationIndex] = useState(0);
  const [itemPerIndex, setItemPerIndex] = useState(100);

  //TableData
  const TableData = useSelector((state) => state.publisherTags.tableData);

  const perPageCountOptions = [
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "1000", value: 1000 },
  ];
  const handlePageChange = (data) => {
    setPaginationIndex(data);
  };
  const handleRowsPerPageChange = (data) => {
    setItemPerIndex(data);
    setPaginationIndex(0);
  };

  // Get Publisher Tags
  const GetPublisherTagList = useGetApiCallQuery({
    endPoint: PublisherTagModuleAPiEndPoints.getPublisherTags,
    method: "GET",
  });

  // Get Publisher Tags api response
  useEffect(() => {
    if (GetPublisherTagList?.isSuccess && GetPublisherTagList?.data?.data) {
      const tableData = {
        data: GetPublisherTagList?.data?.data?.publisherTags,
        headers: GetPublisherTagList?.data?.data?.headers,
      };
      dispatch(updatePublisherTags(tableData));
    }
  }, [GetPublisherTagList?.data]);

  //delete publisher tag api call
  const [DeleteBuyerTag, DeleteBuyerTagData] = useApiCallMutation();

  // Handle Grid Button ACtion
  const handleActions = (previousData, action) => {
    switch (action) {
      case "EDIT":
        setRowSelected(previousData);
        setIsEditMode(true);
        setIsOpenAddEditBox(true);
        break;
      case "DELETE":
        console.log("delete ho raha hu");
        const tagId = {
          id: previousData?.id,
        };
        DeleteBuyerTag({
          endPoint: PublisherTagModuleAPiEndPoints.deletePublisherTags,
          method: "POST",
          data: { data: tagId },
        });
        dispatch(removeSinglePublisherTags(previousData?.id));
        break;
      default:
    }
  };

  return (
    <>
      <PageHeader
        title={"Publisher Tags"}
        helpText={
          "The “Publisher Tags” feature allows you to create tags that can be added to Publishers and be used as an additional filter in reports."
        }
      />
      {/* Data Table Start */}
      {GetPublisherTagList?.status === "isUninitialized" && (
        <CircularProgressBar />
      )}
      {GetPublisherTagList?.status !== "isUninitialized" && (
        <Box>
          <AgGridComponent
            rowData={TableData?.data || []}
            columnDefs={TableData?.headers || []}
            actions={["EDIT", "DELETE"]}
            performAction={handleActions}
            totalPageCount={TableData?.data.length}
            paginationIndex={paginationIndex}
            itemPerIndex={itemPerIndex}
            perPageCountOptions={perPageCountOptions}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowMode
            addAction={setIsOpenAddEditBox}
            AddButtonName={"Add New Tag"}
            addActionIcon={<AddOutlined />}
          />
        </Box>
      )}

      {/* Data Table ends */}

      {/* Buyer Tag Add Edit Form Start*/}
      {isOpenAddEditBox && (
        <AddEditPublisherTags
          defaultValues={rowSelected}
          isOpen={isOpenAddEditBox}
          setOpen={setIsOpenAddEditBox}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
        />
      )}
      {/* Buyer Tag Add Edit Form End */}
    </>
  );
};

export default PublisherTags;
