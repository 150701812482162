import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Divider,
  Stack,
} from "../../../../Collections/Imports";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useApiCallMutation } from "../../../../Services/ApiCallService";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import GlobalInput from "../../../../Components/CommonComponents/GlobalInput";
import GlobalDropdown from "../../../../Components/CommonComponents/GlobalDropdown";
import CustomButton from "../../../../Components/CommonComponents/GlobalButton";
import { publisherModuleApiEndPoints } from "../../../../Config/APIsEndpoints";
import { updateSnackBarConfig } from "../../../../Store/Global/SnackBar";

function RevShare() {
  const dispatch = useDispatch();
  const [AddRevShare, addRevShareData] = useApiCallMutation();
  const [GetRevShare, getRevShareData] = useApiCallMutation();
  let currentPubData = JSON.parse(
    sessionStorage.getItem("currentPublisherData")
  );
  const [showValue, setShowValue] = useState(true);
  const [showMinMax, setShowMinMax] = useState(false);
  const [percent, setPercent] = useState(false);

  const validationSchema = Yup.object().shape({
    revShareType: Yup.string().required("This Field is required"),
    value:
      showValue && !percent
        ? Yup.number()
            .typeError("Please enter valid digit")
            .required("This Field is required")
        : showValue && percent
        ? Yup.number()
            .typeError("Please enter valid digit")
            .max(100)
            .required("This Field is required")
        : Yup.string().notRequired(),
    min:
      showMinMax && !percent
        ? Yup.number()
            .typeError("Please enter valid digit")
            .required("This Field is required")
        : showMinMax && percent
        ? Yup.number()
            .typeError("Please enter valid digit")
            .max(100)
            .min(0)
            .required("This Field is required")
        : Yup.string().notRequired(),
    max:
      showMinMax && !percent
        ? Yup.number()
            .typeError("Please enter valid digit")
            .required("This Field is required")
        : showMinMax && percent
        ? Yup.number()
            .typeError("Please enter valid digit")
            .max(100)
            .moreThan(Yup.ref("min"))
            .required("This Field is required")
        : Yup.string().notRequired(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      pubUniqueId: currentPubData.pubUniqueId,
      revShareType: "",
      value: "",
      min: "",
      max: "",
    },
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  useEffect(() => {
    const revShareTypeValue = watch("revShareType");

    setShowValue(
      revShareTypeValue !== "floating_percent" &&
        revShareTypeValue !== "min_max"
    );
    setShowMinMax(
      revShareTypeValue === "min_max" ||
        revShareTypeValue === "floating_percent"
    );

    setPercent(
      revShareTypeValue === "floating_percent" ||
        revShareTypeValue === "static_percent"
    );
  }, [watch("revShareType")]);

  const onFormSubmit = (data) => {
    AddRevShare({
      endPoint: publisherModuleApiEndPoints.addRevShare,
      method: "POST",
      data: { data },
    });
    dispatch(
      updateSnackBarConfig({
        open: true,
        color: "success",
        message: "Data Added successfully",
      })
    );
  };

  // Set form values
  const setFormData = (data) => {
    setValue("revShareType", data?.revShareType, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("value", data?.value, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("min", data?.min, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("max", data?.max, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  useEffect(() => {
    if (getRevShareData?.isSuccess && !getRevShareData?.data?.error) {
      setFormData(getRevShareData?.data?.data);
    }
  }, [getRevShareData]);
  useEffect(() => {
    GetRevShare({
      endPoint: publisherModuleApiEndPoints.getRevShare,
      method: "POST",
      data: {
        data: {
          id: currentPubData.pubUniqueId,
        },
      },
    });
  }, []);

  return (
    <>
      <Box sx={{ width: "50%", margin: "0 auto" }}>
        <Box marginBottom={"15px"}>
          <Typography component="h6" variant="h6" fontWeight={600}>
            Rev Share Configuration
          </Typography>
        </Box>
        <Divider sx={{ mb: "20px" }} />
        <Box component={"form"}>
          <Grid container>
            <Grid item xs={12} sx={{ mt: "10px" }}>
              <GlobalDropdown
                control={control}
                size="small"
                options={[
                  { label: "Static Percent", value: "static_percent" },
                  { label: "Floating Percent", value: "floating_percent" },
                  { label: "Fixed Price", value: "fixed_price" },
                  { label: "Fixed Price Min/Max", value: "min_max" },
                ]}
                name="revShareType"
                label="Rev-Share Model"
                error={!!errors.revShareType}
                helperText={errors.revShareType?.message}
                required
              />
            </Grid>
            {showValue && (
              <Grid item xs={12} sx={{ mt: "10px" }}>
                <GlobalInput
                  control={control}
                  size="small"
                  name="value"
                  label="Value"
                  error={!!errors.value}
                  helperText={errors.value?.message}
                  required
                />
              </Grid>
            )}
            {showMinMax && (
              <>
                <Grid item xs={12} sx={{ mt: "10px" }}>
                  <GlobalInput
                    control={control}
                    size="small"
                    name="min"
                    label="Minimum"
                    error={!!errors.min}
                    helperText={errors.min?.message}
                    required={showMinMax}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: "10px" }}>
                  <GlobalInput
                    control={control}
                    size="small"
                    name="max"
                    label="Maximum"
                    error={!!errors.max}
                    helperText={errors.max?.message}
                    required={showMinMax}
                  />
                </Grid>
              </>
            )}
            <Box
              sx={{
                width: "100%",
                borderTop: "1px solid #DCE0E6",
                marginTop: "10px",
              }}
            >
              <Stack
                direction="row"
                component={Box}
                useFlexGap
                flexWrap="wrap"
                justifyContent={"flex-end"}
                bottom={0}
                marginTop={"1rem"}
              >
                <Box marginBottom={"15px"} marginRight={"5px"} float={"right"}>
                  <CustomButton
                    color="#3a5396"
                    label="Save"
                    onClick={handleSubmit(onFormSubmit)}
                  />
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default RevShare;
