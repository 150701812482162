import { createSlice, nanoid } from "@reduxjs/toolkit";
const initialState = {
  data: {
    allPubData: [],
    allPublisherList: [],
  },
};

const PublisherData = createSlice({
 
  name: "publisherData",
  initialState,
  reducers: {
    allPubData: (state, action) => {
      state.data.allPubData = action.payload;
    },
    allPubList: (state, action) => {
      state.data.allPublisherList = action.payload;
    },
    addSinglePublisherData: (state, action) => {
      state.data.allPubData.publishers.unshift(action.payload);
    },
    updateSinglePublisherData: (state, action) => {
      const updatedRow = action.payload;
      const index = state.data.allPubData.publishers.findIndex(
        (data) => data?.id === updatedRow?.id
      );
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.data.allPubData.publishers[index][key] = updatedRow[key];
      });
    },
    removeSinglePublisherData: (state, action) => {
      state.data.allPubData.publishers =
        state.data.allPubData.publishers.filter(
          (data) => data.id !== action.payload
        );
    },
  },
});

export const {
  allPubData,
  allPubList,
  addSinglePublisherData,
  removeSinglePublisherData,
  updateSinglePublisherData,
} = PublisherData.actions;
export default PublisherData.reducer;
