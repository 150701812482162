import { useEffect, Box } from "../../../../Collections/Imports";
import {
  Container,
  Card,
  Typography,
  Divider,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useApiCallMutation } from "../../../../Services/ApiCallService";
import { useDispatch, useSelector } from "react-redux";
import { leadDetailsModuleApiEndPoints } from "../../../../Config/APIsEndpoints";
import { getPostingLeads } from "../../../../Store/LeadDetailsModule/LeadInfoBody";
const LeadPostingLogs = () => {
  let currentLeadInfo = JSON.parse(sessionStorage.getItem("currentLeadData"));
  const [getAllLeadPostingLogs, PostingLogs] = useApiCallMutation();
  const dispatch = useDispatch();
  const theme = createTheme({
    palette: {
      text: {
        primary: "#3D506E",
        secondary: "#000000",
      },
    },
  });
  const postData = useSelector(
    (state) => state.LeadInfoBody?.data?.getPostingLeads
  );
  useEffect(() => {
    getAllLeadPostingLogs({
      endPoint: leadDetailsModuleApiEndPoints.getAllLeadDetailsById,
      method: "POST",
      data: {
        data: {
          leadId: currentLeadInfo.leadId,
        },
      },
    });
  }, []);
  useEffect(() => {
    if (PostingLogs) {
      dispatch(getPostingLeads(PostingLogs?.data?.data?.leadPostingLogs));
    }
  }, [PostingLogs]);

  return (
    <Container>
      <ThemeProvider theme={theme}>
        <Card sx={{ padding: 2, marginTop: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "16.94px",
                color: "text.primary",
              }}
            >
              Request: https://dev.gateway.visuallabs.io/lds/lead/lead-posting
            </Typography>
          </Box>
          <Divider sx={{ marginY: 2 }} />

          <Typography
            variant="body1"
            component="pre"
            sx={{
              whiteSpace: "pre-wrap",
              color: "text.primary",
              fontWeight: "400",
              fontSize: "11px",
              lineHeight: "13.31px",
            }}
          >
            {` ${JSON.stringify(postData?.response, null, 2)}`}
          </Typography>

          <Divider sx={{ marginY: 2 }} />
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "16.94px",
              color: "text.primary",
            }}
          >
            Response
          </Typography>
          <Divider sx={{ marginY: 2 }} />
          <Box
            component="pre"
            sx={{
              backgroundColor: "#f5f5f5",
              padding: 2,
              borderRadius: 1,
              fontSize: "10px",
              fontWeight: "400",
              lineHeight: "12.1px",
              color: "text.secondary",
            }}
          >
            {`JSON:
          {
            ${JSON.stringify(postData?.payload, null, 2)}
          }
       `}
          </Box>
          {/* 
      <Typography variant="h6" gutterBottom>
        Values
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer> */}
        </Card>
      </ThemeProvider>
    </Container>
  );
};
export default LeadPostingLogs;
