import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
    data: {
        InfoBody: [],
        getLeadLogs:[],
        getRedirect:[],
        getTrackingLeads:[],
        getPostingLeads:[],
        getRevenueLogs:[]
    },
}

const LeadInfoBody = createSlice({
    name: "InfoBody",
    initialState,
    reducers: {
        InfoBody: (state, action) => {
            state.data.InfoBody = action.payload;
        },
        getLeadLogs:(state, action) => {
            state.data.getLeadLogs = action.payload;
        },
        getRedirect:(state, action) => {
            state.data.getRedirect = action.payload;
        },
        getTrackingLeads:(state,action) => {
            state.data.getTrackingLeads = action.payload;
        },
        getPostingLeads: (state,action) => {
            state.data.getPostingLeads = action.payload;
        },
        getRevenueLogs:(state,action) =>{
            state.data.getRevenueLogs = action.payload;
        }
    }
});

export const {InfoBody,getLeadLogs,getRedirect,getTrackingLeads,getPostingLeads,getRevenueLogs} = LeadInfoBody.actions;
export default LeadInfoBody.reducer;