import CallbackHandlerPage from "../Views/Authentication/CallbackHandler";
import Login from "../Views/Authentication/Login";
import Layout from "../Layout";
import AuthenticationRoutes from "./AuthenticationRoutes";
import Dashboard from "../Views/Dashboard";
import BuyerListingPage from "../Views/BuyerListing";
import { Navigate } from "react-router-dom";
import Logout from "../Views/Authentication/Logout";
import CampaignListingPage from "../Views/CampaignListing";
import CampaignConfiguration from "../Views/CampaignListing/CampaignConfiguration";
import PublisherListing from "../Views/PublisherListing";
import PublisherConfiguration from "../Views/PublisherListing/PublisherConfiguration";
import LeadInfo from "../Views/LeadDetails/LeadInfo";
import ChannelListing from "../Views/ChannelListing";
import PlAndDnpl from "../Views/PlAndDnpl";
import CampaignTagsPage from "../Views/CampaignTags";
import BuyersTagPage from "../Views/BuyersTag";
import BuyerLabel from "../Views/BuyerLabel";
import PublisherTags from "../Views/PublisherTags";
import PublisherLabel from "../Views/PublisherLabel";
import ChannelAPIDoc from "../Views/ChannelListing/ChannelAPIDoc";
import ChannelMapping from "../Views/ChannelMapping";
import ChannelLabel from "../Views/ChannelLabel";

import PignTree from "../Views/PingTree";
import PingTreeConfiguration from "../Views/PingTree/PingTreeConfiguration";
import LeadDetails from "../Views/LeadDetails";
import PerformanceSummary from "../Views/PerformanceSummary";
const PublicRoutes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: "/dashboard",
        element: (
          <AuthenticationRoutes>
            <Dashboard />
          </AuthenticationRoutes>
        ),
      },
      {
        path: "/buyers",
        element: (
          <AuthenticationRoutes>
            <BuyerListingPage />,
          </AuthenticationRoutes>
        ),
      },
      {
        path: "/buyer-labels",
        element: (
          <AuthenticationRoutes>
            <BuyerLabel />,
          </AuthenticationRoutes>
        ),
      },
      {
        path: "/publisher-label",
        element: (
          <AuthenticationRoutes>
            <PublisherLabel />,
          </AuthenticationRoutes>
        ),
      },
      {
        path: "/channel-label",
        element: (
          <AuthenticationRoutes>
            <ChannelLabel />,
          </AuthenticationRoutes>
        ),
      },
      {
        path: "/campaigns",
        element: (
          <AuthenticationRoutes>
            <CampaignListingPage />
          </AuthenticationRoutes>
        ),
        children: [{ path: ":id", element: <CampaignConfiguration /> }],
      },
      {
        path: "/publisher",
        element: (
          <AuthenticationRoutes>
            <PublisherListing />
          </AuthenticationRoutes>
        ),
        children: [{ path: ":id", element: <PublisherConfiguration /> }],
      },

      {
        path: "/channel/",
        element: (
          <AuthenticationRoutes>
            <ChannelListing />{" "}
          </AuthenticationRoutes>
        ),
        children: [{ path: ":id", element: <ChannelAPIDoc /> }],
      },
      {
        path: "/pldnpl",
        element: (
          <AuthenticationRoutes>
            <PlAndDnpl />
          </AuthenticationRoutes>
        ),
        children: [{ path: ":id", element: <PublisherConfiguration /> }],
      },
      {
        path: "/campaign-tags",
        element: (
          <AuthenticationRoutes>
            <CampaignTagsPage />
          </AuthenticationRoutes>
        ),
        children: [{ path: ":id", element: <PublisherConfiguration /> }],
      },
      {
        path: "/buyer-tags",
        element: (
          <AuthenticationRoutes>
            <BuyersTagPage />
          </AuthenticationRoutes>
        ),
        children: [{ path: ":id", element: <PublisherConfiguration /> }],
      },

      {
        path: "/ping-tree",
        element: (
          <AuthenticationRoutes>
            <PignTree />
          </AuthenticationRoutes>
        ),
        children: [{ path: ":id", element: <PublisherConfiguration /> }],
      },
      {
        path: "/pub-report",
        element: (
          <AuthenticationRoutes>
            <PignTree />
          </AuthenticationRoutes>
        ),
        // children: [{ path: ":id", element: <PublisherConfiguration /> }],
      },
      {
        path: "/pub-report/pldnpl",
        element: (
          <AuthenticationRoutes>
            <BuyersTagPage />
          </AuthenticationRoutes>
        ),
      },
      {
        path: "/pub-report/publisher",
        element: (
          <AuthenticationRoutes>
            <PublisherTags />
          </AuthenticationRoutes>
        ),
      },
      {
        path: "/lead-details",
        element: (
          <AuthenticationRoutes>
            <LeadDetails />
          </AuthenticationRoutes>
        ),
        children: [{ path: ":id", element: <LeadInfo /> }],
      },
      {
        path: "/publisher-summary",
        element: (
          <AuthenticationRoutes>
            <PerformanceSummary />
          </AuthenticationRoutes>
        ),
        children: [{ path: ":publisher", element: <LeadInfo /> }],
      },

      // {
      //   path: "/channel-mapping",
      //   element: (
      //     <AuthenticationRoutes>
      //       <ChannelMapping />
      //     </AuthenticationRoutes>
      //   ),
      //   path: "/publisher-tags",
      //   element: (
      //     <AuthenticationRoutes>
      //       <PublisherTags />
      //     </AuthenticationRoutes>
      //   ),

      //   children: [{ path: ":id", element: <PublisherConfiguration /> }],

      //   path: "/ping-tree",
      //   element: (
      //     <AuthenticationRoutes>
      //       <PignTree />
      //     </AuthenticationRoutes>
      //   ),

      //   children: [
      //     {
      //       path: "pldnpl",
      //       element: (
      //         <AuthenticationRoutes>
      //           <BuyersTagPage />
      //         </AuthenticationRoutes>
      //       ),
      //     },
      //     {
      //       path: "publisher",
      //       element: (
      //         <AuthenticationRoutes>
      //           <PublisherTags />
      //         </AuthenticationRoutes>
      //       ),
      //     },
      //   ],
      //   // children: [{ path: ":id", element: <PingTreeConfiguration /> }],
      // },
    ],
  },

  { path: "/login", element: <Login /> },
  { path: "/callback", element: <CallbackHandlerPage /> },
  { path: "/logout", element: <Logout /> },
];

export default PublicRoutes;
