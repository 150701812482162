import {
    useEffect,
    AddOutlined,
    Box
} from "../../../../Collections/Imports";
import { useApiCallMutation } from "../../../../Services/ApiCallService";
import { useDispatch, useSelector } from "react-redux";
import { leadDetailsModuleApiEndPoints } from "../../../../Config/APIsEndpoints";
import { useState } from "react";
import { getTrackingLeads } from "../../../../Store/LeadDetailsModule/LeadInfoBody";
import AgGridComponent from "../../../../Components/CommonComponents/AG-Grid";

const LeadTracking = () => {
let currentLeadInfo = JSON.parse(sessionStorage.getItem("currentLeadData"));
const [getAllLeadTracking, LeadTracking] = useApiCallMutation();
const dispatch = useDispatch();
const rowStyle = { background: "" };
const tableData = useSelector ((state) => state.LeadInfoBody?.data?.getTrackingLeads);
console.log("tracking..",tableData)
useEffect(() => {
    getAllLeadTracking({
      endPoint: leadDetailsModuleApiEndPoints.getAllLeadDetailsById,
      method: "POST",
      data: {
        data: {
          leadId: currentLeadInfo.leadId,
        },
      },
    });
  }, []);
  useEffect(() => {
    if (LeadTracking) {
      dispatch(getTrackingLeads(LeadTracking?.data?.data?.leadTrackingResponse));
    }
  }, [LeadTracking]);

  return (
    <Box sx={{ padding: "22px" }}>
      <AgGridComponent
        rowData={tableData?.trackingResponse || []}
        columnDefs={tableData?.headers || []}
        search={false}
        addActionIcon={<AddOutlined />}
        downlodBtn={false}
        tableName={"Redirect Leads"}
        domLayout="autoHeight"
        height={"100%"}
        rowStyle={rowStyle}
      />
    </Box>
  );
}
export default LeadTracking;