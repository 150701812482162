// import React, { useEffect, useState } from "react";
// import AgGridComponent from "../../Components/CommonComponents/AG-Grid";
// import {
//   Box,
//   Chip,
//   PageHeader,
//   Grid,
//   AddOutlined,
//   AddIcon,
//   moment,
//   Stack,
//   useNavigate,
//   useLocation,
//   Button,
// } from "../../Collections/Imports";
// import {
//   useApiCallMutation,
//   useGetApiCallQuery,
// } from "../../Services/ApiCallService";
// import { getTableData, removeSinglePingTree } from "../../Store/PingTreeModule";
// import { pingtreeApiEndPoints } from "../../Config/APIsEndpoints";
// import { useDispatch, useSelector } from "react-redux";
// import AddEditPingTree from "../../Forms/AddEditPingTree";
// import CircularProgressBar from "../../Components/UIComponents/CircularProgressBar";

// import { Outlet } from "react-router-dom";

// const PingTree = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   let location = useLocation();
//   const [rowSelected, setRowSelected] = useState({});
//   const [isEditMode, setIsEditMode] = useState(false);
//   // Form pagination handler
//   const [paginationIndex, setPaginationIndex] = useState(0);
//   const [itemPerIndex, setItemPerIndex] = useState(100);
//   const perPageCountOptions = [
//     { label: "100", value: 100 },
//     { label: "500", value: 500 },
//     { label: "1000", value: 1000 },
//   ];
//   const handlePageChange = (data) => {
//     setPaginationIndex(data);
//   };
//   const handleRowsPerPageChange = (data) => {
//     setItemPerIndex(data);
//     setPaginationIndex(0);
//   };
//   const [isOpenAddEditBox, setIsOpenAddEditBox] = useState(false);

//   const TableData = useSelector((state) => state.pingTree?.tableData);
//   // Get Campaign Tags
//   const pingTreeDataList = useGetApiCallQuery({
//     endPoint: pingtreeApiEndPoints.getPingtreeList,
//     method: "GET",
//   });
//   // delete pentree
//   const [DeletePingTree, DeletePingTreeData] = useApiCallMutation();
//   // Get Campaign Tags api response
//   useEffect(() => {
//     if (pingTreeDataList?.isSuccess && pingTreeDataList?.data?.data) {
//       const tableData = {
//         data: pingTreeDataList?.data?.data?.pingTree,
//         headers: pingTreeDataList?.data?.data?.headers,
//       };
//       dispatch(getTableData(tableData));
//     }
//   }, [pingTreeDataList?.data]);

//   const handleActions = (previousData, action) => {
//     switch (action) {
//       case "EDIT":
//         setRowSelected(previousData);
//         setIsEditMode(true);
//         setIsOpenAddEditBox(true);
//         break;
//       case "DELETE":
//         // console.log("delete ho raha hu");
//         const treeUniqueId = {
//           treeUniqueId: previousData?.treeUniqueId,
//         };
//         DeletePingTree({
//           endPoint: pingtreeApiEndPoints.deletePingTree,
//           method: "POST",
//           data: { data: treeUniqueId },
//         });
//         dispatch(removeSinglePingTree(previousData?.treeUniqueId));
//         break;
//       case "CONFIG":
//         setRowSelected(previousData);
//         sessionStorage.setItem(
//           "currentPingTreeRowData",
//           JSON.stringify(previousData)
//         );
//         // dispatch(updateCampaignRowData(previousData));
//         const currentPingTreeData = JSON.parse(
//           sessionStorage.getItem("currentPingTreeRowData")
//         );

//         // setCampainName(currentCampData.campaignName);
//         navigate(String(currentPingTreeData?.treeUniqueId));

//         break;
//       default:
//     }
//   };

//   return (
//     <>
//       <PageHeader title={"Pign Tree"} helpText={"Pign Tree"} />

//       {location?.pathname === "/ping-tree" && (
//         <>
//           {pingTreeDataList.isLoading && <CircularProgressBar />}
//           {!pingTreeDataList.isLoading && (
//             <Box>
//               <AgGridComponent
//                 rowData={TableData?.data || []}
//                 columnDefs={TableData?.headers || []}
//                 tableName={"Pign Tree"}
//                 actions={["EDIT", "DELETE", "CONFIG"]}
//                 performAction={handleActions}
//                 totalPageCount={TableData?.data.length}
//                 paginationIndex={paginationIndex}
//                 itemPerIndex={itemPerIndex}
//                 perPageCountOptions={perPageCountOptions}
//                 onPageChange={handlePageChange}
//                 onRowsPerPageChange={handleRowsPerPageChange}
//                 rowMode
//                 addAction={setIsOpenAddEditBox}
//                 AddButtonName={"Add New Tag"}
//                 addActionIcon={<AddOutlined />}
//               />
//             </Box>
//           )}
//           {isOpenAddEditBox && (
//             <AddEditPingTree
//               defaultValues={rowSelected}
//               isOpen={isOpenAddEditBox}
//               setOpen={setIsOpenAddEditBox}
//               isEditMode={isEditMode}
//               setIsEditMode={setIsEditMode}
//             />
//           )}
//         </>
//       )}
//       <div>
//         <Outlet />
//       </div>
//     </>
//   );
// };

// export default PingTree;

import React from "react";
import {
  Box,
  Tab,
  Divider,
  TabList,
  TabContext,
  TabPanel,
  CodeOutlinedIcon,
  KeyboardCommandKeyOutlinedIcon,
  PageHeader,
  useLocation,
  ArrowBack,
  useNavigate,
} from "./../../Collections/Imports";
import PropTypes from "prop-types";
import { useTabContext } from "../../Collections/Tabcontext";
import RedirectPingTree from "./PingTreeConfiguration/RedirectPingTree/RedirectPingTree";
import SilentPingTree from "./PingTreeConfiguration/SilentPingTree/SilentPingTree";

const PingTree = () => {
  const { tabValue, handleChange } = useTabContext();
  const tabIconColorActive = "#97B7F9";
  const tabTextColorActive = "#3E7DFD";
  const tabIconColorInactive = "#525252";
  const tabTextColorInactive = "#525252";
  let location = useLocation();
  const navigate = useNavigate();
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        {location?.pathname === "/ping-tree" ? (
          <PageHeader title={"Pign Tree"} helpText={"Pign Tree"} />
        ) : (
          <PageHeader
            title={"PingTree Config"}
            backIcon={<ArrowBack />}
            onBack={() => navigate(-1)}
            helpText={"Configuration and Settings of PingTree"}
          />
        )}

        <TabContext value={tabValue}>
          {location?.pathname === "/ping-tree" && (
            <TabList
              onChange={handleChange}
              className="tabList"
              sx={{
                p: 0,
                m: 0,
                height: "15px",
                "& .MuiTabs-indicator": {
                  borderRadius: "4px 4px 0px 0px",
                  borderBottom: "3px solid #3E7DFD",
                },
              }}
            >
              <Tab
                icon={
                  <KeyboardCommandKeyOutlinedIcon
                    style={{
                      fill:
                        tabValue === 1
                          ? tabIconColorActive
                          : tabIconColorInactive,
                      marginRight: "4px",
                    }}
                  />
                }
                iconPosition="start"
                label="Redirect PingTree"
                value={1}
                className="tabHeading"
                style={{
                  color:
                    tabValue === 1 ? tabTextColorActive : tabTextColorInactive,
                  fontWeight: tabValue === 1 ? 600 : 400,
                }}
              />

              <Tab
                icon={
                  <CodeOutlinedIcon
                    style={{
                      fill:
                        tabValue === 2
                          ? tabIconColorActive
                          : tabIconColorInactive,
                      marginRight: "4px",
                    }}
                  />
                }
                iconPosition="start"
                label="Silent PingTree"
                value={2}
                className="tabHeading"
                style={{
                  color:
                    tabValue === 2 ? tabTextColorActive : tabTextColorInactive,
                  fontWeight: tabValue === 2 ? 600 : 400,
                }}
              />
            </TabList>
          )}
          <Divider />
          <Box sx={{ background: "white", minHeight: "100vh" }}>
            {/* Main tab */}

            <TabPanel value={1} sx={{ paddingRight: "0" }}>
              <RedirectPingTree />
            </TabPanel>
            {/* Contact Details tab */}

            <TabPanel
              value={2}
              sx={{ background: "#fff", padding: "0", pt: "0px" }}
            >
              {/* <Contact /> */}
              <SilentPingTree />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </Box>
  );
};

export default PingTree;
