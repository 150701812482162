import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rowData: {},
};

const currentCampaignRowData = createSlice({
  name: "currentCampaignRowData",
  initialState,
  reducers: {
    updateCampaignRowData: (state, action) => {
      state.rowData = action.payload;
    },
  },
});

export const { updateCampaignRowData } = currentCampaignRowData.actions;
export default currentCampaignRowData.reducer;
