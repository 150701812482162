// import React, { useState } from "react";
// import { Autocomplete, Checkbox, TextField } from "@mui/material";
// import { Controller } from "react-hook-form";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

// const GlobalMultiSelect = ({ control, name, label, options, ...props }) => {
//   const [selectedValue, setSelectedValue] = useState([]);

//   const handleCheckedValues = (data) => {
//     const abc = options.filter((opt) => data.find((val) => val == opt.value));
//     return !!abc.length;
//   };

//   // new

//   // const [selectedOptions, setSelectedOptions] = useState([]);

//   // const handleChange = (event) => {
//   //   const selected = Array.from(
//   //     event.target.selectedOptions,
//   //     (option) => option.value
//   //   );

//   //   // Update selected options
//   //   setSelectedOptions(selected);

//   //   // Apply logic based on selection
//   //   if (selected.includes("ALL")) {
//   //     setSelectedOptions(["ALL"]);
//   //   } else if (selected.includes("PL") && selected.includes("DNPL")) {
//   //     setSelectedOptions(["ALL"]);
//   //   }
//   // };

//   return (
//     <Controller
//       name={name}
//       control={control}
//       defaultValue=""
//       render={({ field, fieldState }) => (
//         <>
//           <Autocomplete
//             {...props}
//             multiple
//             id="checkboxes-tags-demo"
//             options={options || []}
//             disableCloseOnSelect
//             // getOptionLabel={(option) => option?.label}
//             value={
//               field?.value && field?.value.length
//                 ? options.filter((res) =>
//                     field?.value?.find((val) => val === res.value)
//                   )
//                 : []
//             }
//             // value={selectedValue}
//             // onChange={(_, newValue) => {
//             //   // alert(JSON.stringify(newValue));
//             //   if (newValue.length - 1 == options.length - 1) {
//             //     setSelectedValue(["All"]);
//             //   }
//             //   //  else if (newValue.length - 1 == options.length - 1) {
//             //   // }
//             //   else {
//             //     field.onChange(
//             //       newValue.length ? newValue.map((res) => res?.value) : []
//             //     );
//             //     setSelectedValue(newValue);
//             //   }
//             // }}
//             onBlur={() => {
//               field.onBlur(selectedValue?.value || null);
//             }}
//             getOptionDisabled={(option) => {
//               return (
//                 (!field?.value ||
//                   (field?.value && field.value.includes("All"))) &&
//                 option.value !== "All"
//               );
//             }}
//             renderOption={(props, option, { selected }) => (
//               <li {...props}>
//                 <Checkbox
//                   icon={icon}
//                   checkedIcon={checkedIcon}
//                   style={{ marginRight: 8 }}
//                   checked={selected}
//                 />
//                 {option?.label}
//               </li>
//             )}
//             renderInput={(params) => (
//               <TextField
//                 {...params}
//                 label={label}
//                 error={fieldState.invalid}
//                 helperText={fieldState.error?.message}
//                 required={props?.required ? true : false}
//                 // placeholder="Favorites"
//               />
//             )}
//           />
//         </>
//       )}
//     />
//   );
// };

// export default GlobalMultiSelect;

import React, { useState } from "react";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const GlobalMultiSelect = ({ control, name, label, options, ...props }) => {
  const [selectedValue, setSelectedValue] = useState([]);

  const handleCheckedValues = (data) => {
    const abc = options.filter((opt) => data.find((val) => val == opt.value));
    return !!abc.length;
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field, fieldState }) => (
        <>
          <Autocomplete
            {...props}
            multiple
            id="checkboxes-tags-demo"
            options={options || []}
            disableCloseOnSelect
            // getOptionLabel={(option) => option?.label}
            value={
              field?.value && field?.value.length
                ? options.filter((res) =>
                    field?.value?.find((val) => val === res.value)
                  )
                : []
            }
            // value={selectedValue}
            onChange={(_, newValue) => {
              field.onChange(
                newValue.length ? newValue.map((res) => res?.value) : []
              );
              setSelectedValue(newValue);
            }}
            // onBlur={() => {
            //   field.onBlur(selectedValue?.value || null);
            // }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option?.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                required={props?.required ? true : false}
                // placeholder="Favorites"
              />
            )}
          />
        </>
      )}
    />
  );
};

export default GlobalMultiSelect;
