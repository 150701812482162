import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import GlobalInput from "../../Components/CommonComponents/GlobalInput";
import GlobalDropdown from "../../Components/CommonComponents/GlobalDropdown";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  campaignModuleAPiEndPoints,
  coreAPIEndsPoints,
} from "../../Config/APIsEndpoints";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import { useDispatch, useSelector } from "react-redux";
import {
  addSingleBuyerData,
  updateSingleBuyerData,
} from "../../Store/BuyerModule";
import CustomButton from "../../Components/CommonComponents/GlobalButton";
import { updateLabelOptions } from "../../Store/Global/LabelOptions";
import { updateManagerOptions } from "../../Store/Global/ManagerOptions";
import { updateTypeOptions } from "../../Store/Global/TypeOptions";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import "./style.css";
import moment from "moment";
import SingleDatePicker from "../../Components/CommonComponents/GlobalDatePicker";
import {
  addSingleScheduleRule,
  updateSingleScheduleData,
} from "../../Store/ScheduleModule";

const AddEditSchedulerRule = (props) => {
  const { setOpen, isOpen, defaultValues, isEditMode, setIsEditMode } = props;
  const [defaultValuesSchema, setDefaultValuesSchema] = useState({});
  const dispatch = useDispatch();

  let currentCampaignData = JSON.parse(
    sessionStorage.getItem("currentCampaignData")
  );

  const currentScheduleRowData = useSelector(
    (state) => state?.currentScheduleRowData?.rowData
  );

  console.log("currentScheduleRowData", currentScheduleRowData);

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&:before, &:after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 22,
        height: 22,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 9,
        color: theme.palette.getContrastText(theme.palette.primary.main),
      },
      "&:before": {
        left: 8,
        content: "'ON'",
      },
      "&:after": {
        right: 8.5,
        content: "'OFF'",
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 20,
      height: 20,
    },
  }));

  const [CreatCampaignRule, creatCampiginRuleData] = useApiCallMutation();

  // const [UpdateBuyer, UpdateScheduleData] = useApiCallMutation();

  const [UpdateSchedul, UpdateScheduleData] = useApiCallMutation();

  const getLabelOptions = useGetApiCallQuery({
    endPoint: coreAPIEndsPoints.getLabel,
    method: "GET",
  });
  const getManagerOptions = useGetApiCallQuery({
    endPoint: coreAPIEndsPoints.getManager,
    method: "GET",
  });
  const getTypeOptions = useGetApiCallQuery({
    endPoint: coreAPIEndsPoints.getType,
    method: "GET",
  });

  if (getLabelOptions?.isSuccess) {
    dispatch(updateLabelOptions(getLabelOptions?.data?.data));
  }
  if (getManagerOptions?.isSuccess) {
    dispatch(updateManagerOptions(getManagerOptions?.data?.data));
  }
  if (getTypeOptions?.isSuccess) {
    dispatch(updateTypeOptions(getTypeOptions?.data?.data));
  }

  // Set Incoming Default values
  useEffect(() => {
    if (Object.keys(defaultValues).length && isEditMode) {
      SetFormValues(defaultValues);
    }
  }, []);

  // Update Created Schedule
  useEffect(() => {
    if (
      creatCampiginRuleData?.isSuccess &&
      !creatCampiginRuleData?.data?.error
    ) {
      dispatch(addSingleScheduleRule(creatCampiginRuleData.data?.data));
      setOpen(false);
      setIsEditMode(false);
    }
  }, [creatCampiginRuleData]);

  useEffect(() => {
    if (UpdateScheduleData?.isSuccess && !UpdateScheduleData?.data?.error) {
      console.log("All Buyer DATA", UpdateScheduleData.data?.data);
      dispatch(updateSingleScheduleData(UpdateScheduleData.data?.data));
      setOpen(false);
      setIsEditMode(false);
    }
  }, [UpdateScheduleData]);

  //get Schedule method Value
  const [selectMethod, setSelectMethod] = useState({
    value: defaultValues?.scheduleMethod?.toUpperCase(),
  });

  console.log("Method value get", selectMethod);

  const handleMethodValue = useCallback(
    (method) => {
      setSelectMethod(method);
    },
    [selectMethod]
  );

  useEffect(() => {
    // Check if value is undefined and update it to "DAYS"
    if (selectMethod.value === undefined) {
      setSelectMethod((prevSelectMethod) => ({
        ...prevSelectMethod,
        value: "DAYS",
      }));
    }
  }, [selectMethod, handleMethodValue]);

  //get Schedule Action Value

  const [selectAction, setSelectAction] = useState({
    value: defaultValues?.action,
  });

  console.log("Action value get", selectAction);

  const handleActionavlue = useCallback(
    (action) => {
      setSelectAction(action);
    },
    [selectAction]
  );

  useEffect(() => {
    // Check if value is undefined and update it to "DAYS"
    if (selectAction.value === undefined) {
      setSelectAction((prevSelectAction) => ({
        ...prevSelectAction,
        value: "Post",
      }));
    }
  }, [selectAction, handleActionavlue]);

  console.log("defaultValues?.scheduleMethod", selectMethod);

  // useEffect(() => {

  // }, []);

  // Set Initial Value here
  const initialValues = {
    ScheduleStatus: true,
    action: "Post",
    scheduleMethod: "DAYS",
    days: [],
    startTime: "",
    endTime: "",
    startMinute: "",
    endMinute: "",
    soldLeadLimit: "",
    sentLeadLimit: "",
    totalSold: "",
    totalSoldInMinutes: "",
    totalPost: "",
    totalPostInMinutes: "",
    // startDate: "",
    // endDate: "",
  };

  // Set Validation Schema here
  const numericErrorMessage = "Please enter a valid number";
  const validationSchema = Yup.object().shape({
    action: Yup.string().required("This Field is required"),
    scheduleMethod: Yup.string().required("This Field is required"),

    startTime: Yup.string()
      .required("This Field is required")
      .matches(/^\d+$/, numericErrorMessage),
    endTime: Yup.string()
      .required("This Field is required")
      .matches(/^\d+$/, numericErrorMessage),
    startMinute: Yup.string()
      .required("This Field is required")
      .matches(/^\d+$/, numericErrorMessage),
    endMinute: Yup.string()
      .required("This Field is required")
      .matches(/^\d+$/, numericErrorMessage),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  // Set Forms Value

  const SetFormValues = (data) => {
    console.log("Default Value is", data);

    setValue("ScheduleStatus", data.status, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

    if (data.scheduleMethod === "Date range") {
      const period = data.period;

      // Check if period is a non-empty string
      if (typeof period === "string" && period.trim() !== "") {
        // Split the period string into an array based on the separator " - "
        const [startDate, endDate] = period.split(" - ");

        const startDateObject = new Date(startDate);
        const endDateObject = new Date(endDate);

        // Set the values
        setValue("startDate", startDateObject, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
        setValue("endDate", endDateObject, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      } else {
        // Handle the case where period is not a valid string

        console.error("Invalid period format:", period);
      }
    } else {
      setValue("days", data.period, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }

    setValue("action", data?.action, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

    if (data.action === "Post") {
      setValue("soldLeadLimit", data?.soldLeadLimit, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });

      setValue("totalPost", data?.totalPost, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });

      setValue("totalSold", data?.totalSold, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });

      setValue("totalSoldInMinutes", data?.totalSoldInMinutes, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });

      setValue("totalPostInMinutes", data?.totalPostInMinutes, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });

      setValue("sentLeadLimit", data?.sentLeadLimit, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }

    setValue(
      "scheduleMethod",
      data?.scheduleMethod ? data.scheduleMethod.toUpperCase() : null,
      {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      }
    );

    setValue("startTime", `${data?.startTime.match(/\d{2}/)}`, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

    setValue("startMinute", `${data?.startTime.match(/\d{2}$/)}`, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

    setValue("endTime", `${data?.endTime.match(/\d{2}/)}`, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("endMinute", `${data?.endTime.match(/\d{2}$/)}`, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

    // setValue("soldLeadLimit", data?.soldLeadLimit, {
    //   shouldValidate: true,
    //   shouldDirty: true,
    //   shouldTouch: true,
    // });

    // setValue("totalPost", data?.totalPost, {
    //   shouldValidate: true,
    //   shouldDirty: true,
    //   shouldTouch: true,
    // });

    // setValue("totalSold", data?.totalSold, {
    //   shouldValidate: true,
    //   shouldDirty: true,
    //   shouldTouch: true,
    // });

    // setValue("totalSoldInMinutes", data?.totalSoldInMinutes, {
    //   shouldValidate: true,
    //   shouldDirty: true,
    //   shouldTouch: true,
    // });

    // setValue("totalPostInMinutes", data?.totalPostInMinutes, {
    //   shouldValidate: true,
    //   shouldDirty: true,
    //   shouldTouch: true,
    // });

    // setValue("sentLeadLimit", data?.sentLeadLimit, {
    //   shouldValidate: true,
    //   shouldDirty: true,
    //   shouldTouch: true,
    // });
  };

  //***********FORM SUBMISSION ********//
  const onFormSubmit = async (obj) => {
    console.log("Form Data OBJ", obj);

    const data = {
      action: obj?.action,
      scheduleMethod: obj?.scheduleMethod == "DAYS" ? "Days" : "Date range",
      startDate: moment(obj?.startDate).format("YYYY-MM-DD"),
      endDate: moment(obj?.endDate).format("YYYY-MM-DD"),
      days: obj?.days,
      startTime: `${obj?.startTime}:${obj?.startMinute}`,
      endTime: `${obj?.endTime}:${obj?.endMinute}`,
      soldLeadLimit: obj?.soldLeadLimit,
      sentLeadLimit: obj?.sentLeadLimit,
      totalSold: obj?.totalSold,
      totalPost: obj?.totalPost,
      totalSoldInMinutes: obj?.totalSoldInMinutes,
      totalPostInMinutes: obj?.totalPostInMinutes,
      status: obj?.ScheduleStatus,
      campaignId: currentCampaignData?.campaignId,
      campaignScheduleId: currentScheduleRowData?.campaignScheduleId,
    };

    if (isEditMode) {
      UpdateSchedul({
        endPoint: campaignModuleAPiEndPoints.updateSchedule,
        method: "POST",
        data: { data },
      });
    } else {
      CreatCampaignRule({
        endPoint: campaignModuleAPiEndPoints.addCampaignSchedule,
        method: "POST",
        data: { data },
      });
    }

    console.log("data", data);
  };

  //
  const integrations = [
    { label: "Redirect", id: 1, value: "1" },
    { label: "Integration 1", id: 2, value: "2" },
  ];
  const buyerStatusOptions = [
    { label: "TRUE", value: "true" },
    { label: "FALSE", value: "false" },
  ];
  //static options
  const actionBtn1 = [
    { label: "Post", value: "Post" },
    { label: "Do Not Post", value: "Do Not Post" },
  ];

  const scheduleMethods = [
    { label: "DAYS", value: "DAYS" },
    { label: "DATE RANGE", value: "DATE RANGE" },
  ];

  // time array
  const startTime = [];
  for (let i = 0; i <= 23; i++) {
    const paddedValue = i < 10 ? `0${i}` : i.toString();
    startTime.push({ label: paddedValue, value: paddedValue });
  }
  // minute array
  const startMinute = [];
  for (let i = 0; i <= 59; i++) {
    const paddedValue = i < 10 ? `0${i}` : i.toString();
    startMinute.push({ label: paddedValue, value: paddedValue });
  }
  //check box
  const [checkedDays, setCheckedDays] = useState([]);
  const allDays = ["MON", "TUE", "WED", "THURS", "FRI", "SAT", "SUN"];

  const handleCheckAllDays = (isChecked) => {
    const newValues = isChecked ? allDays : [];

    setValue("days", newValues);
    const newStartTime = isChecked ? `${"00"}` : "";
    const newStartMinute = isChecked ? `${"00"}` : "";

    const newEndTime = isChecked ? `${"23"}` : "";
    const newEndMinute = isChecked ? `${"59"}` : "";

    setValue("startTime", `${newStartTime.match(/\d{2}/)}`);
    setValue("startMinute", `${newStartMinute.match(/\d{2}/)}`);
    setValue("endTime", `${newEndTime.match(/\d{2}/)}`);
    setValue("endMinute", `${newEndMinute.match(/\d{2}/)}`);
  };

  return (
    <>
      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={() => {
          setOpen(false);
          setIsEditMode(false);
          setSelectAction("");
        }}
        sx={{
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            top: "15px",
            borderRadius: "16px",
            width: "100%",
            maxWidth: "500px",
          },
        }}
      >
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent={"space-between"}
          >
            <Box marginBottom={"15px"}>
              <Typography component="h6" variant="h6" fontWeight={600}>
                {(isEditMode && "Update Schedule Rule") || "Add Schedule Rule"}
              </Typography>
            </Box>
            <Box marginBottom={"15px"} onClick={() => reset()}>
              <Typography component="h7" variant="h7" fontWeight={200}>
                Clear All
              </Typography>
            </Box>
          </Stack>

          <Divider
            sx={{
              mb: "20px",
            }}
          />

          <Box component={"form"}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {/* <Controller
                  name="ScheduleStatus"
                  control={control}
                  defaultValue={true} // Set the default value to true
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Android12Switch {...field} />}
                      // control={<Switch {...field} />}
                      label="Status"
                    />
                  )}
                /> */}
                <Controller
                  name="ScheduleStatus"
                  control={control}
                  defaultValue={true}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Android12Switch
                          {...field}
                          checked={field.value} // Make sure to set the 'checked' prop based on the field value
                        />
                      }
                      label="Status"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  size="small"
                  name="action"
                  label="Action"
                  valueGet={handleActionavlue}
                  // defaultValue={true}
                  options={actionBtn1}
                  error={!!errors.action}
                  helperText={errors.action?.message}
                  required
                />
              </Grid>
              {/* 
              <Grid item xs={12}>
                <GlobalDateRangePicker />
              </Grid> */}
              <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  size="small"
                  name="scheduleMethod"
                  label="Schedule Method"
                  options={scheduleMethods}
                  valueGet={handleMethodValue}
                  defaultValue={true}
                  error={!!errors.scheduleMethod}
                  helperText={errors.scheduleMethod?.message}
                  required
                />
              </Grid>
              {selectMethod.value === "DATE RANGE" && (
                <>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ pr: 1 }}>
                        <Box>Start Date</Box>

                        <SingleDatePicker
                          name="startDate"
                          control={control}
                          minDate={new Date()}
                        />
                      </Box>

                      <Box sx={{ pl: 1 }}>
                        <Box>End Date</Box>

                        <SingleDatePicker
                          name="endDate"
                          control={control}
                          minDate={new Date()}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </>
              )}

              {selectMethod.value === "DAYS" && (
                <>
                  <Grid item sx={12}>
                    <Box>
                      <Typography variant="h6" component="h6">
                        Days{" "}
                        <Controller
                          name="selectAll"
                          control={control}
                          render={({ field }) => (
                            <Button
                              onClick={() => handleCheckAllDays(!field.value)}
                              size="small"
                              sx={{
                                background: "#394656",
                                color: "#fff",
                                height: "20px",
                                "&:hover": {
                                  background: "#3A5396",
                                  color: "#fff",
                                },
                              }}
                            >
                              {field.value ? "Deselect All" : "Set 24/7"}
                            </Button>
                          )}
                        />
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      {allDays.map((day) => (
                        <Box key={day}>
                          <Controller
                            name="days"
                            control={control}
                            render={({ field }) => (
                              <Checkbox
                                {...field}
                                checked={
                                  Array.isArray(field.value) &&
                                  field.value.includes(day)
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    field.onChange([
                                      ...(field.value || []),
                                      day,
                                    ]);
                                  } else {
                                    field.onChange(
                                      (field.value || []).filter(
                                        (selectedDay) => selectedDay !== day
                                      )
                                    );
                                  }
                                }}
                              />
                            )}
                          />
                          <label
                            style={{ fontSize: "14px", paddingLeft: "8px" }}
                          >
                            {day}
                          </label>
                        </Box>
                      ))}
                    </Box>

                    {errors.days && (
                      <p className="error-color">{errors.days.message}</p>
                    )}
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box width={"17%"} sx={{ paddingTop: "6px" }}>
                    Start time
                  </Box>
                  <Box sx={{ flex: 1, px: 2 }}>
                    <GlobalDropdown
                      control={control}
                      size="small"
                      name="startTime"
                      label="Hour"
                      options={startTime}
                      error={!!errors.startTime}
                      helperText={errors.startTime?.message}
                      required
                    />
                  </Box>

                  <Box sx={{ flex: 1 }}>
                    <GlobalDropdown
                      control={control}
                      size="small"
                      name="startMinute"
                      label="Minute"
                      options={startMinute}
                      error={!!errors.startMinute}
                      helperText={errors.startMinute?.message}
                      required
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box width={"17%"} sx={{ paddingTop: "6px" }}>
                    End time
                  </Box>
                  <Box sx={{ flex: 1, px: 2 }}>
                    <GlobalDropdown
                      control={control}
                      size="small"
                      name="endTime"
                      label="Hour"
                      options={startTime}
                      error={!!errors.endTime}
                      helperText={errors.endTime?.message}
                      required
                    />
                  </Box>

                  <Box sx={{ flex: 1 }}>
                    <GlobalDropdown
                      control={control}
                      size="small"
                      name="endMinute"
                      label="Minute"
                      options={startMinute}
                      error={!!errors.startMinute}
                      helperText={errors.startMinute?.message}
                      required
                    />
                  </Box>
                </Box>
              </Grid>

              {selectAction.value === "Post" && (
                <>
                  <Grid item xs={6}>
                    <Box>
                      <GlobalInput
                        control={control}
                        size="small"
                        name="soldLeadLimit"
                        label="Sold Leads Limit"
                        error={!!errors.soldLeadLimit}
                        helperText={errors.soldLeadLimit?.message}
                        type="number"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <GlobalInput
                        control={control}
                        size="small"
                        name="sentLeadLimit"
                        label="Sent Leads Limit"
                        error={!!errors.sentLeadLimit}
                        helperText={errors.sentLeadLimit?.message}
                        type="number"
                      />
                    </Box>
                  </Grid>
                  <Box
                    width={"100%"}
                    sx={{ padding: "20px 0px 0px 32px", marginBottom: "-20px" }}
                  >
                    <Typography variant="h6" component="h6">
                      Campaign Shedule
                    </Typography>
                  </Box>
                  <Grid item xs={6}>
                    <Box>
                      <GlobalInput
                        control={control}
                        size="small"
                        name="totalSold"
                        label="Total Sold"
                        error={!!errors.totalSold}
                        helperText={errors.totalSold?.message}
                        type="number"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <GlobalInput
                        control={control}
                        size="small"
                        name="totalSoldInMinutes"
                        label="In Minutes"
                        error={!!errors.totalSoldInMinutes}
                        helperText={errors.totalSoldInMinutes?.message}
                        type="number"
                        inputProps={{
                          onInput: (e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 5);
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <GlobalInput
                        control={control}
                        size="small"
                        name="totalPost"
                        label="Total Post"
                        error={!!errors.totalPost}
                        helperText={errors.totalPost?.message}
                        type="number"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <GlobalInput
                        control={control}
                        size="small"
                        name="totalPostInMinutes"
                        label="In Minutes"
                        error={!!errors.totalPostInMinutes}
                        helperText={errors.totalPostInMinutes?.message}
                        type="number"
                        inputProps={{
                          onInput: (e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 5);
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            // pl: "1rem",
          }}
        >
          <Stack
            direction="row"
            component={Box}
            useFlexGap
            flexWrap="wrap"
            justifyContent={"flex-end"}
            bottom={0}
            marginTop={"1rem"}
          >
            <Box marginBottom={"15px"} marginRight={"5px"}>
              <CustomButton
                color="#3a5396"
                label={isEditMode ? "Update" : "Add"}
                onClick={handleSubmit(onFormSubmit)}
              />
            </Box>
            <Box marginBottom={"15px"}>
              <CustomButton
                labelColor="#7a7870"
                variant="outlined"
                color="white"
                label="Close"
                onClick={() => {
                  setOpen(false);
                  setIsEditMode(false);
                }}
              />
            </Box>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default AddEditSchedulerRule;
