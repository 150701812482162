import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Routes from "./Routes";
import { Provider } from "react-redux";
import store from "./Store";
import { BrowserRouter as Router } from "react-router-dom";
import { createContext } from "react";
import GlobalSnackbar from "./Components/UIComponents/Snackbar";
import { TabProvider } from "./Collections/Tabcontext";
//import ThemeProvider from "./theme";

function App() {
  const ThemeContext = createContext();
  return (
    <Provider store={store}>
      {/* <ThemeProvider> */}
      {/* <HelmetProvider> */}
      <TabProvider>
        <Router>
          <section>
            <GlobalSnackbar />
            <Routes />
          </section>
        </Router>
      </TabProvider>

      {/* </HelmetProvider> */}
      {/* </ThemeProvider> */}
    </Provider>
  );
}

export default App;
