// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   rowData: {},
// };

// const currentFilterRowData = createSlice({
//   name: "currentCampaignFilterRowData",
//   initialState,
//   reducers: {
//     correntFilterRowData: (state, action) => {
//       state.rowData = action.payload;
//     },
//   },
// });

// export const { currentFilterData } = currentFilterRowData.actions;

// export default currentFilterRowData.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterRowData: {},
};

const currentCampaignFilterRowData = createSlice({
  name: "currentCampaignFilterRowData",
  initialState,

  reducers: {
    currentFilterData: (state, action) => {
      state.filterRowData = action.payload;
    },
  },
});

export const { currentFilterData } = currentCampaignFilterRowData.actions;
export default currentCampaignFilterRowData.reducer;
