import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableData: {
    data: [],
    headers: [],
  },
};

const PingTreeSilent = createSlice({
  name: "PingTreeSilent",
  initialState,
  reducers: {
    getTableDataSilent: (state, action) => {
      state.tableData = action.payload;
    },

    addSingleSilentPingTree: (state, action) => {
      state.tableData.data.unshift(action.payload);
    },

    // addSinglePingTree: (state, action) => {
    //   state.tableData.data.unshift(action.payload);
    // },

    updateSingleSilentPingTree: (state, action) => {
      const updatedRow = action.payload;
      const index = state.tableData.data.findIndex((data) => {
        return data?.treeUniqueId === updatedRow?.treeUniqueId;
      });
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.tableData.data[index][key] = updatedRow[key];
      });
    },

    // updateSinglePingTree: (state, action) => {
    //   const updatedRow = action.payload;
    //   const index = state.tableData.data.findIndex((data) => {
    //     return data?.treeUniqueId === updatedRow?.treeUniqueId;
    //   });
    //   const keys = Object.keys(updatedRow);
    //   keys.map((key) => {
    //     state.tableData.data[index][key] = updatedRow[key];
    //   });
    // },

    removeSingleSilentPingTree: (state, action) => {
      state.tableData.data = state.tableData.data.filter(
        (data) => data.treeUniqueId !== action.payload
      );
    },

    // removeSinglePingTree: (state, action) => {
    //   state.tableData.data = state.tableData.data.filter(
    //     (data) => data.treeUniqueId !== action.payload
    //   );
    // },
  },
});

export const {
  getTableDataSilent,
  addSingleSilentPingTree,
  updateSingleSilentPingTree,
  removeSingleSilentPingTree,
  // addSinglePingTree,

  // removeSinglePingTree,
  // addSingleBuyerTags,
  // removeSingleBuyerTags,
} = PingTreeSilent.actions;
export default PingTreeSilent.reducer;
