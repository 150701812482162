import React from 'react'
import AgGridComponent from '../../../../Components/CommonComponents/AG-Grid'

function TrafficSource() {
  return (
    <AgGridComponent
            rowData={[]}
            columnDefs={[]}
            actions={["EDIT"]}
            //performAction={handleActions}
            //totalPageCount={AllbuyersData.data?.data?.total}
            //paginationIndex={paginationIndex}
            //itemPerIndex={itemPerIndex}
            //perPageCountOptions={perPageCountOptions}
            //onPageChange={handlePageChange}
            //onRowsPerPageChange={handleRowsPerPageChange}
            //addAction={setIsOpenAddEditBuyer}
            //AddButtonName={"Add New Buyer"}
            //addActionIcon={<AddOutlined />}
            downlodBtn={true}
            tableName={"Traffic Sources"}
            domLayout="autoHeight"
            height={"100%"}
            rowMode
          />
  )
}

export default TrafficSource