import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { moment } from "../../Collections/Imports";
import {
  Box,
  Card,
  Divider,
  Drawer,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import GlobalInput from "../../Components/CommonComponents/GlobalInput";
import GlobalDropdown from "../../Components/CommonComponents/GlobalDropdown";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import { useDispatch } from "react-redux";
import CustomButton from "../../Components/CommonComponents/GlobalButton";
import {
  channelLabelModuleAPiEndPoints,
  channelModuleApiEndPoints,
  publisherModuleApiEndPoints,
} from "../../Config/APIsEndpoints";
import { useSelector } from "react-redux";
import { allPubData } from "../../Store/PublisherModule";
import { updateSnackBarConfig } from "../../Store/Global/SnackBar";
import { addData, updateData } from "../../Store/ChannelModule";

function AddEditChannel(props) {
  const { setOpen, isOpen, defaultValues, isEditMode, setIsEditMode } = props;
  const dispatch = useDispatch();
  const [channelLabelOptions, setChannelLabelOptions] = useState([]);
  // Api Call Intialization
  const [CreateChannel, createChannelData] = useApiCallMutation();
  const [UpdateChannel, updateChannelData] = useApiCallMutation();
  const pubData = useSelector(
    (state) => state?.publisherData?.data?.allPubData?.publishers
  );
  console.log("pubData---" ,pubData);
  const pubListing = pubData?.map((labels) => {
    return {
      label: labels.publisherName,
      value: labels.pubUniqueId,
      id: labels.id,
    };
  });
  const statusOptions = [
    { label: "Active", value: "true" },
    { label: "Disable", value: "false" },
  ];

  const channelLabels = useSelector((state) => state?.channelLabels?.tableData);

  // Set Initial Value here
  const initialValues = {
    channelName: "",
    marketingMethod: "",
    sourceWebsite: "",
    channelLabel: "",
    pubUniqueId: "",
  };

  // Set Validation Schema here
  const validationSchema = Yup.object().shape({
    channelName: Yup.string()
      .min(2)
      .trim("Channel Name cannot include leading and trailing spaces")
      .max(50)
      .matches(/[A-Za-z]/, "Only numbers are not allowed")
      .required("Channel Name is required"),
    marketingMethod: Yup.string()
      .min(2)
      .max(50)
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .trim("Method cannot include leading and trailing spaces"),
    sourceWebsite: Yup.string()
      .trim("Website cannot include leading and trailing spaces")
      .matches(/[A-Za-z]/, "Only numbers are not allowed")
      .min(2)
      .max(50),
    pubUniqueId: Yup.string().required("Publisher Name is required"),
  });

  const onFormSubmit = (data) => {
    if (isEditMode) {
      const modifiedData = data;
      modifiedData.channelUniqueId = defaultValues?.channelUniqueId;
      UpdateChannel({
        endPoint: channelModuleApiEndPoints.updateChannel,
        method: "POST",
        data: { data: modifiedData },
      });
    } else {
      CreateChannel({
        endPoint: channelModuleApiEndPoints.addChannel,
        method: "POST",
        data: { data },
      });
    }
  };

  const SetFormValues = (data) => {
    setValue("channelName", data?.channelName, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("marketingMethod", data?.marketingMethod, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("sourceWebsite", data?.sourceWebsite, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue(
      "channelLabel",
      data?.channelLabel,

      {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      }
    );
    setValue("pubUniqueId", data?.pubUniqueId, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("status", data?.status == true ? "true" : "false");
  };

  // initialise Form here
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  useEffect(() => {
    setChannelLabelOptions(
      channelLabels.channelLabel.map(({ id, name }) => ({
        value: name,
        label: name,
        id: id,
      }))
    );
  }, [channelLabels]);
  useEffect(() => {
    if (createChannelData?.isSuccess && !createChannelData?.data?.error) {
      dispatch(addData(createChannelData.data?.data));
      dispatch(
        updateSnackBarConfig({
          open: true,
          color: "success",
          message: "Data Added successfully",
        })
      );
      setOpen(false);
      setIsEditMode(false);
    }
  }, [createChannelData]);

  useEffect(() => {
    if (updateChannelData?.isSuccess && !updateChannelData?.data?.error) {
      dispatch(updateData(updateChannelData.data?.data));
      dispatch(
        updateSnackBarConfig({
          open: true,
          color: "success",
          message: "Data Added successfully",
        })
      );
      setOpen(false);
      setIsEditMode(false);
    }
  }, [updateChannelData]);

  useEffect(() => {
    if (Object.keys(defaultValues).length && isEditMode) {
      SetFormValues(defaultValues);
    }
  }, []);
  return (
    <>
      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={() => {
          setOpen(false);
          setIsEditMode(false);
        }}
        sx={{
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            top: "15px",
            borderRadius: "16px",
            width: "100%",
            maxWidth: "500px",
          },
        }}
      >
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent={"space-between"}
          >
            <Box marginBottom={"15px"}>
              <Typography component="h6" variant="h6" fontWeight={600}>
                {(isEditMode && "Update Channel") || "Create New Channel"}
              </Typography>
            </Box>
            <Box marginBottom={"15px"} onClick={() => reset()}>
              <Typography component="h7" variant="h7" fontWeight={200}>
                Clear All
              </Typography>
            </Box>
          </Stack>
          <Divider
            sx={{
              mb: "20px",
            }}
          />
          <Box component={"form"}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <GlobalInput
                  control={control}
                  size="small"
                  name="channelName"
                  label="Channel Name"
                  error={!!errors.channelName}
                  helperText={errors.channelName?.message}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalInput
                  control={control}
                  size="small"
                  name="marketingMethod"
                  label="Marketing Method(SEO/Email/PPC/Social)"
                  error={!!errors.marketingMethod}
                  helperText={errors.marketingMethod?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalInput
                  control={control}
                  size="small"
                  name="sourceWebsite"
                  label="Source Website"
                  error={!!errors.sourceWebsite}
                  helperText={errors.sourceWebsite?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  size="small"
                  name="channelLabel"
                  label="Label"
                  options={channelLabelOptions || []}
                  error={!!errors.channelLabel}
                  helperText={errors.channelLabel?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  size="small"
                  name="pubUniqueId"
                  label="Publisher"
                  options={pubListing || []}
                  error={!!errors.pubUniqueId}
                  helperText={errors.pubUniqueId?.message}
                  required
                />
              </Grid>
              {isEditMode && (
                <Grid item xs={12}>
                  <GlobalDropdown
                    control={control}
                    size="small"
                    name="status"
                    label="Status"
                    options={statusOptions || []}
                    error={!!errors.satus}
                    helperText={errors.status?.message}
                    required
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            // pl: "1rem",
          }}
        >
          <Stack
            direction="row"
            component={Box}
            useFlexGap
            flexWrap="wrap"
            justifyContent={"flex-end"}
            bottom={0}
            marginTop={"1rem"}
          >
            <Box marginBottom={"15px"} marginRight={"5px"}>
              <CustomButton
                color="#3a5396"
                // label="Add Campaign"
                label={isEditMode ? "Update" : "Add"}
                onClick={handleSubmit(onFormSubmit)}
              />
            </Box>
            <Box marginBottom={"15px"}>
              <CustomButton
                labelColor="#7a7870"
                variant="outlined"
                color="white"
                label="Close"
                onClick={() => {
                  setOpen(false);
                  setIsEditMode(false);
                }}
              />
            </Box>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
}

export default AddEditChannel;
