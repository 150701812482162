import React, { useEffect } from "react";
import {
  Box,
  Card,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import GlobalCheckbox from "../../../../Components/CommonComponents/GlobalCheckBox";
import { Controller, useForm } from "react-hook-form";
import GlobalDropdown from "../../../../Components/CommonComponents/GlobalDropdown";
import GlobalInput from "../../../../Components/CommonComponents/GlobalInput";
import GlobalMultiSelect from "../../../../Components/CommonComponents/GlobalMultiSelect";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomButton from "../../../../Components/CommonComponents/GlobalButton";
import { useApiCallMutation } from "../../../../Services/ApiCallService";
import { campaignModuleAPiEndPoints } from "../../../../Config/APIsEndpoints";
import { useDispatch, useSelector } from "react-redux";
import { currentFilterData } from "../../../../Store/CampaignFilter/CampaignFilter";
// import currentCampaignFilterRowData from "../../../../Store/CampaignFilter/CampaignFilter";

const CampaignFilters = () => {
  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&:before, &:after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 22,
        height: 22,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 9,
        color: theme.palette.getContrastText(theme.palette.primary.main),
      },
      "&:before": {
        left: 8,
        content: "'ON'",
      },
      "&:after": {
        right: 8.5,
        content: "'OFF'",
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 20,
      height: 20,
    },
  }));
  const dispatch = useDispatch();

  const CardSX = {
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.08)",
    border: "0.5px solid",
    "&:hover": {
      border: "1px solid #b8c7ff",
      boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.20)",
      padding: 0,
    },
  };

  // initialise Form here
  // const {
  //   handleSubmit,
  //   control,
  //   formState: { errors },
  //   reset,
  //   setValue,
  // } = useForm({
  //   defaultValues: {},
  //   // resolver: yupResolver(validationSchema),
  //   mode: "all",
  // });

  const ageOptions = [
    { value: 18, id: 18, label: "18 years" },
    { value: 19, id: 19, label: "19 years" },
    { value: 20, id: 20, label: "20 years" },
    { value: 21, id: 21, label: "21 years" },
    { value: 22, id: 22, label: "22 years" },
    { value: 23, id: 23, label: "23 years" },
    { value: 24, id: 24, label: "24 years" },
    { value: 25, id: 25, label: "25 years" },
    { value: 26, id: 26, label: "26 years" },
    { value: 27, id: 27, label: "27 years" },
    { value: 28, id: 28, label: "28 years" },
    { value: 29, id: 29, label: "29 years" },
    { value: 30, id: 30, label: "30 years" },
    { value: 31, id: 31, label: "31 years" },
    { value: 32, id: 32, label: "32 years" },
    { value: 33, id: 33, label: "33 years" },
    { value: 34, id: 34, label: "34 years" },
    { value: 35, id: 35, label: "35 years" },
    { value: 36, id: 36, label: "36 years" },
    { value: 37, id: 37, label: "37 years" },
    { value: 38, id: 38, label: "38 years" },
    { value: 39, id: 39, label: "39 years" },
    { value: 40, id: 40, label: "40 years" },
    { value: 41, id: 41, label: "41 years" },
    { value: 42, id: 42, label: "42 years" },
    { value: 43, id: 43, label: "43 years" },
    { value: 44, id: 44, label: "44 years" },
    { value: 45, id: 45, label: "45 years" },
    { value: 46, id: 46, label: "46 years" },
    { value: 47, id: 47, label: "47 years" },
    { value: 48, id: 48, label: "48 years" },
    { value: 49, id: 49, label: "49 years" },
    { value: 50, id: 50, label: "50 years" },
    { value: 51, id: 51, label: "51 years" },
    { value: 52, id: 52, label: "52 years" },
    { value: 53, id: 53, label: "53 years" },
    { value: 54, id: 54, label: "54 years" },
    { value: 55, id: 55, label: "55 years" },
    { value: 56, id: 56, label: "56 years" },
    { value: 57, id: 57, label: "57 years" },
    { value: 58, id: 58, label: "58 years" },
    { value: 59, id: 59, label: "59 years" },
    { value: 60, id: 60, label: "60 years" },
    { value: 61, id: 61, label: "61 years" },
    { value: 62, id: 62, label: "62 years" },
    { value: 63, id: 63, label: "63 years" },
    { value: 64, id: 64, label: "64 years" },
    { value: 65, id: 65, label: "65 years" },
    { value: 66, id: 66, label: "66 years" },
    { value: 67, id: 67, label: "67 years" },
    { value: 68, id: 68, label: "68 years" },
    { value: 69, id: 69, label: "69 years" },
    { value: 70, id: 70, label: "70 years" },
    { value: 71, id: 71, label: "71 years" },
    { value: 72, id: 72, label: "72 years" },
    { value: 73, id: 73, label: "73 years" },
    { value: 74, id: 74, label: "74 years" },
    { value: 75, id: 75, label: "75 years" },
    { value: 76, id: 76, label: "76 years" },
    { value: 77, id: 77, label: "77 years" },
    { value: 78, id: 78, label: "78 years" },
    { value: 79, id: 79, label: "79 years" },
    { value: 80, id: 80, label: "80 years" },
    { value: 81, id: 81, label: "81 years" },
    { value: 82, id: 82, label: "82 years" },
    { value: 83, id: 83, label: "83 years" },
    { value: 84, id: 84, label: "84 years" },
    { value: 85, id: 85, label: "85 years" },
    { value: 86, id: 86, label: "86 years" },
    { value: 87, id: 87, label: "87 years" },
    { value: 88, id: 88, label: "88 years" },
    { value: 89, id: 89, label: "89 years" },
    { value: 90, id: 90, label: "90 years" },
    { value: 91, id: 91, label: "91 years" },
    { value: 92, id: 92, label: "92 years" },
    { value: 93, id: 93, label: "93 years" },
    { value: 94, id: 94, label: "94 years" },
    { value: 95, id: 95, label: "95 years" },
    { value: 96, id: 96, label: "96 years" },
    { value: 97, id: 97, label: "97 years" },
    { value: 98, id: 98, label: "98 years" },
    { value: 99, id: 99, label: "99 years" },
    { value: 100, id: 100, label: "100 years" },
  ];

  const state = [
    { value: "AL", label: "Alabama" },
    { value: "AK", label: "Alaska" },
    { value: "AZ", label: "Arizona" },
    { value: "AR", label: "Arkansas" },
    { value: "CA", label: "California" },
    { value: "CO", label: "Colorado" },
    { value: "CT", label: "Connecticut" },
    { value: "DE", label: "Delaware" },
    { value: "FL", label: "Florida" },
    { value: "GA", label: "Georgia" },
    { value: "HI", label: "Hawaii" },
    { value: "ID", label: "Idaho" },
    { value: "IL", label: "Illinois" },
    { value: "IN", label: "Indiana" },
    { value: "IA", label: "Iowa" },
    { value: "KS", label: "Kansas" },
    { value: "KY", label: "Kentucky" },
    { value: "LA", label: "Louisiana" },
    { value: "ME", label: "Maine" },
    { value: "MD", label: "Maryland" },
    { value: "MA", label: "Massachusetts" },
    { value: "MI", label: "Michigan" },
    { value: "MN", label: "Minnesota" },
    { value: "MS", label: "Mississippi" },
    { value: "MO", label: "Missouri" },
    { value: "MT", label: "Montana" },
    { value: "NE", label: "Nebraska" },
    { value: "NV", label: "Nevada" },
    { value: "NH", label: "New Hampshire" },
    { value: "NJ", label: "New Jersey" },
    { value: "NM", label: "New Mexico" },
    { value: "NY", label: "New York" },
    { value: "NC", label: "North Carolina" },
    { value: "ND", label: "North Dakota" },
    { value: "OH", label: "Ohio" },
    { value: "OK", label: "Oklahoma" },
    { value: "OR", label: "Oregon" },
    { value: "PA", label: "Pennsylvania" },
    { value: "RI", label: "Rhode Island" },
    { value: "SC", label: "South Carolina" },
    { value: "SD", label: "South Dakota" },
    { value: "TN", label: "Tennessee" },
    { value: "TX", label: "Texas" },
    { value: "UT", label: "Utah" },
    { value: "VT", label: "Vermont" },
    { value: "VA", label: "Virginia" },
    { value: "WA", label: "Washington" },
    { value: "WV", label: "West Virginia" },
    { value: "WI", label: "Wisconsin" },
    { value: "WY", label: "Wyoming" },
  ];
  const employedForMax = [
    { value: 1, label: "1 month" },
    { value: 2, label: "2 months" },
    { value: 3, label: "3 months" },
    { value: 4, label: "4 months" },
    { value: 5, label: "5 months" },
    { value: 6, label: "6 months" },
    { value: 7, label: "7 months" },
    { value: 8, label: "8 months" },
    { value: 9, label: "9 months" },
    { value: 10, label: "10 months" },
    { value: 11, label: "11 months" },
    { value: 12, label: "1 year" },
    { value: 18, label: "1.5 years" },
    { value: 24, label: "2 years" },
    { value: 30, label: "2.5 years" },
    { value: 36, label: "3 years" },
    { value: 42, label: "3.5 years" },
    { value: 48, label: "4 years" },
    { value: 54, label: "4.5 years" },
    { value: 60, label: "5 years" },
    { value: 0, label: "No limit" },
  ];
  const Addresslengthmin = [
    { value: 0, label: "0 months" },
    { value: 1, label: "1 month" },
    { value: 2, label: "2 months" },
    { value: 3, label: "3 months" },
    { value: 4, label: "4 months" },
    { value: 5, label: "5 months" },
    { value: 6, label: " 6 months" },
    { value: 7, label: "7 months" },
    { value: 8, label: "8 months" },
    { value: 9, label: "9 months" },
    { value: 10, label: "10 months" },
    { value: 11, label: "11 months" },
    { value: 12, label: "1 year" },
    { value: 18, label: "1.5 years" },
    { value: 24, label: "2 years" },
    { value: 30, label: "2.5 years" },
    { value: 36, label: "3 years" },
    { value: 42, label: "3.5 years" },
    { value: 48, label: "4 years" },
    { value: 54, label: "4.5 years" },
    { value: 60, label: "5 years" },
    { value: 61, label: "5+ years" },
  ];

  const loanReasons = [
    { value: "DEBT_CONSOLIDATION", label: "DEBT_CONSOLIDATION" },
    { value: "EMERGENCY_SITUATION", label: "EMERGENCY_SITUATION" },
    { value: "AUTO_REPAIR", label: "AUTO_REPAIR" },
    { value: "AUTO_OURCHASE", label: "AUTO_OURCHASE" },
    { value: "MOVING", label: "MOVING" },
    { value: "HOME_IMPROVEMENT", label: "HOME_IMPROVEMENT" },
    { value: "MEDICAL", label: "MEDICAL" },
    { value: "BUSINESS", label: "BUSINESS" },
    { value: "VACTION", label: "VACTION" },
    { value: "TAXES", label: "TAXES" },
    { value: "RENT_OR_MORTGAGE", label: "RENT_OR_MORTGAGE" },
    { value: "WEDDING", label: "WEDDING" },
    { value: "MAJOR_PURCHASE", label: "MAJOR_PURCHASE" },
    { value: "STUDENT_LOAN_REFINANCE", label: "STUDENT_LOAN_REFINANCE" },
    { value: "CREDIT_CARD_CONSOLIDATION", label: "CREDIT_CARD_CONSOLIDATION" },
    { value: "OTHER", label: "OTHER" },
  ];

  // Set Initial Value here
  const initialValues = {
    globalCheckbox: "",
    globalCheckbox1: "",
    militaryStatus: "",

    ageStatus: "",
    minAge: "",
    maxAge: "",

    addressLengthStatus: "",
    minAddressLength: "",
    maxAddressLength: "",

    bankABAStatus: "",
    bankABA: [],

    addressStatus: "",
    address: [],

    bankAccountLengthStatus: "",
    minbankAccountLength: "",
    maxbankAccountLength: "",

    bankAccountDigitsStatus: "",
    minbankAccountDigits: "",
    maxbankAccountDigits: "",

    accountTypeStatus: "",
    checking: "",
    saving: "",

    bankAccNoStatus: "",
    bankAccNo: [],

    bankDepositStatus: "",
    deposit: "",
    noDeposit: "",

    employedForStatus: "",
    minEmployedFor: "",
    maxEmployedFor: "",

    employerListExclusionStatus: "",
    employerListExclusion: [],

    workPhoneStatus: "",
    workPhone: "",

    homePhoneStatus: "",
    homePhone: "",

    netIncomeMonthlyStatus: "",
    minNetIncomeMonthly: "",
    maxNetIncomeMonthly: "",

    incomePaymentFrequencyStatus: "",
    incomeWeekly: "",
    incomeBiweekly: "",
    incomeTwiceMonthly: "",
    incomeMonthly: "",

    incomeTypeStatus: "",
    employment: "",
    benefits: "",
    selfEmployment: "",

    jobTitleStatus: "",
    jobTitleName: "",

    loanAmountStatus: "",
    minLoanAmount: "",
    maxLoanAmount: "",

    stateStatus: "",
    stateName: [],

    workCompanyNameStatus: "",
    workCompanyName: [],

    zipStatus: "",
    zip: "",

    rentStatus: "",
    minRent: "",
    maxRent: "",

    contactTimeStatus: "",
    anyTime: "",
    morning: "",
    afternoon: "",
    evening: "",

    loanReasonStatus: "",
    loanReasons: [],

    ownHomeStatus: "",
    home: "",
    noHome: "",

    creditScoreStatus: "",
    excellent: "",
    good: "",
    fair: "",
    poor: "",

    pubSourceStatus: "",
    pubSource: "",

    unSecuredDebtStatus: "",
    securedDebt: "",
    unSecuredDebt: "",

    vehicleStatus: "",
    paid: "",
    payment: "",
    no: "",

    unSecuredDebtAmountStatus: "",
    minunSecuredDebtAmount: "",
    maxunSecuredDebtAmount: "",
  };

  // Set Validation Schema here

  const validationSchema = Yup.object().shape({
    // globalCheckbox: Yup.string().required("This Field is required"),
    // globalCheckbox1: Yup.string().required("This Field is required"),
    // minbankAccountDigits: Yup.string()
    //   .required("This Field is required")
    //   .matches(/^\d+$/, numericErrorMessage),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });
  const [CreatCampaignFilter, creatCampiginFilterData] = useApiCallMutation();
  const [getCampaignFilter, getAllCampaignFilterData] = useApiCallMutation();

  const CurrentRowData = useSelector(
    (state) => state?.currentCampaignFilterRowData.filterRowData
  );

  console.log("212121211212--CurrentRowData", CurrentRowData);
  console.log("212121211212--Create filter", creatCampiginFilterData);
  console.log("212121211212--GetAll Filter", getAllCampaignFilterData);

  //get Campaign Id

  let currentCampaignData = JSON.parse(
    sessionStorage.getItem("currentCampaignData")
  );

  // console.log("Filter datwe998", creatCampiginFilterData);
  const setFormData = (data) => {
    console.log("FORM DATA111%%%%%%%%", data);
    // const data = CurrentRowData

    // Find the "military" filter in the data
    const militaryFilter = data?.find(
      (filter) => filter?.filterName === "military"
    );

    // Extract the status from the military filter, defaulting to false if not found
    const militaryStatus = militaryFilter?.status || false;

    setValue(
      "globalCheckbox",
      militaryFilter?.values[0] === "YES" ? true : false,
      {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      }
    );

    setValue(
      "globalCheckbox1",
      militaryFilter?.values[1] === "NO" ? true : false,
      {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      }
    );

    setValue("militaryStatus", militaryStatus, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the "age" filter in the data
    const ageFilter = data?.find((filter) => filter?.filterName === "age");

    // Extract the status from the age filter, defaulting to false if not found
    const ageStatus = ageFilter?.status || false;

    setValue("ageStatus", ageStatus, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("minAge", ageFilter?.min || "", {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("maxAge", ageFilter?.max || "", {
      shouldTouch: true,
      shouldDirty: true,
    });

    const addressFilter = data?.find(
      (filter) => filter?.filterName === "addressLength"
    );
    console.log("78878788887887887878", addressFilter?.min, addressFilter?.max);

    setValue("addressLengthStatus", addressFilter?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("minAddressLength", addressFilter?.min, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("maxAddressLength", addressFilter?.max, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the object in the 'data' array with 'filterName' equal to 'bankABA'
    const bankABA = data?.find((filter) => filter?.filterName === "bankABA");
    console.log("ABA", String(bankABA?.values));

    setValue("bankABAStatus", bankABA?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("bankABA", String(bankABA?.values), {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the object in the 'data' array with 'filterName' equal to 'bankABA'
    const address = data?.find((filter) => filter?.filterName === "address");
    console.log("ADDERSS", String(address?.values));

    setValue("addressStatus", address?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("address", String(address?.values), {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the object in the 'data' array with 'filterName' equal to 'bankABA'
    const bankAccLength = data?.find(
      (filter) => filter?.filterName === "bankAccountLength"
    );
    console.log("ADDERSS", bankAccLength);

    setValue("bankAccountLengthStatus", bankAccLength?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("minbankAccountLength", bankAccLength?.min, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("maxbankAccountLength", bankAccLength?.max, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the object in the 'data' array with 'filterName' equal to 'bankABA'
    const bankAccNumners = data?.find(
      (filter) => filter?.filterName === "bankAccountDigits"
    );
    console.log("bankAccNumners", bankAccNumners);

    setValue("bankAccountDigitsStatus", bankAccNumners?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("minbankAccountDigits", bankAccNumners?.min, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("maxbankAccountDigits", bankAccNumners?.max, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the "Acc Type" filter in the data
    const AccType = data?.find(
      (filter) => filter.filterName === "bankAccountType"
    );

    setValue("checking", AccType?.values[0] === "CHECKING" ? true : false, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("saving", AccType?.values[1] === "SAVING" ? true : false, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("accountTypeStatus", AccType?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the "Acc Type" filter in the data
    const AccNo = data?.find(
      (filter) => filter?.filterName === "bankAccountNo"
    );

    setValue("bankAccNo", String(AccNo?.values), {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("bankAccNoStatus", AccNo?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the "Acc Direct" filter in the data
    const bankDirect = data?.find(
      (filter) => filter.filterName === "bankDirectDeposit"
    );

    setValue("deposit", bankDirect?.values[0] === "YES" ? true : false, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("noDeposit", bankDirect?.values[1] === "NO" ? true : false, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("bankDepositStatus", bankDirect?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the "Acc Direct" filter in the data
    const employFor = data?.find(
      (filter) => filter?.filterName === "employedFor"
    );

    setValue("minEmployedFor", employFor?.min, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("maxEmployedFor", employFor?.max, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("employedForStatus", employFor?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the "Acc Direct" filter in the data
    const employExcusion = data?.find(
      (filter) => filter?.filterName === "employerListExclusion"
    );

    setValue("employerListExclusion", String(employExcusion?.values), {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("employerListExclusionStatus", employExcusion?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the "Acc Direct" filter in the data
    const workPhone = data?.find(
      (filter) => filter?.filterName === "workPhoneMatch"
    );

    setValue("workPhone", workPhone?.values[0] === "YES" ? true : false, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("workPhoneStatus", workPhone?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the "Acc Direct" filter in the data
    const repetPhone = data?.find(
      (filter) => filter?.filterName === "homePhoneRepeatDigit"
    );

    setValue("homePhone", repetPhone?.values[0] === "YES" ? true : false, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("homePhoneStatus", repetPhone?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the "Acc Direct" filter in the data
    const netIncoms = data?.find(
      (filter) => filter?.filterName === "netIncomeMonthly"
    );

    setValue("minNetIncomeMonthly", netIncoms?.min, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("maxNetIncomeMonthly", netIncoms?.max, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("netIncomeMonthlyStatus", netIncoms?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the "Acc Direct" filter in the data
    const incomePaymentFre = data?.find(
      (filter) => filter?.filterName === "incomePaymentFrequency"
    );
    setValue("incomePaymentFrequencyStatus", incomePaymentFre?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue(
      "incomeWeekly",
      incomePaymentFre?.values[0] === "WEEKLY" ? true : false,
      {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      }
    );

    setValue(
      "incomeBiweekly",
      incomePaymentFre?.values[1] === "BIWEEKLY" ? true : false,
      {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      }
    );
    setValue(
      "incomeTwiceMonthly",
      incomePaymentFre?.values[2] === "TWICEMONTHLY" ? true : false,
      {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      }
    );
    setValue(
      "incomeMonthly",
      incomePaymentFre?.values[3] === "MONTHLY" ? true : false,
      {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      }
    );

    // Find the "Acc Direct" filter in the data
    const incomeTypes = data?.find(
      (filter) => filter?.filterName === "incomeType"
    );
    setValue("incomeTypeStatus", incomeTypes?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue(
      "employment",
      incomeTypes?.values[0] === "EMPLOYMENT" ? true : false,
      {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      }
    );

    setValue("benefits", incomeTypes?.values[1] === "BENEFITS" ? true : false, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue(
      "selfEmployment",
      incomeTypes?.values[2] === "SELF_EMPLOYMENT" ? true : false,
      {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      }
    );

    // Find the "Acc Direct" filter in the data
    const jobTitles = data?.find((filter) => filter?.filterName === "jobTitle");

    setValue("jobTitleName", String(jobTitles?.values), {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("jobTitleStatus", jobTitles?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the "Acc Direct" filter in the data
    const loanAmounts = data?.find(
      (filter) => filter?.filterName === "loanAmount"
    );

    setValue("minLoanAmount", loanAmounts?.min, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("maxLoanAmount", loanAmounts?.max, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("loanAmountStatus", loanAmounts?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the "Acc Direct" filter in the data
    const states = data?.find((filter) => filter?.filterName === "state");

    setValue("stateName", states?.values, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("stateStatus", states?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the "Acc Direct" filter in the data
    const copmanyName = data?.find(
      (filter) => filter?.filterName === "workCompanyName"
    );

    setValue("workCompanyName", String(copmanyName?.values), {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("workCompanyNameStatus", copmanyName?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the "Acc Direct" filter in the data
    const zipCode = data?.find((filter) => filter?.filterName === "zip");

    setValue("zip", String(zipCode?.values), {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("zipStatus", copmanyName?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the "Acc Direct" filter in the data
    const rentPay = data?.find((filter) => filter?.filterName === "rent");

    setValue("minRent", rentPay?.min, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("maxRent", rentPay?.max, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("rentStatus", rentPay?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    // Find the "Acc Direct" filter in the data
    const contactTimes = data?.find(
      (filter) => filter?.filterName === "contactTime"
    );
    setValue("contactTimeStatus", contactTimes?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("anyTime", contactTimes?.values[0] === "ANYTIME" ? true : false, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("morning", contactTimes?.values[1] === "MORNING" ? true : false, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue(
      "afternoon",
      contactTimes?.values[2] === "AFTERNOON" ? true : false,
      {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      }
    );

    setValue("evening", contactTimes?.values[3] === "EVENING" ? true : false, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the "Acc Direct" filter in the data
    const resons = data?.find((filter) => filter?.filterName === "loanReasons");

    setValue("loanReasons", resons?.values, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("loanReasonStatus", resons?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the "Acc Direct" filter in the data
    const home = data?.find((filter) => filter?.filterName === "ownHome");

    setValue("home", home?.values[0] === "YES" ? true : false, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("noHome", home?.values[1] === "NO" ? true : false, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("ownHomeStatus", home.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the "Acc Direct" filter in the data
    const credit = data?.find((filter) => filter?.filterName === "creditScore");

    setValue("excellent", credit?.values[0] === "EXCELLENT" ? true : false, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("good", credit?.values[1] === "GOOD" ? true : false, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("fair", credit?.values[2] === "FAIR" ? true : false, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("poor", credit?.values[3] === "POOR" ? true : false, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("creditScoreStatus", credit?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the "Acc Direct" filter in the data
    const pubSources = data?.find(
      (filter) => filter?.filterName === "pubSources"
    );

    setValue("pubSource", pubSources?.values, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("pubSourceStatus", pubSources?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the "Acc Direct" filter in the data
    const unSecureDebt = data?.find(
      (filter) => filter.filterName === "unsecuredDebt"
    );

    setValue("securedDebt", unSecureDebt?.values[0] === "YES" ? true : false, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("unSecuredDebt", unSecureDebt?.values[1] === "NO" ? true : false, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("unSecuredDebtStatus", unSecureDebt?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the "Acc Direct" filter in the data
    const unSecureAmount = data?.find(
      (filter) => filter?.filterName === "unsecuredDebtAmount"
    );

    setValue("minunSecuredDebtAmount", unSecureAmount?.min, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("maxunSecuredDebtAmount", unSecureAmount?.max, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("unSecuredDebtAmountStatus", unSecureAmount?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    // Find the "Acc Direct" filter in the data
    const VehicleStatus = data?.find(
      (filter) => filter?.filterName === "vehicleStatus"
    );

    setValue("paid", VehicleStatus?.values[0] === "PAID" ? true : false, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("payment", VehicleStatus?.values[1] === "PAYMENT" ? true : false, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("no", VehicleStatus?.values[2] === "NO" ? true : false, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue("vehicleStatus", VehicleStatus?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  // useEffect(() => {
  //   setFormData(CurrentRowData);
  // }, []);
  // api call to get campaign data
  useEffect(() => {
    getCampaignFilter({
      endPoint: campaignModuleAPiEndPoints.getCampaignFilter,
      method: "POST",
      data: {
        data: {
          campaignId: currentCampaignData?.campaignId,
        },
      },
    });
  }, []);

  // update form with api data
  useEffect(() => {
    if (
      getAllCampaignFilterData?.isSuccess &&
      !getAllCampaignFilterData?.data?.error
    ) {
      setFormData(getAllCampaignFilterData?.data?.data);
      dispatch(currentFilterData(getAllCampaignFilterData?.data?.data));
    }
  }, [getAllCampaignFilterData]);

  const onFormSubmit = async (obj) => {
    const {
      globalCheckbox,
      globalCheckbox1,
      militaryStatus,
      minAge,
      maxAge,
      ageStatus,
      addressLengthStatus,
      minAddressLength,
      maxAddressLength,
      bankABAStatus,
      bankABA,
      addressStatus,
      address,
      bankAccountLengthStatus,
      minbankAccountLength,
      maxbankAccountLength,
      bankAccountDigitsStatus,
      minbankAccountDigits,
      maxbankAccountDigits,
      accountTypeStatus,
      checking,
      saving,
      bankAccNoStatus,
      bankAccNo,
      bankDepositStatus,
      deposit,
      noDeposit,
      employedForStatus,
      minEmployedFor,
      maxEmployedFor,
      employerListExclusionStatus,
      employerListExclusion,
      workPhoneStatus,
      workPhone,
      homePhoneStatus,
      homePhone,
      netIncomeMonthlyStatus,
      minNetIncomeMonthly,
      maxNetIncomeMonthly,
      incomePaymentFrequencyStatus,
      incomeWeekly,
      incomeBiweekly,
      incomeTwiceMonthly,
      incomeMonthly,
      incomeTypeStatus,
      employment,
      benefits,
      selfEmployment,
      jobTitleStatus,
      jobTitleName,
      loanAmountStatus,
      minLoanAmount,
      maxLoanAmount,
      stateStatus,
      stateName,
      workCompanyNameStatus,
      workCompanyName,
      zipStatus,
      zip,
      rentStatus,
      minRent,
      maxRent,
      contactTimeStatus,
      anyTime,
      morning,
      afternoon,
      evening,
      loanReasonStatus,
      loanReasons,
      ownHomeStatus,
      home,
      noHome,
      creditScoreStatus,
      excellent,
      good,
      fair,
      poor,
      pubSourceStatus,
      pubSource,
      unSecuredDebtStatus,
      securedDebt,
      unSecuredDebt,

      vehicleStatus,
      paid,
      payment,
      no,

      unSecuredDebtAmountStatus,
      minunSecuredDebtAmount,
      maxunSecuredDebtAmount,
    } = obj;

    // const bankAbaNumbers =
    //   typeof bankABA === "string"
    //     ? bankABA
    //         .split(",")
    //         .map(Number)
    //         .filter((num) => !isNaN(num) && num !== 0)
    //     : [];

    //militaryFilters
    const militaryFilters = [
      {
        filterName: "military",
        values: [globalCheckbox && "YES", globalCheckbox1 && "NO"].filter(
          Boolean
        ),
        status: militaryStatus,
      },
      {
        filterName: "age",
        status: ageStatus,
        min: minAge,
        max: maxAge,
      },
      {
        filterName: "addressLength",
        status: addressLengthStatus,
        min: minAddressLength,
        max: maxAddressLength,
      },
      {
        filterName: "bankABA",
        status: bankABAStatus,
        values:
          typeof bankABA === "string"
            ? bankABA.split(",").filter((item) => item.trim() !== "")
            : [],
      },
      {
        filterName: "address",
        status: addressStatus,

        values:
          typeof address === "string"
            ? address.split(",").filter((item) => item.trim() !== "")
            : [],
      },
      {
        filterName: "bankAccountLength",
        status: bankAccountLengthStatus,
        min: minbankAccountLength,
        max: maxbankAccountLength,
      },
      {
        filterName: "bankAccountDigits",
        status: bankAccountDigitsStatus,
        min: minbankAccountDigits,
        max: maxbankAccountDigits,
      },
      {
        filterName: "bankAccountType",
        values: [checking && "CHECKING", saving && "SAVING"].filter(Boolean),
        status: accountTypeStatus,
      },
      {
        filterName: "bankAccountNo",
        values:
          typeof bankAccNo === "string"
            ? bankAccNo.split(",").filter((item) => item.trim() !== "")
            : [],
        // values: [bankAccNo],
        status: bankAccNoStatus,
      },
      {
        filterName: "bankDirectDeposit",
        values: [deposit && "YES", noDeposit && "NO"].filter(Boolean),
        status: bankDepositStatus,
      },
      {
        filterName: "employedFor",
        min: minEmployedFor,
        max: maxEmployedFor,
        status: employedForStatus,
      },
      {
        filterName: "employerListExclusion",
        values:
          typeof employerListExclusion === "string"
            ? employerListExclusion
                .split(",")
                .filter((item) => item.trim() !== "")
            : [],
        status: employerListExclusionStatus,
      },
      {
        filterName: "workPhoneMatch",
        values: [workPhone && "YES"].filter(Boolean),
        status: workPhoneStatus,
      },
      {
        filterName: "homePhoneRepeatDigit",
        values: [homePhone && "YES"].filter(Boolean),
        status: homePhoneStatus,
      },
      {
        filterName: "netIncomeMonthly",
        min: minNetIncomeMonthly,
        max: maxNetIncomeMonthly,
        status: netIncomeMonthlyStatus,
      },
      {
        filterName: "incomePaymentFrequency",
        values: [
          incomeWeekly && "WEEKLY",
          incomeBiweekly && "BIWEEKLY",
          incomeTwiceMonthly && "TWICEMONTHLY",
          incomeMonthly && "MONTHLY",
        ].filter(Boolean),
        status: incomePaymentFrequencyStatus,
      },
      {
        filterName: "incomeType",
        values: [
          employment && "EMPLOYMENT",
          benefits && "BENEFITS",
          selfEmployment && "SELF_EMPLOYMENT",
        ].filter(Boolean),
        status: incomeTypeStatus,
      },
      {
        filterName: "jobTitle",
        status: jobTitleStatus,
        // values: [jobTitleName],
        values:
          typeof jobTitleName === "string"
            ? jobTitleName.split(",").filter((item) => item.trim() !== "")
            : [],
      },
      {
        filterName: "loanAmount",
        status: loanAmountStatus,
        min: minLoanAmount,
        max: maxLoanAmount,
      },
      {
        filterName: "state",
        status: stateStatus,
        values: stateName,
      },
      {
        filterName: "workCompanyName",
        status: workCompanyNameStatus,
        // values: [workCompanyName],
        values:
          typeof workCompanyName === "string"
            ? workCompanyName.split(",").filter((item) => item.trim() !== "")
            : [],
      },
      {
        filterName: "zip",
        status: zipStatus,
        values:
          typeof zip === "string"
            ? zip.split(",").filter((item) => item.trim() !== "")
            : [],
      },

      {
        filterName: "rent",
        status: rentStatus,
        min: minRent,
        max: maxRent,
      },
      {
        filterName: "contactTime",
        values: [
          anyTime && "ANYTIME",
          morning && "MORNING",
          afternoon && "AFTERNOON",
          evening && "EVENING",
        ].filter(Boolean),
        status: contactTimeStatus,
      },
      {
        filterName: "loanReasons",
        status: loanReasonStatus,
        values: loanReasons,
      },
      {
        filterName: "ownHome",
        values: [home && "YES", noHome && "NO"].filter(Boolean),
        status: ownHomeStatus,
      },
      {
        filterName: "creditScore",
        values: [
          excellent && "EXCELLENT",
          good && "GOOD",
          fair && "FAIR",
          poor && "POOR",
        ].filter(Boolean),
        status: creditScoreStatus,
      },
      {
        filterName: "pubSources",
        status: pubSourceStatus,
        values:
          typeof pubSource === "string"
            ? pubSource.split(",").filter((item) => item.trim() !== "")
            : [],
        // values: [pubSource],
      },
      {
        filterName: "unsecuredDebt",
        values: [securedDebt && "YES", unSecuredDebt && "NO"].filter(Boolean),
        status: unSecuredDebtStatus,
      },
      {
        filterName: "unsecuredDebtAmount",
        min: minunSecuredDebtAmount,
        max: maxunSecuredDebtAmount,
        status: unSecuredDebtAmountStatus,
      },
      {
        filterName: "vehicleStatus",
        values: [paid && "PAID", payment && "PAYMENT", no && "NO"].filter(
          Boolean
        ),
        status: vehicleStatus,
      },
    ];

    // AgeFilters

    const data = {
      campaignId: currentCampaignData?.campaignId,
      campaignFilters: militaryFilters,
    };

    CreatCampaignFilter({
      endPoint: campaignModuleAPiEndPoints.addCampaignFilter,
      method: "POST",
      data: { data },
    });

    // Add any additional processing or API calls as needed
    // reset();
  };

  return (
    <Box component={"form"}>
      <Card>
        <Grid padding={1} container spacing={4}>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography> Active Military </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="militaryStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch
                              {...field}
                              checked={field.value} // Make sure to set the 'checked' prop based on the field value
                            />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      name="globalCheckbox"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="Yes"
                        />
                      )}
                    />
                    <Controller
                      name="globalCheckbox1"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="No"
                        />
                      )}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography> Age </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="ageStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch
                              {...field}
                              checked={field.value} // Make sure to set the 'checked' prop based on the field value
                            />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Stack direction="row" spacing={4} sx={{ width: "100%" }}>
                      <GlobalDropdown
                        control={control}
                        name="minAge"
                        label="Min"
                        options={ageOptions}
                        error={!!errors.minAge}
                        helperText={errors.minAge?.message}
                      />
                      <GlobalDropdown
                        control={control}
                        name="maxAge"
                        label="Max"
                        options={ageOptions}
                        error={!!errors.maxAge}
                        helperText={errors.maxAge?.message}
                      />
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography>Address Length Months </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="addressLengthStatus"
                      control={control}
                      //
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch
                              {...field}
                              checked={field.value} // Make sure to set the 'checked' prop based on the field value
                            />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <Stack direction="row" spacing={4}>
                      <GlobalDropdown
                        control={control}
                        size="small"
                        name="minAddressLength"
                        label="Min"
                        options={Addresslengthmin}
                        error={!!errors.minAddressLength}
                        helperText={errors.minAddressLength?.message}
                      />
                      <GlobalDropdown
                        control={control}
                        size="small"
                        name="maxAddressLength"
                        label="Max"
                        options={Addresslengthmin}
                        error={!!errors.maxAddressLength}
                        helperText={errors.maxAddressLength?.message}
                      />
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography> Bank ABA </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="bankABAStatus"
                      control={control}
                      //
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <GlobalInput
                      control={control}
                      size="small"
                      name="bankABA"
                      multiline
                      rows={3}
                      label=""
                      error={!!errors.bankABA}
                      helperText={errors.bankABA?.message}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography> Address </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="addressStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <GlobalInput
                      control={control}
                      size="small"
                      name="address"
                      multiline
                      rows={3}
                      label=""
                      error={!!errors.address}
                      helperText={errors.address?.message}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography> Bank Account Length Months </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="bankAccountLengthStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <Stack direction="row" spacing={4}>
                      <GlobalDropdown
                        control={control}
                        size="small"
                        name="minbankAccountLength"
                        label="Min"
                        options={employedForMax}
                        error={!!errors.minbankAccountLength}
                        helperText={errors.minbankAccountLength?.message}
                      />
                      <GlobalDropdown
                        control={control}
                        size="small"
                        name="maxbankAccountLength"
                        label="Max"
                        options={employedForMax}
                        error={!!errors.maxbankAccountLength}
                        helperText={errors.maxbankAccountLength?.message}
                      />
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography>Bank Account Number Digits</Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="bankAccountDigitsStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <Stack direction="row" spacing={4}>
                      <GlobalInput
                        control={control}
                        size="small"
                        name="minbankAccountDigits"
                        label="Min"
                        type={"number"}
                        error={!!errors.minbankAccountDigits}
                        helperText={errors.minbankAccountDigits?.message}
                      />
                      <GlobalInput
                        control={control}
                        size="small"
                        name="maxbankAccountDigits"
                        type={"number"}
                        label="Max"
                        error={!!errors.maxbankAccountDigits}
                        helperText={errors.maxbankAccountDigits?.message}
                      />
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography>Bank Account Type </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="accountTypeStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      name="checking"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="Checking"
                        />
                      )}
                    />
                    <Controller
                      name="saving"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="Saving"
                        />
                      )}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography>Bank Account Number </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="bankAccNoStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <GlobalInput
                      control={control}
                      size="small"
                      name="bankAccNo"
                      multiline
                      rows={3}
                      label=""
                      error={!!errors.bankAccNo}
                      helperText={errors.bankAccNo?.message}
                      inputProps={{
                        pattern: "[0-9]*", // Allow only numeric input
                      }}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography>Bank Direct Deposit </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="bankDepositStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      name="deposit"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="Yes"
                        />
                      )}
                    />
                    <Controller
                      name="noDeposit"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="No"
                        />
                      )}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography> Employed For </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="employedForStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <Stack direction="row" spacing={4}>
                      <GlobalDropdown
                        control={control}
                        size="small"
                        name="minEmployedFor"
                        label="Min"
                        options={employedForMax}
                        error={!!errors.minEmployedFor}
                        helperText={errors.minEmployedFor?.message}
                      />
                      <GlobalDropdown
                        control={control}
                        size="small"
                        name="maxEmployedFor"
                        label="Max"
                        options={employedForMax}
                        error={!!errors.maxEmployedFor}
                        helperText={errors.maxEmployedFor?.message}
                      />
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography>Employer List Exclusion</Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="employerListExclusionStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <GlobalInput
                      control={control}
                      size="small"
                      name="employerListExclusion"
                      multiline
                      rows={3}
                      label=""
                      error={!!errors.employerListExclusion}
                      helperText={errors.employerListExclusion?.message}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography>
                  Filter Out Matching work and cell phone Number [Personal Loan
                  US]
                </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="workPhoneStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      name="workPhone"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="Yes"
                        />
                      )}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography>
                  Filter out if 7 or more repeating digits whithin a home phone
                  number
                </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="homePhoneStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      name="homePhone"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="Yes"
                        />
                      )}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography>Income Net Monthly </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="netIncomeMonthlyStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <Stack direction="row" spacing={4}>
                      <GlobalInput
                        control={control}
                        size="small"
                        name="minNetIncomeMonthly"
                        type={"number"}
                        label="Min"
                        error={!!errors.minLoanAmount}
                        helperText={errors.minLoanAmount?.message}
                      />
                      <GlobalInput
                        control={control}
                        size="small"
                        name="maxNetIncomeMonthly"
                        type={"number"}
                        label="Max"
                        error={!!errors.maxLoanAmount}
                        helperText={errors.maxLoanAmount?.message}
                      />
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography>Income Payment Frequency</Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="incomePaymentFrequencyStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      name="incomeWeekly"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="Weekly"
                        />
                      )}
                    />
                    <Controller
                      name="incomeBiweekly"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="Biweekly"
                        />
                      )}
                    />
                    <Controller
                      name="incomeTwiceMonthly"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="TwiceMonthly"
                        />
                      )}
                    />
                    <Controller
                      name="incomeMonthly"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="Monthly"
                        />
                      )}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography>Income Type </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="incomeTypeStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      name="employment"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="Employment"
                        />
                      )}
                    />
                    <Controller
                      name="benefits"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="Benefits"
                        />
                      )}
                    />
                    <Controller
                      name="selfEmployment"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="Self Employment(Not Recommended)"
                        />
                      )}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography> Job Title </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="jobTitleStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <GlobalInput
                      control={control}
                      size="small"
                      name="jobTitleName"
                      label=""
                      error={!!errors.jobTitleName}
                      helperText={errors.jobTitleName?.message}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography> Loan Amount </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="loanAmountStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <Stack direction="row" spacing={4}>
                      <GlobalInput
                        control={control}
                        size="small"
                        name="minLoanAmount"
                        type={"number"}
                        label="Min"
                        options={[]}
                        error={!!errors.minLoanAmount}
                        helperText={errors.minLoanAmount?.message}
                      />
                      <GlobalInput
                        control={control}
                        size="small"
                        name="maxLoanAmount"
                        type={"number"}
                        label="Max"
                        options={[]}
                        error={!!errors.maxLoanAmount}
                        helperText={errors.maxLoanAmount?.message}
                      />
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography>State </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="stateStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <GlobalMultiSelect
                      control={control}
                      size="small"
                      name="stateName"
                      label=""
                      options={state}
                      error={!!errors.stateName}
                      helperText={errors.stateName?.message}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography> Work Company Name </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="workCompanyNameStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <GlobalInput
                      control={control}
                      size="small"
                      name="workCompanyName"
                      label="Work Company"
                      error={!!errors.workCompanyName}
                      helperText={errors.workCompanyName?.message}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography> ZIP </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="zipStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <GlobalInput
                      control={control}
                      size="small"
                      name="zip"
                      label="zip"
                      error={!!errors.zip}
                      helperText={errors.zip?.message}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography> Rent or Mortgage Payment </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="rentStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <Stack direction="row" spacing={4}>
                      <GlobalInput
                        control={control}
                        size="small"
                        name="minRent"
                        type={"number"}
                        label="Min"
                        error={!!errors.minRent}
                        helperText={errors.minRent?.message}
                      />
                      <GlobalInput
                        control={control}
                        size="small"
                        type={"number"}
                        name="maxRent"
                        label="Max"
                        error={!!errors.maxRent}
                        helperText={errors.maxRent?.message}
                      />
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography>Contact Time </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="contactTimeStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      name="anyTime"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="Any Time"
                        />
                      )}
                    />
                    <Controller
                      name="morning"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="Morning"
                        />
                      )}
                    />
                    <Controller
                      name="afternoon"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="Afternoon"
                        />
                      )}
                    />
                    <Controller
                      name="evening"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="Evening"
                        />
                      )}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography>Loan Reasons </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="loanReasonStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <GlobalMultiSelect
                      control={control}
                      size="small"
                      name="loanReasons"
                      label=""
                      options={loanReasons}
                      error={!!errors.loanReasons}
                      helperText={errors.loanReasons?.message}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography>Own Home </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="ownHomeStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      name="home"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="Yes"
                        />
                      )}
                    />
                    <Controller
                      name="noHome"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="No"
                        />
                      )}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography>Credit Score </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="creditScoreStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      name="excellent"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="EXCELLENT"
                        />
                      )}
                    />
                    <Controller
                      name="good"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="GOOD"
                        />
                      )}
                    />
                    <Controller
                      name="fair"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="FAIR"
                        />
                      )}
                    />
                    <Controller
                      name="poor"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="POOR"
                        />
                      )}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography> Pub Sources </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="pubSourceStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <GlobalInput
                      control={control}
                      size="small"
                      name="pubSource"
                      label="Source"
                      error={!!errors.banckABA}
                      helperText={errors.banckABA?.message}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography>Unsecured Debt </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="unSecuredDebtStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      name="securedDebt"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="Yes"
                        />
                      )}
                    />
                    <Controller
                      name="unSecuredDebt"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="No"
                        />
                      )}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography>Unsecured Debt Amount</Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="unSecuredDebtAmountStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <Stack direction="row" spacing={4}>
                      <GlobalInput
                        control={control}
                        size="small"
                        name="minunSecuredDebtAmount"
                        type={"number"}
                        label="Min"
                        error={!!errors.minLoanAmount}
                        helperText={errors.minLoanAmount?.message}
                      />
                      <GlobalInput
                        control={control}
                        size="small"
                        name="maxunSecuredDebtAmount"
                        type={"number"}
                        label="Max"
                        error={!!errors.maxLoanAmount}
                        helperText={errors.maxLoanAmount?.message}
                      />
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={CardSX}>
              <Box marginTop={2} marginLeft={3}>
                <Typography>Vehicle Status </Typography>
              </Box>
              <Box
                sx={{
                  padding: "2rem",
                }}
              >
                <Stack direction="row" spacing={10}>
                  <Box>
                    <Controller
                      name="vehicleStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Android12Switch {...field} checked={field.value} />
                          }
                          label="Status"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      name="paid"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="PAID"
                        />
                      )}
                    />
                    <Controller
                      name="payment"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="PAYMENT"
                        />
                      )}
                    />
                    <Controller
                      name="no"
                      control={control}
                      render={({ field }) => (
                        <GlobalCheckbox
                          name={field.name}
                          control={control}
                          label="NO"
                        />
                      )}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
            <Box marginBottom={"15px"} marginRight={"5px"}>
              <CustomButton
                color="#3a5396"
                label={"ADD"}
                // label={isEditMode ? "Update" : "Add"}
                onClick={handleSubmit(onFormSubmit)}
              />
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default CampaignFilters;
