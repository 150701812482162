import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterData: {
    fromDate:"2020-06-30T18:30:00.000Z",
  },
};

const buyersLabelDataFilterSession = createSlice({
  name: "buyersLabelDataFilterSession",
  initialState,
  reducers: {
    updateBuyersLabelFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
});

export const { updateBuyersLabelFilterData } = buyersLabelDataFilterSession.actions;
export default buyersLabelDataFilterSession.reducer;
