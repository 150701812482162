import { Box, Divider, Drawer, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import GlobalInput from "../../Components/CommonComponents/GlobalInput";
import GlobalDropdown from "../../Components/CommonComponents/GlobalDropdown";
import CustomButton from "../../Components/CommonComponents/GlobalButton";
import { useDispatch } from "react-redux";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import {
  addSinglePublisherLabelData,
  updateSinglePublisherLabelData,
} from "../../Store/PublisherLabelModule";
import { updateLabelOptions } from "../../Store/Global/LabelOptions";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  coreAPIEndsPoints,
  publisherLabelApiEndPoint,
} from "../../Config/APIsEndpoints";

const AddEditPublisherLabel = (props) => {
  const { setOpen, isOpen, defaultValues, isEditMode, setIsEditMode } = props;
  const dispatch = useDispatch();
  const [labelDropdown, setLabelDropdown] = useState([]);

  const [CreatPublisherLabel, creatPublisherLabelData] = useApiCallMutation();
  const [UpdatePublisherLabel, UpdatePublisherLabelData] = useApiCallMutation();

  //   const getLabelOptions = useGetApiCallQuery({
  //     endPoint: coreAPIEndsPoints.getLabel,
  //     method: "GET",
  //   });

  // Set Forms Value
  const SetFormValues = (data) => {
    if (setValue) {
      setValue("name", data?.name || "", {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue("badge", data?.badge || "", {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  //Set Incoming Default values
  useEffect(() => {
    if (Object.keys(defaultValues).length && isEditMode) {
      SetFormValues(defaultValues);
    }
  }, []);

  useEffect(() => {
    if (
      creatPublisherLabelData?.isSuccess &&
      !creatPublisherLabelData?.data?.error
    ) {
      dispatch(addSinglePublisherLabelData(creatPublisherLabelData.data?.data));
      setOpen(false);
      setIsEditMode(false);
    }
  }, [creatPublisherLabelData]);

  useEffect(() => {
    if (
      UpdatePublisherLabelData?.isSuccess &&
      !UpdatePublisherLabelData?.data?.error
    ) {
      console.log(UpdatePublisherLabelData.data);
      dispatch(
        updateSinglePublisherLabelData(UpdatePublisherLabelData.data?.data)
      );
      setOpen(false);
      setIsEditMode(false);
    }
  }, [UpdatePublisherLabelData]);

  // Set Initial Value here
  const initialValues = {
    name: "",
    badge: "",
  };

  // Set Validation Schema here
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2)
      .trim("Name cannot include leading and trailing spaces")
      .max(50)
      .matches(/[A-Za-z]/, "Only numbers are not allowed")
      .required("campaignTag Name is required"),
    badge: Yup.string().required("Badge is required"),
  });

  // initialise Form here
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const onFormSubmit = (data) => {
    if (isEditMode) {
      const modifiedData = data;
      modifiedData.id = defaultValues?.id;

      UpdatePublisherLabel({
        endPoint: publisherLabelApiEndPoint.updatePublisherLabel,
        method: "POST",
        data: {
          data: { ...data, id: defaultValues?.id },
        },
      });
    } else {
      CreatPublisherLabel({
        endPoint: publisherLabelApiEndPoint.createPublisherLabel,
        method: "POST",
        data: { data },
      });
    }
  };

  return (
    <>
      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={() => {
          setOpen(false);
          setIsEditMode(false);
        }}
        sx={{
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            top: "15px",
            borderRadius: "16px",
            width: "100%",
            maxWidth: "500px",
          },
        }}
      >
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent={"space-between"}
          >
            <Box marginBottom={"15px"}>
              <Typography component="h6" variant="h6" fontWeight={600}>
                {(isEditMode && "Update Publisher Label") ||
                  "Add Publisher Label"}
              </Typography>
            </Box>
            <Box marginBottom={"15px"} onClick={() => reset()}>
              <Typography component="h7" variant="h7" fontWeight={200}>
                Clear All
              </Typography>
            </Box>
          </Stack>

          <Divider
            sx={{
              mb: "20px",
            }}
          />

          <Box component={"form"}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <GlobalInput
                  control={control}
                  size="small"
                  name="name"
                  label="Name"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  name="badge"
                  label="Badge"
                  options={[
                    { label: "Primary", value: "Primary" },
                    { label: "Info", value: "Info" },
                    { label: "Warning", value: "Warning" },
                    { label: "Error", value: "Error" },
                  ]}
                  error={!!errors.badge}
                  helperText={errors.badge?.message}
                  required
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            // pl: "1rem",
          }}
        >
          <Stack
            direction="row"
            component={Box}
            useFlexGap
            flexWrap="wrap"
            justifyContent={"flex-end"}
            bottom={0}
            marginTop={"1rem"}
          >
            <Box marginBottom={"15px"} marginRight={"5px"}>
              <CustomButton
                color="#3a5396"
                // label="Add Campaign"
                label={isEditMode ? "Update" : "Add"}
                onClick={handleSubmit(onFormSubmit)}
              />
            </Box>
            <Box marginBottom={"15px"}>
              <CustomButton
                labelColor="#7a7870"
                variant="outlined"
                color="white"
                label="Close"
                onClick={() => {
                  setOpen(false);
                  setIsEditMode(false);
                }}
              />
            </Box>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default AddEditPublisherLabel;
