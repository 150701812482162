import React, { useEffect, useState } from "react";
import { Box } from "../../../../Collections/Imports";
import { useApiCallMutation } from "../../../../Services/ApiCallService";
import { useDispatch, useSelector } from "react-redux";
import { leadDetailsModuleApiEndPoints } from "../../../../Config/APIsEndpoints";
import { InfoBody } from "../../../../Store/LeadDetailsModule/LeadInfoBody";
import AgGridComponent from "../../../../Components/CommonComponents/AG-Grid";
import { AddOutlined } from "@mui/icons-material";
import "../../../../Components/CommonComponents/AG-Grid/agGrid.css";
const LeadBody = () => {
  let currentLeadInfo = JSON.parse(sessionStorage.getItem("currentLeadData"));
  const [getLeadInfoBody, { data: leadInfoData }] = useApiCallMutation();
  const [rowData, setRowData] = useState([]);
  const dispatch = useDispatch();
  const rowStyle = { background: "" };
  const tableData = useSelector(
    (state) => state.LeadInfoBody?.data?.InfoBody?.leadInfo
  );
  useEffect(() => {
    getLeadInfoBody({
      endPoint: leadDetailsModuleApiEndPoints.getAllLeadDetailsById,
      method: "POST",
      data: {
        data: {
          leadId: currentLeadInfo.leadId,
        },
      },
    });
  }, [currentLeadInfo.leadId, getLeadInfoBody]);

  useEffect(() => {
    if (leadInfoData?.data?.leadInfo) {
      dispatch(InfoBody(leadInfoData?.data));
    }
  }, [leadInfoData, dispatch]);

  useEffect(() => {
    if (tableData) {
      setRowData(
        Object.entries(tableData).map(([key, value]) => ({
          field: key,
          value: value,
        }))
      );
    }
  }, [tableData]);

  const columnDefs = [
    {
      headerName: "Field",
      field: "field",
      filter: true,
      cellStyle: { textAlign: "left" },
      headerClass: "ag-header-left",
      width: 100,
    },
    {
      headerName: "Value",
      field: "value",
      filter: true,
      cellStyle: { textAlign: "left" },
      headerClass: "ag-header-left",
    },
  ];

  return (
    <Box sx={{ padding: "22px" }}>
      <AgGridComponent
        rowData={rowData || []}
        columnDefs={columnDefs || []}
        search={false}
        addActionIcon={<AddOutlined />}
        downlodBtn={false}
        tableName={"Lead Info"}
        domLayout="autoHeight"
        height={"100%"}
        rowStyle={rowStyle}
      />
    </Box>
  );
};

export default LeadBody;
