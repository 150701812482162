import { Box, Divider, Drawer, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import GlobalInput from "../../Components/CommonComponents/GlobalInput";

import GlobalDropdown from "../../Components/CommonComponents/GlobalDropdown";
import CustomButton from "../../Components/CommonComponents/GlobalButton";
import { useDispatch } from "react-redux";
import { useApiCallMutation } from "../../Services/ApiCallService";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { PublisherTagModuleAPiEndPoints } from "../../Config/APIsEndpoints";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  addSinglePublisherTags,
  updateSinglePublisherTags,
} from "../../Store/PublisherTagModule";

const AddEditPublisherTags = (props) => {
  const { setOpen, isOpen, defaultValues, isEditMode, setIsEditMode } = props;
  const dispatch = useDispatch();
  //badge options
  const badgeOptions = [
    { label: "Primary", id: "status-1", value: "Primary" },
    { label: "Info", id: "status-2", value: "Info" },
    { label: "Warning", id: "status-3", value: "Warning" },
    { label: "Error", id: "status-4", value: "Error" },
  ];

  // Api Call Intialization
  const [UpdatePublisherTag, updatePublisherTagData] = useApiCallMutation();
  const [CreatePublisherTag, createPublisherTagData] = useApiCallMutation();

  // Set Initial Value here
  const initialValues = {
    name: "",
    badge: "",
  };

  // Set Validation Schema here
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2)
      .trim("Name cannot include leading and trailing spaces")
      .max(50)
      .matches(/[A-Za-z]/, "Only numbers are not allowed")
      .required("campaignTag Name is required"),
    badge: Yup.string().required("Field is required"),
  });

  // initialise Form here
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const SetFormValues = (data) => {
    setValue("name", data?.name, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("badge", data?.badge, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const onFormSubmit = (data) => {
    if (isEditMode) {
      const modifiedData = data;
      modifiedData.id = defaultValues?.id;
      UpdatePublisherTag({
        endPoint: PublisherTagModuleAPiEndPoints.updatePublisherTag,
        method: "POST",
        data: { data: modifiedData },
      });
    } else {
      CreatePublisherTag({
        endPoint: PublisherTagModuleAPiEndPoints.addPublisherTags,
        method: "POST",
        data: { data },
      });
    }
  };

  //Set Incoming Default values
  useEffect(() => {
    if (Object.keys(defaultValues).length && isEditMode) {
      SetFormValues(defaultValues);
    }
  }, []);

  useEffect(() => {
    if (
      createPublisherTagData?.isSuccess &&
      !createPublisherTagData?.data?.error
    ) {
      dispatch(addSinglePublisherTags(createPublisherTagData.data?.data));
      setOpen(false);
      setIsEditMode(false);
    }
  }, [createPublisherTagData]);
  useEffect(() => {
    if (
      updatePublisherTagData?.isSuccess &&
      !updatePublisherTagData?.data?.error
    ) {
      const updatedDataWithId = {
        id: defaultValues?.id,
        ...updatePublisherTagData.data.data,
      };
      dispatch(updateSinglePublisherTags(updatedDataWithId));
      setOpen(false);
      setIsEditMode(false);
    }
  }, [updatePublisherTagData]);

  return (
    <>
      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={() => {
          setOpen(false);
          setIsEditMode(false);
        }}
        sx={{
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            top: "15px",
            borderRadius: "16px",
            width: "100%",
            maxWidth: "500px",
          },
        }}
      >
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent={"space-between"}
          >
            <Box marginBottom={"15px"}>
              <Typography component="h6" variant="h6" fontWeight={600}>
                {(isEditMode && "Update Publisher Tag") ||
                  "Add New Publisher Tag"}
              </Typography>
            </Box>
            <Box marginBottom={"15px"} onClick={() => reset()}>
              <Typography component="h7" variant="h7" fontWeight={200}>
                Clear All
              </Typography>
            </Box>
          </Stack>

          <Divider
            sx={{
              mb: "20px",
            }}
          />

          <Box component={"form"}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <GlobalInput
                  control={control}
                  size="small"
                  name="name"
                  label="Tag Name"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  size="small"
                  name="badge"
                  label="Badge"
                  options={badgeOptions || []}
                  error={!!errors.badge}
                  helperText={errors.badge?.message}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            // pl: "1rem",
          }}
        >
          <Stack
            direction="row"
            component={Box}
            useFlexGap
            flexWrap="wrap"
            justifyContent={"flex-end"}
            bottom={0}
            marginTop={"1rem"}
          >
            <Box marginBottom={"15px"} marginRight={"5px"}>
              <CustomButton
                color="#3a5396"
                // label="Add Campaign"
                label={isEditMode ? "Update" : "Add"}
                onClick={handleSubmit(onFormSubmit)}
              />
            </Box>
            <Box marginBottom={"15px"}>
              <CustomButton
                labelColor="#7a7870"
                variant="outlined"
                color="white"
                label="Close"
                onClick={() => {
                  setOpen(false);
                  setIsEditMode(false);
                }}
              />
            </Box>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default AddEditPublisherTags;
