import React, { useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoginLogo from "../../assets/images/login-logo.png";
import ZapLogo from "../../assets/images/zappian-logo.png";
import LoginBackground from "../../assets/images/login-bg.png";
import "./Login.css";
import { Box, Typography, Button } from "../../Collections/Imports";
import { useGetApiCallQuery } from "../../Services/ApiCallService";
import { callBackURL, idpURL } from "../../Config/envConfig";
import { Link } from "react-router-dom";
import { apiEndPointsConfig } from "../../Config/APIsEndpoints";

const defaultTheme = createTheme();
const Login = () => {
  const { data, refetch, isError, isFetching, isLoading, isSuccess } =
    useGetApiCallQuery({
      endPoint: apiEndPointsConfig.getClients,
      method: "POST",
      data: {
        data: {},
      },
      headers: {},
    });

  const getAuthorizationCode = () => {
    return `${idpURL}/oauth2/authorize?response_type=code&client_id=lds&scope=openid&redirect_uri=${callBackURL}`;
  };

  useEffect(() => {
    refetch();
  }, [null]);
  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            width: "100%",
            height: "100%",
          }}
        >
          <Box sx={{ width: "20px", backgroundColor: "#0e356cd9" }}></Box>
          <Box sx={{ width: "100%" }} bgcolor="#0E356C" position="relative">
            <Box position="absolute" height="100%" right={1} bottom={1}>
              <img
                src={LoginBackground}
                alt="Login Background"
                width="100%"
                height="100%"
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              mx={4}
              gap={2}
              sx={{ position: "absolute", top: "2rem" }}
            >
              <img src={LoginLogo} alt="icon" />
              <img src={ZapLogo} alt="Zappian Logo" className="zappian-logo" />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={"100%"}
            >
              <Box
                className="login-box"
                max-width="375px"
                bgcolor="white"
                py={6}
                px={4}
                m={"10px"}
                borderRadius={3}
              >
                <img src={ZapLogo} alt="logo" className="central-logo" />
                <Typography
                  color="#636363"
                  fontSize="19px"
                  textAlign="center"
                  marginTop="40px"
                  marginBottom="20px"
                >
                  Sign Up with Google for Quick and Secure Access
                </Typography>
                <Button
                  sx={{maxWidth:"250px"}}
                  componet={Link}
                  href={getAuthorizationCode()}
                  target="blank"
                  className="login-btn"
                >
                  Login with Google
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default Login;
