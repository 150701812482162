import { OutboxOutlined } from "@mui/icons-material";
import { Box, Button, Grid } from "../../../Collections/Imports";
import "./style.css";
function Filters(props) {
  const { id, children, title, onClick, icons } = props;

  return (
    <Box>
      <Box
        className="filter-box"
        component={Button}
        sx={{ color: "unset", fontWeight: "400", cursor: "pointer" }}
        onClick={onClick}
      >
        <div className="flexee">
          <div className="title">
            <span> {icons}</span>
            {title}
          </div>
          <div className="children">{children}</div>
        </div>
      </Box>
    </Box>
  );
}

export default Filters;
