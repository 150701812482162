import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterData: {
    fromDate:"2020-06-30T18:30:00.000Z",
  },
};

const campaignTagDataFilterSession = createSlice({
  name: "campaignTagDataFilterSession",
  initialState,
  reducers: {
    updateCampaignTagFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
});

export const { updateCampaignTagFilterData } = campaignTagDataFilterSession.actions;
export default campaignTagDataFilterSession.reducer;
