import React from "react";
import {
  Grid,
  useEffect,
  useState,
  Box,
  Card,
  Stack,
  Typography,
  Divider,
  AddOutlined,
  useNavigate,
  useLocation,
} from "../../../../Collections/Imports";

import CustomButton from "../../../../Components/CommonComponents/GlobalButton";
import AgGridComponent from "../../../../Components/CommonComponents/AG-Grid";

import AddEditPublisherContact from "../../../../Forms/AddEditPublisherContact";
import { publisherModuleApiEndPoints } from "../../../../Config/APIsEndpoints";
import { useApiCallMutation } from "../../../../Services/ApiCallService";
import { useDispatch, useSelector } from "react-redux";
import {
  getPublisherContactData,
  removeSinglePubDetails,
} from "../../../../Store/PublisherModule/PublisherContactModule";

function Contact() {
  const [isEditMode, setIsEditMode] = useState(false);
  const [rowSelected, setRowSelected] = useState({});
  const [isOpenAddEditBox, setIsOpenAddEditBox] = useState(false);

  const navigate = useNavigate();
  let location = useLocation();

  const [GetPublisherContact, PublisherContact] = useApiCallMutation();

  const [DeletePubContact, setDeletePubContact] = useApiCallMutation();
  const dispatch = useDispatch();

  const TableData = useSelector((state) => state.PubContactData?.tableData);

  let currentPubData = JSON.parse(
    sessionStorage.getItem("currentPublisherData")
  );

  console.log("Publisher Current Data0-0--0-0-0-0-0-0-0-0", currentPubData);

  // get all publisher data
  useEffect(() => {
    GetPublisherContact({
      endPoint: publisherModuleApiEndPoints.getPubContactDetails,
      method: "POST",
      data: {
        data: {
          pubUniqueId: currentPubData.pubUniqueId,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (
      PublisherContact?.isSuccess &&
      PublisherContact?.data?.data?.pubContactDetails?.length
    ) {
      const campaignDataList = [
        ...PublisherContact?.data?.data?.pubContactDetails,
      ];

      const headers = PublisherContact?.data?.data?.headers;

      if (headers) {
        const tableData = {
          data: campaignDataList,
          headers: headers,
        };
        console.log("tableData", tableData);
        dispatch(getPublisherContactData(tableData));
      }
    } else {
      dispatch(getPublisherContactData({}));
    }
  }, [PublisherContact]);

  // Handle Grid Button ACtion
  const handleActions = (previousData, action) => {
    console.log("Prives DATA0---0--0-0-0-0-0-0-00-0-", previousData);

    switch (action) {
      case "EDIT":
        setRowSelected(previousData);
        setIsEditMode(true);
        setIsOpenAddEditBox(true);
        break;
      case "CONFIG":
        setRowSelected(previousData);
        sessionStorage.setItem(
          "currentCampaignData",
          JSON.stringify(previousData)
        );
        // dispatch(updateCampaignRowData(previousData));

        // const currentCampData = JSON.parse(
        //   sessionStorage.getItem("currentCampaignData")
        // );

        // setCampainName(currentCampData.campaignName);

        // navigate(String(previousData?.uniqueId));

        break;
      case "Status":
        const data = {
          id: previousData?.campaignId,
          campaignName: previousData?.campaignName,
          timezone: previousData?.timezone,
          campaignType: previousData?.campaignType,
          minPrice: previousData?.minPrice,
          campaignIntegrations: previousData?.campaignIntegrations,
          status: previousData?.status,
        };
        break;

      case "DELETE":
        DeletePubContact({
          endPoint: publisherModuleApiEndPoints.deletePubContactDetails,
          method: "POST",
          // data: { data: listId },

          data: {
            data: {
              pubUniqueId: currentPubData.pubUniqueId,
              type: previousData?.type,
              value: previousData?.value,
            },
          },
        });
        dispatch(removeSinglePubDetails(previousData?.value));
        break;

      // UpdateCampaign({
      //   endPoint: campaignModuleAPiEndPoints.updateCampaign,
      //   method: "POST",
      //   data: { data },
      // });
      // break;
    }
  };

  return (
    <>
      <Box
        sx={{
          padding: "28px",
        }}
      >
        {isOpenAddEditBox && (
          <Box>
            <AddEditPublisherContact
              defaultValues={rowSelected}
              isOpen={isOpenAddEditBox}
              setOpen={setIsOpenAddEditBox}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
            />
          </Box>
        )}

        <AgGridComponent
          performAction={handleActions}
          rowData={TableData?.data || []}
          columnDefs={TableData.headers || []}
          addActionIcon={<AddOutlined />}
          addAction={setIsOpenAddEditBox}
          AddButtonName={"Add Contact"}
          downlodBtn={true}
          tableName={"Contact"}
          actions={["EDIT", "DELETE"]}
          disabledCondition={[{ fieldName: "verify", value: "primary" }]}
          domLayout="autoHeight"
          height={"100%"}
          rowMode
        />
      </Box>
    </>
  );
}

export default Contact;
