import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Card,
  Divider,
  Drawer,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import GlobalDropdown from "../../Components/CommonComponents/GlobalDropdown";
import GlobalMultiSelect from "../../Components/CommonComponents/GlobalMultiSelect";
import GlobalInput from "../../Components/CommonComponents/GlobalInput";
import CustomButton from "../../Components/CommonComponents/GlobalButton";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import {
  buyerLabelApiEndPoint,
  coreAPIEndsPoints,
} from "../../Config/APIsEndpoints";
import { useDispatch } from "react-redux";
import { addSingleBuyerLabelData, updateSingleBuyerLabelData } from "../../Store/BuyerLabelModule";
import { updateLabelOptions } from "../../Store/Global/LabelOptions";

const AddEditBuyerLabel = (props) => {
  const { setOpen, isOpen, defaultValues, isEditMode, setIsEditMode } = props;
  const dispatch = useDispatch();
  const [labelDropdown, setLabelDropdown] = useState([]);

  const [CreatBuyerLabel, creatBuyerLabelData] = useApiCallMutation();
  const [UpdateBuyerLabel, UpdateBuyerLabelData] = useApiCallMutation();

  const getLabelOptions = useGetApiCallQuery({
    endPoint: coreAPIEndsPoints.getLabel,
    method: "GET",
  });

  // Set Forms Value

  const SetFormValues = (data) => {
    setValue("name", data?.name, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("badge", data?.badge, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  //Set Incoming Default values
  useEffect(() => {
    if (Object.keys(defaultValues).length && isEditMode) {
      SetFormValues(defaultValues);
    }
  }, []);

  useEffect(() => {
    if (creatBuyerLabelData?.isSuccess && !creatBuyerLabelData?.data?.error) {
      dispatch(addSingleBuyerLabelData(creatBuyerLabelData.data?.data));
      setOpen(false);
      setIsEditMode(false);
    }
  }, [creatBuyerLabelData]);

  useEffect(() => {
    if (UpdateBuyerLabelData?.isSuccess && !UpdateBuyerLabelData?.data?.error) {
      // console.log("All Buyer DATA", UpdateBuyerLabelData.data?.data);
      dispatch(updateSingleBuyerLabelData(UpdateBuyerLabelData.data?.data));
      setOpen(false);
      setIsEditMode(false);
    }
  }, [UpdateBuyerLabelData]); 

  useEffect(() => {
    if (getLabelOptions.isSuccess) {
      setLabelDropdown(getLabelOptions?.data?.data?.label);
      dispatch(updateLabelOptions(getLabelOptions?.data?.data));
    }
  }, [getLabelOptions]);

  // Set Initial Value here
  const initialValues = {
    name: "",
    badge: "",
  };

  // Set Validation Schema here
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    badge: Yup.string().required("Badge is required"),
  });
  // initialise Form here
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const onFormSubmit = (data) => {
    if (isEditMode) {
      const modifiedData = data;
      modifiedData.id = defaultValues?.id;

      UpdateBuyerLabel({
        endPoint: buyerLabelApiEndPoint.updateBuyerLabel,
        method: "POST",
        data: {
          data: { ...data, labelUniqueId: defaultValues?.labelUniqueId },
        },
      });
    } else {
      CreatBuyerLabel({
        endPoint: buyerLabelApiEndPoint.createBuyerLabel,
        method: "POST",
        data: { data },
      });
    }
  };

  return (
    <>
      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={() => {
          setOpen(false);
          setIsEditMode(false);
        }}
        sx={{
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            top: "15px",
            borderRadius: "16px",
            width: "100%",
            maxWidth: "500px",
          },
        }}
      >
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent={"space-between"}
          >
            <Box marginBottom={"15px"}>
              <Typography component="h6" variant="h6" fontWeight={600}>
                {(isEditMode && "Update Buyer Label") || "Add Buyer Label"}
              </Typography>
            </Box>
            <Box marginBottom={"15px"} onClick={() => reset()}>
              <Typography component="h7" variant="h7" fontWeight={200}>
                Clear All
              </Typography>
            </Box>
          </Stack>

          <Divider
            sx={{
              mb: "20px",
            }}
          />

          <Box component={"form"}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <GlobalInput
                  control={control}
                  size="small"
                  name="name"
                  label="Name"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  name="badge"
                  label="Badge"
                  options={[
                    { label: "Primary", value: "Primary" },
                    { label: "Info", value: "Info" },
                    { label: "Warning", value: "Warning" },
                    { label: "Error", value: "Error" },
                  ]}
                  error={!!errors.badge}
                  helperText={errors.badge?.message}
                  required
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            // pl: "1rem",
          }}
        >
          <Stack
            direction="row"
            component={Box}
            useFlexGap
            flexWrap="wrap"
            justifyContent={"flex-end"}
            bottom={0}
            marginTop={"1rem"}
          >
            <Box marginBottom={"15px"} marginRight={"5px"}>
              <CustomButton
                color="#3a5396"
                // label="Add Campaign"
                label={isEditMode ? "Update" : "Add"}
                onClick={handleSubmit(onFormSubmit)}
              />
            </Box>
            <Box marginBottom={"15px"}>
              <CustomButton
                labelColor="#7a7870"
                variant="outlined"
                color="white"
                label="Close"
                onClick={() => {
                  setOpen(false);
                  setIsEditMode(false);
                }}
              />
            </Box>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default AddEditBuyerLabel;
