import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  tableData: {
    data: [],
    headers: [],
  },
};

const PubTrackData = createSlice({
  name: "PubTrackingData",
  initialState,
  reducers: {
    getPublisherTrackingData: (state, action) => {
      state.tableData = action.payload;
    },

    addSingleTracking: (state, action) => {
      state.tableData.data.unshift(action.payload);
    },

    updateSingleTrackingData: (state, action) => {
      const updatedRow = action.payload;
      console.log(updatedRow);
      console.log(state?.tableData?.data);
      const index = state.tableData.data.findIndex(
        (data) => data?.campaignScheduleId === updatedRow?.id
      );

      const keys = Object.keys(updatedRow);

      keys.map((key) => {
        state.tableData.data[index][key] = updatedRow[key];
      });
    },
    // Schedule Data
    // addSingleScheduleRule: (state, action) => {
    //   state.tableData.data.unshift(action.payload);
    // },
    removeSinglePubTracking: (state, action) => {
      console.log("removedId", state, action);
      state.tableData.data = state.tableData.data.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});

export const {
  getPublisherTrackingData,
  addSingleTracking,
  removeSinglePubTracking,
  updateSingleTrackingData,
  // addSingleScheduleRule,
  //   updateSingleScheduleData,
  //   removeSingleSchedule,
} = PubTrackData.actions;
export default PubTrackData.reducer;
