import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
  };
  const PldnplOptions = createSlice({
    name: "PldnplOptions",
    initialState,
    reducers: {
      updatePldnplOptions: (state, action) => {
        state.data = action.payload;
      },
    },
  });
  
  export const { updatePldnplOptions } = PldnplOptions.actions;
  export default PldnplOptions.reducer;