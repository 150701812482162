import { Button, Divider, Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, Grid } from "../../Collections/Imports";
import GlobalInput from "../../Components/CommonComponents/GlobalInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import CustomButton from "../../Components/CommonComponents/GlobalButton";

import GlobalDropdown from "../../Components/CommonComponents/GlobalDropdown";
import countries from "./Countrys";
import { useApiCallMutation } from "../../Services/ApiCallService";
import { publisherModuleApiEndPoints } from "../../Config/APIsEndpoints";
import { addSingleContact } from "../../Store/PublisherModule/PublisherContactModule";
import { useDispatch } from "react-redux";
import { is } from "date-fns/locale";
import { updateSnackBarConfig } from "../../Store/Global/SnackBar";

const AddEditPublisherContact = (props) => {
  const { setOpen, isOpen, defaultValues, isEditMode, setIsEditMode } = props;
  const dispatch = useDispatch();
  let currentPubData = JSON.parse(
    sessionStorage.getItem("currentPublisherData")
  );

  console.log("-0-00-00-0-00-0-0-0-0-0-0-0-", defaultValues);
  // Set Initial Value here
  const initialValues = {
    email: "",
    cellPhone: "",
    landLine: "",
    skype: "",
    webSite: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    address: "",
  };

  const [AddPublisherContact, addPublisherContactData] = useApiCallMutation();
  const [UpdatePublisherContact, updatePublisherContactData] =
    useApiCallMutation();

  const [showEmail, setShowEmail] = useState(true);
  const [showCellPhone, setShowCellPhone] = useState(true);
  const [showLandLine, setShowLandLine] = useState(true);
  const [showSkype, setShowSkype] = useState(true);
  const [showWebsite, setShowWebsite] = useState(true);
  const [showCountry, setShowCountry] = useState(true);
  const [showState, setShowState] = useState(true);
  const [showCity, setShowCity] = useState(true);
  const [showZip, setShowZip] = useState(true);
  const [showAddress, setShowAddress] = useState(true);

  //   // Set Validation Schema here
  const numericErrorMessage = "Please enter a valid number";

  const validationSchema = Yup.object().shape({
    email:
      showEmail && isEditMode
        ? Yup.string()
            .trim("Email can not include leading and trailing spaces")
            .strict(true)
            .required("This Field is required")
            .email("Invalid email format")
        : Yup.string()
            .notRequired()
            .trim("Email can not include leading and trailing spaces")
            .strict(true)
            .email("Invalid email format"),

    // cellPhone:
    //   showCellPhone && isEditMode
    //     ? Yup.string()
    //         .trim("Field can not include leading and trailing spaces")
    //         .strict(true)

    //         .matches(/^\d*$/, "Only digits are allowed") // Allow only digits
    //         .max(20, "Maximum length is twenty digits ")
    //         .required("This Field is required")
    //     : Yup.string()
    //         .notRequired()
    //         .trim("Field can not include leading and trailing spaces")
    //         .strict(true),
    // cellPhone:
    //   showCellPhone && isEditMode
    //     ? Yup.string()
    //         .trim("Field cannot include leading and trailing spaces")
    //         .matches(/^\d+$/, "Only numbers are allowed") // Allow only numbers
    //         .max(20, "Maximum length is twenty digits")
    //         .notRequired()
    //     : Yup.string()
    //         .trim("Field cannot include leading and trailing spaces")
    //         .matches(/^\d+$/, { message: "Only numbers are allowed" }) // Allow only numbers
    //         .max(20, "Maximum length is twenty digits")
    //         .notRequired(),

    cellPhone:
      showCellPhone && isEditMode
        ? Yup.string()
            .trim("Field cannot include leading and trailing spaces")
            .max(12, "Maximum length is Twelve digits")
            .notRequired()
        : Yup.string()
            .trim("Field cannot include leading and trailing spaces")
            .transform((value, originalValue) =>
              originalValue ? value.replace(/\D/g, "") : value
            )
            .max(12, "Maximum length is Twelve digits")
            .notRequired(),

    landLine:
      showLandLine && isEditMode
        ? Yup.string()
            .trim("Field cannot include leading and trailing spaces")
            .max(12, "Maximum length is Twelve digits")
            .notRequired()
        : Yup.string()
            .trim("Field cannot include leading and trailing spaces")
            .transform((value, originalValue) =>
              originalValue ? value.replace(/\D/g, "") : value
            )
            .max(12, "Maximum length is Twelve digits")
            .notRequired(),
    skype:
      showSkype && isEditMode
        ? Yup.string()
            .trim("Field can not include leading and trailing spaces")
            .strict(true)
            .required("This Field is required")
            .max(100, "Maximum length is one hundred ")
            .matches(/^(\S+$)/g, "* This field cannot contain only blankspaces")
        : Yup.string()
            .notRequired()
            .trim("Field can not include leading and trailing spaces")
            .max(100, "Maximum length is one hundred ")
            .strict(true),

    webSite:
      showWebsite && isEditMode
        ? Yup.string()
            .trim()
            // .matches(/^\S+$/, "* This field cannot contain only blank spaces")
            .strict(true)
            .required("This Field is required")
            .max(100, "Maximum length is one hundred characters")
        : Yup.string()
            .notRequired()
            .trim()
            // .matches(/^\S+$/, "* This field cannot contain only blank spaces")
            .max(100, "Maximum length is one hundred characters")
            .strict(true),

    country:
      showCountry && isEditMode
        ? Yup.string().notRequired("This Field is required")
        : Yup.string().notRequired(),

    state:
      showState && isEditMode
        ? Yup.string()
            .matches(/^[A-Za-z][A-Za-z\s]*$/, "Only characters are allowed") // Allow only characters (letters)
            .required("This Field is required")
            .max(100, "Maximum length is one hundred ")
        : Yup.string().test(
            "only Char",
            "Only characters are allowed",
            (value) => {
              if (value) {
                if (/^[A-Za-z][A-Za-z\s]*$/.test(value)) {
                  return true;
                } else return false;
              } else return true;
            }
          ),
    // .notRequired()
    // .matches(/^[A-Za-z][A-Za-z\s]*$/, "Only characters are allowed")
    // // .trim("Field can not include leading and trailing spaces")
    // .max(30, "Maximum length of city is 30 characters")
    // .nullable(),

    // city:
    //   showCity && isEditMode
    //     ? Yup.string()
    //         .trim("Field can not include leading and trailing spaces")
    //         .strict(true)
    //         .required("This Field is required")
    //         .matches(/^[a-zA-Z]*$/, "Only characters are allowed") // Allow only characters (letters)
    //         .max(100, "Maximum length is one hundred ")
    //         .matches(/^(\S+$)/g, "* This field cannot contain only blankspaces")
    //     : Yup.string()
    //         .notRequired()
    //         .trim("Field can not include leading and trailing spaces")
    //         .strict(true),

    // city:
    //   showCity && isEditMode
    //     ? Yup.string()
    //         .trim("Field cannot include leading and trailing spaces")
    //         .strict(true)
    //         .required("This field is required")
    //         .max(100, "Maximum length is one hundred characters")
    //         .matches(/^[A-Za-z]+$/, "Only characters are allowed")
    //         .when("$city", {
    //           is: (val) => val && val.length > 0,
    //           then: Yup.string().matches(
    //             /^[A-Za-z]+$/,
    //             "Only characters are allowed"
    //           ),
    //         })
    //     : Yup.string()
    //         .notRequired()
    //         .trim("Field cannot include leading and trailing spaces")
    //         .strict(true),

    city:
      showCity && isEditMode
        ? Yup.string()
            .matches(/^[A-Za-z][A-Za-z\s]*$/, "Only characters are allowed") // Allow only characters (letters)
            .required("This Field is required")
            .max(100, "Maximum length is one hundred ")
        : Yup.string().test(
            "only Char",
            "Only characters are allowed",
            (value) => {
              if (value) {
                if (/^[A-Za-z][A-Za-z\s]*$/.test(value)) {
                  return true;
                } else return false;
              } else return true;
            }
          ),

    // city:
    //   showCity && isEditMode
    //     ? Yup.string()
    //         .trim("Field cannot include leading and trailing spaces")
    //         .strict(true)
    //         .when("$city", {
    //           is: (val) => val && val.length > 0,
    //           then: Yup.string().matches(
    //             /^[A-Za-z]+$/,
    //             "Only characters are allowed"
    //           ),
    //         })
    //         .required("This Field is required")
    //         .matches(/^[a-zA-Z]*$/, "Only characters are allowed") // Allow only characters (letters)
    //         .max(100, "Maximum length is one hundred ")
    //         .test("no-numbers", "Numbers are not allowed", function (value) {
    //           return !value || !/\d/.test(value); // Checks if there are no numbers
    //         })
    //         .matches(/^(\S+$)/g, "* This field cannot contain only blankspaces")
    //     : Yup.string()
    //         .notRequired()
    //         .trim("Field cannot include leading and trailing spaces")
    //         .strict(true),

    zip:
      showZip && isEditMode
        ? Yup.string()
            .trim("Field can not include leading and trailing spaces")
            .strict(true)
            .max(6, "Maximum length is six ")
            .notRequired()
        : // .matches(/^\d*$/, "Only digits are allowed") // Allow only digits

          Yup.string().max(6, "Maximum length is Six digits").notRequired(),
    address:
      showAddress && isEditMode
        ? Yup.string()
            .trim("Field can not include leading and trailing spaces")
            .strict(true)
            .required()
            .max(250, "Maximum length is two hundred fifty ")
        : Yup.string()
            .notRequired()
            .trim("Field can not include leading and trailing spaces")
            .strict(true),
  });
  // initialise Form here
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    setValue,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const onFormSubmit = (data) => {
    // debugger;
    // const testData = { ...data, pubUniqueId: `${currentPubData.pubUniqueId}` };
    const testData = {
      email: [data.email],
      cellPhone: [data.cellPhone],
      landLine: [data.landLine],
      skype: [data.skype],
      website: [data.webSite],
      country: [data.country],
      state: [data.state],
      city: [data.city],
      zip: [data.zip],
      address: [data.address],
      pubUniqueId: `${currentPubData.pubUniqueId}`,
    };

    const updateData = {
      email: [data.email],
      cellPhone: [data.cellPhone],
      landLine: [data.landLine],
      skype: [data.skype],
      website: [data.webSite],
      country: [data.country],
      state: [data.state],
      city: [data.city],
      zip: [data.zip],
      address: [data.address],
      pubUniqueId: `${currentPubData.pubUniqueId}`,
    };

    if (isEditMode) {
      UpdatePublisherContact(
        {
          endPoint: publisherModuleApiEndPoints.updatePubContactDetails,
          method: "POST",
          data: { data: { ...updateData, oldValue: defaultValues.value } },
        },
        reset()
      );
    } else {
      AddPublisherContact(
        {
          endPoint: publisherModuleApiEndPoints.createPubContactDetails,
          method: "POST",
          data: { data: testData },
        },
        reset()
      );
    }
  };

  // Add single data
  useEffect(() => {
    if (
      addPublisherContactData?.isSuccess &&
      !addPublisherContactData?.data?.error
    ) {
      const campaignDataList = [
        ...addPublisherContactData?.data?.data?.pubContactDetails,
      ];

      const headers = addPublisherContactData?.data?.data?.headers;
      const tableData = {
        data: campaignDataList,
        headers: headers,
      };

      dispatch(addSingleContact(tableData));
      setOpen(false);
    }
  }, [addPublisherContactData]);

  //Update single data
  useEffect(() => {
    if (
      updatePublisherContactData?.isSuccess &&
      !updatePublisherContactData?.data?.error
    ) {
      console.log(
        "updatePublisherContactData?.data?.data",
        updatePublisherContactData?.data?.data?.pubContactDetails
      );

      const campaignDataList = [
        ...updatePublisherContactData?.data?.data?.pubContactDetails,
      ];

      const headers = updatePublisherContactData?.data?.data?.headers;

      if (headers) {
        const tableData = {
          data: campaignDataList,
          headers: headers,
        };
        console.log("tableData", tableData);
        // dispatch(getPublisherContactData(tableData));

        dispatch(addSingleContact(tableData));
        setOpen(false);
        setIsEditMode(false);
      }
    }
  }, [updatePublisherContactData]);

  //Set Incoming Default values
  useEffect(() => {
    if (Object.keys(defaultValues).length && isEditMode) {
      SetFormValues(defaultValues);
    }
  }, []);

  // Set Forms Value

  const SetFormValues = (data) => {
    // setShowEmail(data.value &&);
    console.log("DES=-=-=--=-=-=", data);

    if (data.type === "email") {
      console.log("SetData", data);
      setShowEmail(true);
      setValue("email", data?.value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    } else {
      setShowEmail(false);
    }
    if (data.type === "cellPhone") {
      console.log("SetData", data);
      setShowCellPhone(true);
      setValue("cellPhone", data?.value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    } else {
      setShowCellPhone(false);
    }
    if (data.type === "landLine") {
      console.log("SetData", data);
      setShowLandLine(true);
      setValue("landLine", data?.value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    } else {
      setShowLandLine(false);
    }
    if (data.type === "skype") {
      console.log("SetData", data);
      setShowSkype(true);
      setValue("skype", data?.value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    } else {
      setShowSkype(false);
    }
    if (data.type === "website") {
      console.log("SetData", data);
      setShowWebsite(true);
      setValue("webSite", data?.value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    } else {
      setShowWebsite(false);
    }
    if (data.type === "country") {
      console.log("SetData", data);
      setShowCountry(true);
      setValue("country", data?.value.toString(), {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    } else {
      setShowCountry(false);
    }
    if (data.type === "state") {
      console.log("SetData", data);
      setShowState(true);
      setValue("state", data?.value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    } else {
      setShowState(false);
    }
    if (data.type === "city") {
      console.log("SetData", data);
      setShowCity(true);
      setValue("city", data?.value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    } else {
      setShowCity(false);
    }
    if (data.type === "zip") {
      console.log("SetData", data);
      setShowZip(true);
      setValue("zip", data?.value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    } else {
      setShowZip(false);
    }
    if (data.type === "address") {
      console.log("SetData", data);
      setShowAddress(true);
      setValue("address", data?.value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    } else {
      setShowAddress(false);
    }
  };

  return (
    <>
      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={() => {
          setOpen(false);
          setIsEditMode(false);
        }}
        sx={{
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            top: "15px",
            borderRadius: "16px",
            width: "100%",
            maxWidth: "500px",
          },
        }}
      >
        <Box sx={{ padding: "1rem 1rem 1rem 1rem" }}>
          <Stack
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent={"space-between"}
          >
            <Box marginBottom={"15px"}>
              <Typography component="h6" variant="h6" fontWeight={600}>
                {(isEditMode && "Update Allow List") || "Add Publisher Contact"}
                {/* {"Add Publisher Contact"} */}
              </Typography>
            </Box>
            <Box
              marginBottom={"15px"}
              onClick={() => reset()}
              sx={{ color: "#000", cursor: "pointer" }}
            >
              <Typography component="h7" variant="h7" fontWeight={200}>
                Clear All
              </Typography>
            </Box>
          </Stack>
          <Box sx={{ pb: "2rem" }}>
            <Divider />
          </Box>

          <Box component={"form"}>
            <Grid container spacing={4}>
              {showEmail && (
                <>
                  <Grid item xs={12}>
                    <GlobalInput
                      control={control}
                      size="small"
                      name="email"
                      label="Email"
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      // disabled={true}
                    />
                  </Grid>
                </>
              )}
              {showCellPhone && (
                <>
                  <Grid item xs={12}>
                    <GlobalInput
                      type={"number"}
                      control={control}
                      size="small"
                      name="cellPhone"
                      label="Cell Phone"
                      error={!!errors.cellPhone}
                      helperText={errors.cellPhone?.message}
                      oninput="validity.valid||(value='');"
                    />
                  </Grid>
                </>
              )}

              {showLandLine && (
                <>
                  <Grid item xs={12}>
                    <GlobalInput
                      type={"number"}
                      control={control}
                      size="small"
                      name="landLine"
                      label="Land Line"
                      error={!!errors.landLine}
                      helperText={errors.landLine?.message}
                    />
                  </Grid>
                </>
              )}

              {showSkype && (
                <>
                  <Grid item xs={12}>
                    <GlobalInput
                      control={control}
                      size="small"
                      name="skype"
                      label="Skype"
                      error={!!errors.skype}
                      helperText={errors.skype?.message}
                    />
                  </Grid>
                </>
              )}
              {showWebsite && (
                <>
                  <Grid item xs={12}>
                    <GlobalInput
                      control={control}
                      size="small"
                      name="webSite"
                      label="Web Site"
                      error={!!errors.webSite}
                      helperText={errors.webSite?.message}
                    />
                  </Grid>
                </>
              )}
              {showCountry && (
                <>
                  <Grid item xs={12}>
                    {/* <GlobalInput
                  control={control}
                  size="small"
                  name="country"
                  label="Country"
                  error={!!errors.country}
                  helperText={errors.country?.message}
                /> */}

                    <GlobalDropdown
                      control={control}
                      size="small"
                      name="country"
                      label="Country"
                      options={countries || []}
                      required
                      error={!!errors.country}
                      helperText={errors.country?.message}
                    />
                  </Grid>
                </>
              )}

              {showState && (
                <>
                  <Grid item xs={12}>
                    <GlobalInput
                      control={control}
                      size="small"
                      name="state"
                      label="State"
                      error={!!errors.state}
                      helperText={errors.state?.message}
                    />
                  </Grid>
                </>
              )}

              {showCity && (
                <>
                  <Grid item xs={12}>
                    <GlobalInput
                      control={control}
                      size="small"
                      name="city"
                      label="City"
                      error={!!errors.city}
                      helperText={errors.city?.message}
                    />
                  </Grid>
                </>
              )}

              {showZip && (
                <>
                  <Grid item xs={12}>
                    <GlobalInput
                      type={"number"}
                      control={control}
                      size="small"
                      name="zip"
                      label="Zip"
                      error={!!errors.zip}
                      helperText={errors.zip?.message}
                    />
                  </Grid>
                </>
              )}
              {showAddress && (
                <>
                  <Grid item xs={12}>
                    <GlobalInput
                      control={control}
                      size="small"
                      name="address"
                      label="Address"
                      error={!!errors.address}
                      helperText={errors.address?.message}
                    />
                  </Grid>
                </>
              )}
            </Grid>

            <Box
              sx={{
                borderTop: "1px solid #DCE0E6",
                pr: "1rem",
                pt: "1rem",
                mt: "1rem",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Box px={2}>
                <CustomButton
                  color="#3a5396"
                  disabled={!isDirty}
                  label={isEditMode ? "Update" : "Add"}
                  onClick={handleSubmit(onFormSubmit)}
                />
              </Box>
              <CustomButton
                labelColor="#7a7870"
                variant="outlined"
                color="white"
                label="Close"
                onClick={() => {
                  reset();
                  setOpen(false);
                  setIsEditMode(false);
                }}
              />
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default AddEditPublisherContact;
