import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    filterData: {
        fromDate: "2020-06-30T18:30:00.000Z",
    },
    leadOptions: {}
}

const leadDetailsFilterSession = createSlice ({
    name: "leadDetailsFilterSession",
    initialState,
    reducers: {
        updateLeadDetailsFilterData: (state, action) => {
            state.filterData = action.payload;
        },
        updateLeadOptions: (state,action)=>{
            state.leadOptions = action.payload;
        }
    }
});

export const { updateLeadDetailsFilterData, updateLeadOptions } = leadDetailsFilterSession.actions;
export default leadDetailsFilterSession.reducer;