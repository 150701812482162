import { useNavigate } from "react-router-dom";
import { apiEndPointsConfig } from "../../Config/APIsEndpoints";
import { useApiCallMutation } from "../../Services/ApiCallService";
import { useCallback } from "react";

function useRefreshToken() {
  const navigate = useNavigate();
  const [refreshTokeAPiCall, refreshTokeAPiCallData] = useApiCallMutation();

  const handleRefreshTokenCall = useCallback(async () => {
    try {
      const previousRefreshToken = JSON.parse(
        localStorage.getItem("userCredential")?.refresh_token
      );
      localStorage.removeItem("userCredential");

      const responce = await refreshTokeAPiCall({
        endPoint: apiEndPointsConfig.refreshToken,
        method: "POST",
        data: {
          data: {
            clientName: "lds",
            refreshToken: previousRefreshToken,
          },
        },
        headers: {},
      });
      if (responce.data) {
        localStorage.setItem(
          "userCredential",
          JSON.stringify(responce.data.data)
        );
      }
      return responce;
    } catch (error) {
      localStorage.clear();
      navigate("/");
    }
  });
  return handleRefreshTokenCall;
}
export default useRefreshToken;
