import React, { useRef } from "react";
import {
  Box,
  Button,
  Divider,
  Typography,
  useEffect,
  useState,
} from "./../../../Collections/Imports";
import AgGridComponent from "../../../Components/CommonComponents/AG-Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useApiCallMutation } from "../../../Services/ApiCallService";
import { channelModuleApiEndPoints } from "../../../Config/APIsEndpoints";
import generatePDF from "react-to-pdf";
import CustomButton from "../../../Components/CommonComponents/GlobalButton";
function ChannelAPIDoc() {
  const inputRef = useRef(null);
  const [GetChannelDoc, ChannelDoc] = useApiCallMutation();
  const currentChannelData = JSON.parse(
    sessionStorage.getItem("currentChannelData")
  );

  useEffect(() => {
    GetChannelDoc({
      endPoint: channelModuleApiEndPoints.getApiDoc,
      method: "POST",
      data: {
        data: { channelUniqueId: currentChannelData.channelUniqueId },
      },
    });
  }, []);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>

        <Box
          sx={{ background: "white", minHeight: "100vh", padding: "20px" }}
          ref={inputRef}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "6px",
            }}
          >
            <Typography
              component="h4"
              variant="h4"
              fontWeight={600}
              marginBottom={"20px"}
            >
              {currentChannelData.channelName}
            </Typography>
            <CustomButton
              variant="outlined"
              color="#3a5396"
              label="Generate PDF"
              onClick={() => {
                generatePDF(inputRef, {
                  filename:
                    currentChannelData.channelName + " - API Document.pdf",
                });
              }}
            />
          </Box>
          <Divider />
          <Typography
            component="h5"
            variant="h5"
            fontWeight={600}
            marginTop={"20px"}
            marginBottom={"20px"}
          >
            1. Introduction
          </Typography>
          <Typography
            component="p"
            variant="p"
            fontWeight={400}
            marginBottom={"20px"}
          >
            Lead Posting Specifications
          </Typography>
          <Typography
            component="p"
            variant="p"
            fontWeight={400}
            marginBottom={"20px"}
          >
            This document will explain the posting method, provide sample
            requests, sample responses, to help you with posting. This
            information is subject to change without notice. This document
            contains confidential and proprietary business information and is
            only distributed in accordance with a signed confidentiality
            agreement. Any use or distribution not expressly allowed by the
            confidentiality agreement is strictly prohibited.
          </Typography>

          <Typography
            component="h5"
            variant="h5"
            fontWeight={600}
            marginBottom={"20px"}
          >
            2. Overview
          </Typography>

          <Typography component="p" variant="p" fontWeight={600}>
            Method -
          </Typography>
          <Typography
            component="p"
            variant="p"
            fontWeight={400}
            marginBottom={"20px"}
          >
            Leadscience accepts leads through the HTTP POST process. Lead
            generators are asked to send leads via HTTP POST to the URL
            specified in the Leads Specs section.
          </Typography>

          <Typography component="p" variant="p" fontWeight={600}>
            Format -
          </Typography>
          <Typography
            component="p"
            variant="p"
            fontWeight={400}
            marginBottom={"20px"}
          >
            JSON Data Format
          </Typography>

          <Typography component="p" variant="p" fontWeight={600}>
            Fields -
          </Typography>
          <Typography
            component="p"
            variant="p"
            fontWeight={400}
            marginBottom={"20px"}
          >
            Leadscience API contains both required and optional fields.
            Leadscience will not accept a lead when any of the required fields
            are omitted, or the value of those fields is null or blank.
            Leadscience also has some specific formatting for particular fields.
            If the formatting is incorrect, the lead will be rejected. Data can
            be sent in the request in any order, but all of the field names are
            case sensitive and must match examples in the specifications,
            including capitalization (e.g., cApiTaLiZaTioN).
          </Typography>

          <Typography
            component="h5"
            variant="h5"
            fontWeight={600}
            marginBottom={"20px"}
          >
            3. Posting URL
          </Typography>
          <Typography
            component="p"
            variant="p"
            fontWeight={400}
            marginBottom={"20px"}
          >
            https://leads.zappian.net/lead/
          </Typography>
          <Typography
            component="h5"
            variant="h5"
            fontWeight={600}
            marginBottom={"20px"}
          >
            4. Fields
          </Typography>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
                >
                  <TableCell align="left">Field Name</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ChannelDoc?.data?.data.map((fields) => (
                  <TableRow key={fields.name} sx={{ "td,th": { border: 1 } }}>
                    <TableCell component="th" scope="row">
                      {fields.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {fields.description}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <b>{fields.value === undefined ? " - " : fields.value}</b>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
}

export default ChannelAPIDoc;
