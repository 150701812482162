import { Box } from "../../../../Collections/Imports";
import {
  Container,
  Card,
  Typography,
  Divider,
  ThemeProvider,
  createTheme,
  Drawer,
} from "@mui/material";
const LeadLogInfo = (props) => {
  const { setOpen, isOpen, setIsEditMode } = props;
  console.log("props", props.defaultValues.log);
  const theme = createTheme({
    palette: {
      text: {
        primary: "#3D506E",
        secondary: "#000000",
      },
    },
  });
  const leadLogValue = props?.defaultValues?.log;

  return (
    <>
      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={() => {
          setOpen(false);
          setIsEditMode(false);
        }}
        sx={{
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            top: "15px",
            borderRadius: "16px",
            width: "100%",
            maxWidth: "500px",
          },
        }}
      >
        <Container>
          <ThemeProvider theme={theme}>
            <Card sx={{ padding: 2, marginTop: 2 }}>
              {!leadLogValue?.url && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "16.94px",
                      color: "text.primary",
                    }}
                  >
                    Message: {leadLogValue?.message}
                  </Typography>
                </Box>
              )}
              {leadLogValue?.url && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "16.94px",
                        color: "text.primary",
                      }}
                    >
                      URL:
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "16.94px",
                          color: "text.primary",
                        }}
                      >
                        {leadLogValue?.url}
                      </Typography>
                    </Typography>
                  </Box>
                  <Divider sx={{ marginY: 2 }} />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16.94px",
                        color: "text.primary",
                      }}
                    >
                      <strong>Request Header : </strong>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "16.94px",
                          color: "text.secondary",
                        }}
                      >
                        {leadLogValue?.requestHeader}
                      </Typography>
                    </Typography>
                  </Box>
                  <Divider sx={{ marginY: 2 }} />
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "16.94px",
                      color: "text.primary",
                    }}
                  >
                    <strong>Response Header:</strong>{" "}
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "16.94px",
                        color: "text.secondary",
                      }}
                    >
                      {leadLogValue?.responseHeader}
                    </Typography>
                  </Typography>
                  <Divider sx={{ marginY: 2 }} />
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "16.94px",
                      color: "text.primary",
                    }}
                  >
                    <strong>Response :</strong>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "16.94px",
                        color: "text.secondary",
                      }}
                    >
                      {leadLogValue?.response}
                    </Typography>
                  </Typography>
                  <Divider sx={{ marginY: 2 }} />
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "16.94px",
                      color: "text.primary",
                    }}
                  >
                    <strong>Status:</strong>{" "}
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "16.94px",
                        color: "text.secondary",
                      }}
                    >
                      {leadLogValue?.status}
                    </Typography>
                  </Typography>
                  <Divider sx={{ marginY: 2 }} />
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "16.94px",
                      color: "text.primary",
                    }}
                  >
                    <strong>Processing Time:</strong>
                    <Typography
                      variant="h4"
                      gutterBottom
                      sx={{
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "16.94px",
                        color: "text.secondary",
                      }}
                    >
                      {leadLogValue?.processingTime}
                    </Typography>
                  </Typography>
                </>
              )}
            </Card>
          </ThemeProvider>
        </Container>
      </Drawer>
    </>
  );
};
export default LeadLogInfo;
