import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomButton from "../../../../Components/CommonComponents/GlobalButton";
import {
  Grid,
  useEffect,
  useState,
  Box,
  Typography,
  Divider,
  Stack,
} from "../../../../Collections/Imports";
import GlobalDropdown from "../../../../Components/CommonComponents/GlobalDropdown";
import { useApiCallMutation } from "../../../../Services/ApiCallService";
import { campaignModuleAPiEndPoints } from "../../../../Config/APIsEndpoints";
import { useSelector } from "react-redux";

const DuplicateSetting = () => {
  let currentCampaignData = JSON.parse(
    sessionStorage.getItem("currentCampaignData")
  );

  const [AddDuplicateSetting, addDuplicateSettingData] = useApiCallMutation();
  const [GetCampaignDuplicate, getCampaignDuplicateData] = useApiCallMutation();

  // Set Initial Value here
  const initialValues = {
    duplicateMethod: "",
    duplicateSold: "",
    duplicatePosted: "",
  };

  // Set Validation Schema here
  const validationSchema = Yup.object().shape({
    duplicateMethod: Yup.string().required("This Field is required"),
    duplicateSold: Yup.string().required("This Field is required"),
    duplicatePosted: Yup.string().required("This Field is required"),
  });
  // initialise Form here
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  // Set form values
  const setFormData = (data) => {
    setValue("duplicateMethod", data?.duplicateMethod, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("duplicateSold", data?.duplicateSold, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("duplicatePosted", data?.duplicatePosted, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const method = [
    { label: "Email", id: 1, value: "email" },
    { label: "Email + SSN", id: 2, value: "ssn+email" },
    { label: "SSN", id: 3, value: "ssn" },
  ];
  const days = [
    { label: "OFF", id: 1, value: "off" },
    { label: "Today Only", id: 2, value: 0 },
    { label: "1 Day", id: 3, value: 1 },
    { label: "2 Day", id: 4, value: 2 },
    { label: "3 Day", id: 5, value: 3 },
    { label: "4 Day", id: 6, value: 4 },
    { label: "5 Day", id: 7, value: 5 },
    { label: "6 Day", id: 8, value: 6 },
    { label: "7 Day", id: 9, value: 7 },
    { label: "14 Day", id: 10, value: 14 },
    { label: "15 Day", id: 11, value: 15 },
    { label: "17 Day", id: 12, value: 17 },
    { label: "20 Day", id: 13, value: 20 },
    { label: "25 Day", id: 14, value: 25 },
    { label: "30 Day", id: 15, value: 30 },
    { label: "31 Day", id: 16, value: 31 },
    { label: "45 Day", id: 17, value: 45 },
    { label: "60 Day", id: 18, value: 60 },
    { label: "90 Day", id: 19, value: 90 },
    { label: "120 Day", id: 20, value: 120 },
    { label: "140 Day", id: 21, value: 140 },
    { label: "150 Day", id: 22, value: 150 },
    { label: "160 Day", id: 23, value: 160 },
    { label: "180 Day", id: 24, value: 180 },
  ];
  const onFormSubmit = (data) => {
    console.log(data);
    data.campaignId = currentCampaignData?.campaignId;
    AddDuplicateSetting({
      endPoint: campaignModuleAPiEndPoints.addCampaignDuplicate,
      method: "POST",
      data: { data },
    });
  };
  // update form with api data
  useEffect(() => {
    if (
      getCampaignDuplicateData?.isSuccess &&
      !getCampaignDuplicateData?.data?.error
    ) {
      setFormData(getCampaignDuplicateData?.data?.data);
    }
  }, [getCampaignDuplicateData]);
  // api call to get campaign data
  useEffect(() => {
    GetCampaignDuplicate({
      endPoint: campaignModuleAPiEndPoints.getCampaignDuplicate,
      method: "POST",
      data: {
        data: {
          id: currentCampaignData?.campaignId,
        },
      },
    });
  }, []);

  return (
    <div>
      <Box sx={{ width: "50%", margin: "0 auto" }}>
        <Box marginBottom={"15px"}>
          <Typography component="h6" variant="h6" fontWeight={600}>
            Campaign Duplicates
          </Typography>
        </Box>
        <Divider sx={{ mb: "20px" }} />
        <Box component={"form"}>
          <Grid container>
            <Grid item xs={12} sx={{ mt: "20px" }}>
              <GlobalDropdown
                control={control}
                size="small"
                name="duplicateMethod"
                label="Duplicate Method"
                options={method}
                error={!!errors.duplicateMethod}
                helperText={errors.duplicateMethod?.message}
                required
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: "20px" }}>
              <GlobalDropdown
                control={control}
                size="small"
                name="duplicateSold"
                label="Duplicate Sold"
                options={days}
                error={!!errors.duplicateSold}
                helperText={errors.duplicateSold?.message}
                required
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: "20px" }}>
              <GlobalDropdown
                control={control}
                size="small"
                name="duplicatePosted"
                label="Duplicate Posted"
                options={days}
                error={!!errors.duplicatePosted}
                helperText={errors.duplicatePosted?.message}
                required
              />
            </Grid>
            <Box
              sx={{
                width: "100%",
                borderTop: "1px solid #DCE0E6",
                marginTop: "10px",
              }}
            >
              <Stack
                direction="row"
                component={Box}
                useFlexGap
                flexWrap="wrap"
                justifyContent={"flex-end"}
                bottom={0}
                marginTop={"1rem"}
              >
                <Box marginBottom={"15px"} marginRight={"5px"} float={"right"}>
                  <CustomButton
                    color="#3a5396"
                    label="Save Duplicate Setting"
                    onClick={handleSubmit(onFormSubmit)}
                  />
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default DuplicateSetting;
