import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import GlobalInput from "../../Components/CommonComponents/GlobalInput";
import GlobalDropdown from "../../Components/CommonComponents/GlobalDropdown";
import GlobalDatePicker from "../../Components/CommonComponents/GlobalDatePicker";
import GlobalFileUpload from "../../Components/CommonComponents/GlobalFileUpload";
import RadioGroupComponent from "../../Components/CommonComponents/GlobalRadioButton";
import GlobalCheckbox from "../../Components/CommonComponents/GlobalCheckBox";
import GlobalDateRangePicker from "../../Components/CommonComponents/GlobalDateRangePicker";

const AddEditBuyerConfig = (props) => {
  const { setOpen, isOpen, defaultValues, isEditMode } = props;

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      remark: "",
      test: 6,
    },
    // resolver: yupResolver(validationSchema),
    mode: "all",
  });

  //***********FORM SUBMISSION ********//
  const onFormSubmit = (data) => {
    console.log(data);
  };

  const testArr = [
    { label: "00", value: 0 },
    { label: "01", value: 1 },
    { label: "02", value: 2 },
    { label: "03", value: 3 },
    { label: "04", value: 4 },
    { label: "05", value: 5 },
    { label: "06", value: 6 },
    { label: "07", value: 7 },
    { label: "08", value: 8 },
    { label: "09", value: 9 },
  ];
  const options = [
    { label: "Female", value: "female" },
    { label: "Male", value: "male" },
    { label: "Other", value: "other" },
  ];

  // Call setFormValue after initialization
  useEffect(() => {
    if (isEditMode && Object.keys(defaultValues)?.length) {
      setFormValue(defaultValues);
    }
  }, [isEditMode, defaultValues]);

  // Set Form Value
  const setFormValue = (data) => {
    setValue("name", data?.name, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("status", data?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("personalManager", data?.manager, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("label", data?.label, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("type", data?.type, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  return (
    <>
      <Drawer anchor={"right"} sx open={isOpen} onClose={() => setOpen(false)}>
        <Box
          sx={{
            width: "30vw",
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent={"space-between"}
          >
            <Box marginBottom={"15px"}>
              <Typography component="h6" variant="h6" fontWeight={600}>
                {(isEditMode && "Update Buyer Config") || "Set Buyer Config"}
              </Typography>
            </Box>
            <Box marginBottom={"15px"}>
              <Typography component="h7" variant="h7" fontWeight={200}>
                Clear All
              </Typography>
            </Box>
          </Stack>

          <Divider
            sx={{
              mb: "20px",
            }}
          />

          <Box component={"form"}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <GlobalInput
                  control={control}
                  name="name"
                  label="Name"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  name="status"
                  label="Status"
                  options={[]}
                  error={!!errors.status}
                  helperText={errors.status?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  name="personalManager"
                  label="Personal Manager"
                  options={[]}
                  error={!!errors.personalManager}
                  helperText={errors.personalManager?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  name="label"
                  label="Label"
                  options={[]}
                  error={!!errors.label}
                  helperText={errors.label?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  name="type"
                  label="Type"
                  options={[]}
                  error={!!errors.type}
                  helperText={errors.type?.message}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            width: "30vw",
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent={"space-between"}
            position={"fixed"}
            bottom={0}
            marginTop={"2rem"}
          >
            <Box marginBottom={"15px"}>
              <Button
                variant="outlined"
                style={{ width: "100%" }}
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </Box>
            <Box marginBottom={"15px"}>
              <Button
                type="submit"
                variant="outlined"
                onClick={handleSubmit(onFormSubmit)}
              >
                ADD
              </Button>
            </Box>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default AddEditBuyerConfig;
