import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Typography,
  Divider,
  Grid,
  DeleteForeverOutlinedIcon,
  Button,
  TextField,
} from "../../../../Collections/Imports";

import GlobalDropdown from "../../../../Components/CommonComponents/GlobalDropdown";
import GlobalInput from "../../../../Components/CommonComponents/GlobalInput";
import CustomButton from "../../../../Components/CommonComponents/GlobalButton";
import "./style.css";
import { useApiCallMutation } from "../../../../Services/ApiCallService";
import { useSelector } from "react-redux";
import { campaignModuleAPiEndPoints } from "../../../../Config/APIsEndpoints";

// test
let renderCount = 0;

function Integration() {
  const [AddIntegrationSetting, addIntegrationSettingData] =
    useApiCallMutation();
  const [GetCampaignIntegration, getCampaignIntegrationData] =
    useApiCallMutation();

  console.log("Integration setion data", addIntegrationSettingData);

  // Set Initial Value here
  const initialValues = {
    campaignIntegration: "",
    postingUrl: "",
    timeout: "",
    customField: [],
  };

  //get Campaign Id

  let currentCampaignData = JSON.parse(
    sessionStorage.getItem("currentCampaignData")
  );

  // Set Validation Schema here
  const validationSchema = Yup.object().shape({
    campaignIntegration: Yup.string().required("This Field is required"),
    postingUrl: Yup.string().required("This Field is required"),
    timeout: Yup.string()
      .required("This Field is required")
      .matches(/^\d+$/, "Only numbers are allowed in this field"),
  });

  // Set form values
  const setFormData = (data) => {
    console.log(
      "campaignIntegration------------------",
      data?.campaignIntegration
    );
    setValue("campaignIntegration", data?.campaignIntegration, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("postingUrl", data?.postingUrl, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("timeout", data?.timeout, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("customField", data?.customField, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  //***********FORM SUBMISSION ********//
  const onFormSubmit = (data) => {
    console.log("Fom DATAT", data);
    data.campaignId = currentCampaignData?.campaignId;
    AddIntegrationSetting({
      endPoint: campaignModuleAPiEndPoints.createCampaignIntegration,
      method: "POST",
      data: { data },
    });
  };

  // initialise Form here
  const {
    handleSubmit,
    control,
    register,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const integrations = [
    { label: "Redirect", id: 1, value: "1" },
    { label: "Integration 1", id: 2, value: "2" },
  ];

  // Array Fields

  const { fields, append, remove } = useFieldArray({
    control,
    name: "customField",
  });

  renderCount++;
  const watchFieldArray = watch("customField");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const removeField = (indexToRemove) => {
    remove(indexToRemove);
  };

  console.log("updated", controlledFields);

  // update form with api data
  useEffect(() => {
    if (
      getCampaignIntegrationData?.isSuccess &&
      !getCampaignIntegrationData?.data?.error
    ) {
      setFormData(getCampaignIntegrationData?.data?.data);
    }
  }, [getCampaignIntegrationData]);
  // api call to get campaign data
  useEffect(() => {
    GetCampaignIntegration({
      endPoint: campaignModuleAPiEndPoints.getCampaignIntegration,
      method: "POST",
      data: {
        data: {
          campaignId: currentCampaignData?.campaignId,
        },
      },
    });
  }, []);

  return (
    <>
      <Box sx={{ width: "50%", margin: "0 auto" }}>
        <Box marginBottom={"15px"}>
          <Typography component="h6" variant="h6" fontWeight={600}>
            Campaign Integration Setting
          </Typography>
        </Box>
        <Divider sx={{ mb: "20px" }} />
        <Box component={"form"}>
          <Grid container>
            <Grid item xs={12} sx={{ mt: "10px" }}>
              <GlobalDropdown
                control={control}
                size="small"
                name="campaignIntegration"
                label="Integration for Campaign"
                options={integrations}
                error={!!errors.campaignIntegration}
                helperText={errors.campaignIntegration?.message}
                required
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: "10px" }}>
              <GlobalInput
                control={control}
                size="small"
                name="postingUrl"
                label="Posting URL"
                error={!!errors.postingUrl}
                helperText={errors.postingUrl?.message}
                required
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: "10px" }}>
              <GlobalInput
                control={control}
                size="small"
                name="timeout"
                label="Timeout"
                error={!!errors.timeout}
                helperText={errors.timeout?.message}
                required
              />
            </Grid>
          </Grid>
          <Divider />
          <Box marginBottom={"15px"}>
            <Grid
              item
              xs={12}
              sx={{ mt: "10px" }}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Grid item sx={{ mt: "10px" }}>
                <Typography component="p" variant="p" fontWeight={400}>
                  Custom Fields
                </Typography>
              </Grid>
              <Grid item sx={{ mt: "10px" }}>
                <Button
                  style={{
                    backgroundColor: "#3a5396",
                    color: "white",
                  }}
                  type="button"
                  onClick={() =>
                    append({
                      // name: "bill",
                    })
                  }
                >
                  Add Field
                </Button>
                <Grid item sx={{ mt: "10px" }}></Grid>
              </Grid>
            </Grid>
          </Box>

          {/* <Grid item xs={12} sx={{ mt: "10px" }}> */}
          {controlledFields.map((field, index) => {
            return (
              <>
                <Grid
                  container
                  item
                  xs={12}
                  sx={{ mt: "10px" }}
                  direction={"row"}
                >
                  <Grid item pr={1} xs={5.5} sx={{ mt: "10px" }}>
                    <TextField
                      size="small"
                      label="key"
                      sx={{
                        width: "100%",
                      }}
                      {...register(`customField.${index}.key`)}
                    />
                  </Grid>
                  <Grid px={1} item xs={5.5} sx={{ mt: "10px" }}>
                    <TextField
                      size="small"
                      label="value"
                      sx={{
                        width: "100%",
                      }}
                      {...register(`customField.${index}.value`)}
                    />
                  </Grid>

                  <Grid xs={1} item sx={{ mt: "10px" }}>
                    <Button
                      style={{
                        backgroundColor: "#d32f2f",
                        color: "white",
                      }}
                      onClick={() => removeField(index)}
                    >
                      <DeleteForeverOutlinedIcon />
                    </Button>
                  </Grid>
                </Grid>
              </>
            );
          })}

          <Box marginBottom={"15px"} marginRight={"5px"} pt={3}>
            <CustomButton
              color="#3a5396"
              label="Integration"
              // label={isEditMode ? "Update" : "Add"}
              onClick={handleSubmit(onFormSubmit)}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Integration;
