import React from "react";
import { Navigate } from "react-router-dom";

const AuthenticationRoutes = ({ children }) => {
  const user =
    localStorage.getItem("userCredential") !== undefined &&
    localStorage.getItem("userCredential") !== null
      ? JSON.parse(localStorage.getItem("userCredential"))?.access_token
      : null;
  if (user) {
    return children;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default AuthenticationRoutes;
