import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Divider, Drawer, Grid, Stack, Typography } from "@mui/material";
import GlobalInput from "../../Components/CommonComponents/GlobalInput";
import GlobalDropdown from "../../Components/CommonComponents/GlobalDropdown";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useApiCallMutation } from "../../Services/ApiCallService";
import CustomButton from "../../Components/CommonComponents/GlobalButton";
import { useDispatch, useSelector } from "react-redux";
import { channelMappingModuleApiEndPoints } from "../../Config/APIsEndpoints";
import { updateSnackBarConfig } from "../../Store/Global/SnackBar";
import {
  addMappingData,
  updateMappingData,
  removeMappingData,
} from "../../Store/ChannelMappingModule";
function AddEditMapping(props) {
  const { setOpen, isOpen, defaultValues, isEditMode, setIsEditMode } = props;
  const [selectedPublisher, setSelectedPublisher] = useState(null);
  const dispatch = useDispatch();
  const [CreateMapping, createMappingData] = useApiCallMutation();
  const [UpdateMapping, updateMapping] = useApiCallMutation();
  const pubData = useSelector(
    (state) => state?.publisherData?.data?.allPubData?.publishers
  );
  const pubListing = pubData?.map((labels) => {
    return {
      label: labels.publisherName,
      value: labels.pubUniqueId,
      id: labels.id,
    };
  });
  const channelData = useSelector(
    (state) => state.ChannelData?.data?.channelData?.Channels
  );

  const filteredChannels = useMemo(() => {
    return channelData.filter(
      (channel) => channel.pubUniqueId === selectedPublisher
    );
  }, [channelData, selectedPublisher]);

  const channelListing = useMemo(() => {
    return filteredChannels.map((channel) => ({
      label: channel.channelName,
      value: channel.channelUniqueId,
    }));
  }, [filteredChannels]);

  // Set Initial Value here
  const initialValues = {
    source: "",
    pubUniqueId: "",
    channelUniqueId: "",
  };

  // Set Validation Schema here
  const validationSchema = Yup.object().shape({
    source: Yup.string()
      .min(2, "Channel Name must be at least 2 characters")
      .max(10, "Channel Name must not exceed 10 characters")
      .required("Channel Name is required"),
    channelUniqueId: Yup.string().required("Channel name is required"),
    pubUniqueId: Yup.string().required("Publisher Name is required"),
  });

  const onFormSubmit = useCallback(
    (data) => {
      if (!isEditMode) {
        CreateMapping({
          endPoint: channelMappingModuleApiEndPoints.addChannelMapping,
          method: "POST",
          data: { data },
        });
      } else {
        const modifiedData = data;
        modifiedData.mappingId = defaultValues?.mappingId;
        UpdateMapping({
          endPoint: channelMappingModuleApiEndPoints.updateChannelMapping,
          method: "POST",
          data: { data: modifiedData },
        });
      }
    },
    [isEditMode, CreateMapping, UpdateMapping]
  );

  // initialise Form here
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const statusOptions = [
    { label: "Active", value: "true" },
    { label: "Disable", value: "false" },
  ];

  const SetFormValues = (data) => {
    setValue("source", data?.source, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("pubUniqueId", data?.pubUniqueId, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("channelUniqueId", data?.channelUniqueId, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("status", data?.status == true ? "true" : "false");
  };

  useEffect(() => {
    if (Object.keys(defaultValues).length && isEditMode) {
      //reset(defaultValues);
      SetFormValues(defaultValues);
    }
  }, [defaultValues, isEditMode, reset]);

  useEffect(() => {
    const pubUniqueId = watch("pubUniqueId");
    setSelectedPublisher(pubUniqueId);
  }, [watch("pubUniqueId")]);

  useEffect(() => {
    if (updateMapping?.isSuccess && !updateMapping?.data?.error) {
      dispatch(updateMappingData(updateMapping.data?.data));
      dispatch(
        updateSnackBarConfig({
          open: true,
          color: "success",
          message: "Mapping updated successfully",
        })
      );
      setOpen(false);
      setIsEditMode(false);
    }
  }, [updateMapping]);

  useEffect(() => {
    if (createMappingData?.isSuccess && !createMappingData?.data?.error) {
      dispatch(addMappingData(createMappingData.data?.data));
      dispatch(
        updateSnackBarConfig({
          open: true,
          color: "success",
          message: "Mapping added successfully",
        })
      );
      setOpen(false);
      setIsEditMode(false);
    }
  }, [createMappingData]);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => {
        setOpen(false);
        setIsEditMode(false);
      }}
      sx={{
        [`& .MuiDrawer-paper`]: {
          boxSizing: "border-box",
          top: "15px",
          borderRadius: "16px",
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <Box
        sx={{
          pt: "1rem",
          pr: "1rem",
          pl: "1rem",
        }}
      >
        <Stack
          spacing={{ xs: 1, sm: 2 }}
          direction="row"
          useFlexGap
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <Box marginBottom={"15px"}>
            <Typography component="h6" variant="h6" fontWeight={600}>
              {isEditMode ? "Update Mapping" : "Create New Mapping"}
            </Typography>
          </Box>
          <Box marginBottom={"15px"} onClick={reset}>
            <Typography component="h7" variant="h7" fontWeight={200}>
              Clear All
            </Typography>
          </Box>
        </Stack>
        <Divider
          sx={{
            mb: "20px",
          }}
        />
        <Box component={"form"}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <GlobalInput
                control={control}
                size="small"
                name="source"
                label="Source"
                error={!!errors.source}
                helperText={errors.source?.message}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <GlobalDropdown
                control={control}
                size="small"
                name="pubUniqueId"
                label="Publisher"
                options={pubListing || []}
                error={!!errors.pubUniqueId}
                helperText={errors.pubUniqueId?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <GlobalDropdown
                control={control}
                size="small"
                name="channelUniqueId"
                label="Channel"
                options={channelListing || []}
                error={!!errors.channelUniqueId}
                helperText={errors.channelUniqueId?.message}
              />
            </Grid>
            {isEditMode && (
              <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  size="small"
                  name="status"
                  label="Status"
                  options={statusOptions || []}
                  error={!!errors.satus}
                  helperText={errors.status?.message}
                  required
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          pt: "1rem",
          pr: "1rem",
          // pl: "1rem",
        }}
      >
        <Stack
          direction="row"
          component={Box}
          useFlexGap
          flexWrap="wrap"
          justifyContent={"flex-end"}
          bottom={0}
          marginTop={"1rem"}
        >
          <Box marginBottom={"15px"} marginRight={"5px"}>
            <CustomButton
              color="#3a5396"
              // label="Add Campaign"
              label={isEditMode ? "Update" : "Add"}
              onClick={handleSubmit(onFormSubmit)}
            />
          </Box>
          <Box marginBottom={"15px"}>
            <CustomButton
              labelColor="#7a7870"
              variant="outlined"
              color="white"
              label="Close"
              onClick={() => {
                setOpen(false);
                setIsEditMode(false);
              }}
            />
          </Box>
        </Stack>
      </Box>
    </Drawer>
  );
}

export default AddEditMapping;
