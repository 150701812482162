import React from "react";
import {
  Grid,
  Chip,
  useEffect,
  useState,
  Box,
  moment,
  PageHeader,
  useNavigate,
  useLocation,
  Card,
  AddOutlined,
  AddIcon,
  ArrowBack,
} from "../../Collections/Imports";
import "@inovua/reactdatagrid-community/theme/blue-light.css";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/index.css";
import "./../BuyerListing/style.css";
import AddEditCampaign from "../../Forms/AddEditCampaign";
import Filters from "../../Components/UIComponents/Filters";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import {
  buyerModuleAPiENdPoints,
  campaignModuleAPiEndPoints,
  campaignTagsModuleAPiEndPoints,
} from "../../Config/APIsEndpoints";
import { useDispatch, useSelector } from "react-redux";
import { buyerNameOptions, updateBuyersData } from "../../Store/BuyerModule";
import AgGridComponent from "../../Components/CommonComponents/AG-Grid";

import CircularProgressBar from "../../Components/UIComponents/CircularProgressBar";
import { updateBuyersFilterData } from "../../Store/BuyerModule/filterSession";
import { Outlet } from "react-router-dom";
import { updateCampaignRowData } from "../../Store/CampaignModule/currentCampaignRowData";
import CampaignFilters from "../../Forms/CampaignFilters";
import {
  CampaignNameOptions,
  CampaignTagOptions,
  updateCampaignsData,
} from "../../Store/CampaignModule";
import { updateCampaignFilterData } from "../../Store/CampaignModule/filterSession";

const CampaignListingPage = () => {
  const navigate = useNavigate();
  let location = useLocation();
  // Form pagination handler
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [itemPerIndex, setItemPerIndex] = useState(100);
  const perPageCountOptions = [
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "1000", value: 1000 },
  ];
  const statusOptions = [
    { label: "Active", value: "true" },
    { label: "Inactive", value: "false" },
  ];

  const typeOptions = [
    { label: "Redirect", value: "redirect" },
    { label: "Silence", value: "silence" },
  ];

  const [rowSelected, setRowSelected] = useState({});
  const [campainName, setCampainName] = useState("");
  const [isOpenAddEditBox, setIsOpenAddEditBox] = useState(false);
  const [isOpenCampaignFilters, setIsOpenCampaignFilter] = useState(false);
  const [isOpenAddEditBuyerConfig, setIsOpenAddEditBuyerConfig] =
    useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [GetAllCampaignForOpt, AllCampaignDataForOpt] = useApiCallMutation();
  const [defaultCampaign, setDefaultCampaign] = useState(false);

  const buyersOptions = useSelector(
    (state) => state?.buyersData?.buyerAllOptions?.buyerNameOpt
  );

  const dispatch = useDispatch();
  const TableData = useSelector((state) => state.CampaignData?.tableData);
  const previousFilterData = useSelector(
    (state) => state?.campaignDataFilterSession?.filterData
  );
  // const labelOptions = useSelector((state) => state?.labelOptions?.data);
  // const typeOptions = useSelector((state) => state?.typeOptions?.data);
  const TagOptions = useSelector(
    (state) => state?.CampaignData?.campaignAllOptions?.CampaignTagOpt
  );

  const campaignOptionsName = useSelector(
    (state) => state?.CampaignData?.campaignAllOptions?.CampaignNameOpt
  );

  //Api Initialization
  const [GetAllCampaign, AllCampaigns] = useApiCallMutation();

  const [UpdateCampaign, UpdateCampaignData] = useApiCallMutation();

  // console.log("CampaignData", AllCampaigns);

  const currentDate = new Date("July 01, 2020");

  // useEffect(() => {
  //   return () => {
  //     dispatch(updateBuyersFilterData({}));
  //   };
  // }, []);

  useEffect(() => {
    if (location?.pathname === "/campaigns") {
      setCampainName("");
    } else {
      const currentCampData = JSON.parse(
        sessionStorage.getItem("currentCampaignData")
      );
      setCampainName(currentCampData.campaignName);
    }
  }, [location]);
  console.log("previousFilterData-------------------", previousFilterData);
  useEffect(() => {
    GetAllCampaign({
      endPoint: campaignModuleAPiEndPoints.getAllCampaigns,
      method: "POST",
      data: {
        data: {
          index: paginationIndex,
          itemPerIndex: itemPerIndex,
          buyerId: previousFilterData?.buyerId || ["All"],
          campaignName: previousFilterData?.campaignName || ["All"],
          campaignTagsId: previousFilterData?.campaignTagsId || ["All"],
          labelId: previousFilterData?.labelId || ["All"],
          campaignType: previousFilterData?.campaignType || ["All"],
          status: previousFilterData?.status || ["All"],
          fromDate: moment(previousFilterData?.fromDate).toISOString(),
          toDate:
            moment(previousFilterData?.toDate).toISOString() == ""
              ? moment(selectedDateFilter?.endDate).toISOString()
              : moment(previousFilterData?.toDate).toISOString(),
        },
      },
    });
  }, [paginationIndex, itemPerIndex, previousFilterData]);

  useEffect(() => {
    if (
      AllCampaigns?.isSuccess &&
      AllCampaigns?.data?.data?.campaigns?.length > 0
    ) {
      // debugger;
      const campaignDataList = [...AllCampaigns?.data?.data?.campaigns];
      const tableData = {
        data: campaignDataList,
        headers: AllCampaigns?.data?.data?.headers,
      };

      dispatch(updateCampaignsData(tableData));
    } else {
      dispatch(updateCampaignsData([]));
    }
  }, [AllCampaigns]);

  // get Preselected Filter label
  const getFilterLabel = (filterType, options) => {
    if (options?.length === 1) {
      return (
        <span>
          <Chip label={options[0]?.label} sx={{ m: "2px" }} size="small" />
        </span>
      );
    } else if (options?.length > 1) {
      return (
        <>
          <span>
            <Chip label={options[0]?.label} sx={{ m: "2px" }} size="small" />
          </span>
          <span
            style={{ cursor: "pointer", color: "#000", paddingLeft: "4px" }}
          >
            {`+${Number(options?.length - 1)}`}
          </span>
        </>
      );
    } else {
      return (
        <span>
          <Chip label={"All"} sx={{ m: "2px" }} size="small" />
        </span>
      );
    }
  };

  // set Preselected Filter label
  const setFilterLabel = (filterType) => {
    console.log("o000000000000000000000000000000000000", filterType);
    switch (filterType) {
      case "campaignName":
        if (previousFilterData?.campaignName != "") {
          // debugger;
          const campaignoptions = campaignOptionsName?.filter((opt) =>
            previousFilterData?.campaignName?.find((val) => val == opt.value)
          );

          return getFilterLabel("campaignName", campaignoptions);
        }

        break;
      case "buyerName":
        const buyersoptions = buyersOptions?.filter((opt) =>
          previousFilterData?.buyerId?.find((val) => val == opt.value)
        );

        return getFilterLabel("buyerName", buyersoptions);
        break;

      case "status":
        const statusoptions = statusOptions?.filter(
          (opt) => previousFilterData?.status == opt.value
        );
        return getFilterLabel("status", statusoptions);
        break;

      case "tag":
        if (TagOptions?.length > 0) {
          const tagoptions = TagOptions?.filter((opt) =>
            previousFilterData?.campaignTagsId?.find((val) => val == opt.value)
          );
          return getFilterLabel("label", tagoptions);
        }
        break;

      case "type":
        const typeoptions = typeOptions?.filter(
          (opt) => previousFilterData?.campaignType == opt.value
        );

        return getFilterLabel("type", typeoptions);

        break;
    }
  };

  const toggleDrawer = (ele) => {
    // debugger;
    ele === "created"
      ? setDefaultCampaign("created")
      : ele === "type"
      ? setDefaultCampaign("type")
      : ele === "campaignName"
      ? setDefaultCampaign("campaignName")
      : ele === "buyerName"
      ? setDefaultCampaign("buyerName")
      : ele === "tag"
      ? setDefaultCampaign("tag")
      : ele === "status"
      ? setDefaultCampaign("status")
      : setDefaultCampaign(false);

    setIsOpenCampaignFilter((prev) => !prev);
  };

  const [selectedDateFilter, setSelectedDateFilter] = useState({
    startDate: new Date("July 01, 2020"),
    endDate: new Date(),
    key: "selection",
  });

  // Handle Grid Button ACtion
  const handleActions = (previousData, action) => {
    console.log("Prives DATA0---0--0-0-0-0-0-0-00-0-", previousData);

    switch (action) {
      case "EDIT":
        setRowSelected(previousData);
        setIsEditMode(true);
        setIsOpenAddEditBox(true);
        break;
      case "CONFIG":
        setRowSelected(previousData);
        sessionStorage.setItem(
          "currentCampaignData",
          JSON.stringify(previousData)
        );
        dispatch(updateCampaignRowData(previousData));
        const currentCampData = JSON.parse(
          sessionStorage.getItem("currentCampaignData")
        );
        setCampainName(currentCampData.campaignName);
        navigate(String(previousData?.uniqueId));

        break;
      case "Status":
        const data = {
          id: previousData?.campaignId,
          campaignName: previousData?.campaignName,
          timezone: previousData?.timezone,
          campaignType: previousData?.campaignType,
          minPrice: previousData?.minPrice,
          campaignIntegrations: previousData?.campaignIntegrations,
          status: previousData?.status,
        };

        UpdateCampaign({
          endPoint: campaignModuleAPiEndPoints.updateCampaign,
          method: "POST",
          data: { data },
        });
        break;
    }
  };

  const handlePageChange = (data) => {
    setPaginationIndex(data);
  };
  const handleRowsPerPageChange = (data) => {
    setItemPerIndex(data);
    setPaginationIndex(0);
  };

  //options Apis
  //Get All Cam For Name Options

  useEffect(() => {
    GetAllCampaignForOpt({
      endPoint: campaignModuleAPiEndPoints.getAllCampaigns,
      method: "POST",
      data: {
        data: {
          index: "All",
          itemPerIndex: "All",
          campaignName: ["All"],
          buyerNames: ["All"],
          campaignTagsId: ["All"],
          campaignType: ["All"],
          labelIds: ["All"],
          buyerId: ["All"],
          typeIds: ["All"],
          status: ["All"],
          fromDate: currentDate,
          toDate: new Date(),
        },
      },
    });
  }, []);

  useEffect(() => {
    if (
      AllCampaignDataForOpt?.isSuccess &&
      AllCampaignDataForOpt?.data?.data?.campaigns?.length > 0
    ) {
      const campaignOptions = AllCampaignDataForOpt?.data?.data?.campaigns.map(
        (campaign) => {
          return {
            label: campaign?.campaignName,
            value: campaign?.campaignName,
          };
        }
      );
      dispatch(CampaignNameOptions(campaignOptions));
    }
  }, [AllCampaignDataForOpt]);

  // Get Campaign Tags
  const GetCampaignTagList = useGetApiCallQuery({
    endPoint: campaignTagsModuleAPiEndPoints.getCampaignsTags,
    method: "GET",
  });
  // Get Campaign Tags api response
  useEffect(() => {
    if (GetCampaignTagList?.isSuccess && GetCampaignTagList?.data?.data) {
      const campaignTagOptions =
        GetCampaignTagList?.data?.data?.campaignTags?.map((ctag) => {
          return {
            label: ctag?.name,
            value: ctag?.id,
          };
        });

      dispatch(CampaignTagOptions(campaignTagOptions));
    }
  }, [GetCampaignTagList]);

  //Get All Buyer

  const [GetAllbuyers, AllbuyersData] = useApiCallMutation();

  useEffect(() => {
    GetAllbuyers({
      endPoint: buyerModuleAPiENdPoints.getAllbuyers,
      method: "POST",
      data: {
        data: {
          index: "All",
          itemPerIndex: "All",
          buyerNames: ["All"],
          labelIds: ["All"],
          typeIds: ["All"],
          status: "all",
          fromDate: currentDate,
          toDate: new Date(),
        },
      },
    });
  }, []);

  useEffect(() => {
    if (AllbuyersData?.isSuccess && AllbuyersData?.data?.data?.buyers?.length) {
      const buyersOptions = AllbuyersData?.data?.data?.buyers.map((buyer) => {
        return { label: buyer?.buyerName, value: buyer?.id };
      });
      dispatch(buyerNameOptions(buyersOptions));
    }
  }, [AllbuyersData]);

  const ClearFilters = () => {
    const filterData = {
      status: "All",
      campaignName: ["All"],
      campaignTagId: ["All"],
      campaignType: "All",
      timezone: ["All"],
      minPrice: ["All"],
      fromDate: selectedDateFilter?.startDate,
      toDate: moment().endOf("day").toDate(),
    };

    dispatch(updateCampaignFilterData(filterData));
    // reset();
  };

  return (
    <>
      <Box>
        <PageHeader
          title={campainName || "Campaigns"}
          helpText={
            "Campaigns are used to qualify incoming publishers’ traffic against buyers' requirements. Here are some ways by which this is accomplished: Global Settings, Campaign Offer, Duplicate Checks, Do Not Present Lists, Schedules, Integrations, Notifications, Reject Groups, Cap Groups, Publisher Filters, Traffic Quality, Filters, Test Leads."
          }
          backIcon={location?.pathname === "/campaigns" ? null : <ArrowBack />}
          onBack={() => navigate(-1)}
        />

        {location?.pathname === "/campaigns" && (
          <>
            {/* Filters Start*/}
            <Box sx={{ pl: "0px", pr: "10px" }} mt={3} mb={1}>
              <Grid container>
                <Filters
                  title="Name :"
                  onClick={() => toggleDrawer("campaignName")}
                >
                  {setFilterLabel("campaignName")}
                </Filters>
                <Filters
                  title="Buyer :"
                  onClick={() => toggleDrawer("buyerName")}
                >
                  {setFilterLabel("buyerName")}
                </Filters>
                <Filters
                  title="Status :"
                  onClick={() => toggleDrawer("status")}
                >
                  {setFilterLabel("status")}
                </Filters>
                <Filters
                  title="Created :"
                  onClick={() => toggleDrawer("created")}
                >
                  <Box>
                    <span style={{ fontSize: "14px" }}>
                      <Chip
                        label={moment(
                          previousFilterData?.fromDate
                            ? previousFilterData?.fromDate
                            : selectedDateFilter?.startDate
                        ).format("ll")}
                        sx={{ m: "2px" }}
                        size="small"
                      />
                    </span>{" "}
                    {"-"}{" "}
                    <span style={{ fontSize: "14px" }}>
                      <Chip
                        label={moment(
                          previousFilterData?.toDate
                            ? previousFilterData?.toDate
                            : selectedDateFilter?.endDate
                        ).format("ll")}
                        sx={{ m: "2px" }}
                        size="small"
                      />
                    </span>
                  </Box>
                </Filters>
                <Filters title="Tag :" onClick={() => toggleDrawer("tag")}>
                  {setFilterLabel("tag")}
                </Filters>
                <Filters title="Type :" onClick={() => toggleDrawer("type")}>
                  {setFilterLabel("type")}
                </Filters>
                <Filters
                  icons={<AddIcon />}
                  title="Filters"
                  onClick={() => toggleDrawer("filters")}
                ></Filters>
                <Filters title="Clear All" onClick={ClearFilters}></Filters>
              </Grid>
            </Box>
            {/* Filters End*/}

            {AllCampaigns.status === "isUninitialized" && (
              <CircularProgressBar />
            )}
            {AllCampaigns.status !== "isUninitialized" && (
              <Box>
                {AllCampaigns.isLoading && <CircularProgressBar />}
                {!AllCampaigns.isLoading && (
                  <AgGridComponent
                    rowData={TableData?.data || []}
                    columnDefs={TableData.headers || []}
                    actions={["EDIT", "CONFIG"]}
                    performAction={handleActions}
                    totalPageCount={AllCampaigns.data?.data?.total}
                    paginationIndex={paginationIndex}
                    itemPerIndex={itemPerIndex}
                    perPageCountOptions={perPageCountOptions}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    rowMode
                    addAction={setIsOpenAddEditBox}
                    AddButtonName={"Add New Campaign"}
                    addActionIcon={<AddOutlined />}
                    downlodBtn={true}
                    tableName={"Campaigns"}
                  />
                )}
              </Box>
            )}

            {isOpenAddEditBox && (
              <AddEditCampaign
                defaultValues={rowSelected}
                isOpen={isOpenAddEditBox}
                setOpen={setIsOpenAddEditBox}
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
              />
            )}

            {isOpenCampaignFilters && (
              <CampaignFilters
                defaultValues={rowSelected}
                isOpen={isOpenCampaignFilters}
                setOpen={setIsOpenCampaignFilter}
                isEditMode={isEditMode}
                defaultCampaign={defaultCampaign}
                ClearFilters={ClearFilters}
              />
            )}
          </>
        )}
        <div>
          <Outlet />
        </div>
      </Box>
    </>
  );
};
export default CampaignListingPage;
